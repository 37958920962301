import React from 'react';
import { useTable, useFilters } from 'react-table';
import { FaCheck, FaTimes, FaTruck, FaSpinner } from 'react-icons/fa';

const RecentOrders = ({ data }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: 'Product',
        accessor: 'product', // Product name
      },
      {
        Header: 'Customer Name',
        accessor: 'customerName', // Customer name
      },
      {
        Header: 'Date',
        accessor: 'date', // Order date
      },
      {
        Header: 'Status',
        accessor: 'status', // Order status
        Cell: ({ value }) => {
          let icon, color;
          switch (value.toLowerCase()) {
            case 'delivered':
              icon = <FaCheck />;
              color = 'text-green-600';
              break;
            case 'cancelled':
              icon = <FaTimes />;
              color = 'text-red-600';
              break;
            case 'shipped':
              icon = <FaTruck />;
              color = 'text-blue-600';
              break;
            case 'in process':
              icon = <FaSpinner className="animate-spin" />;
              color = 'text-yellow-600';
              break;
            case 'refunded':
              icon = <FaTimes />;
              color = 'text-orange-600';
              break;
            default:
              icon = null;
              color = 'text-gray-600';
          }
          return (
            <span className={`flex items-center ${color}`}>
              {icon} <span className="ml-2 capitalize">{value}</span>
            </span>
          );
        },
      },
      {
        Header: 'Amount',
        accessor: 'amount', // Order amount
        Cell: ({ value }) => `$${value}`, // Format amount as currency
      },
      {
        Header: 'Image',
        accessor: 'profilePicture', // Product image or fallback avatar
        Cell: ({ row }) => {
          const imageUrl =
            row.original.profilePicture ||
            'https://avatar.iran.liara.run/public/48'; // Default avatar if profilePicture is null
          return (
            <img
              src={imageUrl}
              alt={row.original.customerName}
              className="w-10 h-10 rounded-lg object-cover"
            />
          );
        },
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data }, useFilters);

  return (
    <div className="bg-white shadow-md rounded-lg p-4">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-gray-700 text-lg font-semibold">Recent Orders</h2>
      </div>
      <table {...getTableProps()} className="w-full table-auto">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} className="text-left bg-gray-100">
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()} className="px-4 py-2 text-sm font-semibold">
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className="border-b">
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()} className="px-4 py-2 text-sm">
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default RecentOrders;
