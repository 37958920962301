import React, { useEffect, useMemo, useState } from "react";
import { useTable } from "react-table";
import Modal from "react-modal";
import { getQuestionsForAdmin, replyToQuestion } from "../../Hooks/faqHooks"; // Import the API function

const Questionares = ({ userId }) => {
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [answer, setAnswer] = useState("");

  const itemsPerPage = 10; // Same as backend `limit`

  const columns = useMemo(
    () => [
      {
        Header: "Product Name",
        accessor: (row) => row.productId?.productName || "N/A", // Handle nested productName
      },
      {
        Header: "Customer Name",
        accessor: (row) => row.createdBy?.name || row.guestName, // Handle nested name
      },
      {
        Header: "Question",
        accessor: "question", // Direct mapping
      },
      {
        Header: "Date & Time",
        accessor: "createdAt", // Direct mapping for createdAt
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ row }) => (
          <button
            className="text-blue-500 px-4 py-2 rounded"
            onClick={() => openModal(row.original)}
          >
            See Details
          </button>
        ),
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  // Fetch data from the backend
  const fetchQuestions = async (page) => {
    const response = await getQuestionsForAdmin(userId, page, itemsPerPage);
    if (response.success) {
      setData(response.data.faqs);
      console.log(response.data.faqs);
      setTotalPages(response.data.totalPages);
      setCurrentPage(response.data.currentPage);
    } else {
      console.error(response.message);
    }
  };

  useEffect(() => {
    fetchQuestions(currentPage);
  }, [currentPage]);

  const openModal = (questionData) => {
    setSelectedQuestion(questionData);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedQuestion(null);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const goToPage = (page) => {
    setCurrentPage(page);
  };

  const handleSubmitAnswer = async () => {
    if (!answer.trim()) {
      console.log("Answer cannot be empty.");
      return;
    }

    try {
      // Call the replyToQuestion API function
      const response = await replyToQuestion({
        questionId: selectedQuestion?._id,
        answer: answer,
      });

      if (response.success) {
        console.log("Answer submitted successfully!");
        console.log("Question:", response.question);

        // Clear the input field and close the modal (if needed)
        setAnswer("");
        closeModal();
      } else {
        console.error("Failed to submit answer:", response.message);
      }
    } catch (error) {
      console.error("Error submitting answer:", error.message);
    }
  };

  return (
    <div className="p-4">
      <h1 className="text-center text-2xl mb-6">Questions</h1>
      <table
        {...getTableProps()}
        className="min-w-full bg-white text-black shadow-lg rounded"
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} className="border-b">
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  className="py-2 px-4 text-left bg-gray-100"
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className="border-b">
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()} className="py-2 px-4">
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* Pagination Controls */}
      <div className="flex justify-between items-center mt-4">
        <p>
          Showing page {currentPage} of {totalPages}
        </p>
        <div className="flex space-x-2">
          <button
            onClick={handlePrevPage}
            disabled={currentPage === 1}
            className={`w-10 h-10 flex justify-center items-center rounded border ${
              currentPage === 1
                ? "bg-gray-300 cursor-not-allowed"
                : "bg-white border-gray-400"
            }`}
          >
            Prev
          </button>
          {[...Array(totalPages)].map((_, page) => (
            <button
              key={page + 1}
              onClick={() => goToPage(page + 1)}
              className={`w-10 h-10 flex justify-center items-center rounded border ${
                currentPage === page + 1
                  ? "bg-gray-200 border-gray-400"
                  : "bg-white border-gray-400"
              }`}
            >
              {page + 1}
            </button>
          ))}
          <button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
            className={`w-10 h-10 flex justify-center items-center rounded border ${
              currentPage === totalPages
                ? "bg-gray-300 cursor-not-allowed"
                : "bg-white border-gray-400"
            }`}
          >
            Next
          </button>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Complete Question"
        className="bg-white p-8 w-96 h-[500px] max-w-full mx-auto rounded-lg shadow-2xl border-gray-300"
        overlayClassName="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50"
      >
        <h2 className="text-2xl font-semibold mb-6 text-center">
          Complete Question
        </h2>
        {selectedQuestion && (
          <div>
            <p className="mb-4 text-sm">
              <strong className="block mb-1">Question:</strong>
              <span className="text-gray-700">
                {selectedQuestion?.question || "N/A"}
              </span>
            </p>
            <p className="mb-4 text-sm">
              <strong className="block mb-1">Product Name:</strong>
              <span className="text-gray-700">
                {selectedQuestion?.productId?.productName || "N/A"}
              </span>
            </p>
            <p className="mb-4 text-sm">
              <strong className="block mb-1">Customer Name:</strong>
              <span className="text-gray-700">
                {selectedQuestion?.createdBy?.name ||
                  selectedQuestion.guestName}
              </span>
            </p>

            {selectedQuestion?.answer ? (
              // If the question is already answered, show the answer
              <div className="mb-4">
                <p className="text-sm">
                  <strong className="block mb-1">Answer:</strong>
                  <span className="text-gray-700">
                    {selectedQuestion.answer}
                  </span>
                </p>
              </div>
            ) : (
              // If the question is not answered, show the textarea
              <div className="mb-6">
                <label htmlFor="answer" className="block font-medium mb-2">
                  Your Answer:
                </label>
                <textarea
                  id="answer"
                  rows="4"
                  className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                  placeholder="Type your answer here..."
                  onChange={(e) => setAnswer(e.target.value)}
                ></textarea>
                <button
                  onClick={() => handleSubmitAnswer()}
                  className="w-full bg-black text-white py-2 rounded-lg hover:bg-gray transition-colors mt-4"
                >
                  Submit Answer
                </button>
              </div>
            )}
          </div>
        )}
      </Modal>
    </div>
  );
};

export default Questionares;
