import React from 'react';

export default function ProhibitedConduct() {
  return (
    <div className="min-h-screen p-4">
      <div className="max-w-4xl mx-auto bg-white shadow-lg rounded-lg p-6">
        <h1 className="text-3xl font-bold mb-6 text-gray-800">Prohibited Conduct</h1>
        
        <p className="mb-4 text-lg text-gray-700">
          The Wedding Marketplace may not be used in connection with any product, service, transaction, or activity that, in general:
        </p>
        
        <ul className="list-disc list-inside mb-4 text-lg text-gray-700">
          <li>Violates any law or government regulation, or promotes or facilitates such actions by third parties.</li>
          <li>Is fraudulent, deceptive, unfair, or predatory.</li>
          <li>Causes or threatens to damage The Wedding Marketplace's reputation.</li>
          <li>Violates the terms of any bank, card, or electronic funds transfer network.</li>
          <li>Results in or creates a significant risk of chargebacks, penalties, damages, or other harm or liability.</li>
        </ul>
        
        <p className="mb-4 text-lg text-gray-700">
          The Wedding Marketplace has developed a Prohibited Conduct Policy to prevent abuse as outlined above and to protect users from conduct that may harm their experience on The Wedding Marketplace or abuse any features of the service.
        </p>
        
        <p className="mb-4 text-lg text-gray-700">
          By using The Wedding Marketplace, users agree to follow the rules and guidelines included in the Help Center articles and in the Terms and Policies. Users agree not to engage in the following Prohibited Conduct:
        </p>
        
        <ul className="list-disc list-inside mb-4 text-lg text-gray-700">
          <li>Using the service for any illegal purpose or in violation of any local, state, national, or international law or regulation.</li>
          <li>Posting, uploading, or distributing any User Content or other content that is unlawful, defamatory, libelous, inaccurate, or objectionable.</li>
          <li>Interfering with security-related features of the service or the operation of the service.</li>
          <li>Performing fraudulent activities, including impersonation, false affiliations, or unauthorized access to accounts.</li>
        </ul>
        
        <p className="mb-4 text-lg text-gray-700">
          The Wedding Marketplace may take various actions on an account that has violated the policies or if prohibited conduct is suspected, including verification processes, removal of items, account suspension, and holding funds after a suspension or termination.
        </p>
        
        <p className="text-gray-700">
          The Wedding Marketplace restricts users from participating in various actions related to transactions, accounts, listings, solicitations, and other behaviors that are outlined in the Prohibited Conduct list. Users are advised to contact The Wedding Marketplace if they encounter or are solicited for any prohibited actions.
        </p>
      </div>
    </div>
  );
}
