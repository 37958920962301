import React from 'react';
import weddinglogo from '../../assets/weddinglogo.png';

export default function BuyerSellerAgreement() {
  return (
    <div className="bg-white">
      {/* Title Section */}
      <section className="max-w-7xl mx-auto py-16 px-4 text-gray-900">
        <h1 className="text-3xl font-bold mb-6">Buyer/Seller Agreement</h1>
        
        {/* Content Section */}
        <p className="text-base sm:text-lg mb-6">
          This is a binding contract between the buyer/seller. TWM acts as a “host” otherwise known as a Marketplace Facilitator site between the two parties; the buyer and seller. Once an item has been purchased by the buyer the monetary transaction is taken place by Stripe or Paypal. TWM is only the host for these payment methods and is not responsible for user or technical error. TWM will step in as a third party mediator ONLY if the item is misrepresented in photos or description. Please make sure to ask for additional photos if you are unsure of color or size. TWM is not responsible for purchases that do not look the same color on your computer, as each computer setting is different. If there is an issue of misrepresentation, the buyer should contact TWM via the “Contact” tab and a member of the team will reach out to you within 24 hours. Shipping is set up by the seller and any all lost or undelivered items are the responsibility of the seller and shipper, i.e. FedEx, USPS, Rover etc. TWM cannot mediate for lost, damages or undelivered items. All communication and listings are monitored by TWM for legal purposes in the case of misrepresented items or harassment issues. TWM does its best to verify all sellers and their identification through our sign up process. Although, due diligence is done it is never a guarantee that all bots and scammers are identified. As a host site, sales tax will be paid to TWM and will be paid by the host to each of the 50 states. For information on your states sales tax you can visit The Marketplace Facilitator State Guidance at <a href="https://www.streamlinedsalestax.org/for-businesses/marketplace-facilitator" className="text-blue-500 underline">https://www.streamlinedsalestax.org/for-businesses/marketplace-facilitator</a>.
        </p>

        <p className="text-base sm:text-lg mb-6">
          Although, this is just a reference all up to date sales tax collections should be verified.
        </p>

        <p className="text-base sm:text-lg mb-6">
          The seller is responsible to claim any and all profits made through TWM. You will find all sales and profits made in the Seller portion of the website. Each year, the total profits from Sellers will be available in the seller’s profile here. Sellers are responsible for filing their own W9 or tax related forms and TWM will not be responsible for sending out individual tax forms. If the seller has any questions in regards to profits made TWM is available to help answer any questions. If a seller has reached the monetary threshold TWM will send out a 1099k to each individual or business via email or USPS. This took effect on Jan 1, 2022. Most questions can be answered here on the IRS website. <a href="https://www.streamlinedsalestax.org/for-businesses/marketplace-facilitator" className="text-blue-500 underline">https://www.streamlinedsalestax.org/for-businesses/marketplace-facilitator</a>
        </p>
      </section>
      <div className="flex justify-center items-center my-10">
        <div className="w-full border-2 border-t border-gray-300 mx-5" />
        <img src={weddinglogo} alt="" className='w-40 h-40' />
        <div className="w-full border-2 border-t border-gray-300 mx-5" />
      </div>
    </div>
  );
}
