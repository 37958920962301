import React, { useState, useEffect } from 'react';
import weddinglogo from '../../assets/weddinglogo.png';


const TestimonialSection = () => {
    const testimonials = [
        {
            id: 1,
            text: "The Wedding Marketplace was the perfect place to sell all of my leftover wedding items. It is so simple to use and does not cost anything to list your items. I sold all of my signs, centerpieces and aisle runner.",
        },
        {
            id: 2,
            text: "What a genius website! I thought going into my wedding that all of the personalized items I bought were going to be used one time. Boy, was I wrong. I had no idea so many people were looking to reuse items! Any why not?! They are only used for one day! Thanks to The Wedding Marketplace I recouped a generous amount of my money and helped another couple!",
        },
        {
            id: 3,
            text: "5 stars to this site! I had a garage full of my wedding décor that I could not get rid of. I posted them on TWM and within one week I had my garage back and money in my pocket. What a wonderful and easy website!",
        },
        {
            id: 4,
            text: "I didn’t go onto the website to look for anything in particular, however I sure did find a lot of items to check off my wedding list. It was so nice to have been able to get so many different items in one place",
        },
        {
            id: 5,
            text: "Love the ease of purchasing items for my April wedding centerpieces",
        },
        {
            id: 6,
            text: "I wish I had found this site sooner. A total gem that makes perfect sense in todays world. Who needs to buy new when there are literally thousands of couples getting married everyday. I had a specific color them for my wedding (black/purple/rose gold) After searching the typical retail stores I hit the jackpot with TWM and bought all of my décor in one day! So easy! And the best part was Uber delivered some of my locally purchased items to my door! WOW!",
        },
        // Add more testimonials as needed
    ];

    const [selectedTestimonial, setSelectedTestimonial] = useState(0);
    const [fadeIn, setFadeIn] = useState(true);

    // Function to handle manual change of testimonial
    const handleChange = (index) => {
        setFadeIn(false); // Trigger fade-out animation
        setTimeout(() => {
            setSelectedTestimonial(index);
            setFadeIn(true); // Trigger fade-in animation
        }, 500); // Duration of fade-out
    };

    // Automatically change testimonial every 10 seconds
    useEffect(() => {
        const interval = setInterval(() => {
            setFadeIn(false);
            setTimeout(() => {
                setSelectedTestimonial((prev) => (prev + 1) % testimonials.length);
                setFadeIn(true);
            }, 500);
        }, 5000); // Change every 10 seconds

        return () => clearInterval(interval); // Cleanup the interval on component unmount
    }, [testimonials.length]);

    return (
        <div className="py-10 bg-white px-5 text-center">
            {/* Testimonial Header */}
            <h2 className="text-3xl font-semibold font-montserrat text-gray-800 mb-4">The Wedding Marketplace Shopper Reviews</h2>

            {/* Testimonial Text with Fade Animation */}
            <p
                className={`text-lg w-full md:w-[70%] text-center mx-auto text-gray-500 mb-6 transition-opacity duration-500 ${fadeIn ? 'opacity-100' : 'opacity-0'}`}
            >
                {testimonials[selectedTestimonial].text}
            </p>

            {/* Radio Button Navigation */}
            <div className="flex justify-center space-x-2 mb-8">
                {testimonials.map((_, index) => (
                    <input
                        key={index}
                        type="radio"
                        name="testimonial"
                        checked={selectedTestimonial === index}
                        onChange={() => handleChange(index)}
                        className={`w-3 h-3 rounded-full border-2 border-black focus:ring-0 ${selectedTestimonial === index ? 'bg-black' : 'bg-white'}`}
                    />
                ))}
            </div>


            <div className="flex justify-center items-center mt-10">
                <div className="w-full border-2 border-t border-gray-300 mx-5" />
                <img src={weddinglogo} alt="" className='w-40 h-40' />
                <div className="w-full border-2 border-t border-gray-300 mx-5" />
            </div>
        </div>
    );
};

export default TestimonialSection;
