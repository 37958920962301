import React from 'react';
import TestimonialSection from '../../components/Home/TestimonialSection';

export default function HowToBuy() {
    return (
        <div className="bg-white pt-10">
            {/* Hero Section */}
            <section className="relative">
                <img
                    src="https://res.cloudinary.com/dlhwfesiz/image/upload/v1729535772/miss1_q3g3w8.jpg"
                    alt="Wedding Marketplace"
                    className="w-full h-[50vh] sm:h-[60vh] lg:h-[70vh] object-cover"
                />
                <div className="absolute inset-0 bg-black bg-opacity-50"></div>
                <div className="absolute inset-0 flex flex-col items-center justify-center text-white text-center z-10 px-4">
                    <h1 className="text-3xl sm:text-4xl lg:text-5xl font-bold mb-4">
                        HOW TO SHOP AT THE WEDDING MARKETPLACE
                    </h1>
                    <p className="text-lg sm:text-xl">Start shopping for your event today.</p>
                </div>
            </section>

            {/* Info Section */}
            <div className="bg-white text-white py-16">
                <hr className="mb-4" />
                <div
                    className="relative bg-cover bg-center py-10"
                    style={{ backgroundImage: `url(https://res.cloudinary.com/dlhwfesiz/image/upload/v1730209071/AdobeStock_191371230_rkpsxd.jpg)` }}
                >
                    {/* Blackish overlay */}
                    <div className="absolute inset-0 bg-black opacity-50"></div>

                    {/* Content inside the overlay */}
                    <div className="relative z-10 text-center">
                        <h1 className="text-3xl sm:text-4xl font-semibold mb-8">IT IS SO EASY TO BUY!</h1>
                        <div className="py-5">
                            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-5xl mx-auto">
                                {/* Card 1 - Browse */}
                                <div className="text-white bg-[#333333] rounded-lg p-8 shadow-lg">
                                    <div className="flex justify-center mb-4">
                                        <span className="text-5xl">🔍</span>
                                    </div>
                                    <h2 className="text-center text-xl font-semibold mb-4">BROWSE</h2>
                                    <p className="text-center">Color | Condition | Category</p>
                                </div>

                                {/* Card 2 - Buy */}
                                <div className="text-white bg-[#333333] rounded-lg p-8 shadow-lg">
                                    <div className="flex justify-center mb-4">
                                        <span className="text-5xl">💳</span>
                                    </div>
                                    <h2 className="text-center text-xl font-semibold mb-4">BUY</h2>
                                    <p className="text-center">Purchase your items</p>
                                </div>

                                {/* Card 3 - Celebrate */}
                                <div className="text-white bg-[#333333] rounded-lg p-8 shadow-lg">
                                    <div className="flex justify-center mb-4">
                                        <span className="text-5xl">🙌</span>
                                    </div>
                                    <h2 className="text-center text-xl font-semibold mb-4">CELEBRATE</h2>
                                    <p className="text-center">It’s your day!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Shop by Category Section */}
            <div className="bg-white py-16 px-5 sm:px-10">
                <div className="max-w-7xl mx-auto grid grid-cols-1 lg:grid-cols-3 gap-8 items-center">
                    {/* Text Section */}
                    <div className="lg:col-span-2">
                        <h2 className="text-pink-500 text-3xl md:text-4xl font-bold mb-6">SHOP BY CATEGORY</h2>
                        <p className="text-lg mb-6">
                            When you know what you are looking for to make your special day perfect, our easy-to-navigate categories will help you find it quickly and at the best price.
                        </p>
                        <p className="text-lg mb-4">Here are some tips for getting exactly what you want:</p>
                        <p className="text-lg mb-4">
                            <strong>1. Look Carefully at the Photos</strong> <br />
                            Make sure you compare the photos of your desired item with the description. View the item from all available angles and pay special attention to any areas the seller points out, such as unique details or imperfections.
                        </p>
                        <p className="text-lg mb-4">
                            <strong>2. Consider the Description</strong> <br />
                            A clear and honest description that matches the photos is a great way to understand what you are getting when you buy. Pay close attention to color, condition, brand, dimensions and other information to ensure the items are what you need, will fit where needed and are in a satisfactory condition.
                        </p>
                        <p className="text-lg mb-4">
                            <strong>3. Set Your Budget</strong> <br />
                            Fair pricing is the key to sustainable buying and selling. You can easily see if your favorite item is priced fairly by searching similar listings in the category. If you have your heart set on something special and unique, it may be a little more expensive, and if something is imperfect, it may be a little less expensive. There is no right or wrong, only what makes you happy! TWM is all about your event, your way!
                        </p>
                    </div>
                    {/* Image Section */}
                    <div>
                        <img
                            src="https://res.cloudinary.com/dlhwfesiz/image/upload/v1730209752/AdobeStock_120586615-600x400_lnh0f2.jpg"
                            alt="Shop by Category"
                            className="w-full h-auto rounded-lg object-cover"
                        />
                    </div>
                </div>
            </div>

            {/* Shop by Color Section */}
            <div className="bg-gray-100 py-16 px-5 sm:px-10">
                <div className="max-w-7xl mx-auto grid grid-cols-1 lg:grid-cols-3 gap-8 items-center">
                    {/* Text Section */}
                    <div className="lg:col-span-2">
                        <h2 className="text-pink-500 text-3xl md:text-4xl font-bold mb-6">SHOP BY COLOR</h2>
                        <p className="text-lg mb-4">
                            Is your heart set on a specific color scheme, but you’re committed to budget-friendly and sustainable planning? Or maybe you’re planning a big event and choosing the color theme is overwhelming? Our shop by color feature helps resolve both sides of the color conundrum!
                        </p>
                        <p className="text-lg mb-4">
                            By choosing to shop by color, you will see results for available items within that color family. It makes coordinating what you need incredibly easy and takes the stress out of color selection and matching.
                        </p>
                        <p className="text-lg mb-4">
                            When you know what you want, it will be right there, and when you are not quite sure, one of the already coordinated themes will catch your eye and help you nail down your perfect look.
                        </p>
                    </div>
                    {/* Image Section */}
                    <div>
                        <img
                            src="https://res.cloudinary.com/dlhwfesiz/image/upload/v1730209892/AdobeStock_400148622-600x399_yrajk0.jpg"
                            alt="Shop by Color"
                            className="w-full h-auto rounded-lg object-cover"
                        />
                    </div>
                </div>
            </div>

            {/* Purchase Your Items Section */}
            <div className="bg-white py-16 px-5 sm:px-10">
                <div className="max-w-7xl mx-auto grid grid-cols-1 lg:grid-cols-3 gap-8 items-center">
                    {/* Text Section */}
                    <div className="lg:col-span-2">
                        <h2 className="text-pink-500 text-3xl md:text-4xl font-bold mb-6">PURCHASE YOUR ITEMS</h2>
                        <p className="text-lg mb-4">
                            Once you select your items, add them to your cart and head to the checkout. You will pay the listed price, plus shipping and a 3% transaction fee. This fee helps support The Wedding Marketplace platform’s innovations and growth.
                        </p>
                        <p className="text-lg mb-4">
                            Shipping prices vary by size and weight and are determined between the buyer and seller. You should understand the cost to ship the item prior to purchase. You can visit the Buyer-Seller agreement for more information on transactions.
                        </p>
                        <p className="text-lg mb-4">
                            Sellers are asked to ship the items as soon as possible, packed safely in appropriate packing materials and send using a tracking number so the item can be located along its delivery route.
                        </p>
                    </div>
                    {/* Image Section */}
                    <div>
                        <img
                            src="https://res.cloudinary.com/dlhwfesiz/image/upload/v1730209752/AdobeStock_120586615-600x400_lnh0f2.jpg"
                            alt="Purchase Items"
                            className="w-full h-auto rounded-lg object-cover"
                        />
                    </div>
                </div>
            </div>

            {/* Ready to Start Preparing Section */}
            <div className="bg-gray-100 py-16 px-5 sm:px-10">
                <div className="max-w-7xl mx-auto grid grid-cols-1 lg:grid-cols-3 gap-8 items-center">
                    {/* Text Section */}
                    <div className="lg:col-span-2">
                        <h2 className="text-pink-500 text-4xl font-bold mb-6">READY TO START PREPARING FOR YOUR EVENT?</h2>
                        <button className="bg-black text-white py-2 px-6 rounded-md text-lg hover:bg-gray-800 transition duration-300">
                            START BUYING
                        </button>
                    </div>
                    {/* Image Section */}
                    <div>

                    </div>
                </div>
            </div>
            <TestimonialSection/>
        </div>
    );
}
