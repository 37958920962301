import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import art from "../assets/art.jpg";
import decor from "../assets/Decor.jpg";
import dinnerware from "../assets/dinnerware.jpg";
import fabric from "../assets/fabric.jpeg";
import favors from "../assets/favors.jpg";
import flowers from "../assets/flowers.jpg";
import furniture from "../assets/furniture.jpg";
import gifts from "../assets/gifts.jpeg";
import jewelry from "../assets/jewelry.jpg";
import lights from "../assets/lights.jpg";
import paper from "../assets/paper.jpg";
import signs from "../assets/signs.jpg";
import logo from "../assets/logo-with-text-2.png";
import logomobile from "../assets/weddinglogo.png";
import videoAsset from "../assets/TWMVideo_vmvagp.mp4"; // Imported local video asset

import {
  auth,
  googleProvider,
  facebookProvider,
  appleProvider,
} from "../firebaseConfig";
import { signInWithPopup } from "firebase/auth";
import { oAuthLogin } from "../Hooks/authHooks";
import { toast } from "react-toastify";

const categories = [
  { name: "Art", image: art },
  { name: "Decor", image: decor },
  { name: "Dinnerware", image: dinnerware },
  { name: "Fabric", image: fabric },
  { name: "Favors", image: favors },
  { name: "Flowers", image: flowers },
  { name: "Furniture", image: furniture },
  { name: "Gifts", image: gifts },
  { name: "Jewelry", image: jewelry },
  { name: "Lights", image: lights },
  { name: "Paper", image: paper },
  { name: "Signs", image: signs },
];

const media = [
  {
    type: "video",
    url: videoAsset,
  },
  {
    type: "image",
    url: "https://res.cloudinary.com/dlhwfesiz/image/upload/v1730207779/AdobeStock_438671614-scaled_sczicu.jpg",
  },
  {
    type: "image",
    url: "https://res.cloudinary.com/dlhwfesiz/image/upload/v1730207070/AdobeStock_545782493-800x533_i0qerv.jpg",
  },
];

export default function HomePage() {
  const navigate = useNavigate(); // Initialize navigation
  const [currentMediaIndex, setCurrentMediaIndex] = useState(0);
  const [categoryIndex, setCategoryIndex] = useState(0);
  const [activeTab, setActiveTab] = useState("seller");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleNext = () => {
    setCategoryIndex(
      (prevIndex) => (prevIndex + 1) % Math.ceil(categories.length / 6)
    );
  };

  const handlePrevious = () => {
    setCategoryIndex(
      (prevIndex) =>
        (prevIndex - 1 + Math.ceil(categories.length / 6)) %
        Math.ceil(categories.length / 6)
    );
  };

  useEffect(() => {
    if (media[currentMediaIndex].type === "image") {
      const interval = setInterval(() => {
        setCurrentMediaIndex((prevIndex) => (prevIndex + 1) % media.length);
      }, 3000);
      return () => clearInterval(interval);
    }
  }, [currentMediaIndex]);

  const handleVideoEnd = () => {
    setCurrentMediaIndex((prevIndex) => (prevIndex + 1) % media.length);
  };

  const displayedCategories = categories.slice(
    categoryIndex * 6,
    categoryIndex * 6 + 6
  );

  const sellerContent = [
    {
      title: "LIST YOUR ITEMS",
      description:
        "Got wedding items gathering dust? Simply snap a photo, set your price, and start selling!",
      image:
        "https://res.cloudinary.com/dlhwfesiz/image/upload/v1731416004/list_xizi0s.png",
    },
    {
      title: "BOOST YOUR REACH",
      description:
        "Share your listings with followers to maximize visibility and attract more buyers.",
      image:
        "https://res.cloudinary.com/dlhwfesiz/image/upload/v1731416005/share_wjohon.png",
    },
    {
      title: "CASH IN",
      description:
        "Shipping is a breeze with our prepaid labels, and you’ll receive payment once your buyer confirms.",
      image:
        "https://res.cloudinary.com/dlhwfesiz/image/upload/v1731416005/earn_ldqqka.png",
    },
  ];

  const buyerContent = [
    {
      title: "EXPLORE DEALS",
      description:
        "Browse through listings to find unique wedding treasures at great prices.",
      image:
        "https://res.cloudinary.com/dlhwfesiz/image/upload/v1730207070/AdobeStock_545782493-800x533_i0qerv.jpg",
    },
    {
      title: "MAKE YOUR PURCHASE",
      description:
        "Add items to your cart, make offers, or negotiate prices with sellers.",
      image:
        "https://res.cloudinary.com/dlhwfesiz/image/upload/v1730207779/AdobeStock_438671614-scaled_sczicu.jpg",
    },
    {
      title: "RECEIVE YOUR ORDER",
      description:
        "Experience quick, reliable shipping with tracking available for every purchase.",
      image:
        "https://res.cloudinary.com/dlhwfesiz/image/upload/v1730207617/AdobeStock_171210719-scaled_wkp9z3.jpg",
    },
  ];

  const handleGoogleLogin = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const token = await result.user.getIdToken();
      const provider = "google";

      const response = await oAuthLogin({ token, provider });

      if (response.success) {
        console.log("Google login successful:", response.user);
        toast.success("login successful! Welcome, " + response.user.name);
        navigate("/"); // Redirect to the main page
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.error("Error logging in:", error.message);
    }
  };

  const handleFacebookLogin = async () => {
    try {
      const result = await signInWithPopup(auth, facebookProvider);
      const token = await result.user.getIdToken();
      const provider = "facebook";

      const response = await oAuthLogin({ token, provider });
    } catch (error) {
      console.error("Error logging in with Facebook:", error.message);
    }
  };

  const handleAppleLogin = async () => {
    try {
      const result = await signInWithPopup(auth, appleProvider);
      const token = await result.user.getIdToken();

      console.log(token);
    } catch (error) {
      console.error("Error logging in with Apple:", error.message);
    }
  };

  const content = activeTab === "seller" ? sellerContent : buyerContent;

  return (
    <div className="bg-white font-sans text-gray-900">
      <header className="flex justify-between items-center p-4 border-b bg-white text-black">
        <div className="text-2xl font-bold">
          {windowWidth < 768 ? (
            <>
              <img
                src={logomobile}
                alt="Wedding Logo"
                className="h-20 self-start"
              />
            </>
          ) : (
            <>
              {" "}
              <img src={logo} alt="" className="h-16" />
            </>
          )}
        </div>
        <div className="space-x-4">
          <Link to={"/login"} className="text-sm">
            Log In
          </Link>
          <Link to={"/sign-up"} className="text-sm">
            Sign Up
          </Link>
        </div>
      </header>

      <section className="relative flex flex-col-reverse lg:flex-row items-center p-4 lg:p-16 bg-gradient-to-r from-[#e3dcd0] via-white to-[#dcdcdc]">
        <div className="lg:w-1/2 space-y-6">
          <h1 className="text-4xl lg:text-5xl font-bold text-gray-900">
            Buy, sell, and discover wedding fashion, décor, accessories, and
            more
          </h1>
          <p className="text-lg text-gray-700">
            Sign up now and join millions of people on the social marketplace
            for all things wedding-related!
          </p>
          <div className="space-y-3">
            <button
              onClick={handleFacebookLogin}
              className="w-full md:w-[300px] bg-blue-600 text-white px-4 py-2 rounded flex items-center justify-center"
            >
              <i className="fab fa-facebook mr-2"></i> Continue with Facebook
            </button>
            <button
              onClick={handleGoogleLogin}
              className="flex items-center w-full md:w-[300px] justify-center bg-white dark:bg-gray-900 border border-gray-300  shadow-md rounded px-6 py-2 text-sm font-medium text-gray-800 dark:text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
            >
              <svg
                className="h-6 w-6 mr-2"
                xmlns="http://www.w3.org/2000/svg"
                xlink="http://www.w3.org/1999/xlink"
                width="800px"
                height="800px"
                viewBox="-0.5 0 48 48"
                version="1.1"
              >
                {" "}
                <title>Google-color</title> <desc>Created with Sketch.</desc>{" "}
                <defs> </defs>{" "}
                <g
                  id="Icons"
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                >
                  {" "}
                  <g
                    id="Color-"
                    transform="translate(-401.000000, -860.000000)"
                  >
                    {" "}
                    <g
                      id="Google"
                      transform="translate(401.000000, 860.000000)"
                    >
                      {" "}
                      <path
                        d="M9.82727273,24 C9.82727273,22.4757333 10.0804318,21.0144 10.5322727,19.6437333 L2.62345455,13.6042667 C1.08206818,16.7338667 0.213636364,20.2602667 0.213636364,24 C0.213636364,27.7365333 1.081,31.2608 2.62025,34.3882667 L10.5247955,28.3370667 C10.0772273,26.9728 9.82727273,25.5168 9.82727273,24"
                        id="Fill-1"
                        fill="#FBBC05"
                      >
                        {" "}
                      </path>{" "}
                      <path
                        d="M23.7136364,10.1333333 C27.025,10.1333333 30.0159091,11.3066667 32.3659091,13.2266667 L39.2022727,6.4 C35.0363636,2.77333333 29.6954545,0.533333333 23.7136364,0.533333333 C14.4268636,0.533333333 6.44540909,5.84426667 2.62345455,13.60426667 L10.5322727,19.6437333 C12.3545909,14.112 17.5491591,10.1333333 23.7136364,10.1333333"
                        id="Fill-2"
                        fill="#EB4335"
                      >
                        {" "}
                      </path>{" "}
                      <path
                        d="M23.7136364,37.8666667 C17.5491591,37.8666667 12.3545909,33.888 10.5322727,28.3562667 L2.62345455,34.3946667 C6.44540909,42.1557333 14.4268636,47.4666667 23.7136364,47.4666667 C29.4455,47.4666667 34.9177955,45.4314667 39.0249545,41.6181333 L31.5177727,35.8144 C29.3995682,37.1488 26.7323182,37.8666667 23.7136364,37.8666667"
                        id="Fill-3"
                        fill="#34A853"
                      >
                        {" "}
                      </path>{" "}
                      <path
                        d="M46.1454545,24 C46.1454545,22.6133333 45.9318182,21.12 45.6113636,19.7333333 L23.7136364,19.7333333 L23.7136364,28.8 L36.3181818,28.8 C35.6879545,31.8912 33.9724545,34.2677333 31.5177727,35.8144 L39.0249545,41.6181333 C43.3393409,37.6138667 46.1454545,31.6490667 46.1454545,24"
                        id="Fill-4"
                        fill="#4285F4"
                      >
                        {" "}
                      </path>{" "}
                    </g>{" "}
                  </g>{" "}
                </g>{" "}
              </svg>
              <span>Continue with Google</span>
            </button>
            <button
              onClick={handleAppleLogin}
              className="w-full md:w-[300px] bg-black text-white px-4 py-2 rounded flex items-center justify-center"
              disabled
            >
              <i className="fab fa-apple mr-2"></i> Continue with Apple
            </button>
            <Link to={"/login"} className="text-sm text-blue-600 underline">
              Sign up with Email
            </Link>
            <div className="flex items-center space-x-2">
              <input
                type="checkbox"
                id="stay-signed-in"
                className="form-checkbox"
              />
              <label htmlFor="stay-signed-in" className="text-sm text-gray-700">
                Stay Signed In
              </label>
            </div>
          </div>
        </div>

        <div className="w-full lg:w-1/2 mt-8 lg:mt-0 flex justify-center">
          {media[currentMediaIndex].type === "video" ? (
            <video
              src={media[currentMediaIndex].url}
              autoPlay
              muted
              loop={false}
              onEnded={handleVideoEnd}
              className="rounded-lg h-96"
              controls={false}
              disablePictureInPicture
              controlsList="nodownload noplaybackrate nofullscreen"
            />
          ) : (
            <img
              src={media[currentMediaIndex].url}
              alt="Wedding Item"
              className="rounded-lg shadow-lg max-h-96"
            />
          )}
        </div>

        <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
          {media.map((_, index) => (
            <span
              key={index}
              className={`h-3 w-3 rounded-full ${
                index === currentMediaIndex ? "bg-purple-600" : "bg-gray-300"
              }`}
            ></span>
          ))}
        </div>
      </section>

      {/* What you can find on TWM Section */}
      <section className="text-center py-12 text-black">
        <h2 className="text-3xl font-bold">What you can find on TWM</h2>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mt-8 px-4 lg:px-16">
          {displayedCategories.map((category) => (
            <motion.div
              key={category.name}
              className="bg-white border sm:p-4 rounded shadow text-center hover:shadow-lg hover:border border-gray-300 transition"
              whileHover={{ scale: 1.05 }}
            >
              <div className="h-48 flex items-center justify-center overflow-hidden">
                <img
                  src={category.image}
                  alt={category.name}
                  className="object-cover h-full w-full rounded"
                />
              </div>
              <p className="font-bold mt-2">{category.name}</p>
            </motion.div>
          ))}
        </div>
        <div className="flex justify-center mt-4 space-x-4">
          <button
            onClick={handlePrevious}
            className="bg-gray-300 p-2 px-3 rounded-full shadow-md border hover:border-gray-500 transition"
          >
            &lt;
          </button>
          <button
            onClick={handleNext}
            className="bg-gray-300 p-2 px-3 rounded-full shadow-md border hover:border-gray-500 transition"
          >
            &gt;
          </button>
        </div>
      </section>

      {/* How It Works Section with Tabs */}
      <section className="bg-gray-50 py-16 px-4 lg:px-32 text-center text-black">
        <h3 className="text-3xl font-bold mb-8">How It Works</h3>

        {/* Tabs */}
        <div className="flex justify-center space-x-8 mb-8">
          <button
            className={`text-lg font-semibold ${
              activeTab === "seller"
                ? "text-black border-b-2 border-black"
                : "text-gray-600"
            }`}
            onClick={() => setActiveTab("seller")}
          >
            I AM A SELLER
          </button>
          <button
            className={`text-lg font-semibold ${
              activeTab === "buyer"
                ? "text-black border-b-2 border-black"
                : "text-gray-600"
            }`}
            onClick={() => setActiveTab("buyer")}
          >
            I AM A BUYER
          </button>
        </div>

        {/* Content */}
        <div className="grid md:grid-cols-3 gap-8">
          {content.map((item, index) => (
            <div key={index} className="text-center">
              <div className="mb-4">
                <img
                  src={item.image}
                  alt={item.title}
                  className="rounded-lg shadow-lg w-full h-60 object-cover"
                />
              </div>
              <h4 className="font-semibold text-xl mb-4 text-black">
                {item.title}
              </h4>
              <p>{item.description}</p>
            </div>
          ))}
        </div>
      </section>

      {/* Shop & Sell With Confidence Section */}
      <section className="text-center py-16 text-black">
        <h3 className="text-3xl font-bold mb-8">Shop & Sell With Confidence</h3>
        <div className="flex flex-wrap justify-center gap-8 px-4 lg:px-32">
          <div className="max-w-xs text-left">
            <h4 className="text-2xl font-semibold">Protected Payments</h4>
            <p>
              If it’s not what you ordered, we guarantee to give your money
              back.
            </p>
            <Link to="#" className="text-black">
              Learn More &raquo;
            </Link>
          </div>
          <div className="max-w-xs text-left">
            <h4 className="text-2xl font-semibold">TWM Authenticate</h4>
            <p>Free item authentication on all items $500 or more.</p>
            <Link to="#" className="text-black">
              Learn More &raquo;
            </Link>
          </div>
          <div className="max-w-xs text-left">
            <h4 className="text-2xl font-semibold">Expedited Shipping</h4>
            <p>
              All orders ship via USPS Priority Mail. Fast and reliable
              shipping.
            </p>
            <Link to="#" className="text-black">
              Learn More &raquo;
            </Link>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-gray-900 text-white py-8 px-4 text-center">
        <p>&copy; 2024 The Wedding Marketplace. All rights reserved.</p>
      </footer>
    </div>
  );
}
