import React from 'react';

export default function SalesTax() {
  return (
    <div className="min-h-screen p-4">
      <div className="max-w-4xl mx-auto bg-white shadow-lg rounded-lg p-6">
        <h1 className="text-3xl font-bold mb-6 text-gray-800">Sales Tax Policy</h1>
        
        <p className="mb-4 text-lg text-gray-700">
          The Wedding Marketplace adheres to the marketplace facilitator tax laws in most US states, a legal framework that shifts sales tax and state-imposed fee collection duties from third-party sellers to the platform facilitating these transactions. Consequently, The Wedding Marketplace has taken on the responsibility of calculating, collecting, reporting, and remitting applicable sales tax on behalf of third-party sellers in states where these laws apply.
        </p>
        
        <p className="mb-4 text-lg text-gray-700">
          It's important to note that state and local sales tax laws are subject to change, and The Wedding Marketplace will keep you informed of any updates. For more detailed information, please consult our help center article on <a href="#" className=" underline">Sales Tax For Sellers</a>.
        </p>
        
        <p className="mb-4 text-lg text-gray-700">
          In an upcoming update effective July 1, 2023, The Wedding Marketplace will implement a Retail Delivery Fee of <span className="font-semibold">$0.28</span> for each transaction delivered within Colorado, in addition to the required state and local sales tax. This fee will be clearly indicated as a separate line item on buyer purchase receipts, and it remains the buyer's responsibility to settle all taxes and fees owed.
        </p>
        
        <p className="mb-4 text-lg text-gray-700">
          The Wedding Marketplace acknowledges federal and state-issued sales tax exemptions. Buyers seeking exemption from sales tax must furnish the necessary documentation for review. Once validated, The Wedding Marketplace will adjust the account to reflect the exemption status.
        </p>
        
        <p className="mb-4 text-lg text-gray-700">
          For further details, please refer to our help center article on <a href="#" className=" underline">Sales Tax For Buyers</a>.
        </p>
        
        <p className="mb-4 text-lg text-gray-700">
          The calculation of sales tax on orders depends on various factors, including the final total item price, the item's taxable status, separately stated shipping charges, and the delivery location. While sales tax amounts are not displayed during item listing, buyers will see the actual sales tax amount before completing their purchase, based on the combined state and local tax rate at the delivery address.
        </p>
        
        <p className="mb-4 text-lg text-gray-700">
          For more information on the Colorado Retail Delivery Fee taking effect on July 1, 2023, please visit: <a href="#" className=" underline">Retail Delivery Fee | Department of Revenue - Taxation</a>.
        </p>
        
        <p className="text-gray-700">
          The Wedding Marketplace is committed to complying with marketplace facilitator tax laws and is responsible for collecting and remitting tax on taxable items shipped to the states listed in the Marketplace Facilitator Laws table provided.
        </p>
      </div>
    </div>
  );
}
