import axios from "axios";

axios.defaults.baseURL =
  process.env.REACT_APP_BACKEND_URL || "http://localhost:5000";

export async function createStripeSession(requestData) {
  try {
    const { data, status } = await axios.post(
      "/api/payment/create-stripe-session",
      requestData
    );
    return {
      success: data.success,
      message: data.message,
      session: data.session,
      status,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}

export async function createPaypalSession(requestData) {
  try {
    const { data, status } = await axios.post(
      "/api/payment/create-paypal-transaction",
      requestData
    );
    return {
      success: data.success,
      message: data.message,
      session: data.session,
      status,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}

export async function getPaymentsPaginated(page = 1, limit = 10) {
  try {
    const token = localStorage.getItem("twmToken");

    if (!token) {
      return {
        success: false,
        message: "Please login first",
        status: 401,
      };
    }

    const { data, status } = await axios.get(
      `/api/payment/get-all-payments?page=${page}&limit=${limit}`,
      {
        headers: {
          "TWM-auth-token": token,
        },
      }
    );

    return {
      success: data.success,
      message: data.message,
      payments: data.payments,
      totalPages: data.totalPages,
      currentPage: data.currentPage,
      status,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}
