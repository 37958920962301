import React from 'react';
import { FaSearch, FaShippingFast, FaDollarSign } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import sell1 from '../../assets/sell1.jpeg';

export default function Features() {
    const navigate = useNavigate();

    const handleRedirect = () => {
        const token = localStorage.getItem('twmToken');
        if (token) {
            navigate('/admin/new-product');
        } else {
            navigate('/signup');
        }
    };

    return (
        <div className="bg-white text-white py-16">
            <hr className="mb-4" />
            <div className="relative bg-cover bg-center py-5" style={{ backgroundImage: `url(${sell1})` }}>
                <div className="absolute inset-0 bg-black opacity-50"></div>

                <div className="relative z-10">
                    <h1 className="text-center text-3xl font-[500] mb-8">IT'S SO EASY TO SELL!</h1>
                    <div className="py-5">
                        <div className="mx-5 grid grid-cols-1 md:grid-cols-3 gap-8 max-w-5xl md:mx-auto">
                            <div className="bg-white text-black rounded-lg p-8 shadow-lg">
                                <div className="flex justify-center mb-4">
                                    <FaSearch className="text-5xl text-black" />
                                </div>
                                <h2 className="text-center text-xl font-semibold mb-4" onClick={()=>{navigate('/how-to-sell')}}>LIST</h2>
                                <p className="text-center">
                                    Easily list your items in our large selection of user-friendly categories. Don’t forget an accurate and enticing description to help your buyer find you faster!
                                </p>
                            </div>

                            <div className="bg-white text-black rounded-lg p-8 shadow-lg" onClick={()=>{navigate("/seller-agreement")}}>
                                <div className="flex justify-center mb-4">
                                    <FaShippingFast className="text-5xl text-black" />
                                </div>
                                <h2 className="text-center text-xl font-semibold mb-4">SHIP</h2>
                                <p className="text-center">
                                    Pack your items so your buyer receives them in the same condition as you sent them and ship them within 2-3 days. Don’t forget to get a tracking number when you send your items to their new home!
                                </p>
                            </div>

                            <div className="bg-white text-black rounded-lg p-8 shadow-lg" onClick={()=>{navigate("/seller-agreement")}}>
                                <div className="flex justify-center mb-4">
                                    <FaDollarSign className="text-5xl text-black" />
                                </div>
                                <h2 className="text-center text-xl font-semibold mb-4">GET PAID</h2>
                                <p className="text-center">
                                    Getting paid is a breeze with TWM! Once your transaction is completed, you’ll receive your funds less TWM’s 3% transaction fee.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="flex justify-center items-center mt-8">
                        <button
                            onClick={handleRedirect}
                            className="bg-white hover:bg-[#262626] text-black hover:text-white text-2xl px-8 py-4 rounded-lg transition-all duration-300"
                        >
                            Start Selling
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
