import { createSlice } from '@reduxjs/toolkit';

const notificationSlice = createSlice({
  name: 'notifications',
  initialState: {
    notifications: [],
  },
  reducers: {
    setNotifications: (state, action) => {
      state.notifications = action.payload; // Set all notifications
    },
    addNotification: (state, action) => {
      state.notifications.push(action.payload);
    },
    updateNotification: (state, action) => {
      const index = state.notifications.findIndex(
        (notif) => notif._id === action.payload._id
      );
      if (index !== -1) {
        state.notifications[index] = action.payload;
      }
    },
    removeNotification: (state, action) => {
      state.notifications = state.notifications.filter(
        (notif) => notif._id !== action.payload
      ); // Remove the notification with the matching _id
    },
  },
});

export const { setNotifications, addNotification, updateNotification, removeNotification } = notificationSlice.actions;

export default notificationSlice.reducer;
