import React from 'react';
import { FaGoogle, FaApple, FaFacebook } from 'react-icons/fa';
import weddinglogo from '../../assets/weddinglogo.png';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Link, useNavigate } from 'react-router-dom';

export default function AdminSignup() {
    const navigate = useNavigate(); // Initialize navigate hook

    // Handle signup logic and navigation
    const handleSignup = () => {
        // You can add signup logic here if needed
        navigate('/otp'); // Navigate to the /admin/otp route
    };
    return (
        <div className="flex justify-center items-center min-h-screen w-full bg-white">
            {/* Signup Form */}
            <div className="w-full mx-5 sm:mx-auto md:w-[700px] flex justify-center items-center">
                <div className="w-full px-8 py-8 bg-white shadow-lg rounded-lg">
                    {/* Logo */}
                    <div className="flex justify-center mb-8">
                        <img src={weddinglogo} alt="Logo" className="h-12" />
                    </div>

                    {/* Signup Header */}
                    <h2 className="text-3xl font-semibold text-center text-gray-800 mb-6">Create Your Admin Account</h2>

                    {/* Name and Email Fields */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                        <div>
                            <input
                                type="text"
                                placeholder="Name"
                                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                            />
                        </div>
                        <div>
                            <input
                                type="email"
                                placeholder="Email"
                                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                            />
                        </div>
                    </div>

                    {/* Password and Confirm Password Fields */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                        <div>
                            <input
                                type="password"
                                placeholder="Password"
                                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                            />
                        </div>
                        <div>
                            <input
                                type="password"
                                placeholder="Confirm Password"
                                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                            />
                        </div>
                    </div>

                    {/* Phone Number Field */}
                    <div className="mb-4">
                        <PhoneInput
                            country={'us'}
                            inputStyle={{
                                width: '100%',
                                padding: '12px',
                                borderRadius: '8px',
                                borderColor: '#D1D5DB',
                            }}
                        />
                    </div>

                    <button
                        onClick={handleSignup} // Navigate to /admin/otp on click
                        className="w-full bg-black text-white py-3 rounded-lg mb-6 hover:bg-[#262626] transition"
                    >
                        SIGN UP
                    </button>

                    {/* Social Signup Buttons */}
                    <div className="flex justify-between mb-4">
                        <button className="flex items-center justify-center w-1/3 bg-gray-100 border border-gray-300 py-2 rounded-lg hover:bg-gray-200 transition">
                            <FaGoogle className="text-red-500" />
                        </button>
                        <button className="flex items-center justify-center w-1/3 bg-gray-100 border border-gray-300 py-2 rounded-lg hover:bg-gray-200 transition">
                            <FaApple className="text-black" />
                        </button>
                        <button className="flex items-center justify-center w-1/3 bg-gray-100 border border-gray-300 py-2 rounded-lg hover:bg-gray-200 transition">
                            <FaFacebook className="text-blue-600" />
                        </button>
                    </div>
                    <div className='flex justify-end items-end  text-gray-500'>
                        <Link to={'/admin/login'} className='text-sm text-center center'>Already have an account <strong className='text-blue-500'>Login</strong></Link>
                    </div>
                    {/* Terms and Conditions */}
                    <div className="text-center text-sm text-gray-500">
                        By clicking 'Sign Up' you agree to our website{' '}
                        <a href="/terms" className="text-blue-600 hover:underline">
                            Terms & Conditions
                        </a>
                        .
                    </div>
                </div>
            </div>
        </div>
    );
}
