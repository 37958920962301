import React, { useState, useEffect } from 'react';
import { FaEdit, FaTrash } from 'react-icons/fa'; // Importing icons
import { addTag, getAllTags, editTag, deleteTag } from '../../Hooks/tagsHooks';

export default function Tags() {
  const [tags, setTags] = useState([]);
  const [newTag, setNewTag] = useState('');
  const [editingTagIndex, setEditingTagIndex] = useState(null); // To track the tag being edited
  const [editedTag, setEditedTag] = useState(''); // To store the value of the tag being edited

  // Fetch all tags on load
  useEffect(() => {
    const fetchTags = async () => {
      const result = await getAllTags();
      if (result.success) {
        setTags(result.tags);
      } else {
        console.error('Failed to fetch tags:', result.message);
      }
    };

    fetchTags();
  }, []);

  // Function to add a new tag
  const handleAddTag = async () => {
    const trimmedTag = newTag.trim();
    if (trimmedTag !== '' && !tags.some(tag => tag.name === trimmedTag)) {
      try {
        const response = await addTag(trimmedTag);
        if (response.success) {
          setTags([...tags, response.tag]);
          setNewTag(''); // Clear the input field after adding
        } else {
          console.error('Failed to add tag:', response.message);
        }
      } catch (error) {
        console.error('Error adding tag:', error);
      }
    }
  };

  // Function to delete a tag
  const handleDeleteTag = async (index) => {
    const tagId = tags[index]._id;
    try {
      const response = await deleteTag(tagId);
      if (response.success) {
        const updatedTags = tags.filter((_, i) => i !== index);
        setTags(updatedTags);
      } else {
        console.error('Failed to delete tag:', response.message);
      }
    } catch (error) {
      console.error('Error deleting tag:', error);
    }
  };

  // Function to start editing a tag
  const handleEditTag = (index) => {
    setEditingTagIndex(index);
    setEditedTag(tags[index].name); // Set the current value to edit
  };

  // Function to save the edited tag
  const handleSaveEdit = async () => {
    const trimmedTag = editedTag.trim();
    if (trimmedTag !== '' && !tags.some(tag => tag.name === trimmedTag)) {
      try {
        const response = await editTag(tags[editingTagIndex]._id, trimmedTag);
        if (response.success) {
          const updatedTags = [...tags];
          updatedTags[editingTagIndex] = { ...updatedTags[editingTagIndex], name: trimmedTag };
          setTags(updatedTags);
          setEditingTagIndex(null); // Exit edit mode
        } else {
          console.error('Failed to edit tag:', response.message);
        }
      } catch (error) {
        console.error('Error editing tag:', error);
      }
    }
  };

  // Handle input changes for adding and editing tags
  const handleInputChange = (e) => setNewTag(e.target.value);
  const handleEditInputChange = (e) => setEditedTag(e.target.value);

  return (
    <div className="p-6 bg-white min-h-screen">
      <h1 className="text-2xl font-semibold mb-6">Manage Wedding E-commerce Tags</h1>

      {/* Add new tag input */}
      <div className="bg-gray-100 p-4 rounded-lg shadow-sm mb-6">
        <h2 className="text-lg font-medium mb-4">Add New Tag</h2>
        <div className="flex space-x-4">
          <input
            type="text"
            value={newTag}
            onChange={handleInputChange}
            placeholder="Enter tag name"
            className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-black"
          />
          <button
            onClick={handleAddTag}
            className="bg-black text-white px-4 py-2 rounded-lg hover:bg-gray-800 transition-colors"
          >
            Add Tag
          </button>
        </div>
        {newTag.trim() === '' && (
          <p className="text-sm text-gray-500 mt-2">Tag name cannot be empty.</p>
        )}
        {tags.some(tag => tag.name === newTag.trim()) && (
          <p className="text-sm text-red-500 mt-2">Tag already exists.</p>
        )}
      </div>

      {/* Display the list of tags */}
      <div className="mb-6">
        <h2 className="text-xl font-medium mb-4">Current Tags:</h2>
        <ul className="list-disc list-inside text-gray-700 grid grid-cols-2 gap-x-10 gap-y-4">
          {tags.length === 0 ? (
            <li className="text-gray-500">No tags available</li>
          ) : (
            tags.map((tag, index) => (
              <li key={index} className="flex justify-between items-center mb-2">
                {editingTagIndex === index ? (
                  <input
                    type="text"
                    value={editedTag}
                    onChange={handleEditInputChange}
                    className="w-[80%] p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-black"
                  />
                ) : (
                  <span>{tag.name}</span>
                )}
                <div className="flex space-x-2">
                  {editingTagIndex === index ? (
                    <button
                      onClick={handleSaveEdit}
                      className="bg-black text-white px-2 py-1 rounded-lg hover:bg-gray-800 transition-colors"
                    >
                      Save
                    </button>
                  ) : (
                    <button
                      onClick={() => handleEditTag(index)}
                      className="text-blue-500 hover:text-blue-700 transition-colors"
                    >
                      <FaEdit />
                    </button>
                  )}
                  <button
                    onClick={() => handleDeleteTag(index)}
                    className="text-red-500 hover:text-red-700 transition-colors"
                  >
                    <FaTrash />
                  </button>
                </div>
              </li>
            ))
          )}
        </ul>
      </div>
    </div>
  );
}
