import React, { useEffect, useState } from "react";
import {
  FaHome,
  FaUser,
  FaBox,
  FaCog,
  FaSignOutAlt,
  FaBars,
  FaTimes,
  FaChevronDown,
  FaChevronRight,
  FaClipboardList,
  FaTags,
  FaQuestionCircle,
  FaMoneyBill,
  FaGift,
  FaThLarge,
} from "react-icons/fa";
import { Link, useNavigate, useLocation } from "react-router-dom";
import weddinglogo from "../assets/weddinglogo.png";

function Sidebar() {
  const [isOpen, setIsOpen] = useState(true); // For large screens
  const [isMobileOpen, setIsMobileOpen] = useState(false); // For small screens
  const [isOrdersOpen, setIsOrdersOpen] = useState(false); // For Orders dropdown
  const navigate = useNavigate();
  const location = useLocation();

  // Get the user's role from localStorage
  const user = JSON.parse(localStorage.getItem("twmUser"));
  const isAdmin = user?.role === "admin";

  // Function to handle navigating with filter
  const handleFilterClick = (filter) => {
    navigate(`/admin/orders`, { state: { filter } });
    if (isMobileOpen) setIsMobileOpen(false); // Close sidebar on mobile
  };

  // Navigate to "All" orders if sidebar is minimized and Orders icon is clicked
  const handleOrdersClick = () => {
    if (!isOpen) {
      handleFilterClick("All");
    } else {
      setIsOrdersOpen(!isOrdersOpen);
    }
  };

  // Close sidebar on large screens when navigating to /admin/new-product
  useEffect(() => {
    if (location.pathname === "/admin/new-product" && isOpen) {
      setIsOpen(false);
    }
  }, [location]);

  // Prevent body scrolling when mobile sidebar is open
  useEffect(() => {
    if (isMobileOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isMobileOpen]);

  return (
    <>
      {!isMobileOpen && (
        <button
          className="md:hidden fixed top-4 left-4 z-50 bg-gray-800 text-gray-100 p-2 rounded-md"
          onClick={() => setIsMobileOpen(!isMobileOpen)}
        >
          <FaBars />
        </button>
      )}

      {/* Sidebar for large screens */}
      <div
        className={`hidden md:flex flex-col min-h-screen overflow-y-auto h-full bg-gray-800 text-gray-100 duration-300 sticky top-0 ${
          isOpen ? "w-64" : "w-20"
        }`}
      >
        <div className="flex justify-between items-center p-4">
          <div
            className={`text-2xl flex justify-center items-center gap-2 font-bold ${
              !isOpen && "hidden"
            }`}
          >
            <img src={weddinglogo} className="w-10 h-10" alt="" />
            <h1 className={`text-2xl font-bold ${!isOpen && "hidden"}`}>TWM</h1>
          </div>
          <button onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? (
              <span className="text-xl" style={{ fontSize: "1.5rem" }}>
                ←
              </span>
            ) : (
              <span className="text-xl" style={{ fontSize: "1.5rem" }}>
                →
              </span>
            )}
          </button>
        </div>

        <nav className="flex flex-col gap-4 p-4">
          <Link
            to="/admin"
            className="flex items-center gap-4 p-2 hover:bg-gray-700 rounded-md"
            title={!isOpen ? "Dashboard" : ""}
          >
            <FaHome className="text-xl" />
            {isOpen && <span className="text-lg">Dashboard</span>}
          </Link>

          {/* Orders Section */}
          <div>
            <button
              className="flex items-center justify-between w-full p-2 hover:bg-gray-700 rounded-md"
              onClick={handleOrdersClick}
              title={!isOpen ? "Orders" : ""}
            >
              <div className="flex items-center gap-4">
                <FaClipboardList className="text-xl" />
                {isOpen && <span className="text-lg">Orders</span>}
              </div>
              {isOpen && (
                <span>
                  {isOrdersOpen ? <FaChevronDown /> : <FaChevronRight />}
                </span>
              )}
            </button>

            {isOrdersOpen && isOpen && (
              <div className="ml-8 mt-2 space-y-2">
                <button
                  onClick={() => handleFilterClick("All")}
                  className="flex items-center justify-between w-full p-2 text-left hover:bg-gray-700 rounded-md"
                >
                  <span>All</span>
                </button>
                <button
                  onClick={() => handleFilterClick("processing")}
                  className="flex items-center justify-between w-full p-2 text-left hover:bg-gray-700 rounded-md"
                >
                  <span>Processing</span>
                </button>
                <button
                  onClick={() => handleFilterClick("shipped")}
                  className="flex items-center justify-between w-full p-2 text-left hover:bg-gray-700 rounded-md"
                >
                  <span>Shipped</span>
                </button>
                <button
                  onClick={() => handleFilterClick("delivered")}
                  className="flex items-center justify-between w-full p-2 text-left hover:bg-gray-700 rounded-md"
                >
                  <span>Delivered</span>
                </button>
                <button
                  onClick={() => handleFilterClick("cancelled")}
                  className="flex items-center justify-between w-full p-2 text-left hover:bg-gray-700 rounded-md"
                >
                  <span>Canceled</span>
                </button>
              </div>
            )}
          </div>

          {/* New sections with tooltips */}
          <Link
            to="/admin/products"
            className="flex items-center gap-4 p-2 hover:bg-gray-700 rounded-md"
            title={!isOpen ? "Products" : ""}
          >
            <FaBox className="text-xl" />
            {isOpen && <span className="text-lg">Products</span>}
          </Link>
          <Link
            to="/admin/offers"
            className="flex items-center gap-4 p-2 hover:bg-gray-700 rounded-md"
            title={!isOpen ? "Offers" : ""}
          >
            <FaClipboardList className="text-xl" />
            {isOpen && <span className="text-lg">Offers</span>}
          </Link>
          <Link
            to="/admin/questions"
            className="flex items-center gap-4 p-2 hover:bg-gray-700 rounded-md"
            onClick={() => setIsMobileOpen(false)}
          >
            <FaQuestionCircle className="text-xl" />
            {isOpen && <span className="text-lg">Questions</span>}
          </Link>
          <Link
            to="/admin/refund"
            className="flex items-center gap-4 p-2 hover:bg-gray-700 rounded-md"
            onClick={() => setIsMobileOpen(false)}
          >
            <FaQuestionCircle className="text-xl" />
            {isOpen && <span className="text-lg">Refunds</span>}
          </Link>

          {/* Admin-only sections */}
          {isAdmin && (
            <>
              <Link
                to="/admin/users"
                className="flex items-center gap-4 p-2 hover:bg-gray-700 rounded-md"
                title={!isOpen ? "Users" : ""}
              >
                <FaUser className="text-xl" />
                {isOpen && <span className="text-lg">Users</span>}
              </Link>

              <Link
                to="/admin/payments"
                className="flex items-center gap-4 p-2 hover:bg-gray-700 rounded-md"
                title={!isOpen ? "Payments" : ""}
              >
                <FaMoneyBill className="text-xl" />
                {isOpen && <span className="text-lg">Payments</span>}
              </Link>

              <Link
                to="/admin/categories"
                className="flex items-center gap-4 p-2 hover:bg-gray-700 rounded-md"
                title={!isOpen ? "Categories" : ""}
              >
                <FaThLarge className="text-xl" />
                {isOpen && <span className="text-lg">Categories</span>}
              </Link>

              <Link
                to="/admin/tags"
                className="flex items-center gap-4 p-2 hover:bg-gray-700 rounded-md"
                title={!isOpen ? "Tags" : ""}
              >
                <FaTags className="text-xl" />
                {isOpen && <span className="text-lg">Tags</span>}
              </Link>

              <Link
                to="/admin/vouchers"
                className="flex items-center gap-4 p-2 hover:bg-gray-700 rounded-md"
                title={!isOpen ? "Vouchers" : ""}
              >
                <FaGift className="text-xl" />
                {isOpen && <span className="text-lg">Vouchers</span>}
              </Link>
            </>
          )}
        </nav>
      </div>

      {/* Sidebar for mobile screens */}
      <div
        className={`fixed md:hidden top-0 left-0 min-h-screen h-full bg-gray-800 text-gray-100 duration-300 z-40 transform overflow-y-auto ${
          isMobileOpen ? "translate-x-0" : "-translate-x-full"
        } w-64`}
      >
        <div className="flex flex-col min-h-screen">
          <div className="flex justify-between p-4">
            <h1 className="text-2xl font-bold">My App</h1>
            <button onClick={() => setIsMobileOpen(false)}>
              <FaTimes />
            </button>
          </div>

          <nav className="flex flex-col gap-4 p-4">
            <Link
              to="/admin"
              className="flex items-center gap-4 p-2 hover:bg-gray-700 rounded-md"
              onClick={() => setIsMobileOpen(false)} // Close sidebar on link click
            >
              <FaHome className="text-xl" />
              <span className="text-lg">Dashboard</span>
            </Link>

            {/* Orders Section */}
            <div>
              <button
                className="flex items-center justify-between w-full p-2 hover:bg-gray-700 rounded-md"
                onClick={handleOrdersClick}
              >
                <div className="flex items-center gap-4">
                  <FaClipboardList className="text-xl" />
                  <span className="text-lg">Orders</span>
                </div>
                <span>
                  {isOrdersOpen ? <FaChevronDown /> : <FaChevronRight />}
                </span>
              </button>

              {isOrdersOpen && (
                <div className="ml-8 mt-2 space-y-2">
                  <button
                    onClick={() => handleFilterClick("All")}
                    className="flex items-center justify-between w-full p-2 text-left hover:bg-gray-700 rounded-md"
                  >
                    <span>All</span>
                  </button>
                  <button
                    onClick={() => handleFilterClick("processing")}
                    className="flex items-center justify-between w-full p-2 text-left hover:bg-gray-700 rounded-md"
                  >
                    <span>Processing</span>
                  </button>
                  <button
                    onClick={() => handleFilterClick("shipped")}
                    className="flex items-center justify-between w-full p-2 text-left hover:bg-gray-700 rounded-md"
                  >
                    <span>Shipped</span>
                  </button>
                  <button
                    onClick={() => handleFilterClick("delivered")}
                    className="flex items-center justify-between w-full p-2 text-left hover:bg-gray-700 rounded-md"
                  >
                    <span>Delivered</span>
                  </button>
                  <button
                    onClick={() => handleFilterClick("cancelled")}
                    className="flex items-center justify-between w-full p-2 text-left hover:bg-gray-700 rounded-md"
                  >
                    <span>Canceled</span>
                  </button>
                </div>
              )}
            </div>

            <Link
              to="/admin/products"
              className="flex items-center gap-4 p-2 hover:bg-gray-700 rounded-md"
              onClick={() => setIsMobileOpen(false)}
            >
              <FaBox className="text-xl" />
              <span className="text-lg">Products</span>
            </Link>
            <Link
              to="/admin/offers"
              className="flex items-center gap-4 p-2 hover:bg-gray-700 rounded-md"
              onClick={() => setIsMobileOpen(false)}
            >
              <FaClipboardList className="text-xl" />
              <span className="text-lg">Offers</span>
            </Link>
            <Link
              to="/admin/questions"
              className="flex items-center gap-4 p-2 hover:bg-gray-700 rounded-md"
              onClick={() => setIsMobileOpen(false)}
            >
              <FaQuestionCircle className="text-xl" />
              <span className="text-lg">Questions</span>
            </Link>
            <Link
              to="/admin/refund"
              className="flex items-center gap-4 p-2 hover:bg-gray-700 rounded-md"
              onClick={() => setIsMobileOpen(false)}
            >
              <FaQuestionCircle className="text-xl" />
              <span className="text-lg">Refunds</span>
            </Link>

            {/* Admin-only sections */}
            {isAdmin && (
              <>
                <Link
                  to="/admin/users"
                  className="flex items-center gap-4 p-2 hover:bg-gray-700 rounded-md"
                  onClick={() => setIsMobileOpen(false)}
                >
                  <FaUser className="text-xl" />
                  <span className="text-lg">Users</span>
                </Link>

                <Link
                  to="/admin/payments"
                  className="flex items-center gap-4 p-2 hover:bg-gray-700 rounded-md"
                  onClick={() => setIsMobileOpen(false)}
                >
                  <FaMoneyBill className="text-xl" />
                  <span className="text-lg">Payments</span>
                </Link>

                <Link
                  to="/admin/categories"
                  className="flex items-center gap-4 p-2 hover:bg-gray-700 rounded-md"
                  onClick={() => setIsMobileOpen(false)}
                >
                  <FaThLarge className="text-xl" />
                  <span className="text-lg">Categories</span>
                </Link>

                <Link
                  to="/admin/tags"
                  className="flex items-center gap-4 p-2 hover:bg-gray-700 rounded-md"
                  onClick={() => setIsMobileOpen(false)}
                >
                  <FaTags className="text-xl" />
                  <span className="text-lg">Tags</span>
                </Link>

                <Link
                  to="/admin/vouchers"
                  className="flex items-center gap-4 p-2 hover:bg-gray-700 rounded-md"
                  onClick={() => setIsMobileOpen(false)}
                >
                  <FaGift className="text-xl" />
                  <span className="text-lg">Vouchers</span>
                </Link>
              </>
            )}
          </nav>
        </div>
      </div>

      {isMobileOpen && (
        <div
          className="fixed inset-0 bg-black opacity-50 z-30 md:hidden"
          onClick={() => setIsMobileOpen(false)}
        />
      )}
    </>
  );
}

export default Sidebar;
