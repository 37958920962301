import React, { useEffect, useState } from "react";
import { FaEdit, FaTrash, FaToggleOn, FaToggleOff } from "react-icons/fa";
import {
  getVouchers,
  editVoucherById,
  deleteVoucherById,
  createVoucher,
} from "../../Hooks/vouchersHook";
import { toast } from "react-toastify";

export default function Vouchers() {
  const [vouchers, setVouchers] = useState([]);
  const [newVoucher, setNewVoucher] = useState({
    name: "",
    discountPercentage: "",
    maxDiscount: "",
    maxCount: "",
    count: "",
    expiryDate: "",
  });
  const [editingVoucherId, setEditingVoucherId] = useState(null);
  const [editedVoucher, setEditedVoucher] = useState({});

  useEffect(() => {
    async function fetchVouchers() {
      const response = await getVouchers();
      if (response.success) {
        setVouchers(response.vouchers);
      } else {
        // alert(response.message);
        toast.error(response.message);
      }
    }
    fetchVouchers();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewVoucher({ ...newVoucher, [name]: value });
  };

  const handleAddVoucher = async () => {
    const response = await createVoucher(newVoucher);
    if (response.success) {
      setVouchers([...vouchers, response.voucher]);
      setNewVoucher({
        name: "",
        discountPercentage: "",
        maxDiscount: "",
        maxCount: "",
        count: "",
        expiryDate: "",
      });
      toast.success("Voucher added successfully");
    } else {
      // alert(response.message);
      toast.error(response.message);
    }
  };

  const handleDeleteVoucher = async (id) => {
    const response = await deleteVoucherById(id);
    if (response.success) {
      setVouchers(vouchers.filter((voucher) => voucher._id !== id));
      toast.success("Voucher deleted successfully");
    } else {
      alert(response.message);
      toast.error(response.message);
    }
  };

  const handleEditVoucher = (voucher) => {
    setEditingVoucherId(voucher._id);
    setEditedVoucher({ ...voucher });
  };

  const handleSaveEdit = async () => {
    const response = await editVoucherById(editingVoucherId, editedVoucher);
    if (response.success) {
      setVouchers(
        vouchers.map((voucher) =>
          voucher._id === editingVoucherId ? response.voucher : voucher
        )
      );
      toast.success("Voucher updated successfully");
      setEditingVoucherId(null);
    } else {
      alert(response.message);
      toast.error(response.message);
    }
  };

  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setEditedVoucher({ ...editedVoucher, [name]: value });
  };

  const handleCancelEdit = () => {
    setEditingVoucherId(null);
  };

  const handleToggleActive = async (id) => {
    const voucher = vouchers.find((v) => v._id === id);
    const updatedStatus = voucher.status === "active" ? "inactive" : "active";
    const response = await editVoucherById(id, { status: updatedStatus });
    if (response.success) {
      setVouchers(
        vouchers.map((voucher) =>
          voucher._id === id ? { ...voucher, status: updatedStatus } : voucher
        )
      );
      toast.success("Voucher status updated successfully");
    } else {
      alert(response.message);
      toast.error(response.message);
    }
  };

  return (
    <div className="p-6 bg-white min-h-screen">
      <h1 className="text-2xl font-semibold mb-6">Manage Vouchers</h1>

      <div className="bg-gray-100 p-4 rounded-lg shadow-sm mb-6">
        <h2 className="text-lg font-medium mb-4">Add New Voucher</h2>
        <div className="grid grid-cols-2 gap-4 mb-4">
          <input
            type="text"
            name="name"
            value={newVoucher.name}
            onChange={handleInputChange}
            placeholder="Voucher Name"
            className="p-2 border border-gray-300 rounded-lg"
          />
          <input
            type="number"
            name="discountPercentage"
            value={newVoucher.discountPercentage}
            onChange={handleInputChange}
            placeholder="Discount (%)"
            className="p-2 border border-gray-300 rounded-lg"
          />
          <input
            type="number"
            name="maxDiscount"
            value={newVoucher.maxDiscount}
            onChange={handleInputChange}
            placeholder="Max Discount"
            className="p-2 border border-gray-300 rounded-lg"
          />
          <input
            type="number"
            name="maxCount"
            value={newVoucher.maxCount}
            onChange={handleInputChange}
            placeholder="Max Count"
            className="p-2 border border-gray-300 rounded-lg"
          />
          <input
            type="date"
            name="expiryDate"
            value={newVoucher.expiryDate}
            onChange={handleInputChange}
            placeholder="Expiry Date"
            className="p-2 border border-gray-300 rounded-lg"
          />
        </div>
        <button
          onClick={handleAddVoucher}
          className="bg-black text-white px-4 py-2 rounded-lg hover:bg-gray-800 transition-colors"
        >
          Add Voucher
        </button>
      </div>

      <div className="mb-6">
        <h2 className="text-xl font-medium mb-4">Existing Vouchers</h2>
        <ul className="list-disc list-inside text-gray-700">
          {vouchers.map((voucher) => (
            <li
              key={voucher._id}
              className="flex justify-between items-center mb-2 p-4 bg-gray-50 rounded-lg"
            >
              {editingVoucherId === voucher._id ? (
                <div className="flex flex-col w-full">
                  <input
                    type="text"
                    name="name"
                    value={editedVoucher.name}
                    onChange={handleEditInputChange}
                    className="mb-2 p-2 border border-gray-300 rounded-lg"
                  />
                  <div className="grid grid-cols-2 gap-4">
                    <input
                      type="number"
                      name="discountPercentage"
                      value={editedVoucher.discountPercentage}
                      onChange={handleEditInputChange}
                      className="p-2 border border-gray-300 rounded-lg"
                    />
                    <input
                      type="number"
                      name="maxDiscount"
                      value={editedVoucher.maxDiscount}
                      onChange={handleEditInputChange}
                      className="p-2 border border-gray-300 rounded-lg"
                    />
                    <input
                      type="number"
                      name="maxCount"
                      value={editedVoucher.maxCount}
                      onChange={handleEditInputChange}
                      className="p-2 border border-gray-300 rounded-lg"
                    />
                    <input
                      type="date"
                      name="expiryDate"
                      value={editedVoucher.expiryDate}
                      onChange={handleEditInputChange}
                      className="p-2 border border-gray-300 rounded-lg"
                    />
                  </div>
                  <div className="flex space-x-4 mt-4">
                    <button
                      onClick={handleSaveEdit}
                      className="bg-black text-white px-4 py-2 rounded-lg hover:bg-gray-800 transition-colors"
                    >
                      Save Changes
                    </button>
                    <button
                      onClick={handleCancelEdit}
                      className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600 transition-colors"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              ) : (
                <>
                  <span>
                    <strong>{voucher.name}</strong> - {voucher.discountPercentage}%
                    discount (Max: ${voucher.maxDiscount}) - Count: {voucher.count}/
                    {voucher.maxCount} - Expires: {voucher.expiryDate} - Status: {voucher.status}
                  </span>
                  <div className="flex space-x-4">
                    <button
                      onClick={() => handleEditVoucher(voucher)}
                      className="text-blue-500 hover:text-blue-700 transition-colors"
                    >
                      <FaEdit />
                    </button>
                    <button
                      onClick={() => handleDeleteVoucher(voucher._id)}
                      className="text-red-500 hover:text-red-700 transition-colors"
                    >
                      <FaTrash />
                    </button>
                    <button
                      onClick={() => handleToggleActive(voucher._id)}
                      className={
                        voucher.status === "active"
                          ? "text-green-500 hover:text-green-700"
                          : "text-gray-500 hover:text-gray-700"
                      }
                    >
                      {voucher.status === "active" ? (
                        <FaToggleOn className="text-lg" />
                      ) : (
                        <FaToggleOff className="text-lg" />
                      )}
                    </button>
                  </div>
                </>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
