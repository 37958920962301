// src/WeddingQuiz.js
import React, { useState, useEffect } from 'react';
import quizData from '../constants/quizdata';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const gridLayouts = [
  "grid-cols-2",           // Layout for Question 1
  "grid-cols-3 gap-6",      // Layout for Question 2
  "grid-cols-4 gap-4",      // Layout for Question 3
  "grid-cols-2 gap-8",      // Layout for Question 4
  "grid-cols-3 gap-5",      // Layout for Question 5
  "grid-cols-4 gap-3"       // Layout for Question 6
];

const productData = {
  "Classic": [
    { image: 'https://placehold.co/600x400?text=Classic+Product+1', title: 'Classic Product 1' },
    { image: 'https://placehold.co/600x400?text=Classic+Product+2', title: 'Classic Product 2' },
    { image: 'https://placehold.co/600x400?text=Classic+Product+3', title: 'Classic Product 3' },
    { image: 'https://placehold.co/600x400?text=Classic+Product+4', title: 'Classic Product 4' },
    { image: 'https://placehold.co/600x400?text=Classic+Product+5', title: 'Classic Product 5' },
    { image: 'https://placehold.co/600x400?text=Classic+Product+6', title: 'Classic Product 6' },
    { image: 'https://placehold.co/600x400?text=Classic+Product+7', title: 'Classic Product 7' },
    { image: 'https://placehold.co/600x400?text=Classic+Product+8', title: 'Classic Product 8' },
    { image: 'https://placehold.co/600x400?text=Classic+Product+9', title: 'Classic Product 9' },
    { image: 'https://placehold.co/600x400?text=Classic+Product+10', title: 'Classic Product 10' }
  ],
  "Rustic": [
    { image: 'https://placehold.co/600x400?text=Rustic+Product+1', title: 'Rustic Product 1' },
    { image: 'https://placehold.co/600x400?text=Rustic+Product+2', title: 'Rustic Product 2' },
    { image: 'https://placehold.co/600x400?text=Rustic+Product+3', title: 'Rustic Product 3' },
    { image: 'https://placehold.co/600x400?text=Rustic+Product+4', title: 'Rustic Product 4' },
    { image: 'https://placehold.co/600x400?text=Rustic+Product+5', title: 'Rustic Product 5' },
    { image: 'https://placehold.co/600x400?text=Rustic+Product+6', title: 'Rustic Product 6' },
    { image: 'https://placehold.co/600x400?text=Rustic+Product+7', title: 'Rustic Product 7' },
    { image: 'https://placehold.co/600x400?text=Rustic+Product+8', title: 'Rustic Product 8' },
    { image: 'https://placehold.co/600x400?text=Rustic+Product+9', title: 'Rustic Product 9' },
    { image: 'https://placehold.co/600x400?text=Rustic+Product+10', title: 'Rustic Product 10' }
  ],
  "Romantic": [
    { image: 'https://placehold.co/600x400?text=Romantic+Product+1', title: 'Romantic Product 1' },
    { image: 'https://placehold.co/600x400?text=Romantic+Product+2', title: 'Romantic Product 2' },
    { image: 'https://placehold.co/600x400?text=Romantic+Product+3', title: 'Romantic Product 3' },
    { image: 'https://placehold.co/600x400?text=Romantic+Product+4', title: 'Romantic Product 4' },
    { image: 'https://placehold.co/600x400?text=Romantic+Product+5', title: 'Romantic Product 5' },
    { image: 'https://placehold.co/600x400?text=Romantic+Product+6', title: 'Romantic Product 6' },
    { image: 'https://placehold.co/600x400?text=Romantic+Product+7', title: 'Romantic Product 7' },
    { image: 'https://placehold.co/600x400?text=Romantic+Product+8', title: 'Romantic Product 8' },
    { image: 'https://placehold.co/600x400?text=Romantic+Product+9', title: 'Romantic Product 9' },
    { image: 'https://placehold.co/600x400?text=Romantic+Product+10', title: 'Romantic Product 10' }
  ],
  "Modern": [
    { image: 'https://placehold.co/600x400?text=Modern+Product+1', title: 'Modern Product 1' },
    { image: 'https://placehold.co/600x400?text=Modern+Product+2', title: 'Modern Product 2' },
    { image: 'https://placehold.co/600x400?text=Modern+Product+3', title: 'Modern Product 3' },
    { image: 'https://placehold.co/600x400?text=Modern+Product+4', title: 'Modern Product 4' },
    { image: 'https://placehold.co/600x400?text=Modern+Product+5', title: 'Modern Product 5' },
    { image: 'https://placehold.co/600x400?text=Modern+Product+6', title: 'Modern Product 6' },
    { image: 'https://placehold.co/600x400?text=Modern+Product+7', title: 'Modern Product 7' },
    { image: 'https://placehold.co/600x400?text=Modern+Product+8', title: 'Modern Product 8' },
    { image: 'https://placehold.co/600x400?text=Modern+Product+9', title: 'Modern Product 9' },
    { image: 'https://placehold.co/600x400?text=Modern+Product+10', title: 'Modern Product 10' }
  ],
  "Bohemian": [
    { image: 'https://placehold.co/600x400?text=Bohemian+Product+1', title: 'Bohemian Product 1' },
    { image: 'https://placehold.co/600x400?text=Bohemian+Product+2', title: 'Bohemian Product 2' },
    { image: 'https://placehold.co/600x400?text=Bohemian+Product+3', title: 'Bohemian Product 3' },
    { image: 'https://placehold.co/600x400?text=Bohemian+Product+4', title: 'Bohemian Product 4' },
    { image: 'https://placehold.co/600x400?text=Bohemian+Product+5', title: 'Bohemian Product 5' },
    { image: 'https://placehold.co/600x400?text=Bohemian+Product+6', title: 'Bohemian Product 6' },
    { image: 'https://placehold.co/600x400?text=Bohemian+Product+7', title: 'Bohemian Product 7' },
    { image: 'https://placehold.co/600x400?text=Bohemian+Product+8', title: 'Bohemian Product 8' },
    { image: 'https://placehold.co/600x400?text=Bohemian+Product+9', title: 'Bohemian Product 9' },
    { image: 'https://placehold.co/600x400?text=Bohemian+Product+10', title: 'Bohemian Product 10' }
  ],
  "Eclectic": [
    { image: 'https://placehold.co/600x400?text=Eclectic+Product+1', title: 'Eclectic Product 1' },
    { image: 'https://placehold.co/600x400?text=Eclectic+Product+2', title: 'Eclectic Product 2' },
    { image: 'https://placehold.co/600x400?text=Eclectic+Product+3', title: 'Eclectic Product 3' },
    { image: 'https://placehold.co/600x400?text=Eclectic+Product+4', title: 'Eclectic Product 4' },
    { image: 'https://placehold.co/600x400?text=Eclectic+Product+5', title: 'Eclectic Product 5' },
    { image: 'https://placehold.co/600x400?text=Eclectic+Product+6', title: 'Eclectic Product 6' },
    { image: 'https://placehold.co/600x400?text=Eclectic+Product+7', title: 'Eclectic Product 7' },
    { image: 'https://placehold.co/600x400?text=Eclectic+Product+8', title: 'Eclectic Product 8' },
    { image: 'https://placehold.co/600x400?text=Eclectic+Product+9', title: 'Eclectic Product 9' },
    { image: 'https://placehold.co/600x400?text=Eclectic+Product+10', title: 'Eclectic Product 10' }
  ],
  "Glamorous": [
    { image: 'https://placehold.co/600x400?text=Glamorous+Product+1', title: 'Glamorous Product 1' },
    { image: 'https://placehold.co/600x400?text=Glamorous+Product+2', title: 'Glamorous Product 2' },
    { image: 'https://placehold.co/600x400?text=Glamorous+Product+3', title: 'Glamorous Product 3' },
    { image: 'https://placehold.co/600x400?text=Glamorous+Product+4', title: 'Glamorous Product 4' },
    { image: 'https://placehold.co/600x400?text=Glamorous+Product+5', title: 'Glamorous Product 5' },
    { image: 'https://placehold.co/600x400?text=Glamorous+Product+6', title: 'Glamorous Product 6' },
    { image: 'https://placehold.co/600x400?text=Glamorous+Product+7', title: 'Glamorous Product 7' },
    { image: 'https://placehold.co/600x400?text=Glamorous+Product+8', title: 'Glamorous Product 8' },
    { image: 'https://placehold.co/600x400?text=Glamorous+Product+9', title: 'Glamorous Product 9' },
    { image: 'https://placehold.co/600x400?text=Glamorous+Product+10', title: 'Glamorous Product 10' }
  ]
};

const WeddingQuiz = () => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedStyle, setSelectedStyle] = useState('Classic');

  const handleOptionClick = (style) => {
    if (selectedOptions.includes(style)) {
      setSelectedOptions(selectedOptions.filter(option => option !== style));
    } else {
      setSelectedOptions([...selectedOptions, style]);
    }
  };

  const handleNextQuestion = () => {
    if (selectedOptions.length === 0) {
      alert("Please select at least one option to continue.");
      return;
    }

    setAnswers([...answers, ...selectedOptions]);
    const newProgress = ((currentQuestion + 1) / quizData.length) * 100;
    setProgress(newProgress);

    if (currentQuestion < quizData.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
      setSelectedOptions([]); // Clear selections for the next question
      window.scrollTo(0, 0); // Scroll to top
    } else {
      setIsLoading(true);
      setTimeout(() => {
        calculateResult();
        setIsLoading(false);
      }, 2000); // Simulate loading for 2 seconds
    }
  };

  const calculateResult = () => {
    const styleCount = answers.reduce((acc, style) => {
      acc[style] = (acc[style] || 0) + 1;
      return acc;
    }, {});

    const favoriteStyle = Object.keys(styleCount).reduce((a, b) =>
      styleCount[a] > styleCount[b] ? a : b
    );

    setResult(favoriteStyle);
    setSelectedStyle(favoriteStyle);
  };

  const handleStyleChange = (e) => {
    setSelectedStyle(e.target.value);
  };

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    swipeToSlide: true, // Allow users to swipe through slides
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          arrows: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          arrows: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          arrows: true,
        },
      },
    ],
  };

  return (
    <div className="wedding-quiz min-h-screen max-w-4xl mx-auto p-6 bg-white text-gray-900 relative">
      <div className="header text-center mb-6">
        <h1 className="text-3xl font-bold">Wedding Style Quiz</h1>
        <p className="text-lg text-gray-600">Choose 1 to 6 favorite photos that feel like you.</p>
      </div>
      
      <div className="w-full bg-gray-200 rounded-full h-2 mb-6">
        <div
          className="bg-black h-2 rounded-full transition-all duration-300"
          style={{ width: `${progress}%` }}
        ></div>
      </div>

      {isLoading ? (
        <div className="text-center mt-10">
          <div className="loader mx-auto my-8 border-t-4 border-b-4 border-black w-12 h-12 rounded-full animate-spin"></div>
          <p className="text-lg text-gray-600">Calculating your result...</p>
        </div>
      ) : result ? (
        <div className="result text-center mt-10">
          <h2 className="text-4xl font-bold mb-4">Your Wedding Style is: {result}</h2>
          <p className="text-xl text-gray-700 mb-6">
            Based on your selections, we suggest a <span className="font-semibold">{result}</span>-style wedding. Explore venues, décor, and inspiration for a <span className="font-semibold">{result}</span> wedding!
          </p>

          <button className="text-blue-600 hover:underline mb-8" onClick={() => window.location.href = '/products'}>
            Browse this style's products and navigate to the products page.
          </button>

          <div className="style-selector mt-6">
            <label htmlFor="style" className="text-lg font-semibold mr-4">Change Style:</label>
            <select
              id="style"
              value={selectedStyle}
              onChange={handleStyleChange}
              className="p-2 border border-gray-300 rounded-md"
            >
              {Object.keys(productData).map((style) => (
                <option key={style} value={style}>{style}</option>
              ))}
            </select>
          </div>

          <div className="product-slider mt-10">
            <h3 className="text-2xl font-semibold mb-4">What would <span className="underline">{selectedStyle}</span> look like?</h3>
            <Slider {...sliderSettings}>
              {productData[selectedStyle].map((product, index) => (
                <div key={index} className="p-4">
                  <img src={product.image} alt={product.title} className="w-full h-64 object-cover rounded-lg shadow-md mb-4" />
                  <p className="text-center text-gray-800 text-lg font-medium">{product.title}</p>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      ) : (
        <div className="question-section mb-20">
          <h2 className="text-2xl font-semibold mb-4">{quizData[currentQuestion].question}</h2>
          
          <div className={`grid ${gridLayouts[currentQuestion]} gap-4`}>
            {quizData[currentQuestion].options.map((option, index) => (
              <div
                key={index}
                className={`option relative cursor-pointer border-2 rounded-lg overflow-hidden transform transition-transform hover:scale-105 ${
                  selectedOptions.includes(option.style) ? "border-black" : "border-gray-200"
                }`}
                onClick={() => handleOptionClick(option.style)}
              >
                <img src={option.image} alt={option.style} className="w-full h-full object-cover" />
              </div>
            ))}
          </div>

          {/* Fixed Next Button */}
          <button
            className="fixed bottom-8 left-1/2 transform -translate-x-1/2 px-8 py-3 bg-blue-600 text-white font-semibold rounded-full shadow-lg hover:bg-blue-700 transition-all"
            onClick={handleNextQuestion}
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
};

export default WeddingQuiz;
