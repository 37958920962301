import axios from "axios";

axios.defaults.baseURL =
  process.env.REACT_APP_BACKEND_URL || "http://localhost:5000";

export async function makeOffer({
  productId,
  offerAmount,
  userId,
  isCounterOffer,
  offerId,
}) {
  try {
    const token = localStorage.getItem("twmToken");

    if (!token) {
      return {
        success: false,
        message: "Please login first",
        status: 401,
      };
    }

    // Prepare the payload
    const payload = { productId, offerAmount };

    if (isCounterOffer) {
      payload.offerId = offerId; // Include offerId if it's a counter offer
    }

    const { data, status } = await axios.post(
      `/api/offer/${userId}/make-offer`,
      payload,
      {
        headers: {
          "TWM-auth-token": token,
        },
      }
    );

    return {
      success: data.success,
      message: data.message,
      offer: data.offer,
      status,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}

export async function counterOffer({ offerId, counterAmount }) {
  try {
    const token = localStorage.getItem("twmToken");

    if (!token) {
      return {
        success: false,
        message: "Please login first",
        status: 401,
      };
    }

    const { data, status } = await axios.post(
      `/api/offer/${offerId}/counter-offer`,
      { counterAmount },
      {
        headers: {
          "TWM-auth-token": token,
        },
      }
    );

    return {
      success: data.success,
      message: data.message,
      offer: data.offer,
      status,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}

export async function userCounterOffer({ offerId, counterAmount }) {
  try {
    const token = localStorage.getItem("twmToken");

    if (!token) {
      return {
        success: false,
        message: "Please login first",
        status: 401,
      };
    }

    const { data, status } = await axios.post(
      `/api/offer/${offerId}/customer-counter`,
      { counterAmount },
      {
        headers: {
          "TWM-auth-token": token,
        },
      }
    );

    return {
      success: data.success,
      message: data.message,
      offer: data.offer,
      status,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}

export async function declineOffer(offerId) {
  try {
    const token = localStorage.getItem("twmToken");

    if (!token) {
      return {
        success: false,
        message: "Please login first",
        status: 401,
      };
    }

    const { data, status } = await axios.delete(
      `/api/offer/${offerId}/decline-offer`,
      {
        headers: {
          "TWM-auth-token": token,
        },
      }
    );

    return {
      success: data.success,
      message: data.message,
      status,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}

export async function declineCounterOffer(offerId) {
  try {
    const token = localStorage.getItem("twmToken");

    if (!token) {
      return {
        success: false,
        message: "Please login first",
        status: 401,
      };
    }

    const { data, status } = await axios.delete(
      `/api/offer/${offerId}/decline-counter-offer`,
      {
        headers: {
          "TWM-auth-token": token,
        },
      }
    );

    return {
      success: data.success,
      message: data.message,
      status,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}

export async function acceptOffer(offerId) {
  try {
    const token = localStorage.getItem("twmToken");

    if (!token) {
      return {
        success: false,
        message: "Please login first",
        status: 401,
      };
    }

    const { data, status } = await axios.post(
      `/api/offer/${offerId}/accept-offer`,
      {},
      {
        headers: {
          "TWM-auth-token": token,
        },
      }
    );

    return {
      success: data.success,
      message: data.message,
      status,
      cartItem: data.cartItem || null,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}

export async function acceptVendorOffer(offerId) {
  try {
    const token = localStorage.getItem("twmToken");

    if (!token) {
      return {
        success: false,
        message: "Please log in first",
        status: 401,
      };
    }

    const { data, status } = await axios.post(
      `/api/offer/${offerId}/accept-counter`,
      {},
      {
        headers: {
          "TWM-auth-token": token,
        },
      }
    );

    return {
      success: data.success,
      message: data.message,
      status,
      cart: data.cart || null,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}

export async function getAllOffersByCustomerId() {
  try {
    const token = localStorage.getItem("twmToken");

    if (!token) {
      return {
        success: false,
        message: "Please login first",
        status: 401,
      };
    }

    const { data, status } = await axios.get(`/api/offer/customer`, {
      headers: {
        "TWM-auth-token": token,
      },
    });

    return {
      success: data.success,
      message: data.message,
      offers: data.offers || [],
      status,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}

export async function getAllOffersByVendorId() {
  try {
    const token = localStorage.getItem("twmToken");

    if (!token) {
      return {
        success: false,
        message: "Please login first",
        status: 401,
      };
    }

    const { data, status } = await axios.get(`/api/offer/vendor`, {
      headers: {
        "TWM-auth-token": token,
      },
    });

    return {
      success: data.success,
      message: data.message,
      offers: data.offers || [],
      status,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}
