import React, { useMemo, useState, useEffect } from 'react';
import { useTable, usePagination } from 'react-table';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
import { getPaymentsPaginated } from '../../Hooks/paymentHooks';

const GlobalFilter = ({ dateFilter, setDateFilter, typeFilter, setTypeFilter }) => {
  const creditDebitOptions = [
    { value: '', label: 'All' },
    { value: 'credit', label: 'Credit' },
    { value: 'debit', label: 'Debit' },
  ];

  const selectStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: 'white',
      border: '1px solid black',
      color: 'black',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? 'black' : 'white',
      color: state.isSelected ? 'white' : 'black',
      '&:hover': { backgroundColor: 'black', color: 'white' },
    }),
  };

  return (
    <div className="my-4 flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4">
      <DatePicker
        selected={dateFilter}
        onChange={(date) => setDateFilter(date)}
        className="border rounded px-4 py-2 bg-white text-black w-full md:w-auto"
        placeholderText="Filter by date"
        isClearable
      />

      <Select
        options={creditDebitOptions}
        value={creditDebitOptions.find((option) => option.value === typeFilter)}
        onChange={(option) => setTypeFilter(option.value)}
        styles={selectStyles}
        className="w-full md:w-48"
        placeholder="Filter by type"
        isClearable={false}
      />
    </div>
  );
};

const PaymentsTable = ({ columns, data }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data });

  return (
    <div className="overflow-x-auto flex justify-center items-center overflow-hidden">
      <table {...getTableProps()} className="max-w-full sm:max-w-[80%] border-collapse text-lg">
        <thead className="bg-gray-100">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  className="border-b px-4 py-2 text-left font-semibold text-black whitespace-nowrap"
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className="hover:bg-gray-100">
                {row.cells.map((cell) => (
                  <td
                    {...cell.getCellProps()}
                    className="border-b px-4 py-2 text-black whitespace-nowrap"
                    style={{
                      color: cell.column.id === 'transactionDirection' && cell.value === 'credit' ? 'green' :
                             cell.column.id === 'transactionDirection' && cell.value === 'debit' ? 'red' : 'inherit',
                      fontWeight: cell.column.id === 'transactionDirection' ? 'bold' : 'normal',
                    }}
                  >
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default function Payments() {
  const [dateFilter, setDateFilter] = useState(null);
  const [typeFilter, setTypeFilter] = useState('');
  const [paymentsData, setPaymentsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPayments = async () => {
      setLoading(true);
      const response = await getPaymentsPaginated(1, 10);
      if (response.success) {
        setPaymentsData(response.payments);
      } else {
        setError(response.message);
      }
      setLoading(false);
    };

    fetchPayments();
  }, []);

  const columns = useMemo(
    () => [
      { Header: 'Amount', accessor: 'amount' },
      { Header: 'Currency', accessor: 'currency' },
      {
        Header: 'Transaction Direction',
        accessor: 'transactionDirection',
        Cell: ({ value }) => (
          <span className={`font-bold ${value === 'credit' ? 'text-green-500' : 'text-red-500'}`}>
            {value}
          </span>
        ),
      },
      { Header: 'Status', accessor: 'status' },
      {
        Header: 'Vendors',
        accessor: 'vendors',
        Cell: ({ value }) => value.join(', '),
      },
      {
        Header: 'Date',
        accessor: 'createdAt',
        Cell: ({ value }) => new Date(value).toLocaleDateString(),
      },
    ],
    []
  );

  const filteredData = useMemo(() => {
    return paymentsData.filter((payment) => {
      const matchesDate =
        !dateFilter || new Date(payment.createdAt).toDateString() === dateFilter.toDateString();
      const matchesType =
        !typeFilter || payment.transactionDirection.toLowerCase() === typeFilter.toLowerCase();
      return matchesDate && matchesType;
    });
  }, [paymentsData, dateFilter, typeFilter]);

  if (loading) return <p className="text-center text-black">Loading...</p>;
  if (error) return <p className="text-red-500">{error}</p>;

  return (
    <div className="p-6 bg-white text-black h-screen flex flex-col">
      <h2 className="text-2xl font-bold mb-4">Payments Management</h2>

      <GlobalFilter
        dateFilter={dateFilter}
        setDateFilter={setDateFilter}
        typeFilter={typeFilter}
        setTypeFilter={setTypeFilter}
      />

      <PaymentsTable columns={columns} data={filteredData} />
    </div>
  );
}
