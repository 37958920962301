import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { createOrder } from "../Hooks/ordersHook";
import { clearCart } from '../redux/features/cartSlice';
import { useDispatch } from 'react-redux';

export default function PaymentSuccess() {
  const selectedPayment = localStorage.getItem('paymentMethod');
  const selectedShippingData = JSON.parse(localStorage.getItem('selectedShippingData'));
  const discount = localStorage.getItem('discount');
  const cartItems = JSON.parse(localStorage.getItem('cartItems'));
  const voucherCode = localStorage.getItem('voucherCode');
  const dispatch = useDispatch();

  useEffect(() => {
    const handleOrderCreation = async () => {
      try {
        const response = await createOrder({
          selectedShippingData,
          selectedPayment,
          discount,
          voucherCode,
          cartItems,
        });
        console.log(response);
        if (response.success) {
          console.log('Order created successfully');
          // Remove items from localStorage
          localStorage.removeItem('paymentMethod');
          localStorage.removeItem('selectedShippingData');
          localStorage.removeItem('discount');
          localStorage.removeItem('cartItems');
          localStorage.removeItem('voucherCode');
          // Clear cart in Redux
          dispatch(clearCart());
        }
      } catch (error) {
        console.error('Error creating order:', error);
      }
    };

    handleOrderCreation();
  }, []);


  return (
    <div className="min-h-screen flex flex-col justify-center items-center bg-white text-black">
      {/* Framer Motion Animation for Success Message */}
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, ease: 'easeOut' }}
        className="text-center"
      >
        <motion.div
          className="text-4xl font-bold mb-4"
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.5, ease: 'easeOut' }}
        >
          Payment Successful!
        </motion.div>
        <motion.p
          className="text-lg mb-6"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5, duration: 0.5 }}
        >
          Your payment via{' '}
          <span className="font-semibold">{selectedPayment || 'Unknown Method'}</span> has been completed successfully.
        </motion.p>
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.8, duration: 0.8 }}
        >
          <Link
            to="/main-page"
            className="px-6 py-3 bg-black text-white hover:bg-gray-800 rounded-lg font-medium"
          >
            Go to Homepage
          </Link>
        </motion.div>
      </motion.div>

      {/* Framer Motion Animation for Check Icon */}
      <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ duration: 0.5, ease: 'easeOut', delay: 0.3 }}
        className="mt-8"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-16 w-16 text-green-600"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M9 12l2 2 4-4M7 12a5 5 0 1110 0 5 5 0 01-10 0z"
          />
        </svg>
      </motion.div>
    </div>
  );
}
