import React, { useState, useEffect } from 'react';
import { FaFilter, FaTimes, FaChevronDown, FaChevronRight } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { getUserProfile, followUser, unfollowUser } from '../Hooks/UserHooks';
import { toast } from 'react-toastify';

export default function ProfilePage() {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [user, setUserData] = useState({})
  const [products, setProductsData] = useState([]);
  const [isFollowing, setIsFollowing] = useState(false);

  const twmUser = JSON.parse(localStorage.getItem('twmUser'));


  const {userId} = useParams();

  useEffect(() => {
    const fetchUserProfile = async () => {
      const response = await getUserProfile(userId);
      console.log(response);
      if (response.success) {
        setUserData(response.user);
        if (response.user.followers.includes(twmUser._id)) {
          setIsFollowing(true);
        }
        setProductsData(response.products);
      } else {
        toast.error(response.message);
      }
    };
    fetchUserProfile();
  }, [userId]);
  
  const [expandedSections, setExpandedSections] = useState({
    category: false,
    price: false,
    size: false,
    shipping: false,
    availability: false,
    type: false,
    condition: false,
  });

  const followUserHandler = async () => {
    const response = await followUser(userId);
    if (response.success) {
      setUserData((prev) => ({ ...prev, followersCount: prev.followersCount + 1 }));
      setIsFollowing(true)
      toast.success(`You are now following ${user.name}`);
    } else {
      toast.error(response.message);
    }
  };

  const unfollowUserHandler = async () => {
    const response = await unfollowUser(userId);
    if (response.success) {
      setUserData((prev) => ({ ...prev, followersCount: prev.followersCount - 1 }));
      setIsFollowing(false)
      toast.success(`You have unfollowed ${user.name}`);
    } else {
      toast.error(response.message);
    }
  };


  // Dummy data for products
  // const products = [
  //   { id: 1, image: 'https://via.placeholder.com/150', title: 'Product 1', size: 'OS', price: '$25' },
  //   { id: 2, image: 'https://via.placeholder.com/150', title: 'Product 2', size: 'M', price: '$45' },
  //   { id: 3, image: 'https://via.placeholder.com/150', title: 'Product 3', size: 'L', price: '$30' },
  //   { id: 4, image: 'https://via.placeholder.com/150', title: 'Product 4', size: 'S', price: '$60' },
  // ];

  // // Dummy user data
  // const user = {
  //   name: 'Sherry B',
  //   username: '@thewm',
  //   isOwner: true,
  //   listings: 1,
  //   shares: 0,
  //   followers: 43,
  //   following: 142,
  // };

  // Toggle filter drawer
  const toggleFilter = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  // Toggle section collapse
  const toggleSection = (section) => {
    setExpandedSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  return (
    <div className="bg-white text-black min-h-screen">
      {/* Cover Photo */}
      <div className="relative h-52">
        <img
          src={user.coverPhoto ? user.coverPhoto : "https://placehold.co/800x200/png"} // Random cover picture
          alt="Cover"
          className="w-full h-full object-cover"
        />
        <div className="absolute bottom-6 left-4 flex items-center space-x-4">
          {/* Profile Picture */}
          <img
            src={user.profilePicture ? user.profilePicture : "https://via.placeholder.com/150"} // Random profile picture
            alt="Profile"
            className="w-24 h-24 bg-gray-400 rounded-full border-4 border-white object-cover"
          />
          {/* User Info */}
          <div>
            <h1 className="text-xl font-semibold">{user.name}</h1>
            {/* <p className="text-sm text-gray-600">{user.username}</p> */}
            {user._id === twmUser._id ? (
              <button className="mt-2 px-4 py-1 border border-black text-black rounded hover:bg-black hover:text-white transition">
                Edit Profile
              </button>
            ) : (
              <button className="mt-2 px-4 py-1 border border-black text-black rounded hover:bg-black hover:text-white transition" onClick={isFollowing ? unfollowUserHandler : followUserHandler}>
                {isFollowing ? 'Unfollow' : 'Follow'}
              </button>
            )}
          </div>
        </div>
      </div>

      {/* Stats Section */}
      <div className="flex space-x-8 ml-10 border-b border-gray-200 py-4">
        <div className="text-center">
          <p className="text-lg font-semibold">{products.length}</p>
          <p className="text-sm text-gray-600">Listing</p>
        </div>
        {/* <div className="text-center">
          <p className="text-lg font-semibold">{user.shares}</p>
          <p className="text-sm text-gray-600">Share</p>
        </div> */}
        <div className="text-center">
          <p className="text-lg font-semibold">{user.followersCount}</p>
          <p className="text-sm text-gray-600">Followers</p>
        </div>
        <div className="text-center">
          <p className="text-lg font-semibold">{user.followingCount}</p>
          <p className="text-sm text-gray-600">Following</p>
        </div>
      </div>

      {/* Filter Button on Mobile */}
      <div className="flex justify-between items-center px-4 py-2 border-b border-gray-200 md:hidden">
        <p className="text-sm font-semibold">Posh Markets</p>
        <button onClick={toggleFilter} className="text-black">
          <FaFilter className="text-lg" />
        </button>
      </div>

      <div className="flex">
        {/* Sidebar Filters for Desktop */}
        <aside className="hidden md:block w-1/4 p-4 border-r border-gray-200 space-y-6">
          <FilterSections />
        </aside>

        {/* Products */}
        <main className="w-full md:w-3/4 p-4">
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
            {products.map((product) => (
              <div key={product.id} className="border border-gray-200 p-4">
                <img src={product.images[0].image} alt={product.productName} className="w-full h-40 object-cover mb-2" />
                <h3 className="text-sm font-semibold">{product.productName}</h3>
                {/* <p className="text-sm text-gray-500">Size: {product.size}</p> */}
                <p className="text-sm text-gray-500">{product.discountedPrice? product.discountedPrice : product.regularPrice}</p>
              </div>
            ))}
          </div>
        </main>
      </div>

      {/* Filter Drawer for Mobile with Transition */}
      <div
        className={`fixed inset-0 z-50 flex justify-end transform transition-transform duration-300 ease-in-out ${
          isFilterOpen ? 'translate-x-0' : 'translate-x-full'
        } md:hidden`}
      >
        <div className="w-2/3 bg-white h-full p-4">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-lg font-semibold">Filter</h2>
            <button onClick={toggleFilter} className="text-black">
              <FaTimes className="text-lg" />
            </button>
          </div>

          {/* Collapsible Filter Options in Drawer */}
          <div className="space-y-4">
            {Object.keys(expandedSections).map((section) => (
              <div key={section}>
                <div
                  className="flex justify-between items-center cursor-pointer"
                  onClick={() => toggleSection(section)}
                >
                  <span className="capitalize">{section}</span>
                  {expandedSections[section] ? <FaChevronDown /> : <FaChevronRight />}
                </div>
                {expandedSections[section] && <FilterSectionContent section={section} />}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

// Component for displaying filter sections
const FilterSections = () => (
  <>
    {/* Type Filter */}
    <div>
      <h2 className="font-semibold text-lg">Type</h2>
      <ul className="mt-2 space-y-2">
        <li><input type="radio" name="type" /> <span className="ml-2">All Types</span></li>
        <li><input type="radio" name="type" /> <span className="ml-2">Closet</span></li>
        <li><input type="radio" name="type" /> <span className="ml-2">Boutique</span></li>
      </ul>
    </div>
    {/* Additional filter sections (Categories, Size, Price, Shipping, Condition, Availability) */}
    <FilterSectionContent section="categories" />
    <FilterSectionContent section="price" />
    <FilterSectionContent section="size" />
    <FilterSectionContent section="shipping" />
    <FilterSectionContent section="condition" />
    <FilterSectionContent section="availability" />
  </>
);

// Component for filter section content
const FilterSectionContent = ({ section }) => {
  switch (section) {
    case 'categories':
      return (
        <div className="mt-2">
          <h2 className="font-semibold text-lg">Categories</h2>
          <p className="mt-2">All Categories</p>
          <p className="text-purple-600 font-semibold">Women</p>
        </div>
      );
    case 'size':
      return (
        <div className="mt-2">
          <h2 className="font-semibold text-lg">Size</h2>
          <p className="mt-2"><input type="checkbox" /> <span className="ml-2">All Sizes</span></p>
          <p><input type="checkbox" /> <span className="ml-2">My Size</span> <span className="text-blue-600 ml-2 cursor-pointer">Edit</span></p>
          <p className="text-sm text-gray-500">Select a category for specific sizes.</p>
        </div>
      );
    case 'price':
      return (
        <div className="mt-2">
          <h2 className="font-semibold text-lg">Price</h2>
          <ul className="mt-2 space-y-2">
            <li><input type="checkbox" /> <span className="ml-2">All Prices</span></li>
            <li><input type="checkbox" /> <span className="ml-2">Under $25</span></li>
            <li><input type="checkbox" /> <span className="ml-2">$25 - $50</span></li>
            <li><input type="checkbox" /> <span className="ml-2">$50 - $100</span></li>
            <li><input type="checkbox" /> <span className="ml-2">$100 - $250</span></li>
            <li><input type="checkbox" /> <span className="ml-2">$250 - $500</span></li>
            <li><input type="checkbox" /> <span className="ml-2">Over $500</span></li>
          </ul>
          <div className="mt-2 flex items-center space-x-2">
            <input type="text" placeholder="Min Price" className="w-1/2 border px-2 py-1 text-sm" />
            <span>to</span>
            <input type="text" placeholder="Max Price" className="w-1/2 border px-2 py-1 text-sm" />
            <button className="text-blue-600">Apply</button>
          </div>
        </div>
      );
    case 'shipping':
      return (
        <div className="mt-2">
          <h2 className="font-semibold text-lg">Shipping</h2>
          <ul className="mt-2 space-y-2">
            <li><input type="radio" name="shipping" /> <span className="ml-2">All Items</span></li>
            <li><input type="radio" name="shipping" /> <span className="ml-2">Free</span></li>
            <li><input type="radio" name="shipping" /> <span className="ml-2">Discounted + Free</span></li>
          </ul>
        </div>
      );
    case 'condition':
      return (
        <div className="mt-2">
          <h2 className="font-semibold text-lg">Condition</h2>
          <ul className="mt-2 space-y-2">
            <li><input type="radio" name="condition" /> <span className="ml-2">All Conditions</span></li>
            <li><input type="radio" name="condition" /> <span className="ml-2">New With Tags</span></li>
          </ul>
        </div>
      );
    case 'availability':
      return (
        <div className="mt-2">
          <h2 className="font-semibold text-lg">Availability</h2>
          <ul className="mt-2 space-y-2">
            <li><input type="radio" name="availability" /> <span className="ml-2">All Items</span></li>
            <li><input type="radio" name="availability" /> <span className="ml-2">Available Items</span></li>
            <li><input type="radio" name="availability" /> <span className="ml-2">Available + Dropping Soon Items</span></li>
            <li><input type="radio" name="availability" /> <span className="ml-2">Dropping Soon Items</span></li>
            <li><input type="radio" name="availability" /> <span className="ml-2">Sold Items</span></li>
          </ul>
        </div>
      );
    default:
      return null;
  }
};
