import axios from "axios";

axios.defaults.baseURL =
  process.env.REACT_APP_BACKEND_URL || "http://localhost:5000";

export async function addTag(name) {
  try {
    const token = localStorage.getItem("twmToken");

    if (!token) {
      return {
        success: false,
        message: "Please login first",
        status: 401,
      };
    }

    const { data, status } = await axios.post(
      "/api/tags/add-tag",
      { name },
      {
        headers: {
          "TWM-auth-token": token,
        },
      }
    );

    return {
      success: data.success,
      message: data.message,
      tag: data.tag,
      status,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}

export async function editTag(tagId, name) {
  try {
    const token = localStorage.getItem("twmToken");

    if (!token) {
      return {
        success: false,
        message: "Please login first",
        status: 401,
      };
    }

    const { data, status } = await axios.put(
      `/api/tags/edit-tag/${tagId}`,
      { name },
      {
        headers: {
          "TWM-auth-token": token,
        },
      }
    );

    return {
      success: data.success,
      message: data.message,
      tag: data.tag,
      status,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}

export async function deleteTag(tagId) {
  try {
    const token = localStorage.getItem("twmToken");

    if (!token) {
      return {
        success: false,
        message: "Please login first",
        status: 401,
      };
    }

    const { data, status } = await axios.delete(`/api/tags/delete-tag/${tagId}`, {
      headers: {
        "TWM-auth-token": token,
      },
    });

    return {
      success: data.success,
      message: data.message,
      status,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}

export async function getAllTags() {
  try {
    const token = localStorage.getItem("twmToken");

    if (!token) {
      return {
        success: false,
        message: "Please login first",
        status: 401,
      };
    }

    const { data, status } = await axios.get("/api/tags/get-all-tags", {
      headers: {
        "TWM-auth-token": token,
      },
    });

    return {
      success: data.success,
      message: data.message,
      tags: data.tags,
      status,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}

export async function searchTags(searchTerm) {
  try {
    const token = localStorage.getItem("twmToken");

    if (!token) {
      return {
        success: false,
        message: "Please login first",
        status: 401,
      };
    }

    if (!searchTerm || searchTerm.trim() === "") {
      return {
        success: false,
        message: "Search term is required.",
        status: 400,
      };
    }

    const { data, status } = await axios.get("/api/tags/search-tags", {
      params: { searchTerm },
      headers: {
        "TWM-auth-token": token,
      },
    });

    return {
      success: data.success,
      message: data.message,
      tags: data.tags,
      status,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}
