// src/quizData.js
const quizData = [
    {
        question: "What kind of wedding venue excites you the most?",
        options: [
            { image: "https://res.cloudinary.com/dlhwfesiz/image/upload/v1730901972/f1/classicvenue_xceqfe.webp", style: "Classic" },
            { image: "https://res.cloudinary.com/dlhwfesiz/image/upload/v1730901973/f1/rusticvenue_sxg6qn.webp", style: "Rustic" },
            { image: "https://res.cloudinary.com/dlhwfesiz/image/upload/v1730901972/f1/romanticvenue_feslqu.webp", style: "Romantic" },
            { image: "https://res.cloudinary.com/dlhwfesiz/image/upload/v1730901976/f1/modernvenue_nnavz2.webp", style: "Modern" },
            { image: "https://res.cloudinary.com/dlhwfesiz/image/upload/v1730901972/f1/Bohemianvenue_eiybha.webp", style: "Bohemian" },
            { image: "https://res.cloudinary.com/dlhwfesiz/image/upload/v1730901979/f1/Eclecticvenue_smjk56.webp", style: "Eclectic" },
            { image: "https://res.cloudinary.com/dlhwfesiz/image/upload/v1730901971/f1/Glamorousvenue_yg4wuf.webp", style: "Glamorous" }
        ]
    },
    {
        question: "Which wedding invitation design would you choose?",
        options: [
            { image: "https://res.cloudinary.com/dlhwfesiz/image/upload/v1730902009/f2/classicinvitation_fipnts.webp", style: "Classic" },
            { image: "https://res.cloudinary.com/dlhwfesiz/image/upload/v1730902010/f2/bohemeninvitation_xhpqft.webp", style: "Bohemian" },
            { image: "https://res.cloudinary.com/dlhwfesiz/image/upload/v1730902008/f2/eclecticinvitation_zabgg7.webp", style: "Eclectic" },
            { image: "https://res.cloudinary.com/dlhwfesiz/image/upload/v1730902011/f2/glamorusinvitation_h1mrih.webp", style: "Glamorous" },
            { image: "https://res.cloudinary.com/dlhwfesiz/image/upload/v1730902009/f2/morderinvitation_qauq9f.webp", style: "Modern" },
            { image: "https://res.cloudinary.com/dlhwfesiz/image/upload/v1730902009/f2/rommanticinvitiation_khlcdf.webp", style: "Romantic" },
            { image: "https://res.cloudinary.com/dlhwfesiz/image/upload/v1730902010/f2/Rusticinvitaion_ylduf6.webp", style: "Rustic" }
        ]
    },
    {
        question: "How would you describe your wedding décor?",
        options: [
            { image: "https://res.cloudinary.com/dlhwfesiz/image/upload/v1730902127/f3/classic0_b6yezj.webp", style: "Classic" },
            { image: "https://res.cloudinary.com/dlhwfesiz/image/upload/v1730902129/f3/rustic0_nudmmd.webp", style: "Rustic" },
            { image: "https://res.cloudinary.com/dlhwfesiz/image/upload/v1730902128/f3/eclectic0_yxqsch.webp", style: "Eclectic" },
            { image: "https://res.cloudinary.com/dlhwfesiz/image/upload/v1730902128/f3/glamorous0_l1tv0r.webp", style: "Glamorous" },
            { image: "https://res.cloudinary.com/dlhwfesiz/image/upload/v1730902129/f3/modern0_rrmhh4.webp", style: "Modern" },
            { image: "https://res.cloudinary.com/dlhwfesiz/image/upload/v1730902127/f3/classic0_b6yezj.webp", style: "Romantic" },
            { image: "https://res.cloudinary.com/dlhwfesiz/image/upload/v1730902129/f3/bohemian0_iaaoyo.webp", style: "Bohemian" }
        ]
    },
    {
        question: "What’s your ideal wedding cake style?",
        options: [
            { image: "https://res.cloudinary.com/dlhwfesiz/image/upload/v1730902330/f4/classiccake_bhpnfs.webp", style: "Classic" },
            { image: "https://res.cloudinary.com/dlhwfesiz/image/upload/v1730902333/f4/Rusticcake_dcfwak.webp", style: "Rustic" },
            { image: "https://res.cloudinary.com/dlhwfesiz/image/upload/v1730902332/f4/Eclecticcake_jgqcey.webp", style: "Eclectic" },
            { image: "https://res.cloudinary.com/dlhwfesiz/image/upload/v1730902331/f4/Glamorouscake_ftbq2x.webp", style: "Glamorous" },
            { image: "https://res.cloudinary.com/dlhwfesiz/image/upload/v1730902333/f4/moderncake_fblwgy.webp", style: "Modern" },
            { image: "https://res.cloudinary.com/dlhwfesiz/image/upload/v1730902332/f4/roamnticcake_pg4rkr.webp", style: "Romantic" },
            { image: "https://res.cloudinary.com/dlhwfesiz/image/upload/v1730902334/f4/Bohemiancake_bkhhb7.webp", style: "Bohemian" }
        ]
    },
    {
        question: "What kind of flowers would you choose for your bouquet?",
        options: [
            { image: "https://res.cloudinary.com/dlhwfesiz/image/upload/v1730902405/f5/classicbouquet_yc9de9.webp", style: "Classic" },
            { image: "https://res.cloudinary.com/dlhwfesiz/image/upload/v1730902384/f5/bohemenbouquet_fxz8sf.webp", style: "Bohemian" },
            { image: "https://res.cloudinary.com/dlhwfesiz/image/upload/v1730902380/f5/eclecticbouquet_j2cdbc.webp", style: "Eclectic" },
            { image: "https://res.cloudinary.com/dlhwfesiz/image/upload/v1730902419/f5/glamorusbouquet_i4oqks.webp", style: "Glamorous" },
            { image: "https://res.cloudinary.com/dlhwfesiz/image/upload/v1730902373/f5/modernbouquet_ydws3q.webp", style: "Modern" },
            { image: "https://res.cloudinary.com/dlhwfesiz/image/upload/v1730902413/f5/romancticbouquet_ptwnmu.webp", style: "Romantic" },
            { image: "https://res.cloudinary.com/dlhwfesiz/image/upload/v1730902417/f5/rusticbouquet_j7r2xh.webp", style: "Rustic" }
        ]
    },
    {
        question: "What type of music will set the tone for your wedding?",
        options: [
            { image: "https://res.cloudinary.com/dlhwfesiz/image/upload/v1730902441/f6/classicmusic_ofxchy.webp", style: "Classic" },
            { image: "https://res.cloudinary.com/dlhwfesiz/image/upload/v1730902438/f6/rusticmusic_nvvjvl.webp", style: "Rustic" },
            { image: "https://res.cloudinary.com/dlhwfesiz/image/upload/v1730902441/f6/Eclecticmusic_dpc5as.webp", style: "Eclectic" },
            { image: "https://res.cloudinary.com/dlhwfesiz/image/upload/v1730902440/f6/Glamorousmusic_bukfwi.webp", style: "Glamorous" },
            { image: "https://res.cloudinary.com/dlhwfesiz/image/upload/v1730902441/f6/Modernmusic_myttfy.webp", style: "Modern" },
            { image: "https://res.cloudinary.com/dlhwfesiz/image/upload/v1730902441/f6/Romanticmusic_kpybvd.webp", style: "Romantic" },
            { image: "https://res.cloudinary.com/dlhwfesiz/image/upload/v1730902441/f6/Bohemianmusic_onlovq.webp", style: "Bohemian" }
        ]
    }
];

export default quizData;
