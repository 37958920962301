import React, { useState, useEffect } from "react";
import ProductCard from "./ProductCard";
import SearchBar from "../../components/SearchBar";
import { getProducts } from "../../Hooks/productHooks"; // Import your custom API function
import { toast } from "react-toastify";
import { Link, useSearchParams } from "react-router-dom";

export default function Products() {
  // 1) Use React Router's `useSearchParams` hook
  const [searchParams, setSearchParams] = useSearchParams();

  // 2) Read the current page from the URL (default to 1 if no param)
  const pageFromUrl = parseInt(searchParams.get("page") || "1", 10);

  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortOption, setSortOption] = useState("Date");
  const [productCount, setProductCount] = useState(12);
  const [searchTerm, setSearchTerm] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [currentPage, setCurrentPage] = useState(pageFromUrl);
  const [totalPages, setTotalPages] = useState(1);

  // Keep `currentPage` in sync with the `page` URL param
  // so if the user clicks Back/Forward, we update the UI accordingly.
  useEffect(() => {
    if (pageFromUrl !== currentPage) {
      setCurrentPage(pageFromUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageFromUrl]);

  // Whenever `currentPage` changes, update the URL param.
  useEffect(() => {
    setSearchParams({ page: currentPage });
  }, [currentPage, setSearchParams]);

  // Fetch product data on component mount or when `currentPage`/`productCount` changes
  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);
      const response = await getProducts({
        page: currentPage,
        limit: productCount,
      });
      if (response.success) {
        setProducts(response.products);
        setTotalPages(response.pagination.totalPages);
      } else {
        toast.error(response.message || "Failed to fetch product data");
      }
      setLoading(false);
    };

    fetchProducts();
  }, [currentPage, productCount]);

  // Loading placeholder
  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <p>Loading products...</p>
      </div>
    );
  }

  // No products found
  if (!products.length) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <p>No products found. Please try again later.</p>
      </div>
    );
  }

  // Search handlers
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    if (value.length > 0) {
      const filteredSuggestions = products
        .map((product) => product.productName)
        .filter((name) => name.toLowerCase().includes(value.toLowerCase()));
      setSuggestions(filteredSuggestions);
    } else {
      setSuggestions([]);
    }
  };

  const handleSearchTermSelect = (selectedTerm) => {
    console.log(`Search term selected: ${selectedTerm}`);
    // Optionally, filter the products based on the selected search term
  };

  // Pagination handler
  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  return (
    <div className="bg-white">
      <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:pb-24 sm:pt-16 lg:max-w-7xl lg:px-8">
        <nav className="text-gray-600 text-sm mb-6">
          <ul className="flex items-center space-x-2">
            <li>
              <Link to="/" className="hover:underline">
                Home
              </Link>
            </li>
            <li>/</li>
            <li>Products</li>
          </ul>
        </nav>

        <div className="mb-6">
          <SearchBar onSearchTermSelect={handleSearchTermSelect} />
        </div>

        {/* Sorting and View Control Panel */}
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center space-x-4">
            {/* Sort By Option */}
            <div className="flex items-center">
              <span className="text-gray-700 mr-2">Sort by</span>
              <select
                value={sortOption}
                onChange={(e) => setSortOption(e.target.value)}
                className="border rounded p-2 text-gray-700"
              >
                <option value="Date">Date</option>
                <option value="Price">Price</option>
                <option value="Rating">Rating</option>
              </select>
            </div>

            {/* Number of Products to Show */}
            <div className="flex items-center">
              <span className="text-gray-700 mr-2">Show</span>
              <select
                value={productCount}
                onChange={(e) => setProductCount(Number(e.target.value))}
                className="border rounded p-2 text-gray-700"
              >
                <option value={12}>12 Products</option>
                <option value={24}>24 Products</option>
                <option value={36}>36 Products</option>
              </select>
            </div>
          </div>
        </div>

        {/* Product Grid */}
        <div className="grid  gap-x-3 sm:gap-x-6 gap-y-10 grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          {products.map((product) => (
            <ProductCard key={product._id} product={product} />
          ))}
        </div>

        {/* Pagination Controls */}
        <div className="flex justify-between items-center mt-6">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300 disabled:opacity-50"
          >
            Previous
          </button>
          <span>
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300 disabled:opacity-50"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}
