import React from 'react';
import { useNavigate } from 'react-router-dom';

const OrderList = ({ orders, getStatusColor, title }) => {
  const navigate = useNavigate();

  return (
    <div>
      <h3 className="text-lg sm:text-xl font-semibold mb-4">{title}</h3>
      {orders.length > 0 ? (
        orders.map((order) => (
          <div key={order._id} className="flex flex-col sm:flex-row items-center mb-4 border p-4 rounded-md">
            {/* Image Section */}
            <img
              src={order.img || 'https://via.placeholder.com/100'} // Default image if undefined
              alt={order.item || 'Unknown Product'}
              className="w-28 h-28 sm:w-20 sm:h-20 mb-4 sm:mb-0 sm:mr-4"
            />
            
            {/* Order Details */}
            <div className="flex-1 text-center sm:text-left">
              <h4 className="font-semibold text-base sm:text-lg">{order.item || 'Unknown Product'}</h4>
              <p>Price: ${order.price ? order.price.toFixed(2) : '0.00'}</p>
              <p>Items: {order.itemsCount || 0}</p>
              <p>Payment Type: {order.paymentType || 'N/A'}</p>
              <p className={`font-semibold ${getStatusColor(order.status || 'processing')}`}>
                Status: {order.status || 'Processing'}
              </p>
            </div>

            {/* View Details Button */}
            <button
              onClick={() => navigate(`/my-order/${order._id}`)}
              className="bg-black text-white py-1 px-4 rounded-md mt-4 sm:mt-0 sm:ml-4 w-full sm:w-auto"
            >
              View Details
            </button>
          </div>
        ))
      ) : (
        <p>No orders found.</p>
      )}
    </div>
  );
};

export default OrderList;
