import React from 'react';
import TestimonialSection from '../../components/Home/TestimonialSection';
import Features from '../../components/Home/Features';

export default function HowToSell() {
    return (
        <div className="bg-white">
            {/* Hero Section */}
            <section className="relative">
                <img
                    src="https://res.cloudinary.com/dlhwfesiz/image/upload/v1730196069/AdobeStock_233896299-scaled_tsqcoa.jpg"
                    alt="Wedding Marketplace"
                    className="w-full h-[500px] object-cover"
                />
                <div className="absolute inset-0 bg-black bg-opacity-50"></div>
                <div className="absolute inset-0 flex flex-col items-center justify-center text-white text-center z-10">
                    <h1 className="text-4xl sm:text-5xl font-bold mb-4">You Said Yes, Now It’s Time To Sell The Rest.</h1>
                    <p className="text-lg sm:text-xl mb-6">Learn everything you need to know to start selling today.</p>
                </div>
            </section>

            <Features />

            {/* Create Your Listing Section */}
            <section className="max-w-7xl mx-auto py-16 px-4 text-gray-900">
                <h2 className="text-3xl font-bold text-[#DB8ABA] mb-6">CREATE YOUR LISTING</h2>

                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                    {/* Content spans 2 columns */}
                    <div className="md:col-span-2">
                        <p className="text-lg mb-6">
                            Connecting to buyers is as easy as 1, 2, 3! Our user-friendly categories provide you with the opportunity
                            to showcase your products exactly where they need to be in order to sell.
                        </p>
                        <h3 className="font-semibold mb-2">1. Take High Quality Photos</h3>
                        <p className="text-base sm:text-lg mb-6">
                            To maximize your chances of a successful sale, we'd like to share some photography tips with you. When
                            taking photos of your item, ensure that your lens is clean and that you are using natural and even lighting.
                            Avoid busy backgrounds or bright colors that could be distracting. Take at least one wide shot to show the entire
                            item and one close-up to show the details.
                        </p>
                        <h3 className="font-semibold mb-2">2. Describe Your Item</h3>
                        <p className="text-base sm:text-lg mb-6">
                            Clear descriptions are essential for effective selling. Use relevant keywords to create a robust description
                            with the necessary details such as color, condition, brand, dimensions, and other relevant information.
                        </p>
                        <h3 className="font-semibold mb-2">3. Set Your Price</h3>
                        <p className="text-base sm:text-lg mb-6">
                            Fair pricing is essential for sustainable selling. To ensure you’re not undercharging or overvaluing your item,
                            search for similar listings in your category to see what they sold for.
                        </p>
                    </div>

                    {/* Image spans 1 column */}
                    <div className="flex justify-center items-center md:col-span-1">
                        <img
                            src="https://res.cloudinary.com/dlhwfesiz/image/upload/v1730196069/AdobeStock_233896299-scaled_tsqcoa.jpg"
                            alt="Create Listing"
                            className="w-[300px] h-auto object-cover rounded-md"
                        />
                    </div>
                </div>
            </section>

            {/* Ship Your Item to the Buyer Section */}
            <section className="bg-[#EDECF0] py-16">
                <div className="max-w-7xl mx-auto px-4">
                    <h2 className="text-3xl font-bold text-[#DB8ABA] mb-6">SHIP YOUR ITEM TO THE BUYER</h2>

                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                        {/* Content spans 2 columns */}
                        <div className="md:col-span-2">
                            <p className="text-lg mb-6">
                                Once a buyer purchases your product, you will be provided with their shipping address. It is up to you to
                                ensure the packages are addressed correctly to ensure they reach their destination.
                            </p>
                            <p className="text-base sm:text-lg mb-6">
                                Ship your item as soon and as safely as possible. Pack items the same way you would want someone to send them to you. Take extra care to protect the items using bubble wrap, extra packing materials to prevent movement and correct box sizes. If your items are fragile, make sure the box is well labelled to indicate the shipping service should handle the package with care.
                            </p>
                            <p className='text-base sm:text-lg mb-6'>Shipping costs are part of the seller’s listing process, and you should be clear about shipping costs upfront. Ship your items using a tracking number so you will always know where your package is, reduce shipping delays and ensure the items arrive where they are expected. TWM is not responsible for or involved in the shipping of items.</p>
                            <p className='text-base sm:text-lg mb-6'>Our Seller Agreement and Buyer-Seller Agreement outlines what both parties are agreeing to when performing a transaction.</p>
                        </div>

                        {/* Image spans 1 column */}
                        <div className="flex justify-center items-center md:col-span-1">
                            <img
                                src="https://res.cloudinary.com/dlhwfesiz/image/upload/v1730196069/AdobeStock_233896299-scaled_tsqcoa.jpg"
                                alt="Shipping Items"
                                className="w-[300px] h-auto object-cover rounded-md"
                            />
                        </div>
                    </div>
                </div>
            </section>

            {/* Get Paid Section */}
            <section className="max-w-7xl mx-auto py-16 px-4 text-gray-900">

                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                    {/* Content spans 2 columns */}
                    <div className="md:col-span-2">
                        <h2 className="text-3xl font-bold text-[#DB8ABA] mb-6">GET PAID</h2>
                        <p className="text-lg mb-6">
                            After completing your transaction, you will receive your funds, minus TWM’s 3% transaction fee. This fee helps support The Wedding Marketplace platform’s innovations and growth. You can visit the Withdraw page in your My Account section for more information.
                        </p>
                    </div>

                    {/* Image spans 1 column */}
                    <div className="flex justify-center items-center md:col-span-1">
                        <img
                            src="https://res.cloudinary.com/dlhwfesiz/image/upload/v1730196069/AdobeStock_233896299-scaled_tsqcoa.jpg"
                            alt="Get Paid"
                            className="w-[300px] h-auto object-cover rounded-md"
                        />
                    </div>
                </div>
            </section>

            {/* Ready to List Section */}
            <section className="bg-gray-100 py-16 text-center">

                <div className="grid grid-cols-1 md:grid-cols-3 gap-8 items-left">
                    <div className="md:col-span-2">
                        <h2 className="text-3xl font-bold text-[#DB8ABA] mb-6">READY TO LIST YOUR ITEM?</h2>
                        <button className="bg-[#262626] text-white py-3 px-6 text-lg rounded-lg">Start Selling</button>
                    </div>

                    {/* Image spans 1 column */}
                    <div className="flex justify-center items-center md:col-span-1">
                        <img
                            src="https://res.cloudinary.com/dlhwfesiz/image/upload/v1730196069/AdobeStock_233896299-scaled_tsqcoa.jpg"
                            alt="Ready to List"
                            className="w-[300px] h-auto object-cover rounded-md"
                        />
                    </div>
                </div>
            </section>

            {/* Testimonial Section */}
            <TestimonialSection />
        </div>
    );
}
