import React, { useState } from "react";
import Modal from "react-modal";
import {  toast } from "react-toastify";

Modal.setAppElement("#root"); // Set the app element to avoid accessibility warnings

export default function RefundModal({ isOpen, onClose, onSubmit }) {
  const [images, setImages] = useState([]);
  const [refundReason, setRefundReason] = useState("");

  const handleImageUpload = (event) => {
    const files = Array.from(event.target.files);
    if (files.length > 5) {
      toast.warn("You can upload a maximum of 5 images. Selecting the first 5.");
      const limitedFiles = files.slice(0, 5);
      convertToBase64(limitedFiles);
    } else {
      convertToBase64(files);
    }
  };

  const convertToBase64 = (files) => {
    Promise.all(
      files.map(
        (file) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(file);
          })
      )
    )
      .then((base64Images) => setImages(base64Images))
      .catch(() => toast.error("Failed to convert images to base64."));
  };

  const handleSubmit = () => {
    if (!refundReason.trim()) {
      toast.error("Please provide a reason for the refund.");
      return;
    }
    onSubmit({ images, refundReason });
    onClose();
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        className="bg-white rounded-lg max-w-lg w-full mx-auto p-6"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
      >
        <h2 className="text-2xl font-bold mb-6 text-gray-900">Request Refund</h2>
        <div className="mb-6">
          <label className="block text-gray-800 mb-3 font-medium">
            Upload Images (max 5):
          </label>
          <input
            type="file"
            multiple
            accept="image/*"
            onChange={handleImageUpload}
            className="border rounded px-3 py-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-400"
          />
          {images.length > 0 && (
            <div className="mt-4 grid grid-cols-3 gap-4">
              {images.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`Uploaded ${index + 1}`}
                  className="w-24 h-24 object-cover border rounded-md shadow-md"
                />
              ))}
            </div>
          )}
        </div>
        <div className="mb-6">
          <label className="block text-gray-800 mb-3 font-medium">
            Refund Reason:
          </label>
          <textarea
            value={refundReason}
            onChange={(e) => setRefundReason(e.target.value)}
            className="border rounded px-3 py-2 w-full h-28 resize-none focus:outline-none focus:ring-2 focus:ring-blue-400"
            placeholder="Provide a reason for the refund"
          />
        </div>
        <div className="flex justify-end gap-4">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300 text-gray-800 font-medium"
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 font-medium"
          >
            Submit
          </button>
        </div>
      </Modal>
    </>
  );
}
