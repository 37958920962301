import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { searchItems } from "../Hooks/productHooks";

const SearchBar = ({ onSearchTermSelect }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleSearchChange = async (e) => {
    const value = e.target.value.trim();
    setSearchTerm(value);

    if (value.length === 0) {
      setSuggestions([]);
      setLoading(false);
      return;
    }

    setLoading(true);
    try {
      const response = await searchItems(value);
      if (response.success) {
        setSuggestions(response.results);
      } else {
        setSuggestions([]);
      }
    } catch (error) {
      console.error("Error fetching suggestions:", error);
      setSuggestions([]);
    } finally {
      setLoading(false);
    }
  };

  const handleSelectSuggestion = (suggestion) => {
    setSearchTerm(suggestion.name);
    setSuggestions([]);
    onSearchTermSelect(suggestion);

    if (suggestion.isCategory) {
      navigate(`/category/${suggestion.slug}`);
    } else if (suggestion.isProduct) {
      navigate(`/product/${suggestion._id}`);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && searchTerm.trim() !== "") {
      navigate(`/search?query=${encodeURIComponent(searchTerm)}`);
      setSuggestions([]);
    }
  };

  return (
    <div className="relative">
      <input
        type="text"
        value={searchTerm}
        onChange={handleSearchChange}
        onKeyDown={handleKeyDown}
        placeholder="Search for products or categories..."
        className="w-full p-3 border rounded-lg text-gray-700"
      />

      {/* Loading Indicator */}
      {loading && searchTerm.length > 0 && (
        <div className="absolute left-0 w-full bg-white border mt-1 rounded-lg shadow-lg z-10 p-3 text-gray-500">
          Loading...
        </div>
      )}

      {/* Search Suggestions */}
      {suggestions.length > 0 && searchTerm.length > 0 && !loading && (
        <div className="absolute left-0 w-full bg-white border mt-1 rounded-lg shadow-lg z-10">
          {suggestions.map((suggestion) => (
            <div
              key={suggestion._id}
              className="p-3 cursor-pointer hover:bg-gray-100 flex justify-between items-center"
              onClick={() => handleSelectSuggestion(suggestion)}
            >
              <span>{suggestion.name}</span>
              {suggestion.isCategory ? (
                <span className="text-xs text-blue-500">Category</span>
              ) : (
                <span className="text-xs text-green-500">Product</span>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SearchBar;
