import { configureStore } from '@reduxjs/toolkit';
import notificationReducer from './features/notificationSlice';
import cartReducer from './features/cartSlice';


const store = configureStore({
  reducer: {
    notifications: notificationReducer,
    cart: cartReducer,

  },
});

export default store;
