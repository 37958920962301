import React, { useState, useCallback, useRef } from 'react';
import {
  GoogleMap,
  Marker,
  Autocomplete,
  useJsApiLoader,
} from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '400px',
};

// Default map center (e.g., New York)
const defaultCenter = {
  lat: 40.7128,
  lng: -74.0060,
};

function MapWithSearch({ onAddressSelect, initialAddress }) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ['places'], // IMPORTANT: needs 'places' for Autocomplete
  });

  const [map, setMap] = useState(null);
  const [markerPosition, setMarkerPosition] = useState({
    lat: initialAddress?.lat || defaultCenter.lat,
    lng: initialAddress?.lng || defaultCenter.lng,
  });

  const autocompleteRef = useRef(null);

  const onLoad = useCallback((mapInstance) => {
    setMap(mapInstance);
  }, []);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  // When a user selects a place from the Autocomplete suggestions
  const handlePlaceChanged = () => {
    if (autocompleteRef.current != null) {
      const place = autocompleteRef.current.getPlace();
      if (place.geometry) {
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();

        // Move the marker to the selected location
        setMarkerPosition({ lat, lng });

        // Parse address components
        const addressComponents = place.address_components || [];
        const parsed = parseAddressComponents(addressComponents);

        // Callback to parent with full address info
        onAddressSelect({
          ...parsed,
          lat,
          lng,
          placeId: place.place_id || '',
        });

        // Center the map on the selected location
        map.panTo({ lat, lng });
      }
    }
  };

  // Helper to parse Google place.address_components
  // We attempt to identify line1, city, state, stateCode, postalCode, and country
  const parseAddressComponents = (components) => {
    let line1 = '';
    let city = '';
    let state = '';
    let stateCode = '';
    let postalCode = '';
    let country = '';

    components.forEach((component) => {
      const types = component.types; 
      // Street Number + Route => line1
      if (types.includes('street_number')) {
        line1 = component.long_name + ' ' + line1;
      }
      if (types.includes('route')) {
        line1 = line1 + component.long_name;
      }
      // City
      if (types.includes('locality')) {
        city = component.long_name;
      }
      // State or Province
      if (types.includes('administrative_area_level_1')) {
        state = component.long_name;      // e.g. "New York"
        stateCode = component.short_name; // e.g. "NY"
      }
      // Zip or Postal Code
      if (types.includes('postal_code')) {
        postalCode = component.long_name;
      }
      // Country
      if (types.includes('country')) {
        country = component.long_name;
      }
    });

    return {
      line1: line1.trim(),
      line2: '',
      city,
      state,
      stateCode,
      postalCode,
      country,
    };
  };

  // When the user drags the marker, update lat/lng and optionally reverse-geocode
  const handleMarkerDragEnd = async (e) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    setMarkerPosition({ lat, lng });

    // Reverse geocode if you want to update the address fields automatically
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: { lat, lng } }, (results, status) => {
      if (status === 'OK' && results[0]) {
        const place = results[0];
        const addressComponents = place.address_components || [];
        const parsed = parseAddressComponents(addressComponents);

        onAddressSelect({
          ...parsed,
          lat,
          lng,
          placeId: place.place_id || '',
        });
      }
    });
  };

  return isLoaded ? (
    <div>
      {/* Autocomplete Search Input */}
      <Autocomplete
        onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
        onPlaceChanged={handlePlaceChanged}
      >
        <input
          type="text"
          placeholder="Search a location"
          style={{ width: '100%', padding: '0.5rem' }}
        />
      </Autocomplete>

      {/* Map */}
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={markerPosition}
        zoom={12}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        {/* Draggable Marker */}
        <Marker
          position={markerPosition}
          draggable={true}
          onDragEnd={handleMarkerDragEnd}
        />
      </GoogleMap>
    </div>
  ) : (
    <p>Loading map...</p>
  );
}

export default React.memo(MapWithSearch);
