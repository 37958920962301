import React, { useState } from "react";
import Modal from "react-modal";

Modal.setAppElement("#root");

export default function PayoutInfoModal({ isOpen, onRequestClose, method, onSubmit }) {
  const [email, setEmail] = useState("");
  const [bankDetails, setBankDetails] = useState({
    accountHolderName: "",
    accountHolderType: "",
    routingNumber: "",
    accountNumber: "",
  });

  const handleSubmit = () => {

    console.log("adjasgdja sgdjaj")
    if ((method === "Stripe" || method === "PayPal") && email.trim() === "") {
      alert("Please provide your email.");
      return;
    }

    if (
      method === "Bank" &&
      (!bankDetails.accountHolderName ||
        !bankDetails.accountHolderType ||
        !bankDetails.routingNumber ||
        !bankDetails.accountNumber)
    ) {
      alert("Please fill in all the bank details.");
      return;
    }

    onSubmit({ method, email, bankDetails });
    setEmail("");
    setBankDetails({
      accountHolderName: "",
      accountHolderType: "",
      routingNumber: "",
      accountNumber: "",
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBankDetails((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Provide Payout Information"
      className="bg-white rounded-lg shadow-xl max-w-lg mx-auto p-8"
      overlayClassName="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center"
    >
      <h2 className="text-2xl font-bold mb-4">Provide Information</h2>

      {(method === "Stripe" || method === "PayPal") && (
        <>
          <p className="text-gray-600 mb-6">
            Please provide your <strong>Email</strong> for the {method} payout.
          </p>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full border rounded-md p-2 mb-4"
            placeholder="Enter your email"
          />
        </>
      )}

      {method === "Bank" && (
        <div className="border rounded-lg p-4 shadow-sm bg-gray-50">
          <p className="text-gray-600 mb-4">
            Please provide your bank account details for the payout.
          </p>

          <div className="mb-4">
            <label className="block text-gray-700 mb-2" htmlFor="accountHolderName">
              Account Holder Name
            </label>
            <input
              type="text"
              id="accountHolderName"
              name="accountHolderName"
              value={bankDetails.accountHolderName}
              onChange={handleInputChange}
              className="w-full border rounded-md p-2"
              placeholder="Enter account holder name"
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 mb-2" htmlFor="accountHolderType">
              Account Holder Type
            </label>
            <select
              id="accountHolderType"
              name="accountHolderType"
              value={bankDetails.accountHolderType}
              onChange={handleInputChange}
              className="w-full border rounded-md p-2"
            >
              <option value="">Select Type</option>
              <option value="Individual">Individual</option>
              <option value="Company">Company</option>
            </select>
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 mb-2" htmlFor="routingNumber">
              Routing Number
            </label>
            <input
              type="text"
              id="routingNumber"
              name="routingNumber"
              value={bankDetails.routingNumber}
              onChange={handleInputChange}
              className="w-full border rounded-md p-2 appearance-none"
              placeholder="Enter routing number"
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 mb-2" htmlFor="accountNumber">
              Account Number
            </label>
            <input
              type="text"
              id="accountNumber"
              name="accountNumber"
              value={bankDetails.accountNumber}
              onChange={handleInputChange}
              className="w-full border rounded-md p-2 appearance-none"
              placeholder="Enter account number"
            />
          </div>
        </div>
      )}

      <div className="flex justify-end mt-4">
        <button
          onClick={handleSubmit}
          className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition duration-300"
        >
          Submit
        </button>
        <button
          onClick={onRequestClose}
          className="ml-2 bg-gray-300 px-4 py-2 rounded-md hover:bg-gray-400 transition duration-300"
        >
          Cancel
        </button>
      </div>
    </Modal>
  );
}
