import React from "react";
import Modal from "react-modal";
import { FaStripe, FaPaypal, FaUniversity } from "react-icons/fa";

Modal.setAppElement("#root");

export default function PayoutOptionsModal({ isOpen, onRequestClose, onSelectPayoutMethod }) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Payout Options"
      className="bg-white rounded-lg shadow-xl max-w-xl mx-auto p-8"
      overlayClassName="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center"
    >
      <h2 className="text-2xl font-bold mb-6 text-center">Choose Payout Method</h2>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <button
          onClick={() => onSelectPayoutMethod("Stripe")}
          className="border-2 border-gray-300 rounded-lg p-4 flex items-center gap-4 hover:shadow-lg hover:border-blue-500 transition-all duration-300"
        >
          <FaStripe className="text-4xl text-blue-700" />
          <div>
            <p className="font-bold text-gray-800">By Stripe</p>
            <p className="text-sm text-gray-500">Fast and secure payouts.</p>
          </div>
        </button>

        <button
          onClick={() => onSelectPayoutMethod("Bank")}
          className="border-2 border-gray-300 rounded-lg p-4 flex items-center gap-4 hover:shadow-lg hover:border-green-500 transition-all duration-300"
        >
          <FaUniversity className="text-4xl text-green-700" />
          <div>
            <p className="font-bold text-gray-800">By Bank</p>
            <p className="text-sm text-gray-500">Direct deposit to your bank.</p>
          </div>
        </button>

        <button
          onClick={() => onSelectPayoutMethod("PayPal")}
          className="border-2 border-gray-300 rounded-lg p-4 flex items-center gap-4 hover:shadow-lg hover:border-yellow-500 transition-all duration-300"
        >
          <FaPaypal className="text-4xl text-blue-600" />
          <div>
            <p className="font-bold text-gray-800">By PayPal</p>
            <p className="text-sm text-gray-500">Secure payment through PayPal.</p>
          </div>
        </button>
      </div>

      <button
        onClick={onRequestClose}
        className="mt-6 text-sm text-gray-500 hover:underline text-center w-full"
      >
        Cancel
      </button>
    </Modal>
  );
}
