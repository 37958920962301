import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FiX, FiTrash2 } from "react-icons/fi";
import { motion } from "framer-motion";
import { useSelector, useDispatch } from "react-redux";
import { updateCartItem, removeCartItem } from "../../redux/features/cartSlice";
import { addToCart, removeCartProduct } from "../../Hooks/UserHooks"; // Import API functions

const Cart = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const products = useSelector((state) => state.cart.items || []); // Default to empty array if cart is empty
  const [totalAmt, setTotalAmt] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);

  // Safely get user from localStorage and check if it's valid
  const user = JSON.parse(localStorage.getItem("twmUser"));
  const userId = user ? user._id : null;

  // Calculate total amount and items whenever products change
  useEffect(() => {
    let price = 0;
    let items = 0;
    products.forEach((item) => {
      price += item.price * item.quantity;
      items += item.quantity;
    });
    setTotalAmt(price);
    setTotalItems(items);
  }, [products]);

  const handleQuantityChange = async (id, amount) => {
    if (!userId) return; // Ensure user is logged in

    setLoading(true);
    try {
      const product = products.find((item) => item._id === id);
      if (!product) return;

      const newQuantity = product.quantity + amount;

      if (newQuantity > 0) {
        // Call API to update quantity
        const response = await addToCart(userId, id, amount);
        if (response.success) {
          // Update Redux store
          dispatch(
            updateCartItem({
              _id: id,
              quantity: newQuantity,
            })
          );
        } else {
          console.error(response.message);
        }
      } else {
        // Call API to remove item if quantity is zero
        await handleRemoveItem(id);
      }
    } catch (error) {
      console.error("Error updating quantity:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveItem = async (id) => {
    if (!userId) return; // Ensure user is logged in

    setLoading(true);
    try {
      // Call API to remove item from DB
      const response = await removeCartProduct(userId, id);
      if (response.success) {
        // Update Redux store after successful removal
        dispatch(removeCartItem(id));
      } else {
        console.error(response.message);
      }
    } catch (error) {
      console.error("Error removing item:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className={`fixed top-0 right-0 z-50 bg-white h-full transform transition-transform duration-300 ease-in-out ${
        isOpen ? "translate-x-0" : "translate-x-full"
      } w-full lg:w-1/2 p-4 shadow-lg`}
      style={{ zIndex: 1000 }}
    >
      {/* Close button */}
      <button
        className="absolute top-4 right-4 text-2xl text-black"
        onClick={onClose}
      >
        <FiX />
      </button>

      <h2 className="text-2xl font-semibold mb-4 text-center text-black">
        YOUR CART
      </h2>

      {products.length > 0 ? (
        <div className="mt-5 overflow-y-auto scrollbar-hide h-[60vh]">
          {products.map((item) => (
            <div
              key={item._id}
              className="flex items-center justify-between py-4 border-b border-gray-300"
            >
              <img
                src={item.firstImage}
                alt={item.productName}
                className="w-16 h-16 rounded-md object-cover"
              />
              <div className="flex-1 ml-4">
                <h3 className="text-sm font-semibold text-black">
                  {item.productName}
                </h3>
                <p className="text-xs text-gray-500">Quantity: {item.quantity}</p>
                <div className="flex items-center mt-2">
                  <button
                    onClick={() => handleQuantityChange(item._id, -1)}
                    className="px-2 py-1 border border-gray-300 text-lg text-black"
                    disabled={loading}
                  >
                    -
                  </button>
                  <span className="px-4 text-black">{item.quantity}</span>
                  <button
                    onClick={() => handleQuantityChange(item._id, 1)}
                    className="px-2 py-1 border border-gray-300 text-lg text-black"
                    disabled={loading}
                  >
                    +
                  </button>
                </div>
              </div>
              <div className="flex items-center">
                <span className="text-lg font-semibold text-black mr-4">
                  ${(item.price * item.quantity).toFixed(2)}
                </span>
                <button
                  onClick={() => handleRemoveItem(item._id)}
                  className="text-gray-500 hover:text-red-600"
                  disabled={loading}
                >
                  <FiTrash2 />
                </button>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <motion.div
          initial={{ y: 30, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.4 }}
          className="flex flex-col items-center justify-center h-full"
        >
          <img
            className="w-48 rounded-lg mb-4"
            src="https://via.placeholder.com/150"
            alt="Empty Cart"
          />
          <h1 className="text-xl font-bold text-black">Your Cart is Empty</h1>
          <p className="text-center mt-2 text-black">
            Add items to your cart to start shopping!
          </p>
          <Link to="/shop" onClick={onClose}>
            <button className="bg-black text-white py-2 px-6 rounded-md mt-4">
              Continue Shopping
            </button>
          </Link>
        </motion.div>
      )}

      {/* Subtotal and Checkout */}
      {products.length > 0 && (
        <div className="border-t border-gray-300 mt-4 pt-4">
          <div className="flex justify-between font-semibold text-lg text-black">
            <span>Subtotal ({totalItems} items)</span>
            <span>${totalAmt.toFixed(2)}</span>
          </div>
          <Link to="/checkout" onClick={onClose}>
            <button className="w-full mt-4 py-3 bg-black text-white font-semibold uppercase hover:bg-gray-800 duration-300">
              Checkout
            </button>
          </Link>
        </div>
      )}
    </div>
  );
};

export default Cart;
