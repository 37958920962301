import React, { useState, useEffect } from "react";
import { FaChevronDown, FaChevronRight, FaCcVisa } from "react-icons/fa";
import { BsQuestionCircle } from "react-icons/bs";
import { useSelector, useDispatch } from "react-redux";
import { getUserData } from "../Hooks/UserHooks";
import { getFedexRates } from "../Hooks/productHooks";
import { getVoucherByName } from "../Hooks/vouchersHook";
import {
  createStripeSession,
  createPaypalSession,
} from "../Hooks/paymentHooks";
import { toast } from "react-toastify";
import { motion } from "framer-motion";
import { clearCart } from "../redux/features/cartSlice"; // Import the clearCart action

export default function Checkout() {
  const [isAddressOpen, setIsAddressOpen] = useState(true);
  const [isShippingOpen, setIsShippingOpen] = useState(false);
  const [selectedShipping, setSelectedShipping] = useState({}); // Track selected shipping for each vendor
  const [shippingCharge, setShippingCharge] = useState({}); // Track shipping charges for each vendor
  const [user, setUser] = useState(null);
  const [fedexRates, setFedexRates] = useState([]);
  const [selectedPayment, setSelectedPayment] = useState("Card");
  const [voucherCode, setVoucherCode] = useState("");
  const [discount, setDiscount] = useState(0);
  const [showCelebration, setShowCelebration] = useState(false);

  const cartItems = useSelector((state) => state.cart.items);
  const dispatch = useDispatch(); // Initialize useDispatch

  const subtotal = cartItems.reduce(
    (total, product) => total + product.price * product.quantity,
    0
  );

  const estimatedTax = 0;
  const total =
    subtotal +
    estimatedTax +
    Object.values(shippingCharge).reduce((acc, charge) => acc + charge, 0) -
    discount;

  useEffect(() => {
    const fetchData = async () => {
      const userResponse = await getUserData();
      if (userResponse.success) {
        setUser(userResponse.user);
      } else {
        console.error(userResponse.message);
      }

      const fedexResponse = await getFedexRates(
        userResponse.user?._id || null,
        null,
        cartItems
      );

      if (fedexResponse.success) {
        console.log(fedexResponse.rates);
        setFedexRates(fedexResponse.rates);
      } else {
        console.error(fedexResponse.message);
      }
    };

    fetchData();
  }, [cartItems]);

  const handleShippingChange = (vendorId, rate) => {
    setSelectedShipping((prev) => ({
      ...prev,
      [vendorId]: rate.serviceType,
    }));
    setShippingCharge((prev) => ({
      ...prev,
      [vendorId]: rate.ratedShipmentDetails[0].totalNetFedExCharge,
    }));
  };

  const handleApplyVoucher = async () => {
    if (!voucherCode.trim()) {
      toast.error("Please enter a voucher code.");
      return;
    }

    const voucherResponse = await getVoucherByName(voucherCode.trim());
    if (!voucherResponse.success) {
      toast.error(voucherResponse.message || "Invalid voucher code.");
      return;
    }

    const voucher = voucherResponse.voucher;

    const currentDate = new Date();
    if (voucher.status !== "active") {
      toast.error("Voucher is not active.");
      return;
    }

    if (new Date(voucher.expiryDate) < currentDate) {
      toast.error("Voucher has expired.");
      return;
    }

    if (voucher.maxCount && voucher.count >= voucher.maxCount) {
      toast.error("Voucher usage limit has been reached.");
      return;
    }

    let discountAmount = (subtotal * voucher.discountPercentage) / 100;
    if (voucher.maxDiscount) {
      discountAmount = Math.min(discountAmount, voucher.maxDiscount);
    }

    setDiscount(discountAmount);

    setShowCelebration(true);
    setTimeout(() => setShowCelebration(false), 3000);

    toast.success(`Voucher applied! You saved $${discountAmount.toFixed(2)}`);
  };

  const handlePlaceOrder = async () => {
    const selectedShippingData = Object.keys(selectedShipping).map(
      (vendorId) => ({
        vendorId,
        shippingType: selectedShipping[vendorId],
        shippingCharge: shippingCharge[vendorId] || 0,
      })
    );

    console.log("Shipping Data:", selectedShippingData);
    console.log("Applied Discount:", discount);
    console.log("Voucher Code:", voucherCode);
    console.log("selectedPayment:", selectedPayment);
    console.log("cartItems:", cartItems);

    if (selectedPayment === "Card") {
      const response = await createStripeSession({
        selectedShippingData,
        discount,
        voucherCode,
        cartItems,
      });

      if (response.success) {
        localStorage.setItem("selectedShippingData", JSON.stringify(selectedShippingData));
        localStorage.setItem("discount", discount);
        localStorage.setItem("voucherCode", voucherCode);
        localStorage.setItem("cartItems", JSON.stringify(cartItems));
        localStorage.setItem("paymentMethod", selectedPayment);
        window.location.href = response.session;
        window.location.href = response.session;
      } else {
        toast.error(response.message);
      }
    } else {
      const response = await createPaypalSession({
        selectedShippingData,
        discount,
        voucherCode,
        cartItems,
      });

      if (response.success) {
        //store the selectedShippingData, discount, voucherCode, cartItems in the session storage
        localStorage.setItem("selectedShippingData", JSON.stringify(selectedShippingData));
        localStorage.setItem("discount", discount);
        localStorage.setItem("voucherCode", voucherCode);
        localStorage.setItem("cartItems", JSON.stringify(cartItems));
        localStorage.setItem("paymentMethod", selectedPayment);
        window.location.href = response.session;
      } else {
        toast.error(response.message);
      }
    }
  };

  return (
    <div className="bg-white text-black min-h-screen flex flex-col items-center p-4 md:p-8">
      <div className="max-w-5xl w-full bg-white rounded-lg shadow-md p-4 md:p-6 flex flex-col lg:flex-row">
        {showCelebration && (
          <motion.div
            initial={{ scale: 0, opacity: 0 }}
            animate={{ scale: 1.2, opacity: 1 }}
            exit={{ scale: 0, opacity: 0 }}
            className="fixed top-1/3 left-[20%] md:left-[40%] transform -translate-x-1/2 -translate-y-1/2 text-center z-50"
          >
            <div className="text-6xl mb-4 animate-bounce">🎉</div>
            <h1 className="text-3xl font-bold text-purple-700">Hurrah!</h1>
            <p className="text-lg text-gray-700">
              Your discount has been applied!
            </p>
          </motion.div>
        )}
        {/* Left Section - Shipping and Payment Details */}
        <div className="w-full lg:w-2/3 pr-0 lg:pr-8">
          <div className="flex items-center mb-6">
            <div className="bg-black text-white text-sm px-3 py-1 rounded-full font-semibold mr-2">
              TWM
            </div>
            <span className="text-gray-700 text-sm lg:text-base">
              {user ? user.email : "Loading..."}
            </span>
          </div>

          <div className="mb-6">
            <div
              className="flex justify-between items-center cursor-pointer"
              onClick={() => setIsAddressOpen(!isAddressOpen)}
            >
              <h2 className="font-semibold text-lg">Ship to</h2>
              {isAddressOpen ? <FaChevronDown /> : <FaChevronRight />}
            </div>
            {isAddressOpen && (
              <div className="mt-2 text-sm lg:text-base">
                {user?.billingAddress ? (
                  <p>
                    {user.billingAddress.line1}, {user.billingAddress.line2},{" "}
                    {user.billingAddress.city}, {user.billingAddress.state},{" "}
                    {user.billingAddress.zipCode}, {user.billingAddress.country}
                  </p>
                ) : (
                  <p>Loading address...</p>
                )}
                <button className="mt-2 text-sm font-semibold text-blue-600 hover:underline">
                  Change Address
                </button>
              </div>
            )}
          </div>

          <div className="mb-6">
            <div
              className="flex justify-between items-center cursor-pointer"
              onClick={() => setIsShippingOpen(!isShippingOpen)}
            >
              <h2 className="font-semibold text-lg">Shipping method</h2>
              {isShippingOpen ? <FaChevronDown /> : <FaChevronRight />}
            </div>
            {isShippingOpen && (
              <div className="flex flex-col space-y-2 mt-2 text-sm lg:text-base">
                {fedexRates.map((rate, index) => (
                  <div key={index} className="mb-4">
                    <h3 className="font-bold text-md mb-2">
                      Shipping options for {rate.vendorName}
                    </h3>
                    <div className="flex flex-col space-y-2">
                      {rate.rates.output.rateReplyDetails.map((detail, idx) => (
                        <label key={idx} className="flex items-center">
                          <input
                            type="radio"
                            name={`shipping-${rate.vendorId}`}
                            value={detail.serviceType}
                            onChange={() =>
                              handleShippingChange(rate.vendorId, detail)
                            }
                            className="form-radio text-purple-600"
                          />
                          <span className="ml-2 text-gray-700">
                            {detail.serviceName}
                          </span>
                          <span className="ml-auto font-semibold">
                            $
                            {detail.ratedShipmentDetails[0].totalNetFedExCharge.toFixed(
                              2
                            )}
                          </span>
                        </label>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className="mb-6">
            <h2 className="font-semibold text-lg mb-4">Payment Method</h2>
            <div className="flex flex-col md:flex-row gap-4">
              {/* Card Payment Option */}
              <div
                className={`flex items-center border-2 rounded-lg p-4 cursor-pointer transition ${
                  selectedPayment === "Card"
                    ? "border-purple-600 bg-purple-100"
                    : "border-gray-300 hover:border-purple-600"
                }`}
                onClick={() => setSelectedPayment("Card")}
              >
                <FaCcVisa className="text-3xl text-blue-600 mr-4" />
                <div>
                  <h3 className="text-lg font-semibold text-gray-800">
                    Pay with Card
                  </h3>
                  <p className="text-sm text-gray-500">
                    Visa, MasterCard, Discover
                  </p>
                </div>
              </div>

              {/* PayPal Payment Option */}
              <div
                className={`flex items-center border-2 rounded-lg p-4 cursor-pointer transition ${
                  selectedPayment === "PayPal"
                    ? "border-purple-600 bg-purple-100"
                    : "border-gray-300 hover:border-purple-600"
                }`}
                onClick={() => setSelectedPayment("PayPal")}
              >
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/b/b5/PayPal.svg"
                  alt="PayPal Logo"
                  className="w-10 h-10 mr-4"
                />
                <div>
                  <h3 className="text-lg font-semibold text-gray-800">
                    Pay with PayPal
                  </h3>
                  <p className="text-sm text-gray-500">
                    Secure payment through PayPal
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Right Section - Order Summary */}
        <div className="w-full lg:w-1/3 bg-gray-50 p-4 md:p-6 rounded-lg mt-6 lg:mt-0">
          <h2 className="font-semibold text-lg mb-4">Order Summary</h2>
          <div className="space-y-4 mb-4">
            {cartItems.map((product) => (
              <div key={product._id} className="flex items-center">
                <img
                  src={product.firstImage}
                  alt={product.productName}
                  className="w-16 h-16 rounded-lg mr-4 object-cover"
                />
                <div>
                  <p className="text-sm font-semibold">{product.productName}</p>
                  <p className="text-xs text-gray-500">
                    Quantity: {product.quantity}
                  </p>
                </div>
                <div className="ml-auto font-semibold text-sm lg:text-base">
                  ${product.price.toFixed(2)}
                </div>
              </div>
            ))}
          </div>

          <div className="flex items-center mb-4">
            <input
              type="text"
              placeholder="Discount code or gift card"
              value={voucherCode}
              onChange={(e) => setVoucherCode(e.target.value)}
              className="border border-gray-300 p-2 rounded-l-lg w-full text-sm"
            />
            <button
              onClick={handleApplyVoucher}
              className="bg-gray-200 px-4 py-2 rounded-r-lg font-semibold text-gray-700 text-sm"
            >
              Apply
            </button>
          </div>

          <div className="space-y-2 text-sm text-gray-700">
            <div className="flex justify-between">
              <span>Subtotal</span>
              <span>${subtotal.toFixed(2)}</span>
            </div>
            <div className="flex justify-between">
              <span>Shipping</span>
              <span>
                $
                {Object.values(shippingCharge)
                  .reduce((acc, charge) => acc + charge, 0)
                  .toFixed(2)}
              </span>
            </div>
            <div className="flex justify-between text-green-600 font-semibold">
              <span>Discount</span>
              <span>-${discount.toFixed(2)}</span>
            </div>
          </div>

          <div className="flex justify-between font-semibold text-lg mt-4">
            <span>Total</span>
            <span>USD ${total.toFixed(2)}</span>
          </div>

          <button
            onClick={handlePlaceOrder}
            className="w-full bg-black text-white py-3 mt-6 rounded-lg font-semibold hover:bg-[#262626] transition"
          >
            Place Order
          </button>
        </div>
      </div>
    </div>
  );
}
