import React, { useState, useEffect, useRef } from "react";
import { FaBell, FaTimes, FaPlus, FaMoneyBill, FaHome } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { deleteNotification } from "../../Hooks/notificationHook";
import { removeNotification } from "../../redux/features/notificationSlice";
import { setCartItems } from "../../redux/features/cartSlice";
import { toast } from "react-toastify";

const Topbar = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const notifications = useSelector(
    (state) => state.notifications.notifications
  );
  const username = localStorage.getItem("username") || "Admin";

  const handleToggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleToggleNotifications = () => {
    setNotificationsOpen(!notificationsOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem("twmToken");
    localStorage.removeItem("twmUser");
    dispatch(removeNotification([])); // Clear notifications
    dispatch(setCartItems([]));
    toast.success("Logged out successfully!");
    navigate("/"); // Redirect to homepage
  };

  const handleRemoveNotification = async (notificationId) => {
    const response = await deleteNotification(notificationId);

    if (response.success) {
      dispatch(removeNotification(notificationId));
    } else {
      toast.error(response.message);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const unreadNotificationsCount = notifications.filter(
    (n) => !n.isRead
  ).length;

  return (
    <>
      <div className="flex justify-between items-center text-gray-800 p-4 border-b">
        <div className="flex items-center space-x-4">
          {/* Home Button */}
          <Link
            to={"/"}
            className="flex items-center space-x-2 bg-gray-800 border border-black text-white px-4 py-2 rounded-md hover:bg-white hover:text-black"
          >
            <FaHome />
            <span>Home</span>
          </Link>

          {/* List a Product Button */}
          <Link
            to={"/admin/new-product"}
            className="flex items-center space-x-2 bg-gray-800 border border-black text-white px-4 py-2 rounded-md hover:bg-white hover:text-black"
          >
            <FaPlus />
            <span>List a Product</span>
          </Link>

          {/* Payouts Button */}
          <Link
            to={"/admin/payouts"}
            className="flex items-center space-x-2 bg-gray-800 border border-black text-white px-4 py-2 rounded-md hover:bg-white hover:text-black"
          >
            <FaMoneyBill />
            <span>Payouts</span>
          </Link>
        </div>

        <div className="flex items-center space-x-4">
          <div
            className="relative cursor-pointer"
            onClick={handleToggleNotifications}
          >
            <FaBell className="cursor-pointer" />
            {unreadNotificationsCount > 0 && (
              <span className="absolute top-0 right-0 transform translate-x-2 -translate-y-2 bg-blue-500 text-white text-xs font-bold rounded-full px-1.5 py-0.5">
                {unreadNotificationsCount}
              </span>
            )}
          </div>

          <div className="relative" ref={dropdownRef}>
            <button
              onClick={handleToggleDropdown}
              className="flex items-center space-x-2 border px-2 py-1 rounded"
            >
              <span>{username}</span>
              <svg
                className="w-4 h-4"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                ></path>
              </svg>
            </button>

            {dropdownOpen && (
              <div className="absolute right-0 mt-2 w-48 bg-white border rounded shadow-lg z-10">
                <ul>
                  {/* Profile Settings now navigates to /account */}
                  <li
                    className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                    onClick={() => navigate("/account")}
                  >
                    Profile Settings
                  </li>
                  <li
                    className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                    onClick={handleLogout}
                  >
                    Logout
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>

      <div
        className={`fixed top-0 right-0 overflow-y-auto rounded-tl-2xl w-80 h-full bg-white shadow-lg border-l transform transition-transform duration-300 ease-in-out z-50 ${
          notificationsOpen ? "translate-x-0" : "translate-x-full"
        }`}
      >
        <div className="flex justify-between items-center p-4 border-b">
          <h2 className="text-lg font-semibold">
            Alerts ({notifications.length})
          </h2>
          <FaTimes onClick={handleToggleNotifications} />
        </div>
        <div className="p-4">
          <h3 className="text-gray-500 font-bold">Unread</h3>
          <ul>
            {notifications
              .filter((n) => !n.isRead)
              .reverse()
              .map((notification) => (
                <li
                  key={notification._id}
                  className="p-2 border-b flex justify-between items-start"
                >
                  <div>
                    <div className="flex justify-between items-center">
                      <span className="font-semibold">
                        {notification.title}
                      </span>
                      <span className="text-xs text-gray-500">
                        {new Date(notification.createdAt).toLocaleTimeString()}
                      </span>
                    </div>
                    <p className="text-sm text-gray-600">
                      {notification.message}
                    </p>
                  </div>
                  <button
                    className="text-gray-400 hover:text-gray-600"
                    onClick={() => handleRemoveNotification(notification._id)}
                  >
                    <FaTimes />
                  </button>
                </li>
              ))}
          </ul>

          <h3 className="text-gray-500 font-bold mt-4">Read</h3>
          <ul>
            {notifications
              .filter((n) => n.isRead)
              .map((notification) => (
                <li
                  key={notification._id}
                  className="p-2 border-b flex justify-between items-start"
                >
                  <div>
                    <div className="flex justify-between items-center">
                      <span className="font-semibold">
                        {notification.title}
                      </span>
                      <span className="text-xs text-gray-500">
                        {new Date(notification.createdAt).toLocaleTimeString()}
                      </span>
                    </div>
                    <p className="text-sm text-gray-600">
                      {notification.message}
                    </p>
                  </div>
                  <button
                    className="text-gray-400 hover:text-gray-600"
                    onClick={() => handleRemoveNotification(notification._id)}
                  >
                    <FaTimes />
                  </button>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Topbar;
