import React, { useState, useEffect, useRef } from "react";
import { FaHeart, FaShareAlt } from "react-icons/fa";
import OfferModal from "../components/Modals/Offermodal";
import MoreProducts from "../components/products/MoreProducts";
import AdditionalInfo from "../components/products/AdditionalInfo";
import Faq from "../components/products/Faq";
import SellerInfo from "../components/products/SellerInfo";
import PeopleAlsoSearchedFor from "../components/products/PeopleAlsoSearchedFor";
import { getProduct, likeProduct, unlikeProduct } from "../Hooks/productHooks";
import { addToCart } from "../Hooks/UserHooks";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addCartItem } from "../redux/features/cartSlice";
import { toast } from "react-toastify";

// CustomSlider component that creates a horizontal slider with arrow controls.
const CustomSlider = ({ children }) => {
  const sliderRef = useRef(null);

  const scrollLeft = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({ left: -200, behavior: "smooth" });
    }
  };

  const scrollRight = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({ left: 200, behavior: "smooth" });
    }
  };

  return (
    <div className="relative">
      <button
        onClick={scrollLeft}
        className="absolute left-0 z-10 top-1/2 transform -translate-y-1/2 bg-gray-300 hover:bg-gray-400 p-2 rounded"
      >
        {"<"}
      </button>
      <div
        ref={sliderRef}
        className="overflow-x-auto scroll-smooth scrollbar-hide flex gap-2"
      >
        {children}
      </div>
      <button
        onClick={scrollRight}
        className="absolute right-0 z-10 top-1/2 transform -translate-y-1/2 bg-gray-300 hover:bg-gray-400 p-2 rounded"
      >
        {">"}
      </button>
    </div>
  );
};

const ProductDetails = () => {
  const { productId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [product, setProduct] = useState(null);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isLiked, setIsLiked] = useState(false);
  const [loading, setLoading] = useState(true);
  const [quantity, setQuantity] = useState(1);

  // Detect screen width and update state on window resize
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Fetch product data on component mount
  useEffect(() => {
    const fetchProduct = async () => {
      setLoading(true);
      const response = await getProduct(productId);
      if (response.success) {
        console.log(response);
        setProduct(response.product);
        setRelatedProducts(response.relatedProducts);
        setSelectedImage(response.product.images[0]?.image);
        setQuantity(response.product.minimumOrderQuantity);
      } else {
        console.error(response.message);
      }
      setLoading(false);
    };
    fetchProduct();
  }, [productId]);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <p>Loading product details...</p>
      </div>
    );
  }

  if (!product) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <p>Failed to load product details. Please try again later.</p>
      </div>
    );
  }

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleShare = () => {
    const shareData = {
      title: product.productName,
      text: "Check out this product!",
      url: window.location.href,
    };

    if (navigator.share) {
      navigator
        .share(shareData)
        .then(() => console.log("Successful share"))
        .catch((error) => console.log("Error sharing", error));
    } else {
      navigator.clipboard
        .writeText(window.location.href)
        .then(() => alert("Link copied to clipboard"))
        .catch((error) => console.log("Error copying to clipboard", error));
    }
  };

  const handleAddToCart = async () => {
    try {
      // First check stock
      if (product.manageStock && product.quantity === 0) {
        toast.error("Product is out of stock. Cannot add to cart!");
        return;
      }
      // Check for minimum order quantity
      if (quantity < product.minimumOrderQuantity) {
        toast.error(
          `Minimum order quantity is ${product.minimumOrderQuantity}. Please increase quantity to proceed.`
        );
        return;
      }

      const user = JSON.parse(localStorage.getItem("twmUser"));
      const response = await addToCart(user._id, productId, quantity);
      if (response.success) {
        dispatch(
          addCartItem({
            _id: productId,
            productName: product.productName,
            firstImage:
              product.images.length > 0 ? product.images[0].image : null,
            quantity,
            price: product.discountedPrice
              ? product.discountedPrice
              : product.regularPrice,
          })
        );
        toast.success("Added to cart successfully!");
        console.log("Product added to cart successfully!");
      } else {
        console.error("Failed to add product to cart:", response.message);
      }
    } catch (error) {
      console.error("Error adding product to cart:", error.message);
    }
  };

  const handleQuantityChange = (amount) => {
    const newValue = quantity + amount;
    // Prevent going below minimum or above available stock
    if (
      newValue >= product.minimumOrderQuantity && (!product.manageStock || newValue <= product.quantity)
      
    ) {
      setQuantity(newValue);
    }
  };
  

  const handleLikeProduct = async () => {
    try {
      const user = JSON.parse(localStorage.getItem("twmUser"));
      if (!isLiked) {
        const response = await likeProduct(user._id, productId);
        if (response.success) {
          setIsLiked(true);
          console.log("Product liked successfully!");
        } else {
          console.error("Failed to like product:", response.message);
        }
      } else {
        const response = await unlikeProduct(user._id, productId);
        if (response.success) {
          setIsLiked(false);
          console.log("Product unliked successfully!");
        } else {
          console.error("Failed to unlike product:", response.message);
        }
      }
    } catch (error) {
      console.error("Error liking/unliking product:", error.message);
    }
  };

  const isSmallScreen = windowWidth < 768;

  const handleBuyBtn = async () => {
    try {
      // First check stock
      if (product.manageStock && product.quantity === 0) {
        toast.error("Product is out of stock. Cannot add to cart!");
        return;
      }
      // Check for minimum order quantity
      if (quantity < product.minimumOrderQuantity) {
        toast.error(
          `Minimum order quantity is ${product.minimumOrderQuantity}. Please increase quantity to proceed.`
        );
        return;
      }

      const user = JSON.parse(localStorage.getItem("twmUser"));
      const response = await addToCart(user._id, productId, quantity);
      if (response.success) {
        dispatch(
          addCartItem({
            _id: productId,
            productName: product.productName,
            firstImage:
              product.images.length > 0 ? product.images[0].image : null,
            quantity,
            price: product.discountedPrice
              ? product.discountedPrice
              : product.regularPrice,
          })
        );
        navigate("/checkout");
      } else {
        console.error("Failed to add product to cart:", response.message);
      }
    } catch (error) {
      console.error("Error adding product to cart:", error.message);
    }
  };

  return (
    <div className="container px-4 md:px-12 py-6 mx-auto">
      <nav className="text-gray-600 text-sm mb-6">
        <ul className="flex items-center space-x-2">
          <li>
            <Link to="/" className="hover:underline">
              Home
            </Link>
          </li>
          <li>/</li>
          <li>
            <Link to="/products" className="hover:underline">
              Products
            </Link>
          </li>
          <li>/</li>
          <li className="text-gray-900 font-semibold capitalize">
            {product ? product.productName : "Loading..."}
          </li>
        </ul>
      </nav>
      {isSmallScreen ? (
        <>
          {/* Mobile Layout */}
          <div className="w-full">
            <div className="border border-gray-300 mb-4">
              {typeof selectedImage === "string" &&
              selectedImage?.endsWith(".mp4") ? (
                <video className="w-full h-auto" controls>
                  <source src={selectedImage} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <img
                  src={selectedImage}
                  alt="Selected Product"
                  className="w-full h-[400px] object-contain"
                />
              )}
            </div>

            <CustomSlider>
              {product.images.map((imageObj, index) => (
                <div key={index} className="p-1 flex-shrink-0">
                  <img
                    src={imageObj.image}
                    alt={`Product Image ${index + 1}`}
                    className={`cursor-pointer w-24 h-24 md:w-32 md:h-32 object-cover ${imageObj.filter}`}
                    onClick={() => setSelectedImage(imageObj.image)}
                  />
                </div>
              ))}
              {product.video && (
                <div className="p-1 flex-shrink-0">
                  <video
                    className="cursor-pointer border border-gray-300 w-24 h-24 md:w-32 md:h-32 object-cover"
                    onClick={() => setSelectedImage(product.video)}
                    muted
                  >
                    <source src={product.video} type="video/mp4" />
                  </video>
                </div>
              )}
            </CustomSlider>

            <h1 className="text-xl md:text-2xl font-semibold mb-2">
              {product.productName}
            </h1>
            <div className="flex items-center mb-4">
              <span className="text-yellow-500 text-lg font-bold">
                ★ {product.rating}
              </span>
              <span className="ml-4 text-green-500 font-semibold">
                {product.quantity > 0 ? "In stock" : "Out of stock"}
              </span>
            </div>

            <p className="text-lg md:text-xl font-semibold text-blue-600 mb-2">
              $
              {product.discountedPrice
                ? product.discountedPrice
                : product.regularPrice}
            </p>

            {/* Show minimum order quantity */}
            <p className="text-sm font-bold text-gray-500 mb-2">
              Minimum Quantity: {product.minimumOrderQuantity}
            </p>

            {/* Quantity Selection */}
            <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 mb-4">
              <div>
                <label className="block mb-2 text-sm">Quantity</label>
                <div className="flex items-center border border-gray-300 px-4 py-2 rounded">
                  <button
                    onClick={() => handleQuantityChange(-1)}
                    className="px-2 text-lg font-semibold"
                  >
                    -
                  </button>
                  <span className="px-4">{quantity}</span>
                  <button
                    onClick={() => handleQuantityChange(1)}
                    className="px-2 text-lg font-semibold"
                  >
                    +
                  </button>
                </div>
              </div>
            </div>

            {/* Action Buttons */}
            <div className="flex flex-col md:flex-row gap-2 md:gap-4 mb-4">
              <button
                className="bg-yellow-500 text-white px-6 py-2 rounded font-semibold hover:bg-yellow-600 w-full md:w-auto"
                onClick={handleBuyBtn}
              >
                BUY NOW
              </button>
              <button
                className="bg-blue-600 text-white px-6 py-2 rounded font-semibold hover:bg-blue-700 w-full md:w-auto"
                onClick={handleAddToCart}
              >
                ADD TO CART
              </button>
              <button
                className="border border-gray-300 text-white px-6 py-2 rounded bg-black font-semibold hover:bg-[#262626] w-full md:w-auto"
                onClick={openModal}
              >
                Make An Offer
              </button>
            </div>

            {/* Like and Save Button */}
            <div className="flex items-center my-6">
              <FaHeart
                className={`text-2xl mr-2 ${
                  isLiked ? "text-red-500" : "text-gray-500"
                } cursor-pointer`}
                onClick={handleLikeProduct}
              />
              <span className="text-black mr-4">Like and save for later</span>
              <FaShareAlt
                className="text-2xl text-gray-500 cursor-pointer"
                onClick={handleShare}
              />
              <span className="text-black ml-2">Share</span>
            </div>

            <AdditionalInfo description={product.description} />
            <Faq productId={productId} />
            {/* <SellerInfo seller={product.belongsTo} /> */}
            <div className="mt-10">
              <h3 className="text-lg font-semibold mb-2">Tags</h3>
              <div className="flex flex-wrap gap-2">
                {product.tags.map((tag, index) => (
                  <span
                    key={index}
                    className="inline-block bg-white cursor-pointer text-black py-1 px-3 rounded-full text-sm font-medium border border-black"
                  >
                    {tag}
                  </span>
                ))}
              </div>
            </div>
          </div>
        </>
      ) : (
        // Desktop Layout
        <div className="flex flex-row justify-between md:space-x-8 space-y-8 md:space-y-0">
          <div className="w-full md:w-1/2">
            <div className="border border-gray-300 mb-4">
              {typeof selectedImage === "string" &&
              selectedImage?.endsWith(".mp4") ? (
                <video className="w-full h-auto" controls>
                  <source src={selectedImage} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <img
                  src={selectedImage}
                  alt="Selected Product"
                  className="w-full h-[400px] object-contain"
                />
              )}
            </div>

            <CustomSlider>
              {product.images.map((imageObj, index) => (
                <div key={index} className="p-1 flex-shrink-0">
                  <img
                    src={imageObj.image}
                    alt={`Product Image ${index + 1}`}
                    className={`cursor-pointer w-24 h-24 md:w-32 md:h-32 object-cover ${imageObj.filter}`}
                    onClick={() => setSelectedImage(imageObj.image)}
                  />
                </div>
              ))}
              {product.video && (
                <div className="p-1 flex-shrink-0">
                  <video
                    className="cursor-pointer border border-gray-300 w-24 h-24 md:w-32 md:h-32 object-cover"
                    onClick={() => setSelectedImage(product.video)}
                    muted
                  >
                    <source src={product.video} type="video/mp4" />
                  </video>
                </div>
              )}
            </CustomSlider>

            <div className="my-10">
              <Faq productId={productId} />
            </div>
          </div>

          <div className="w-full md:w-1/2">
            {/* <SellerInfo seller={product.belongsTo} /> */}
            <h1 className="text-xl md:text-2xl font-semibold mb-2">
              {product.productName}
            </h1>
            <div className="flex items-center mb-4">
              <span className="text-yellow-500 text-lg font-bold">
                ★ {product.rating}
              </span>
              <span className="ml-4 text-green-500 font-semibold">
                {product.quantity > 0 ? "In stock" : "Out of stock"}
              </span>
            </div>

            <p className="text-lg md:text-xl font-semibold text-blue-600 mb-2">
              $
              {product.discountedPrice
                ? product.discountedPrice
                : product.regularPrice}
            </p>

        

            {/* Show minimum order quantity */}
            <p className="text-sm text-gray-500 mb-2">
              Minimum Quantity: {product.minimumOrderQuantity}
            </p>

            {/* Quantity Selection */}
            <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 mb-4">
              <div>
                <label className="block mb-2 text-sm">Quantity</label>
                <div className="flex items-center border border-gray-300 px-4 py-2 rounded">
                  <button
                    onClick={() => handleQuantityChange(-1)}
                    className="px-2 text-lg font-semibold"
                  >
                    -
                  </button>
                  <span className="px-4">{quantity}</span>
                  <button
                    onClick={() => handleQuantityChange(1)}
                    className="px-2 text-lg font-semibold"
                  >
                    +
                  </button>
                </div>
              </div>
            </div>

            {/* Action Buttons */}
            <div className="flex flex-col md:flex-row gap-2 md:gap-4 mb-4">
              <button
                className="bg-yellow-500 text-white px-6 py-2 rounded font-semibold hover:bg-yellow-600 w-full md:w-auto"
                onClick={handleBuyBtn}
              >
                BUY NOW
              </button>
              <button
                className="bg-blue-600 text-white px-6 py-2 rounded font-semibold hover:bg-blue-700 w-full md:w-auto"
                onClick={handleAddToCart}
              >
                ADD TO CART
              </button>
              <button
                className="border border-gray-300 text-white px-6 py-2 rounded bg-black font-semibold hover:bg-[#262626] w-full md:w-auto"
                onClick={openModal}
              >
                Make An Offer
              </button>
            </div>

            {/* Like and Save Button */}
            <div className="flex items-center my-6">
              <FaHeart
                className={`text-2xl mr-2 ${
                  isLiked ? "text-red-500" : "text-gray-500"
                } cursor-pointer`}
                onClick={handleLikeProduct}
              />
              <span className="text-black mr-4">Like and save for later</span>
              <FaShareAlt
                className="text-2xl text-gray-500 cursor-pointer"
                onClick={handleShare}
              />
              <span className="text-black ml-2">Share</span>
            </div>

            <AdditionalInfo description={product.description} />
            {/* <SellerInfo seller={product.belongsTo} /> */}
            <div className="mt-10">
              <h3 className="text-lg font-semibold mb-2">Tags</h3>
              <div className="flex flex-wrap gap-2">
                {product.tags.map((tag, index) => (
                  <span
                    key={index}
                    className="inline-block bg-white cursor-pointer text-black py-1 px-3 rounded-full text-sm font-medium border border-black"
                  >
                    {tag}
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}

      <MoreProducts products={relatedProducts} />

      <OfferModal
        isOpen={isModalOpen}
        onClose={closeModal}
        price={
          product.discountedPrice
            ? product.discountedPrice
            : product.regularPrice
        }
        productId={productId}
      />
    </div>
  );
};

export default ProductDetails;
