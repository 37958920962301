import React, { useState } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

function AccordionItem({ question, answer }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b border-gray-200 py-4">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex justify-between items-center w-full text-left"
      >
        <span className="font-semibold text-lg">{question}</span>
        {isOpen ? (
          <FiChevronUp className="text-gray-500" />
        ) : (
          <FiChevronDown className="text-gray-500" />
        )}
      </button>
      {isOpen && <p className="mt-2 text-gray-700">{answer}</p>}
    </div>
  );
}

export default function FaqPage() {
  const faqData = [
    {
      category: 'What is The Wedding Marketplace?',
      faqs: [
        {
          question: 'What is The Wedding Marketplace?',
          answer:
            'The Wedding Marketplace (TWM) allows you to create the look and feel of your dream event in one place and at the price point that is right for you. Our commitment to sustainable wedding and event planning brings sellers and buyers together to share new and gently used event items on a dedicated platform to make wedding planning faster and easier than ever before.',
        },
      ],
    },
    {
      category: 'Fees',
      faqs: [
        {
          question: 'What are the transaction fees on TWM?',
          answer:
            'TWM works hard to keep our transaction costs low. There is a 3% transaction fee to support the innovation and growth of our platform, which the seller covers.',
        },
      ],
    },
    {
      category: 'Logging In',
      faqs: [
        {
          question: 'How do I join TWM?',
          answer:
            'Go to the Login page and fill out the Register portion of the form. Please indicate if you are initially interested in being a buyer or a seller. If you indicate buyer, you can always upgrade to being a seller under your account. Simply click “Become a Vendor” from your account dashboard.',
        },
        {
          question: 'My username and password stopped working, what do I do?',
          answer:
            'If resetting your password does not solve the problem, reach out to us at support@theweddingmarketplace.com for assistance.',
        },
        {
          question: 'I forgot my password, how do I reset it?',
          answer:
            'At the sign-in page, click “forgot my password” and follow the prompts.',
        },
      ],
    },
    {
      category: 'Selling',
      faqs: [
        {
          question: 'How can I become a seller?',
          answer:
            'Create a TWM account and use your Seller Dashboard to begin listing items. Check out our How to Sell page for important tips, the Sellers Agreement for your responsibilities, and the Help page for information on what items you cannot list.',
        },
        {
          question: 'Can I sell something without logging in?',
          answer:
            'TWM is a community-oriented space. You must be a member to list and sell items.',
        },
        {
          question: 'When and how do I receive payment for my items?',
          answer:
            'You will receive your money as soon as the transaction is completed. TWM will not hold any funds. Please see the Shipping FAQ for guidance on packing items for shipping.',
        },
        {
          question: 'How soon should I ship my item?',
          answer:
            'We recommend getting your sold items shipped out as quickly as possible. TWM asks you to pack your items securely for safe transport. We strongly recommend you use a tracking number when shipping your item to prevent misunderstandings or conflicts. Visit our How to Sell page for more information on selling and shipping.',
        },
        {
          question: 'What if there is no category for my specific product?',
          answer:
            'When selling something so special it does not even have a category, use the most closely related category available for the best results. With our wide variety of categories, we are sure we have a home for your item.',
        },
        {
          question: 'How do I change a product description?',
          answer:
            'If you’re interested in editing, just click on the item’s description and a small pen will show up. Click on the pen, and you’ll be able to edit! Items already sold cannot be edited.',
        },
        {
          question: 'Can I change my item’s price?',
          answer:
            'Yes, you can change your item’s price at any time by editing your product price in the description.',
        },
        {
          question: 'Why can’t I find my listing?',
          answer:
            'If your listing is not available, you may have posted an item that is not allowed on TWM. The list of disallowed items is available on our Help Center page. If you believe your item was removed in error, please reach out to us at support@theweddingmarketplace.com and we will investigate the removal and clarify the situation.',
        },
      ],
    },
    {
      category: 'Buying',
      faqs: [
        {
          question: 'Can I ask a seller a question?',
          answer:
            'Yes! Submit a product inquiry to ask the seller your question.',
        },
        {
          question: 'What is an ‘Order Status’?',
          answer:
            'Order status refers to where your items are after sale: ordered (purchased), shipped, and delivered.',
        },
        {
          question: 'How do I keep track of order numbers?',
          answer:
            'To help you track your purchases, we provide a single order number for all items purchased at the same time. If you purchase your items separately, you will receive multiple order numbers. Please note: order numbers are not the same as tracking numbers used by shipping companies and are not used to track packages that have been shipped.',
        },
        {
          question: 'Can I cancel an order?',
          answer:
            'All TWM sales are final. However, if your item has not shipped within a reasonable time frame, contact us at support@theweddingmarketplace.com for further assistance. Custom products are allowed up to 21 days to ship.',
        },
        {
          question: 'Can I return an item that doesn’t fit?',
          answer:
            'All TWM sales are final. Buyers should confirm all measurements or dimensions prior to purchase.',
        },
        {
          question: 'When will my purchase arrive?',
          answer:
            'We recommend sellers ship your item as quickly as possible. We strongly recommend sellers ship using a tracking number. If they choose to share the tracking number with you, you will be able to track your item’s location and its anticipated delivery date. For other shipping questions, please see the Shipping FAQ.',
        },
        {
          question: 'My item is incorrect/arrived damaged. What do I do?',
          answer:
            'We strive to ensure every transaction is a positive one, and when mistakes occur, we want to correct them as quickly as possible. We recommend you document damages or inaccuracies with photos, and then contact the seller to initiate a resolution. If you cannot resolve the issue with the seller, please reach out to us at support@theweddingmarketplace.com and we will do our best to help you out. You can see the terms and conditions of TWM transactions in the Buyer-Seller Agreement.',
        },
      ],
    },
    {
      category: 'Shipping',
      faqs: [
        {
          question: 'How are shipping fees calculated?',
          answer:
            'Shipping is an agreement between the seller and buyer. Sellers are expected to be upfront about true shipping costs, and who is responsible for paying them. If a buyer is unclear about shipping, they should clarify with the seller prior to purchasing. TWM is not involved in or responsible for shipping.',
        },
        {
          question: 'My item never arrived. Now what?',
          answer:
            'TWM always recommends using tracking numbers when sending packages in case this happens. Occasionally, shipping companies (including USPS) list an item as delivered prior to its actual delivery. We recommend waiting a few days, and if it does not arrive, contacting the shipping company to find out what is happening with your package.',
        },
        {
          question: 'How should I pack my items?',
          answer:
            'Please take extra care when packing items for shipment, and pack them the same way you would want someone to send them to you. Protect the items by using bubble wrap in, around, or between items. Place extra packing materials around items to prevent movement and choose the correct box sizes. If your items are fragile, make sure the box is well-labeled to indicate the shipping service should handle the package with care. If you need assistance with odd-sized, heavy, or other items, your local UPS or USPS office should be able to help.',
        },
      ],
    },
  ];

  return (
    <div className="bg-white max-w-7xl mx-auto py-16 px-4 md:px-10 text-gray-900">
      {/* Header */}
      <section className="text-center mb-12">
        <h2 className="text-4xl font-bold">Have Questions? We Can Help.</h2>
        <p className="text-lg mt-4">
          For more information, contact us at{' '}
          <a
            href="mailto:support@theweddingmarketplace.com"
            className="text-blue-500 underline"
          >
            support@theweddingmarketplace.com
          </a>
          .
        </p>
      </section>

      {/* FAQ Section */}
      <section>
        <h2 className="text-3xl font-bold mb-6">FAQ</h2>

        {faqData.map((section, index) => (
          <div key={index} className="mb-8">
            <h3 className="text-2xl font-bold mb-4">{section.category}</h3>
            {section.faqs.map((faq, idx) => (
              <AccordionItem
                key={idx}
                question={faq.question}
                answer={faq.answer}
              />
            ))}
          </div>
        ))}
      </section>
    </div>
  );
}
