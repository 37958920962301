import React, { useState, useEffect } from "react";
import AskQuestionModal from "../Modals/AskQuestionModal";
import { getPaginatedFAQs } from "../../Hooks/faqHooks"; // Import your custom API function
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const Faq = () => {
  const { productId } = useParams(); // Get productId from route params

  const [faqs, setFaqs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true); // State for loading status

  const faqsPerPage = 10;

  // Fetch FAQs whenever the page changes
  useEffect(() => {
    const fetchFaqs = async () => {
      setLoading(true);
      const response = await getPaginatedFAQs(
        productId,
        currentPage,
        faqsPerPage
      );

      if (response.success) {
        setFaqs(response.data?.faqs || []);
        setTotalPages(response.data?.totalPages || 1);
        console.log(response.data?.faqs)
      } else {
        toast.error(response.message || "Failed to fetch FAQs");
      }
      setLoading(false);
    };

    fetchFaqs();
  }, [productId, currentPage]);

  // Open and close modal handlers
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  // Handle pagination click
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleQuestionSubmit = (question) => {
    const newQuestion = {
      question: question, // The question submitted by the user
      user: "You", // You can set this dynamically based on the logged-in user
      answer: null, // No answer yet for a newly asked question
    };

    // Update the faqs state to include the new question
    setFaqs((prevFaqs) => [newQuestion, ...prevFaqs]);
    closeModal();
    toast.success("Your question has been submitted!");
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <p>Loading FAQs...</p>
      </div>
    );
  }

  return (
    <div className="p-5">
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-semibold mb-4 text-black">
          Frequently Asked Questions
        </h2>
        <button
          className="bg-black text-white px-6 py-2 rounded hover:bg-gray-800"
          onClick={openModal}
        >
          Ask a Question
        </button>
      </div>
      <ul className="text-lg">
        {faqs.length > 0 ? (
          faqs.map((faq, index) => (
            <div key={index}>
              <li className="my-2">
                <strong className="text-black">Q: {faq.question}</strong>{" "}
                <span className="text-gray-700">- Asked by {faq.askedBy}</span>
              </li>
              {faq.answer?<p className="text-black">A: {faq.answer}</p>:null}
              <hr className="border-gray-500" />
            </div>
          ))
        ) : (
          <p>
            No FAQs available for this product. Be the first to ask a question!
          </p>
        )}
      </ul>

      {/* Pagination Controls */}
      {totalPages > 1 && (
        <div className="flex justify-between mt-4">
          <button
            className={`px-4 py-2 rounded ${
              currentPage === 1
                ? "bg-gray-300 cursor-not-allowed"
                : "bg-black text-white hover:bg-gray-800"
            }`}
            onClick={handlePrevPage}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <span className="text-lg text-black">
            Page {currentPage} of {totalPages}
          </span>
          <button
            className={`px-4 py-2 rounded ${
              currentPage === totalPages
                ? "bg-gray-300 cursor-not-allowed"
                : "bg-black text-white hover:bg-gray-800"
            }`}
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      )}

      <AskQuestionModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        productId={productId}
        onSubmit={(newQuestion) => {
          setFaqs((prevFaqs) => [newQuestion, ...prevFaqs]);
        }}
      />
    </div>
  );
};

export default Faq;
