import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  FaTrash,
  FaChevronDown,
  FaChevronUp,
  FaCheckSquare,
  FaSquare,
  FaPaintBrush,
  FaCouch,
  FaUtensils,
  FaChair,
  FaGift,
  FaGem,
  FaLightbulb,
  FaPaperPlane,
  FaSearch,
  FaToggleOn,
  FaToggleOff,
} from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getAllChecklist, deleteSubitem, updateSubitemStatus } from '../Hooks/checklistHooks';

const iconsMap = {
  Art: <FaPaintBrush style={{ color: '#e74c3c', marginRight: '8px' }} />, 
  Decor: <FaCouch style={{ color: '#3498db', marginRight: '8px' }} />, 
  Dinnerware: <FaUtensils style={{ color: '#f39c12', marginRight: '8px' }} />, 
  Fabric: <FaChair style={{ color: '#2ecc71', marginRight: '8px' }} />, 
  Favors: <FaGift style={{ color: '#9b59b6', marginRight: '8px' }} />, 
  Flowers: <FaGem style={{ color: '#e67e22', marginRight: '8px' }} />, 
  Furniture: <FaChair style={{ color: '#1abc9c', marginRight: '8px' }} />, 
  Gifts: <FaGift style={{ color: '#c0392b', marginRight: '8px' }} />, 
  'Jewelry and Accessories': <FaGem style={{ color: '#8e44ad', marginRight: '8px' }} />, 
  Lighting: <FaLightbulb style={{ color: '#f1c40f', marginRight: '8px' }} />, 
  Paper: <FaPaperPlane style={{ color: '#2980b9', marginRight: '8px' }} />,
};

export default function CheckList() {
  const [checklist, setChecklist] = useState([]);
  const [expanded, setExpanded] = useState({});
  const [sidePanelOpen, setSidePanelOpen] = useState(false);
  const [currentTask, setCurrentTask] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [showCompleted, setShowCompleted] = useState(false);

  useEffect(() => {
    async function fetchChecklist() {
      const response = await getAllChecklist();
      if (response.success) {
        const formattedChecklist = response.checklists.map((item) => ({
          checklistId: item._id,
          name: item.name,
          icon: iconsMap[item.name] || null,
          subitems: item.subitems.map((subitem) => ({
            subitemId: subitem._id,
            name: subitem.name,
            status: subitem.status,
          })),
        }));
        setChecklist(formattedChecklist);
      } else {
        toast.error(response.message);
      }
    }

    fetchChecklist();
  }, []);

  const handleToggleExpand = (index) => {
    setExpanded({ ...expanded, [index]: !expanded[index] });
  };

  const handleDeleteSubitem = async (mainIndex, subitemIndex) => {
    const subitem = checklist[mainIndex].subitems[subitemIndex];
    const response = await deleteSubitem({
      checklistId: checklist[mainIndex].checklistId,
      subitemId: subitem.subitemId,
    });

    if (response.success) {
      const updatedChecklist = [...checklist];
      updatedChecklist[mainIndex].subitems.splice(subitemIndex, 1);
      setChecklist(updatedChecklist);
      toast.success(response.message);
    } else {
      toast.error(response.message);
    }
  };

  const handleToggleTaskCompletion = async (mainIndex, subitemIndex) => {
    const subitem = checklist[mainIndex].subitems[subitemIndex];
    const newStatus = !subitem.status;

    const response = await updateSubitemStatus({
      checklistId: checklist[mainIndex].checklistId,
      subitemId: subitem.subitemId,
      status: newStatus,
    });

    if (response.success) {
      const updatedChecklist = [...checklist];
      updatedChecklist[mainIndex].subitems[subitemIndex].status = newStatus;
      setChecklist(updatedChecklist);
      toast.success(response.message);
    } else {
      toast.error(response.message);
    }
  };

  const handleViewDetails = (mainIndex, subitemIndex) => {
    setCurrentTask({ mainIndex, subitemIndex });
    setSidePanelOpen(true);
  };

  const handleCloseSidePanel = () => {
    setSidePanelOpen(false);
    setCurrentTask(null);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleShowCompletedToggle = () => {
    setShowCompleted(!showCompleted);
  };

  const filteredChecklist = checklist.map((item) => ({
    ...item,
    subitems: showCompleted
      ? item.subitems.filter((subitem) => subitem.status)
      : item.subitems,
  })).filter((item) =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="checklist-container p-6 bg-white max-w-4xl mx-auto rounded-lg shadow-md">
      <div className="flex items-center justify-between mb-6">
        <h1 className="text-3xl font-bold">Checklist</h1>
      </div>

      <div className="flex items-center mb-6">
        <div className="flex items-center w-1/2 mr-4">
          <FaSearch className="text-gray-600 mr-2" />
          <input
            type="text"
            placeholder="Search tasks"
            value={searchTerm}
            onChange={handleSearch}
            className="border border-gray-300 p-2 rounded-md w-full"
          />
        </div>
        <div className="flex items-center w-1/2">
          <label className="flex items-center cursor-pointer">
            {showCompleted ? (
              <FaToggleOn
                className="mr-2 text-xl text-blue-500"
                onClick={handleShowCompletedToggle}
              />
            ) : (
              <FaToggleOff
                className="mr-2 text-xl text-gray-300"
                onClick={handleShowCompletedToggle}
              />
            )}
            Show completed
          </label>
        </div>
      </div>

      {filteredChecklist.map((item, mainIndex) => (
        <div key={mainIndex} className="mb-4 border-b pb-4">
          <div className="flex items-center justify-between">
            <h2 className="text-2xl font-semibold flex items-center">
              {item.icon} {item.name}
              {item.subitems.length > 0 && (
                <button
                  onClick={() => handleToggleExpand(mainIndex)}
                  className="ml-2"
                >
                  {expanded[mainIndex] ? <FaChevronUp /> : <FaChevronDown />}
                </button>
              )}
            </h2>
          </div>
          {expanded[mainIndex] && item.subitems.length > 0 && (
            <ul className="ml-6 mt-4">
              {item.subitems.map((subitem, subitemIndex) => (
                <li
                  key={subitemIndex}
                  className="flex items-center justify-between mb-2 p-4 rounded-lg shadow-sm transition-all duration-200 hover:bg-gray-50 hover:shadow-md group"
                >
                  <div className="flex items-center">
                    <button
                      onClick={() => handleToggleTaskCompletion(mainIndex, subitemIndex)}
                      className="mr-2 text-black hover:text-gray-700"
                    >
                      {subitem.status ? <FaCheckSquare /> : <FaSquare />}
                    </button>
                    <div>
                      <span
                        className={
                          subitem.status ? 'line-through text-gray-500' : ''
                        }
                      >
                        {subitem.name}
                      </span>
                      <p className="text-sm text-gray-400">Due December 07, 2024</p>
                    </div>
                  </div>
                  <div className="flex items-center">
                    <button
                      onClick={() => handleViewDetails(mainIndex, subitemIndex)}
                      className="text-blue-600 hover:text-blue-800 mr-4 hidden group-hover:block"
                    >
                      View details
                    </button>
                    <button
                      onClick={() => handleDeleteSubitem(mainIndex, subitemIndex)}
                      className="text-gray-600 hover:text-gray-800"
                    >
                      <FaTrash />
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      ))}

      {/* Side Panel for viewing task details */}
      <div
        className={`fixed top-0 right-0 h-full bg-white w-full sm:w-96 shadow-lg transform transition-transform duration-300 ${
          sidePanelOpen ? 'translate-x-0' : 'translate-x-full'
        } z-50`}
      >
        <div className="relative flex flex-col p-6 h-full overflow-y-auto">
          {currentTask && (
            <>
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl font-bold">
                  {checklist[currentTask.mainIndex].subitems[currentTask.subitemIndex].name}
                </h2>
                <button
                  onClick={handleCloseSidePanel}
                  className="text-gray-700 hover:text-gray-900 text-2xl"
                >
                  &times;
                </button>
              </div>
              <div className="mb-4">
                <label className="flex items-center mb-2">
                  <input
                    type="checkbox"
                    checked={
                      checklist[currentTask.mainIndex].subitems[currentTask.subitemIndex].status
                    }
                    onChange={() =>
                      handleToggleTaskCompletion(
                        currentTask.mainIndex,
                        currentTask.subitemIndex
                      )
                    }
                    className="mr-2"
                  />
                  Mark It Done
                </label>
              </div>
              <div className="flex justify-end mt-auto">
                <button
                  onClick={handleCloseSidePanel}
                  className="text-black hover:text-[#262626] px-4 py-2 mr-4"
                >
                  Cancel
                </button>
                <button
                  onClick={() => console.log('Save Clicked')}
                  className="bg-black text-white px-4 py-2 rounded-md hover:bg-[#262626]"
                >
                  Save
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}