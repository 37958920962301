import axios from "axios";

axios.defaults.baseURL =
  process.env.REACT_APP_BACKEND_URL || "http://localhost:5000";

export async function sendPhoneVerificationOtp(phone) {
  try {
    const token = localStorage.getItem("twmToken");

    if (!token) {
      return {
        success: false,
        message: "Please login first",
        status: 401,
      };
    }

    if (!phone) {
      return {
        success: false,
        message: "Phone number is required",
        status: 400,
      };
    }

    const phoneRegex = /^[+]?[0-9]{10,15}$/;
    if (!phoneRegex.test(phone)) {
      return {
        success: false,
        message: "Invalid phone number format",
        status: 400,
      };
    }

    const { data, status } = await axios.post(
      "/api/user/send-phone-verification-otp",
      { phone },
      {
        headers: {
          "TWM-auth-token": token,
        },
      }
    );

    return {
      success: data.success,
      message: data.message,
      status,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}

export async function verifyPhoneOtp(otp) {
  try {
    const token = localStorage.getItem("twmToken");

    if (!token) {
      return {
        success: false,
        message: "Please login first",
        status: 401,
      };
    }

    const { data, status } = await axios.post(
      "/api/user/verify-phone-otp",
      { otp },
      {
        headers: {
          "TWM-auth-token": token,
        },
      }
    );

    return {
      success: data.success,
      message: data.message,
      status,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}

export async function addShippingAddress(address) {
  try {
    const token = localStorage.getItem("twmToken");

    if (!token) {
      return {
        success: false,
        message: "Please login first",
        status: 401,
      };
    }

    const requiredFields = ["line1", "city", "state", "postalCode", "country"];
    const missingFields = requiredFields.filter((field) => !address[field]);

    if (missingFields.length > 0) {
      return {
        success: false,
        message: `Missing required fields: ${missingFields.join(", ")}`,
        status: 400,
      };
    }

    const { data, status } = await axios.post(
      "/api/user/add-shipping-address",
      address,
      {
        headers: {
          "TWM-auth-token": token,
        },
      }
    );

    return {
      success: data.success,
      message: data.message,
      shippingAddress: data.shippingAddress,
      status,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}

export async function addBillingAddress(address) {
  try {
    const token = localStorage.getItem("twmToken");

    if (!token) {
      return {
        success: false,
        message: "Please login first",
        status: 401,
      };
    }

    const requiredFields = ["line1", "city", "state", "postalCode", "country"];
    const missingFields = requiredFields.filter((field) => !address[field]);

    if (missingFields.length > 0) {
      return {
        success: false,
        message: `Missing required fields: ${missingFields.join(", ")}`,
        status: 400,
      };
    }

    const { data, status } = await axios.post(
      "/api/user/add-billing-address",
      address,
      {
        headers: {
          "TWM-auth-token": token,
        },
      }
    );

    return {
      success: data.success,
      message: data.message,
      billingAddress: data.billingAddress,
      status,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}

export async function updateBillingAddress(address) {
  try {
    const token = localStorage.getItem("twmToken");

    if (!token) {
      return {
        success: false,
        message: "Please login first",
        status: 401,
      };
    }

    const requiredFields = ["line1", "city", "state", "postalCode", "country"];
    const missingFields = requiredFields.filter((field) => !address[field]);

    if (missingFields.length > 0) {
      return {
        success: false,
        message: `Missing required fields: ${missingFields.join(", ")}`,
        status: 400,
      };
    }

    const { data, status } = await axios.put(
      "/api/user/update-billing-address",
      address,
      {
        headers: {
          "TWM-auth-token": token,
        },
      }
    );

    return {
      success: data.success,
      message: data.message,
      billingAddress: data.billingAddress,
      status,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}

export async function updateShippingAddress({
  line1,
  line2,
  city,
  state,
  postalCode,
  country,
}) {
  try {
    const token = localStorage.getItem("twmToken");

    if (!token) {
      return {
        success: false,
        message: "Please login first",
        status: 401,
      };
    }

    const { data, status } = await axios.put(
      "/api/user/update-shipping-address",
      { line1, line2, city, state, postalCode, country },
      {
        headers: {
          "TWM-auth-token": token,
        },
      }
    );

    return {
      success: data.success,
      message: data.message,
      shippingAddress: data.shippingAddress,
      status,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}

export async function addCoverPhoto(filePath) {
  try {
    const token = localStorage.getItem("twmToken");

    if (!token) {
      return {
        success: false,
        message: "Please login first",
        status: 401,
      };
    }

    if (!filePath) {
      return {
        success: false,
        message: "File path is required",
        status: 400,
      };
    }

    const { data, status } = await axios.post(
      "/api/user/add-cover-image",
      { filePath },
      {
        headers: {
          "TWM-auth-token": token,
        },
      }
    );

    return {
      success: data.success,
      message: data.message,
      coverPhoto: data.coverPhoto,
      status,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}

export async function addProfileImage(filePath) {
  try {
    const token = localStorage.getItem("twmToken");

    if (!token) {
      return {
        success: false,
        message: "Please login first",
        status: 401,
      };
    }

    if (!filePath) {
      return {
        success: false,
        message: "File path is required",
        status: 400,
      };
    }

    const { data, status } = await axios.post(
      "/api/user/add-profile-image",
      { filePath },
      {
        headers: {
          "TWM-auth-token": token,
        },
      }
    );

    return {
      success: data.success,
      message: data.message,
      profilePicture: data.profilePicture,
      status,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}

export async function sendRoleChangeOtp() {
  try {
    const token = localStorage.getItem("twmToken");

    if (!token) {
      return {
        success: false,
        message: "Please login first",
        status: 401,
      };
    }

    const { data, status } = await axios.post(
      "/api/user/send-otp-for-role-change",
      {},
      {
        headers: {
          "TWM-auth-token": token,
        },
      }
    );

    return {
      success: data.success,
      message: data.message,
      status,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}

export async function verifyOtpAndChangeRole({ otp, newRole }) {
  try {
    const token = localStorage.getItem("twmToken");

    if (!token) {
      return {
        success: false,
        message: "Please login first",
        status: 401,
      };
    }

    if (!otp || !newRole) {
      return {
        success: false,
        message: "OTP and new role are required.",
        status: 400,
      };
    }

    const { data, status } = await axios.post(
      "/api/user/verify-otp-change-role",
      { otp, newRole },
      {
        headers: {
          "TWM-auth-token": token,
        },
      }
    );

    return {
      success: data.success,
      message: data.message,
      role: data.role,
      status,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}

export async function followUser(targetUserId) {
  try {
    const token = localStorage.getItem("twmToken");

    if (!token) {
      return {
        success: false,
        message: "Please login first",
        status: 401,
      };
    }

    if (!targetUserId) {
      return {
        success: false,
        message: "Target user ID is required.",
        status: 400,
      };
    }

    const { data, status } = await axios.post(
      "/api/user/follow-user",
      { targetUserId },
      {
        headers: {
          "TWM-auth-token": token,
        },
      }
    );

    return {
      success: data.success,
      message: data.message,
      targetUser: data.targetUser,
      currentUser: data.currentUser,
      status,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}

export async function unfollowUser(targetUserId) {
  try {
    const token = localStorage.getItem("twmToken");

    if (!token) {
      return {
        success: false,
        message: "Please login first",
        status: 401,
      };
    }

    if (!targetUserId) {
      return {
        success: false,
        message: "Target user ID is required.",
        status: 400,
      };
    }

    const { data, status } = await axios.post(
      "/api/user/unfollow-user",
      { targetUserId },
      {
        headers: {
          "TWM-auth-token": token,
        },
      }
    );

    return {
      success: data.success,
      message: data.message,
      targetUser: data.targetUser,
      currentUser: data.currentUser,
      status,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}

export async function addToCart(userId, productId, quantity) {
  try {
    const token = localStorage.getItem("twmToken");

    if (!token) {
      return {
        success: false,
        message: "Please login first",
        status: 401,
      };
    }

    if (!productId || quantity === undefined) {
      return {
        success: false,
        message: "Product ID and quantity are required.",
        status: 400,
      };
    }

    const { data, status } = await axios.post(
      `/api/user/cart/add/${userId}`,
      { productId, quantity },
      {
        headers: {
          "TWM-auth-token": token,
        },
      }
    );

    return {
      success: data.success,
      message: data.message,
      cart: data.cart,
      status,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}

export async function removeCartProduct(userId, productId) {
  try {
    const token = localStorage.getItem("twmToken");

    if (!token) {
      return {
        success: false,
        message: "Please login first",
        status: 401,
      };
    }

    if (!productId) {
      return {
        success: false,
        message: "Product ID is required to remove the product from the cart.",
        status: 400,
      };
    }

    const { data, status } = await axios.delete(
      `/api/user/cart/remove/${userId}`,
      {
        data: { productId },
        headers: {
          "TWM-auth-token": token,
        },
      }
    );

    return {
      success: data.success,
      message: data.message,
      cart: data.cart,
      status,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}

export async function getCartProducts(userId) {
  try {
    const token = localStorage.getItem("twmToken");

    if (!token) {
      return {
        success: false,
        message: "Please login first",
        status: 401,
      };
    }

    const { data, status } = await axios.get(`/api/user/cart/${userId}`, {
      headers: {
        "TWM-auth-token": token,
      },
    });

    return {
      success: data.success,
      message: data.message,
      cartProducts: data.cartProducts,
      status,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}

export async function getUserData() {
  try {
    const token = localStorage.getItem("twmToken");

    // Set up headers
    const headers = {
      "TWM-auth-token": token,
    };

    // Make the API request
    const { data, status } = await axios.get(`/api/user`, { headers });

    return {
      success: data.success,
      message: data.message,
      status,
      user: data.user || null,
    };
  } catch (error) {
    return {
      success: false,
      message:
        error.response?.data?.message || error.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}

export async function getUserProfile(userId) {
  try {
    const { data, status } = await axios.get(`/api/user/profile/${userId}`);

    return {
      success: data.success,
      message: data.message,
      user: data.user,
      products: data.products,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}

export async function getPayoutInfo() {
  try {
    const token = localStorage.getItem("twmToken");

    const { data, status } = await axios.get(`/api/user/payout-info`, {
      headers: {
        "TWM-auth-token": token,
      },
    });

    return {
      success: data.success,
      message: data.message,
      payoutInfo: data.payoutInfo,
      status,
    };
  } catch (error) {}
}

export async function getAccountIdsBool() {
  try {
    const token = localStorage.getItem("twmToken");

    const { data, status } = await axios.get(`/api/user/account-ids-bool`, {
      headers: {
        "TWM-auth-token": token,
      },
    });

    return {
      success: data.success,
      message: data.message,
      isStripeAccountID: data.isStripeAccountID,
      isPayPalID: data.isPayPalID,
      isStripeBankAccountID: data.isStripeBankAccountID,
      status,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}

export async function addStripeAccount(AccountData) {
  try {
    const token = localStorage.getItem("twmToken");

    const { data, status } = await axios.post(
      `/api/user/add-stripe-account`,
      AccountData,
      {
        headers: {
          "TWM-auth-token": token,
        },
      }
    );

    return {
      success: data.success,
      message: data.message,
      status,
      accountId: data.accountId,
      onboardingLink: data.onboardingLink,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}

export async function addStripeAccountId(AccountData) {
  try {
    const token = localStorage.getItem("twmToken");

    const { data, status } = await axios.post(
      `/api/user/add-stripe-account-id`,
      AccountData,
      {
        headers: {
          "TWM-auth-token": token,
        },
      }
    );

    return {
      success: data.success,
      message: data.message,
      status,
      accountId: data.accountId,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}

export async function addBankAccount(bankDetails) {
  try {
    const token = localStorage.getItem("twmToken");

    const { data, status } = await axios.post(
      "/api/user/add-bank-account",
      bankDetails,
      {
        headers: {
          "TWM-auth-token": token,
        },
      }
    );

    return {
      success: data.success,
      message: data.message,
      status,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}

export async function addPayPalId(paypalId) {
  try {
    const token = localStorage.getItem("twmToken");

    const { data, status } = await axios.post(
      "/api/user/add-paypal-id",
      { paypalId },
      {
        headers: {
          "TWM-auth-token": token,
        },
      }
    );

    return {
      success: data.success,
      message: data.message,
      status,
      paypalId: data.paypalId,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}

export async function payoutToStripeAccount(amount) {
  try {
    const token = localStorage.getItem("twmToken");

    const { data, status } = await axios.post(
      "/api/user/payout-to-stripe-account",
      { amount },
      {
        headers: {
          "TWM-auth-token": token,
        },
      }
    );

    return {
      success: data.success,
      message: data.message,
      status,
      payout: data.payout,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
  
}

export async function payoutToBankAccount(amount) {
  try {
    const token = localStorage.getItem("twmToken");

    const { data, status } = await axios.post(
      "/api/user/payout-to-bank-account",
      { amount },
      {
        headers: {
          "TWM-auth-token": token,
        },
      }
    );

    return {
      success: data.success,
      message: data.message,
      status,
      payout: data.payout,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}

export async function payoutToPayPal(amount) {
  try {
    const token = localStorage.getItem("twmToken");

    const { data, status } = await axios.post(
      "/api/user/payout-to-paypal",
      { amount },
      {
        headers: {
          "TWM-auth-token": token,
        },
      }
    );

    return {
      success: data.success,
      message: data.message,
      status,
      payout: data.payout,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}

export async function updateUser(userBody) {
  try {
    const token = localStorage.getItem("twmToken");

    const { data, status } = await axios.post("/api/user/update-profile", {userBody}, {
      headers: {
        "TWM-auth-token": token,
      },
    });

    return {
      success: data.success,
      message: data.message,
      status,
      user: data.user,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}