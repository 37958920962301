import React from 'react';
import { FaGoogle, FaApple, FaFacebook } from 'react-icons/fa';
import weddinglogo from '../../assets/weddinglogo.png';
import { Link, useNavigate } from 'react-router-dom';


export default function AdminLogin() {
    const navigate = useNavigate(); // Initialize navigate hook

    // Handle login logic and navigation
    const handleLogin = () => {
        // You can add authentication logic here
        navigate('/admin'); // Navigate to the /admin route
    };
    return (
        <div className="flex justify-center items-center min-h-screen bg-white">

            <div className=" w-full mx-5 sm:mx-auto md:w-1/2 flex justify-center items-center">
                <div className="w-full max-w-md px-8 py-8 bg-white shadow-lg rounded-lg">
                    <div className="flex justify-center mb-8">
                        <img src={weddinglogo} alt="Logo" className="h-12" />
                    </div>

                    <h2 className="text-3xl font-semibold text-center text-gray-800 mb-6">Login To Your Panel</h2>

                    <div className="text-right mb-4">
                        <a href="/forgot-password" className="text-sm text-gray-500 hover:text-gray-700">
                            Forgot your password?
                        </a>
                    </div>

                    {/* Email and Password fields */}
                    <div className="mb-4">
                        <input
                            type="email"
                            placeholder="Email"
                            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                        />
                    </div>
                    <div className="mb-4">
                        <input
                            type="password"
                            placeholder="Password"
                            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                        />
                    </div>

                    {/* Keep me logged in */}
                    <div className="flex items-center mb-6">
                        <input type="checkbox" id="keep-logged-in" className="mr-2" />
                        <label htmlFor="keep-logged-in" className="text-gray-600 text-sm">
                            Keep me logged in
                        </label>
                    </div>

                    <button
                        onClick={handleLogin} // Navigate to /admin on click
                        className="w-full bg-black text-white py-3 rounded-lg mb-6 hover:bg-[#262626] transition"
                    >
                        EMAIL LOGIN
                    </button>

                    {/* Social Login Buttons */}
                    <div className="flex justify-between mb-4">
                        <button className="flex items-center justify-center w-1/3 bg-gray-100 border border-gray-300 py-2 rounded-lg hover:bg-gray-200 transition">
                            <FaGoogle className="text-red-500" />
                        </button>
                        <button className="flex items-center justify-center w-1/3 bg-gray-100 border border-gray-300 py-2 rounded-lg hover:bg-gray-200 transition">
                            <FaApple className="text-black" />
                        </button>
                        <button className="flex items-center justify-center w-1/3 bg-gray-100 border border-gray-300 py-2 rounded-lg hover:bg-gray-200 transition">
                            <FaFacebook className="text-blue-600" />
                        </button>
                    </div>
                    <div className='flex justify-end items-end  text-gray-500'>
                        <Link to={'/admin/signup'} className='text-sm text-center center'>Don't have an Account <strong className='text-blue-500'>Signup</strong></Link>
                    </div>

                    {/* Terms and Conditions */}
                    <div className="text-center text-sm text-gray-500">
                        By clicking 'Log In' you agree to our website{' '}
                        <a href="/terms" className="text-blue-600 hover:underline">
                            Terms & Conditions
                        </a>
                        .
                    </div>
                </div>
            </div>
        </div>
    );
}
