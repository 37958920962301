// components/AddressSettings.js

import React, { useState } from 'react';
import { toast } from 'react-toastify';

// Example hooks for sending data to backend
import { addShippingAddress, addBillingAddress } from '../../Hooks/UserHooks';

// Import the MapWithSearch component
import MapWithSearch from '../MapWithSearch';

export default function AddressSettings({ userData }) {
  // State to hold both shipping and billing form values
  const [formData, setFormData] = useState({
    // Shipping Fields
    addressLine1: userData?.shippingAddress?.line1 || '',
    addressLine2: userData?.shippingAddress?.line2 || '',
    city: userData?.shippingAddress?.city || '',
    state: userData?.shippingAddress?.state || '',
    stateCode: userData?.shippingAddress?.stateCode || '',
    country: userData?.shippingAddress?.country || '',
    zipCode: userData?.shippingAddress?.postalCode || '',
    lat: userData?.shippingAddress?.lat || null,
    lng: userData?.shippingAddress?.lng || null,
    placeId: userData?.shippingAddress?.placeId || '',

    // Billing Fields (nested)
    billingAddress: {
      addressLine1: userData?.billingAddress?.line1 || '',
      addressLine2: userData?.billingAddress?.line2 || '',
      city: userData?.billingAddress?.city || '',
      state: userData?.billingAddress?.state || '',
      stateCode: userData?.billingAddress?.stateCode || '',
      country: userData?.billingAddress?.country || '',
      zipCode: userData?.billingAddress?.postalCode || '',
      lat: userData?.billingAddress?.lat || null,
      lng: userData?.billingAddress?.lng || null,
      placeId: userData?.billingAddress?.placeId || '',
    },
  });

  // Control form visibility
  const [showShippingForm, setShowShippingForm] = useState(!!userData?.shippingAddress);
  const [showBillingForm, setShowBillingForm] = useState(!!userData?.billingAddress);

  // Handle shipping address input (standard text fields)
  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  // Handle billing address input (standard text fields)
  const handleBillingInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      billingAddress: {
        ...prevData.billingAddress,
        [id]: value,
      },
    }));
  };

  // Handle shipping address Map selection (from MapWithSearch)
  const handleShippingMapSelect = (addressObj) => {
    // addressObj includes line1, line2, city, state, stateCode, postalCode, country, lat, lng, placeId
    setFormData((prevData) => ({
      ...prevData,
      addressLine1: addressObj.line1 || '',
      addressLine2: addressObj.line2 || '',
      city: addressObj.city || '',
      state: addressObj.state || '',
      stateCode: addressObj.stateCode || '',
      zipCode: addressObj.postalCode || '',
      country: addressObj.country || '',
      lat: addressObj.lat || null,
      lng: addressObj.lng || null,
      placeId: addressObj.placeId || '',
    }));
  };

  // Handle billing address Map selection (from MapWithSearch)
  const handleBillingMapSelect = (addressObj) => {
    setFormData((prevData) => ({
      ...prevData,
      billingAddress: {
        ...prevData.billingAddress,
        addressLine1: addressObj.line1 || '',
        addressLine2: addressObj.line2 || '',
        city: addressObj.city || '',
        state: addressObj.state || '',
        stateCode: addressObj.stateCode || '',
        zipCode: addressObj.postalCode || '',
        country: addressObj.country || '',
        lat: addressObj.lat || null,
        lng: addressObj.lng || null,
        placeId: addressObj.placeId || '',
      },
    }));
  };

  // Submit shipping address
  const handleShippingSubmit = async (e) => {
    e.preventDefault();
    const address = {
      line1: formData.addressLine1,
      line2: formData.addressLine2,
      city: formData.city,
      state: formData.state,
      stateCode: formData.stateCode,
      postalCode: formData.zipCode,
      country: formData.country,
      lat: formData.lat,
      lng: formData.lng,
      placeId: formData.placeId,
    };

    const result = await addShippingAddress(address);
    if (result.success) {
      toast.success(result.message);
      setShowShippingForm(false);
    } else {
      toast.error(result.message);
    }
  };

  // Submit billing address
  const handleBillingSubmit = async (e) => {
    e.preventDefault();
    const address = {
      line1: formData.billingAddress.addressLine1,
      line2: formData.billingAddress.addressLine2,
      city: formData.billingAddress.city,
      state: formData.billingAddress.state,
      stateCode: formData.billingAddress.stateCode,
      postalCode: formData.billingAddress.zipCode,
      country: formData.billingAddress.country,
      lat: formData.billingAddress.lat,
      lng: formData.billingAddress.lng,
      placeId: formData.billingAddress.placeId,
    };

    const result = await addBillingAddress(address);
    if (result.success) {
      toast.success(result.message);
      setShowBillingForm(false);
    } else {
      toast.error(result.message);
    }
  };

  return (
    <div className="max-w-3xl mx-auto p-6 bg-white rounded-lg shadow-lg">
      <h3 className="text-2xl font-bold mb-6 text-black">My Addresses</h3>

      {/* SHIPPING ADDRESS */}
      <div className="mb-8 p-4 bg-gray-100 rounded-lg">
        <h4 className="text-lg font-semibold text-gray-700 uppercase mb-2">
          Default Shipping Address
        </h4>
        {!showShippingForm ? (
          <div className="flex justify-between items-center">
            <p className="text-gray-600">
              {userData?.shippingAddress
                ? `${userData.shippingAddress.line1}, ${userData.shippingAddress.city}, ${userData.shippingAddress.state}, ${userData.shippingAddress.country}, ${userData.shippingAddress.postalCode}`
                : 'You have not set a default shipping address yet.'}
            </p>
            <button
              onClick={() => setShowShippingForm(true)}
              className="border border-black text-black px-4 py-1 rounded hover:bg-black hover:text-white transition-colors"
            >
              {userData?.shippingAddress ? 'Update Address' : 'Add Address'}
            </button>
          </div>
        ) : (
          <form className="mt-4 space-y-4" onSubmit={handleShippingSubmit}>
            {/* Map + Autocomplete for Shipping */}
            <MapWithSearch
              onAddressSelect={handleShippingMapSelect}
              initialAddress={{
                lat: formData.lat,
                lng: formData.lng,
              }}
            />

            {/* Shipping Form Fields */}
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4">
              <div>
                <label className="block text-black font-semibold mb-2" htmlFor="addressLine1">
                  Address Line 1
                </label>
                <input
                  id="addressLine1"
                  type="text"
                  placeholder="Enter your address line 1"
                  value={formData.addressLine1}
                  onChange={handleInputChange}
                  className="w-full border border-gray-300 p-2 rounded-md bg-white text-black"
                />
              </div>
              <div>
                <label className="block text-black font-semibold mb-2" htmlFor="addressLine2">
                  Address Line 2
                </label>
                <input
                  id="addressLine2"
                  type="text"
                  placeholder="Enter your address line 2"
                  value={formData.addressLine2}
                  onChange={handleInputChange}
                  className="w-full border border-gray-300 p-2 rounded-md bg-white text-black"
                />
              </div>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div>
                <label className="block text-black font-semibold mb-2" htmlFor="city">
                  City
                </label>
                <input
                  id="city"
                  type="text"
                  placeholder="Enter your city"
                  value={formData.city}
                  onChange={handleInputChange}
                  className="w-full border border-gray-300 p-2 rounded-md bg-white text-black"
                />
              </div>
              <div>
                <label className="block text-black font-semibold mb-2" htmlFor="state">
                  State/Province
                </label>
                <input
                  id="state"
                  type="text"
                  placeholder="Enter your state"
                  value={formData.state}
                  onChange={handleInputChange}
                  className="w-full border border-gray-300 p-2 rounded-md bg-white text-black"
                />
              </div>
            </div>

            {/* We add a field for the short code as well if you want to display/edit manually */}
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div>
                <label className="block text-black font-semibold mb-2" htmlFor="stateCode">
                  State/Province Code
                </label>
                <input
                  id="stateCode"
                  type="text"
                  placeholder="e.g. CA, NY, ON..."
                  value={formData.stateCode}
                  onChange={handleInputChange}
                  className="w-full border border-gray-300 p-2 rounded-md bg-white text-black"
                />
              </div>
              <div>
                <label className="block text-black font-semibold mb-2" htmlFor="zipCode">
                  ZIP Code
                </label>
                <input
                  id="zipCode"
                  type="text"
                  placeholder="Enter your ZIP code"
                  value={formData.zipCode}
                  onChange={handleInputChange}
                  className="w-full border border-gray-300 p-2 rounded-md bg-white text-black"
                />
              </div>
            </div>

            <div>
              <label className="block text-black font-semibold mb-2" htmlFor="country">
                Country
              </label>
              <input
                id="country"
                type="text"
                placeholder="Enter your country"
                value={formData.country}
                onChange={handleInputChange}
                className="w-full border border-gray-300 p-2 rounded-md bg-white text-black"
              />
            </div>

            <button
              type="submit"
              className="mt-4 bg-black text-white px-4 py-2 rounded hover:bg-gray-800 transition-colors"
            >
              Save Shipping Address
            </button>
          </form>
        )}
      </div>

      {/* BILLING ADDRESS */}
      <div className="p-4 bg-gray-100 rounded-lg">
        <h4 className="text-lg font-semibold text-gray-700 uppercase mb-2">
          Default Billing Address
        </h4>
        {!showBillingForm ? (
          <div className="flex justify-between items-center">
            <p className="text-gray-600">
              {userData?.billingAddress
                ? `${userData.billingAddress.line1}, ${userData.billingAddress.city}, ${userData.billingAddress.state}, ${userData.billingAddress.country}, ${userData.billingAddress.postalCode}`
                : 'You have not set a default billing address yet.'}
            </p>
            <button
              onClick={() => setShowBillingForm(true)}
              className="border border-black text-black px-4 py-1 rounded hover:bg-black hover:text-white transition-colors"
            >
              {userData?.billingAddress ? 'Update Address' : 'Add Address'}
            </button>
          </div>
        ) : (
          <form className="mt-4 space-y-4" onSubmit={handleBillingSubmit}>
            {/* Map + Autocomplete for Billing */}
            <MapWithSearch
              onAddressSelect={handleBillingMapSelect}
              initialAddress={{
                lat: formData.billingAddress.lat,
                lng: formData.billingAddress.lng,
              }}
            />

            {/* Billing Form Fields */}
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4">
              <div>
                <label className="block text-black font-semibold mb-2" htmlFor="addressLine1">
                  Address Line 1
                </label>
                <input
                  id="addressLine1"
                  type="text"
                  placeholder="Enter billing address line 1"
                  value={formData.billingAddress.addressLine1}
                  onChange={handleBillingInputChange}
                  className="w-full border border-gray-300 p-2 rounded-md bg-white text-black"
                />
              </div>
              <div>
                <label className="block text-black font-semibold mb-2" htmlFor="addressLine2">
                  Address Line 2
                </label>
                <input
                  id="addressLine2"
                  type="text"
                  placeholder="Enter billing address line 2"
                  value={formData.billingAddress.addressLine2}
                  onChange={handleBillingInputChange}
                  className="w-full border border-gray-300 p-2 rounded-md bg-white text-black"
                />
              </div>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div>
                <label className="block text-black font-semibold mb-2" htmlFor="city">
                  City
                </label>
                <input
                  id="city"
                  type="text"
                  placeholder="Enter billing city"
                  value={formData.billingAddress.city}
                  onChange={handleBillingInputChange}
                  className="w-full border border-gray-300 p-2 rounded-md bg-white text-black"
                />
              </div>
              <div>
                <label className="block text-black font-semibold mb-2" htmlFor="state">
                  State/Province
                </label>
                <input
                  id="state"
                  type="text"
                  placeholder="Enter billing state"
                  value={formData.billingAddress.state}
                  onChange={handleBillingInputChange}
                  className="w-full border border-gray-300 p-2 rounded-md bg-white text-black"
                />
              </div>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div>
                <label className="block text-black font-semibold mb-2" htmlFor="stateCode">
                  State/Province Code
                </label>
                <input
                  id="stateCode"
                  type="text"
                  placeholder="e.g. CA, NY, ON..."
                  value={formData.billingAddress.stateCode}
                  onChange={handleBillingInputChange}
                  className="w-full border border-gray-300 p-2 rounded-md bg-white text-black"
                />
              </div>
              <div>
                <label className="block text-black font-semibold mb-2" htmlFor="zipCode">
                  ZIP Code
                </label>
                <input
                  id="zipCode"
                  type="text"
                  placeholder="Enter billing ZIP code"
                  value={formData.billingAddress.zipCode}
                  onChange={handleBillingInputChange}
                  className="w-full border border-gray-300 p-2 rounded-md bg-white text-black"
                />
              </div>
            </div>

            <div>
              <label className="block text-black font-semibold mb-2" htmlFor="country">
                Country
              </label>
              <input
                id="country"
                type="text"
                placeholder="Enter billing country"
                value={formData.billingAddress.country}
                onChange={handleBillingInputChange}
                className="w-full border border-gray-300 p-2 rounded-md bg-white text-black"
              />
            </div>

            <button
              type="submit"
              className="mt-4 bg-black text-white px-4 py-2 rounded hover:bg-gray-800 transition-colors"
            >
              Save Billing Address
            </button>
          </form>
        )}
      </div>
    </div>
  );
}
