import React, { useState, useEffect, useMemo } from "react";
import { useTable, usePagination } from "react-table";
import Modal from "react-modal";
import { getAllOffersByVendorId, counterOffer, acceptOffer, declineOffer } from "../../Hooks/offerHooks";
import { toast } from "react-toastify";

export default function Offers() {
  const [offers, setOffers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [counterAmount, setCounterAmount] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    async function fetchOffers() {
      const response = await getAllOffersByVendorId();

      if (response.success) {
        // Sort offers by latest updatedAt field
        const sortedOffers = response.offers.sort(
          (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
        );
        setOffers(sortedOffers);
      } else {
        setError(response.message);
      }

      setLoading(false);
    }

    fetchOffers();
  }, []);

  const handleAccept = async (offerId) => {
    const response = await acceptOffer(offerId);

    if (response.success) {
      toast.success(response.message);
      setOffers((prevOffers) =>
        prevOffers.filter((offer) => offer._id !== offerId)
      );
    } else {
      toast.error(response.message);
    }
  };

  const handleDecline = async (offerId) => {
    const response = await declineOffer(offerId);

    if (response.success) {
      toast.success(response.message);
      setOffers((prevOffers) =>
        prevOffers.filter((offer) => offer._id !== offerId)
      );
    } else {
      toast.error(response.message);
    }
  };

  const handleCounterOffer = async () => {
    if (!counterAmount || isNaN(counterAmount) || counterAmount <= 0) {
      toast.error("Please enter a valid counter amount");
      return;
    }

    const response = await counterOffer({
      offerId: selectedOffer._id,
      counterAmount: parseFloat(counterAmount),
    });

    if (response.success) {
      toast.success(response.message);
      setOffers((prevOffers) =>
        prevOffers.map((offer) =>
          offer._id === selectedOffer._id ? response.offer : offer
        )
      );
      closeModal();
    } else {
      toast.error(response.message);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Product Name",
        accessor: "productId.productName",
      },
      {
        Header: "Image",
        accessor: "productId.images[0].image",
        Cell: ({ value }) => (
          <img src={value} alt="Product" className="w-12 h-12 rounded-md" />
        ),
      },
      {
        Header: "Customer Name",
        accessor: "customerId.name",
      },
      {
        Header: "Offered Amount",
        accessor: "offer",
        Cell: ({ value }) => `$${value.toFixed(2)}`,
      },
      {
        Header: "Original Price",
        accessor: (row) =>
          row.productId.discountedPrice
            ? row.productId.discountedPrice
            : row.productId.regularPrice,
        Cell: ({ value }) => `$${parseFloat(value).toFixed(2)}`,
      },
      {
        Header: "Action",
        Cell: ({ row }) => (
          <div className="flex gap-2">
            <button
              className="bg-black text-white py-1 px-4 rounded hover:bg-gray-800"
              onClick={() => openModal(row.original)}
            >
              Counter
            </button>
            <button
              className="bg-green-500 text-white py-1 px-4 rounded hover:bg-green-600"
              onClick={() => handleAccept(row.original._id)}
            >
              Accept
            </button>
            <button
              className="bg-red-500 text-white py-1 px-4 rounded hover:bg-red-600"
              onClick={() => handleDecline(row.original._id)}
            >
              Decline
            </button>
          </div>
        ),
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: offers,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    usePagination
  );

  const openModal = (offer) => {
    setSelectedOffer(offer);
    setCounterAmount("");
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedOffer(null);
  };

  if (loading) {
    return <div className="text-center mt-20">Loading offers...</div>;
  }

  if (error || offers.length === 0) {
    return (
      <div className="flex flex-col items-center mt-32">
        <div className="bg-gray-100 rounded-full p-8 shadow-lg">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-20 w-20 text-gray-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 4v16m8-8H4"
            />
          </svg>
        </div>
        <h2 className="text-2xl font-bold text-gray-700 mt-6">No Offers Found</h2>
        <p className="text-gray-500 mt-2 text-center max-w-md">
          We couldn’t find any offers for your products at the moment. Please check
          back later or adjust your product settings to attract more offers.
        </p>
      </div>
    );
  }
  

  return (
    <div className="p-6 bg-white text-black">
      <h1 className="text-2xl font-bold mb-6">Vendor Offers</h1>
      <div className="overflow-x-auto">
        <table
          {...getTableProps()}
          className="w-full table-auto border-collapse border border-gray-300 text-black"
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} className="bg-gray-100">
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    className="border border-gray-300 px-4 py-2 text-left font-semibold"
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} className="hover:bg-gray-50">
                  {row.cells.map((cell) => (
                    <td
                      {...cell.getCellProps()}
                      className="border border-gray-300 px-4 py-2"
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {/* Pagination Controls */}
      <div className="flex justify-between items-center mt-4">
        <button
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
          className={`py-2 px-4 rounded ${
            canPreviousPage ? "bg-black text-white" : "bg-gray-300 text-gray-500"
          }`}
        >
          Previous
        </button>
        <span>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>
        </span>
        <button
          onClick={() => nextPage()}
          disabled={!canNextPage}
          className={`py-2 px-4 rounded ${
            canNextPage ? "bg-black text-white" : "bg-gray-300 text-gray-500"
          }`}
        >
          Next
        </button>
      </div>

      {/* Modal for Counter Offer */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Make Counter Offer"
        className="bg-white p-6 w-full max-w-md mx-auto my-20 rounded-md shadow-lg"
        overlayClassName="fixed z-50 inset-0 bg-black bg-opacity-50 flex justify-center items-center"
      >
        <h2 className="text-2xl font-semibold mb-4">Make Counter Offer</h2>
        {selectedOffer && (
          <div>
            <p className="mb-4">
              Countering offer for{" "}
              <strong>{selectedOffer.productId.productName}</strong> offered by{" "}
              <strong>{selectedOffer.customerId.name}</strong>.
            </p>
            <input
              type="number"
              placeholder="Enter your counter offer amount"
              className="w-full border border-gray-300 rounded p-2 mb-4"
              value={counterAmount}
              onChange={(e) => setCounterAmount(e.target.value)}
            />
            <div className="flex justify-end">
              <button
                onClick={closeModal}
                className="bg-gray-300 text-black py-2 px-4 rounded mr-2"
              >
                Cancel
              </button>
              <button
                onClick={handleCounterOffer}
                className="bg-black text-white py-2 px-4 rounded"
              >
                Submit Offer
              </button>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
}
