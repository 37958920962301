import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { verifyPhoneOtp } from '../../Hooks/UserHooks';

export default function OTP() {
  const [otp, setOtp] = useState(Array(6).fill(''));
  const [isTimerActive, setIsTimerActive] = useState(true); // Timer state
  const [secondsLeft, setSecondsLeft] = useState(60); // Countdown seconds
  const inputRefs = useRef([]);
  const navigate = useNavigate()

  // Start the timer when the component loads
  useEffect(() => {
    if (isTimerActive && secondsLeft > 0) {
      const timer = setInterval(() => {
        setSecondsLeft((prevSeconds) => prevSeconds - 1);
      }, 1000);

      return () => clearInterval(timer); // Clear the timer on unmount
    } else if (secondsLeft === 0) {
      setIsTimerActive(false); // Enable the resend button after timer ends
    }
  }, [isTimerActive, secondsLeft]);

  // Handle input change
  const handleChange = (element, index) => {
    const value = element.value;

    // Only allow numeric input
    if (/^[0-9]$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Move focus to next input
      if (index < 5) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  // Handle backspace key press
  const handleBackspace = (event, index) => {
    if (event.key === 'Backspace') {
      const newOtp = [...otp];
      newOtp[index] = '';
      setOtp(newOtp);

      // Move focus to previous input
      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    }
  };

  // Handle paste event
  const handlePaste = (event) => {
    const pastedData = event.clipboardData.getData('text');
    if (/^\d{6}$/.test(pastedData)) {
      const newOtp = pastedData.split('');
      setOtp(newOtp);

      // Automatically move focus to last box after pasting
      inputRefs.current[5].focus();
    }
  };

  const handleConfirm = async () => {
    const otpValue = otp.join("");
    const reason = localStorage.getItem("OTPreason");
    if (reason !== "number-verify") {
      toast.error("Invalid OTP reason. Please try again.");
      return;
    }

    const response = await verifyPhoneOtp(otpValue);
    if (response.success) {
      toast.success("Phone number verified successfully!");
      localStorage.removeItem("OTPreason");
      navigate(-1); // Navigate back
    } else {
      toast.error(response.message || "Failed to verify OTP");
    }
  };

  // Handle resend OTP
  const handleResend = () => {
    console.log('OTP Resent');
    setIsTimerActive(true);
    setSecondsLeft(60); // Reset the timer to 60 seconds
    // Implement resend OTP logic here
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-md px-8 py-8 bg-white shadow-sm rounded-lg">
        {/* Information Message */}
        <h2 className="text-2xl font-semibold text-center mb-6">Enter OTP</h2>
        <p className="text-center text-gray-600 mb-4">
          We have sent an OTP to your phone number: <strong>+1 ****** 541</strong>
        </p>
        <p className="text-center text-gray-600 mb-6">
          Please enter the 6-digit OTP below.
        </p>

        {/* OTP Input Fields */}
        <div className="flex justify-center space-x-4 mb-6" onPaste={handlePaste}>
          {otp.map((data, index) => (
            <input
              key={index}
              ref={(el) => (inputRefs.current[index] = el)}
              type="text"
              maxLength="1"
              value={otp[index]}
              onChange={(e) => handleChange(e.target, index)}
              onKeyDown={(e) => handleBackspace(e, index)}
              className="w-12 h-12 text-center text-2xl font-semibold border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-black"
            />
          ))}
        </div>

        {/* Confirm OTP Button */}
        <button
          onClick={handleConfirm}
          className="w-full bg-black text-white py-3 rounded-lg mb-6 hover:bg-gray-800 transition"
        >
          Confirm OTP
        </button>

        {/* Resend OTP Button with Timer */}
        <button
          onClick={handleResend}
          disabled={isTimerActive}
          className={`w-full py-3 rounded-lg transition ${
            isTimerActive
              ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
              : 'text-black border border-black hover:bg-gray-200'
          }`}
        >
          {isTimerActive ? `Resend OTP in ${secondsLeft}s` : 'Resend OTP'}
        </button>
      </div>
    </div>
  );
}
