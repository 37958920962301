import React from 'react';
import { FaSearch, FaTruck, FaMoneyBillWave } from 'react-icons/fa';

export default function HowToSellLocally() {
    return (
        <div className="bg-white pt-10">
            {/* Hero Section */}
            <section className="relative">
                <img
                    src="https://res.cloudinary.com/dlhwfesiz/image/upload/v1730206658/AdobeStock_23429754-scaled_h1tr5w.jpg"
                    alt="Wedding Marketplace"
                    className="w-full h-[70vh] sm:h-[400px] lg:h-[500px] object-cover"
                />
                <div className="absolute inset-0 bg-black bg-opacity-50"></div>
                <div className="absolute inset-0 flex flex-col items-center justify-center text-white text-center z-10 px-4">
                    <h1 className="text-3xl sm:text-4xl lg:text-5xl font-bold mb-4">Your Local Wedding Marketplace</h1>
                    <p className="text-lg sm:text-xl">Buy and Sell Close to Home from Other Happy Couples</p>
                    <button className="my-4 bg-white hover:bg-gray-900 text-black hover:text-white py-3 px-6 rounded-md text-sm md:text-lg font-semibold">
                        START SELLING
                    </button>
                </div>
            </section>

           {/* Info Section */}
           <div className="bg-white text-black px-5 sm:px-10 py-16">
                <hr className="mb-4" />
                <div className="relative">
                    <div className="relative z-10">
                        <h1 className="text-center text-4xl font-semibold mb-8">Skip the meet up</h1>
                        <p className="text-center">
                            Whether it’s heavy tables or delicate glassware, The Wedding Marketplace recommends Roadie to safely buy and sell difficult-to-ship products locally.
                        </p>
                        <div className="py-5">
                            <div className="mx-5 grid grid-cols-1 md:grid-cols-3 gap-8 max-w-5xl md:mx-auto">
                                {/* Card 1 - List */}
                                <div className="bg-white text-black rounded-lg p-8 shadow-lg">
                                    <div className="flex justify-center mb-4">
                                        <FaSearch className="text-5xl text-black" />
                                    </div>
                                    <h2 className="text-center text-xl font-semibold mb-4">Contact Free</h2>
                                    <p className="text-center">
                                        Door-to-door delivery, safe and easy. From cupcakes to kittens to couches, you can send pretty much anything that’s legal with the Roadie app.
                                    </p>
                                </div>

                                {/* Card 2 - Ship */}
                                <div className="bg-white text-black rounded-lg p-8 shadow-lg">
                                    <div className="flex justify-center mb-4">
                                        <FaTruck className="text-5xl text-black" />
                                    </div>
                                    <h2 className="text-center text-xl font-semibold mb-4">Vendors Schedule Pickup / Delivery</h2>
                                    <p className="text-center">
                                        Schedule Roadie to handle your local deliveries. Deliveries under 200 miles are usually delivered same-day or next-day.
                                    </p>
                                </div>

                                {/* Card 3 - Get Paid */}
                                <div className="bg-white text-black rounded-lg p-8 shadow-lg">
                                    <div className="flex justify-center mb-4">
                                        <FaMoneyBillWave className="text-5xl text-black" />
                                    </div>
                                    <h2 className="text-center text-xl font-semibold mb-4">Stress Free Payment</h2>
                                    <p className="text-center">
                                        Get paid for your wedding items and don’t worry about driving all over town to deliver them!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Additional Section with Image */}
            <div className="bg-white py-16 px-5 sm:px-10">
                <div className="flex flex-col-reverse md:flex-row items-center gap-8 max-w-7xl mx-auto">
                    {/* Text Section */}
                    <div className="md:w-1/2">
                        <h2 className="text-pink-500 text-3xl md:text-4xl font-semibold mb-4">Search for products near you</h2>
                        <p className="text-lg mb-4">
                            On the shop page, buyers can search for their dream product by location. Why waste time sitting in traffic or running errands all over town when you don’t have to? With Roadie drivers, you can have almost anything delivered right to your doorstep, eliminating the need to pick it up yourself.
                        </p>
                    </div>
                    {/* Image Section */}
                    <div className="md:w-1/2">
                        <img
                            src="https://res.cloudinary.com/dlhwfesiz/image/upload/v1730207070/AdobeStock_545782493-800x533_i0qerv.jpg"
                            alt="Woman searching products"
                            className="w-full h-auto rounded-lg object-cover"
                        />
                    </div>
                </div>
            </div>

            {/* New Section - Purchase from vendors using Roadie */}
            <div className="bg-gray-100 py-16 px-5 sm:px-10">
                <div className="flex flex-col md:flex-row items-center gap-8 max-w-7xl mx-auto">
                    {/* Text Section */}
                    <div className="md:w-1/2">
                        <h2 className="text-gray-900 text-3xl md:text-4xl font-semibold mb-4">Purchase from vendors using Roadie</h2>
                        <p className="text-lg mb-4">
                            If vendors have Roadie accounts, they will be able to ship larger and more complex shipments directly to your doorstep. However, please note that Roadie drivers are not movers, so it’s important to ensure that there’s someone available at the pickup and delivery locations to handle any heavy lifting.
                        </p>
                    </div>
                    {/* Image Section */}
                    <div className="md:w-1/2">
                        <img
                            src="https://res.cloudinary.com/dlhwfesiz/image/upload/v1730207617/AdobeStock_171210719-scaled_wkp9z3.jpg"
                            alt="Roadie delivery"
                            className="w-full h-auto rounded-lg object-cover"
                        />
                    </div>
                </div>
            </div>

            {/* New Section - Vendors schedule their deliveries */}
            <div className="bg-white py-16 px-5 sm:px-10">
                <div className="flex flex-col md:flex-row items-center gap-8 max-w-7xl mx-auto">
                    {/* Text Section */}
                    <div className="md:w-1/2">
                        <h2 className="text-gray-900 text-3xl md:text-4xl font-semibold mb-4">Vendors schedule their deliveries</h2>
                        <p className="text-lg mb-4">
                            Point-to-point delivery means that Roadie connects you with a driver who is already heading in the right direction, similar to how Uber and Lyft work for catching a ride. Deliveries under 200 miles are typically delivered the same day or next day.
                        </p>
                    </div>
                    {/* Image Section */}
                    <div className="md:w-1/2">
                        <img
                            src="https://res.cloudinary.com/dlhwfesiz/image/upload/v1730207779/AdobeStock_438671614-scaled_sczicu.jpg"
                            alt="Delivery vendor"
                            className="w-full h-auto rounded-lg object-cover"
                        />
                    </div>
                </div>
            </div>

            {/* FAQ Section */}
            <div className="bg-white py-16 px-5 sm:px-10">
                <div className="max-w-5xl mx-auto">
                    <div className="mb-8">
                        <h2 className="text-gray-900 text-xl font-semibold">I am a vendor, can I track my shipment?</h2>
                        <p className="text-lg">
                            Yes! Not only does Roadie offer real-time tracking, but you’ll also receive confirmation photos and text updates at every point in the process so you’ll know your shipment went to the right place.
                        </p>
                    </div>

                    <div className="mb-8">
                        <h2 className="text-gray-900 text-xl font-semibold">Can I require a signature at delivery?</h2>
                        <p className="text-lg">Yes.</p>
                    </div>

                    <div className="mb-8">
                        <h2 className="text-gray-900 text-xl font-semibold">How much does Roadie cost?</h2>
                        <p className="text-lg">
                            It’s free to join the Roadie community and get an instant estimate on any item you want to deliver. Deliveries vary by size and distance, but most users find that we’re a faster, cheaper delivery option, whether you’re delivering cupcakes across town or sending a couch 3 states away. Best of all, since Roadie lets your cargo ride commando, you’ll save money on boxes and packing supplies! And you can rest easy with $100 protection FREE on every delivery, with up to $10,000 coverage available for purchase if you need it.
                        </p>
                        <p className="text-lg">
                            Use the price estimator on <a href="https://www.roadie.com" className="text-blue-500 underline">www.roadie.com</a> or download the Roadie mobile app to see what other members are sending!
                        </p>
                        <p className="text-lg">
                            For any additional questions, please go to <a href="https://www.roadie.com" className="text-blue-500 underline">www.roadie.com</a>.
                        </p>
                    </div>

                </div>
            </div>
            {/* Search Section */}
            <div className="bg-gray-100 py-16">
                <div className="max-w-5xl mx-auto text-center">
                    <hr className="border-gray-400 mb-8" />
                    <h2 className="text-pink-500 text-3xl md:text-4xl font-semibold mb-8">Search Your Local Wedding Marketplace</h2>
                    <div className="relative max-w-xl mx-auto">
                        <FaSearch className="absolute left-3 top-3 text-gray-500" />
                        <input
                            type="text"
                            placeholder="Search..."
                            className="w-full py-3 pl-10 pr-4 text-lg border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-pink-500"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
