// ProductManagement.js
import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getUserProducts, deleteProduct } from "../../Hooks/productHooks";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa"; // Import icons


// ------------------------
// Reusable Confirmation Modal Component
// ------------------------

// For accessibility, set the app root element (adjust the selector if needed)
Modal.setAppElement("#root");

export function ConfirmModal({
  isOpen,
  onRequestClose,
  title,
  message,
  onConfirm,
  confirmText,
  cancelText,
}) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={title}
      className="bg-white p-6 rounded-lg max-w-md mx-auto mt-40 outline-none"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
    >
      <h2 className="text-xl font-semibold mb-4">{title}</h2>
      <p className="mb-6">{message}</p>
      <div className="flex justify-end space-x-4">
        <button
          onClick={onRequestClose}
          className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400"
        >
          {cancelText || "Cancel"}
        </button>
        <button
          onClick={onConfirm}
          className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
        >
          {confirmText || "Delete"}
        </button>
      </div>
    </Modal>
  );
}

// ------------------------
// ProductCard Component
// ------------------------

function ProductCard({ product, onUpdate, onDelete }) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const openDeleteModal = () => {
    setIsDeleteModalOpen(true);
    setIsDropdownOpen(false);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const handleDelete = async () => {
    const result = await deleteProduct(product._id);
    if (result.success) {
      toast.success(result.message);
      onDelete(product._id);
    } else {
      toast.error(result.message);
    }
    closeDeleteModal();
  };

  return (
    <div
      className="p-4 border w-[300px] border-gray-300 rounded-lg shadow-sm bg-white flex flex-col justify-between"
      style={{ minHeight: "250px" }}
    >
      <div>
        <div className="flex justify-between items-start">
          {product.firstImage ? (
            <img
              src={product.firstImage}
              alt={product.productName}
              className="w-16 h-16 rounded"
            />
          ) : (
            <div className="w-16 h-16 bg-gray-200 rounded flex items-center justify-center">
              No Image
            </div>
          )}
          <div className="relative">
            <button onClick={toggleDropdown} className="text-gray-500">
              ...
            </button>
            {isDropdownOpen && (
              <div className="absolute right-0 mt-2 w-40 bg-white border border-gray-300 rounded shadow-lg z-10">
                <button
                  onClick={openDeleteModal}
                  className="w-full text-left p-2 hover:bg-gray-100"
                >
                  Delete Product
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="mt-2">
          <h2 className="text-lg font-semibold">{product.productName}</h2>
          <p className="text-gray-500">{product.category}</p>
          <p className="text-lg font-bold">${product.regularPrice}</p>
        </div>
      </div>
      <div className="mt-4">
        <div
          className={`flex justify-between items-center text-sm ${
            product.stockQuantity === null ? "invisible" : ""
          }`}
        >
          <p>Stock Quantity</p>
          <p>{product.stockQuantity}</p>
        </div>
      </div>
      <button
        onClick={() => onUpdate(product._id)}
        className="mt-4 w-full p-2 bg-black text-white rounded-md"
      >
        Update
      </button>

      {/* Reusable Delete Confirmation Modal */}
      <ConfirmModal
        isOpen={isDeleteModalOpen}
        onRequestClose={closeDeleteModal}
        title="Confirm Delete"
        message="Are you sure you want to delete this product?"
        onConfirm={handleDelete}
        confirmText="Delete"
        cancelText="Cancel"
      />
    </div>
  );
}

// ------------------------
// ProductManagement Component
// ------------------------

function ProductManagement() {
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const productsPerPage = 10;

  // Fetch user-specific products
  useEffect(() => {
    const fetchUserProducts = async () => {
      try {
        const storedUser = localStorage.getItem("twmUser");
        if (!storedUser) {
          toast.error("User not found in localStorage.");
          return;
        }
        const user = JSON.parse(storedUser);
        const userId = user._id;

        const response = await getUserProducts(userId);
        if (response.success) {
          setProducts(response.products);
        } else {
          toast.error(response.message);
        }
      } catch (error) {
        console.error("Error fetching products:", error);
        toast.error("An error occurred while fetching products.");
      }
    };

    fetchUserProducts();
  }, []);

  // Calculate pagination
  const totalPages = Math.ceil(products.length / productsPerPage);
  const currentProducts = products.slice(
    (currentPage - 1) * productsPerPage,
    currentPage * productsPerPage
  );

  const handleUpdate = (productId) => {
    navigate(`/admin/update-product/${productId}`);
  };

  const handleDelete = (deletedProductId) => {
    setProducts((prevProducts) =>
      prevProducts.filter((product) => product._id !== deletedProductId)
    );
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePrevious = () => {
    if (currentPage > 1) setCurrentPage((prev) => prev - 1);
  };
  
  const handleNext = () => {
    if (currentPage < totalPages) setCurrentPage((prev) => prev + 1);
  };

  const generatePageNumbers = () => {
    const visiblePages = 10;
    if (totalPages <= visiblePages) {
      return Array.from({ length: totalPages }, (_, i) => i + 1);
    } else {
      if (currentPage <= 6) {
        return [1, 2, 3, 4, 5, "...", totalPages - 2, totalPages - 1, totalPages];
      } else if (currentPage >= totalPages - 5) {
        return [1, 2, "...", totalPages - 5, totalPages - 4, totalPages - 3, totalPages - 2, totalPages - 1, totalPages];
      } else {
        return [1, "...", currentPage - 1, currentPage, currentPage + 1, "...", totalPages - 2, totalPages - 1, totalPages];
      }
    }
  };
  

  return (
    <div className="p-6">
      {/* Products Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {currentProducts.map((product) => (
          <ProductCard
            key={product._id}
            product={product}
            onUpdate={handleUpdate}
            onDelete={handleDelete}
          />
        ))}
      </div>
  
      {/* Pagination */}
      {totalPages > 1 && (
        <div className="mt-8 flex items-center justify-center space-x-2">
          {/* Previous Button */}
          <button
            onClick={handlePrevious}
            disabled={currentPage === 1}
            className={`px-3 py-2 rounded-md border flex items-center gap-1 transition ${
              currentPage === 1 ? "bg-gray-300 text-gray-500 cursor-not-allowed" : "bg-white hover:bg-gray-200"
            }`}
          >
            <FaChevronLeft className="text-sm" /> Prev
          </button>
  
          {/* Page Numbers */}
          {generatePageNumbers().map((page, index) =>
            page === "..." ? (
              <span key={index} className="px-3 py-2 text-gray-500">...</span>
            ) : (
              <button
                key={index}
                onClick={() => handlePageChange(page)}
                className={`px-3 py-2 rounded-md border transition ${
                  currentPage === page
                    ? "bg-black text-white font-semibold"
                    : "bg-white hover:bg-gray-200"
                }`}
              >
                {page}
              </button>
            )
          )}
  
          {/* Next Button */}
          <button
            onClick={handleNext}
            disabled={currentPage === totalPages}
            className={`px-3 py-2 rounded-md border flex items-center gap-1 transition ${
              currentPage === totalPages ? "bg-gray-300 text-gray-500 cursor-not-allowed" : "bg-white hover:bg-gray-200"
            }`}
          >
            Next <FaChevronRight className="text-sm" />
          </button>
        </div>
      )}
    </div>
  );
}

export default ProductManagement;
