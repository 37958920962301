import React from 'react';

export default function SalesTaxBuyer() {
  return (
    <div className="min-h-screen p-4">
      <div className="max-w-4xl mx-auto bg-white shadow-lg rounded-lg p-6">
        <h1 className="text-3xl font-bold mb-6 text-gray-800">Sales Tax for Buyers</h1>

        <p className="mb-4 text-lg text-gray-700">
          Most US states have implemented marketplace facilitator tax laws that impact online marketplaces like The Wedding Marketplace. These laws transfer tax collection responsibilities from third-party sellers to the marketplace facilitating the transactions. Consequently, The Wedding Marketplace is responsible for calculating, collecting, reporting, and remitting applicable sales tax on behalf of our third-party sellers using our registered sales tax identification numbers.
        </p>

        <h2 className="text-2xl font-semibold mt-6 mb-4 text-gray-800">Additional Information for Buyers</h2>

        <p className="mb-4 text-lg text-gray-700">
          Items sold on The Wedding Marketplace platform and shipped to buyers in certain marketplace states may be subject to sales tax.
        </p>
        <p className="mb-4 text-lg text-gray-700">
          Whether tax will be applied to your order and at what rate depends on the type of item purchased and the delivery address.
        </p>
        <p className="mb-4 text-lg text-gray-700">
          The tax rate on your order will be the total of the state and any relevant county, city, or other local taxes based on the delivery address. Please note that even if you live in a state without sales tax, you may still see tax on your order if it is shipped to a state that imposes sales tax.
        </p>
        <p className="mb-4 text-lg text-gray-700">
          Shipping charges may or may not be taxable separately, depending on the delivery location. The overall tax amount on your order will encompass tax on the item and shipping, if applicable.
        </p>
        <p className="mb-4 text-lg text-gray-700">
          The Wedding Marketplace does accept resale certificates or other sales tax exemptions for purchases made on the platform. Buyers who claim an exemption from sales tax must provide the necessary tax exemption documents to The Wedding Marketplace for evaluation. Once validated, your account will be updated to reflect the exemption.
        </p>
        <p className="mb-4 text-lg text-gray-700">
          Buyer coupons issued by The Wedding Marketplace that lower the sales price for the buyer but not the amount received by the seller are included in the sales tax base when applicable. The full sale price paid by the seller may be subject to tax.
        </p>
      </div>
    </div>
  );
}
