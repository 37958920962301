import React, { useEffect, useRef, useState } from 'react';
import stylishpic1 from '../../assets/stylishpic1.jpeg';
import miss1 from '../../assets/miss1.jpeg';
import ins1 from '../../assets/ins1.jpeg';
import ins2 from '../../assets/ins2.jpeg';
import ins3 from '../../assets/ins3.jpeg';
import ins4 from '../../assets/ins4.jpeg';

export default function Quotes() {
  const zainRef = useRef(null);
  const [backgroundImage, setBackgroundImage] = useState(miss1); // Initialize with miss1

  useEffect(() => {
    const target = zainRef.current;

    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    };

    const observer = new IntersectionObserver(function (entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setBackgroundImage(miss1); // Use the local image
        } else {
          setBackgroundImage(stylishpic1); // Fallback to miss1
        }
      });
    }, options);

    if (target) {
      observer.observe(target);
    }

    return () => {
      if (target) {
        observer.unobserve(target);
      }
    };
  }, []);

  return (
    <div>
      {/* Adding styles directly within a style tag */}
      <style>{`
         body {
          margin: 0;
          padding: 0;
          transition: background-image 0.5s ease-in-out;
        }
        .fixed-background {
          position: fixed;
          top: 0;
          left: 0;
          width: 100vw;
          height: 100vh;
          z-index: -1;
          background-size: cover;
          background-position: center;
        }
        .content {
          background-color: transparent;
        }
        .section {
          color: #fff;
        }
        .text1 {
          margin: 20px;
        }
        .second {
          background-color: white;
          color: black;
        }
      `}</style>
      
      {/* Dynamically change the background image */}
      <div className="fixed-background" style={{ backgroundImage: `url(${backgroundImage})` }}></div>

      <div className="content">
        {/* Scrollable content sections */}
        <div className="section flex justify-center h-[70vh] items-center" id="section1">
          <h1 className="text1 text-[40px] sm:text-[56px] font-bold text-center font-Brittany px-4">
            “A successful marriage requires falling in love many times, always with the same person.”
          </h1>
        </div>

        {/* Section 2 with Title and Grid */}
        <div className="section second py-0 bg-white" id="section2">
          <hr />
          <h1 className="text1 text-center text-4xl font-[400] my-5">Get Inspired</h1>

          {/* Image Grid */}
          <div className=" bg-white grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 pb-12 gap-6 px-5">
            <div className="h-64">
              <img
                src={ins1}
                alt="Wedding 1"
                className="w-full h-full object-cover"
              />
            </div>
            <div className="h-64">
              <img
                src={ins2}
                alt="Wedding 2"
                className="w-full h-full object-cover"
              />
            </div>
            <div className="h-64">
              <img
                src={ins3}
                alt="Wedding 3"
                className="w-full h-full object-cover"
              />
            </div>
            <div className="h-64">
              <img
                src={ins4}
                alt="Wedding 4"
                className="w-full h-full object-cover"
              />
            </div>
          </div>
          <hr />
        </div>

        {/* Section 3 (Zain's Section) - Form with dark background */}
        <div className="mt-0 section flex justify-center items-center h-[70vh]" id="zain" ref={zainRef}>
          <div className="bg-[#333333] text-white px-6 py-10 rounded-lg shadow-lg w-full max-w-lg mx-4">
            <h1 className="text1 text-3xl font-semibold text-center mb-4">Don’t miss out on new items!</h1>
            <p className="text1 text-center mb-6">Sign up today to be notified of new items!</p>
            <form className="flex flex-col sm:flex-row gap-4">
              <input
                type="email"
                placeholder="email address"
                className="px-4 py-2 rounded-lg text-gray-800 w-full sm:w-[400px] focus:outline-none"
              />
              <button
                type="submit"
                className="bg-white text-gray-800 px-4 py-2 rounded-lg font-semibold w-full sm:w-[25%]"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
