import React, { useState, useEffect } from 'react';
import Tabs from './OrdersManagement/Tabs';
import Offers from './OrdersManagement/Offers';
import OrderList from './OrdersManagement/OrderList';
import { getAllOffersByCustomerId } from '../../Hooks/offerHooks';
import { getOrdersByCustomer } from '../../Hooks/ordersHook';

export default function OrderManagement({activeTabParam}) {
  const [activeTab, setActiveTab] = useState(activeTabParam? activeTabParam: "previousOrders");
  const [orders, setOrders] = useState([]); // State to store orders
  const [filter, setFilter] = useState('yourOffers'); // Filter state for offers
  const [offers, setOffers] = useState([]); // State to store offers
  const [loading, setLoading] = useState(false); // State to manage loading
  const [error, setError] = useState(null); // State to manage errors

  // Fetch offers when "offers" tab is active
  useEffect(() => {
    const fetchOffers = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await getAllOffersByCustomerId();
        if (response.success) {
          const transformedOffers = response.offers.map((offer) => ({
            _id: offer._id,
            item: offer.productId?.productName || "Unknown Product",
            offerType: !offer.counterOffer ? "Your Offer" : "Vendor Offer",
            img: offer.productId?.images[0]?.image || "https://via.placeholder.com/100",
            offerPrice: offer.counterOffer || offer.offer,
            originalPrice: offer.productId.discountedPrice || offer.productId?.regularPrice,
            productId: offer.productId._id,
          }));
          setOffers(transformedOffers);
        } else {
          setError(response.message);
        }
      } catch (err) {
        setError("Failed to fetch offers. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    if (activeTab === 'offers') {
      fetchOffers();
    }
  }, [activeTab]);

  // Fetch orders when the component mounts
  useEffect(() => {
    const fetchOrders = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await getOrdersByCustomer();
        if (response.success) {
          const transformedOrders = response.orders.map((order) => {
            const productCount = order.items.length;
            return {
              _id: order._id,
              item:
                productCount > 1
                  ? `${order.items[0]?.productName || "Unknown Product"} +${productCount - 1}`
                  : order.items[0]?.productName || "Unknown Product",
              img: order.items[0]?.firstImage || "https://via.placeholder.com/100",
              price: order.finalAmount || 0,
              itemsCount: productCount || 0,
              paymentType: order.paymentMethod || "N/A",
              status: order.orderStatus || "processing",
            };
          });
          setOrders(transformedOrders);
        } else {
          setError(response.message);
        }
      } catch (err) {
        setError("Failed to fetch orders. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  // Get color based on status
  const getStatusColor = (status) => {
    switch (status) {
      case 'processing':
        return 'text-blue-600';
      case 'shipped':
        return 'text-yellow-600';
      case 'delivered':
        return 'text-green-600';
      case 'cancelled':
        return 'text-red-600';
      default:
        return 'text-gray-600';
    }
  };

  return (
    <div className="max-w-4xl my-4 mx-auto p-4 sm:p-6 md:p-8 bg-white shadow-lg rounded-lg">
      <h3 className="text-xl sm:text-2xl font-bold mb-6">Order Management</h3>

      {/* Tabs Navigation */}
      <Tabs activeTab={activeTab} setActiveTab={setActiveTab} />

      {/* Conditional rendering of tab content */}
      {loading && <p>Loading...</p>}


      {activeTab === 'previousOrders' && (
        <OrderList orders={orders} getStatusColor={getStatusColor} title="Your Orders" />
      )}

      {activeTab === 'offers' && (
        <Offers offers={offers} filter={filter} setFilter={setFilter} />
      )}
    </div>
  );
}
