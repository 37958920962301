import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { searchPaginatedProducts } from "../Hooks/productHooks";
import ProductCard from "./products/ProductCard";

export default function Search() {
  const [searchParams, setSearchParams] = useSearchParams();
  const query = searchParams.get("query");
  const pageParam = searchParams.get("page")
    ? parseInt(searchParams.get("page"), 10)
    : 1;
  const limitParam = searchParams.get("limit")
    ? parseInt(searchParams.get("limit"), 10)
    : 12;

  // Filters state
  const [sortOption, setSortOption] = useState("Date");
  const [productCount, setProductCount] = useState(limitParam);

  const [products, setProducts] = useState([]);
  const [pagination, setPagination] = useState({
    currentPage: pageParam,
    totalPages: 0,
    totalProducts: 0,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Update URL search parameters when filters change
  useEffect(() => {
    setSearchParams({
      ...Object.fromEntries(searchParams),
      page: pagination.currentPage,
      limit: productCount,
      sort: sortOption,
    });
  }, [sortOption, productCount, pagination.currentPage]);

  // Fetch products when query, page, productCount, or sortOption changes
  useEffect(() => {
    if (query && query.trim() !== "") {
      setLoading(true);
      searchPaginatedProducts(query, pageParam, productCount, sortOption)
        .then((result) => {
          if (result.success) {
            setProducts(result.products);
            setPagination({
              currentPage: result.currentPage,
              totalPages: result.totalPages,
              totalProducts: result.totalProducts,
            });
          } else {
            setError(result.message);
          }
        })
        .catch(() => {
          setError("An error occurred while fetching products.");
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setError("Search query is required.");
    }
  }, [query, pageParam, productCount, sortOption]);

  // Update URL query parameters for pagination
  const handlePageChange = (newPage) => {
    setSearchParams({
      ...Object.fromEntries(searchParams),
      page: newPage,
      limit: productCount,
      sort: sortOption,
    });
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Search Results</h1>


      {/* Filters */}
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center space-x-4">
          {/* Sort By Option */}
          <div className="flex items-center">
            <span className="text-gray-700 mr-2">Sort by</span>
            <select
              value={sortOption}
              onChange={(e) => setSortOption(e.target.value)}
              className="border rounded p-2 text-gray-700"
            >
              <option value="Date">Date</option>
              <option value="Price">Price</option>
              <option value="Rating">Rating</option>
            </select>
          </div>
          {/* Number of Products to Show */}
          <div className="flex items-center">
            <span className="text-gray-700 mr-2">Show</span>
            <select
              value={productCount}
              onChange={(e) => setProductCount(Number(e.target.value))}
              className="border rounded p-2 text-gray-700"
            >
              <option value={12}>12 Products</option>
              <option value={24}>24 Products</option>
              <option value={36}>36 Products</option>
            </select>
          </div>
        </div>
      </div>

      {loading && <p>Loading...</p>}
      {error && <p className="text-red-500">{error}</p>}
      {!loading && !error && (
        <>
          <div className="grid gap-x-3 sm:gap-x-6 gap-y-10 grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            {products.map((product) => (
              <ProductCard key={product._id} product={product} />
            ))}
          </div>
          {products.length === 0 && <p>No products found.</p>}
          <div className="flex justify-between items-center mt-4">
            <button
              onClick={() => handlePageChange(pagination.currentPage - 1)}
              disabled={pagination.currentPage <= 1}
              className="px-4 py-2 bg-gray-200 rounded disabled:opacity-50"
            >
              Previous
            </button>
            <span>
              Page {pagination.currentPage} of {pagination.totalPages}
            </span>
            <button
              onClick={() => handlePageChange(pagination.currentPage + 1)}
              disabled={pagination.currentPage >= pagination.totalPages}
              className="px-4 py-2 bg-gray-200 rounded disabled:opacity-50"
            >
              Next
            </button>
          </div>
        </>
      )}
    </div>
  );
}
