import React from 'react';

const BestSellers = ({ data }) => {
  return (
    <div className="bg-white shadow-md rounded-lg p-4">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-gray-700 text-lg font-semibold">Best Sellers</h2>
        <div className="text-gray-400">⋮</div>
      </div>
      <div>
        {data.map((item, index) => (
          <div key={index} className="flex items-center mb-4">
            {/* Display the first image */}
            <img
              src={item.productDetails.images[0].image}
              alt={item.productDetails.productName}
              className="w-10 h-10 rounded-lg mr-4 object-cover"
            />
            <div>
              {/* Display product name */}
              <h3 className="text-gray-800 font-semibold text-sm">
                {item.productDetails.productName}
              </h3>
              {/* Display discounted price */}
              <p className="text-gray-500 text-sm">
                ${item.productDetails.discountedPrice}
              </p>
              {/* Display total sales */}
              <p className="text-gray-500 text-sm">
                {item.totalSales} sales
              </p>
            </div>
          </div>
        ))}
      </div>
      {/* <button className="w-full py-2 mt-4 bg-black text-white rounded-lg text-sm">
        REPORT
      </button> */}
    </div>
  );
};

export default BestSellers;
