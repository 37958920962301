import React, { useState, useEffect, useRef } from "react";
import { FaImage, FaChevronDown, FaTimes, FaSpinner } from "react-icons/fa";
import { usePopper } from "react-popper";
import { useParams, useNavigate } from "react-router-dom";
import {
  addProduct,
  getVendorProduct,
  updateProduct,
  deleteProduct,
} from "../../Hooks/productHooks";
import { toast } from "react-toastify";
import MediaUploadModal from "../../components/Modals/MediaUploadModal";
import { ConfirmModal } from "./ProductManagement";
import { getAllCategories } from "../../Hooks/categoryHooks";
import { getAllTags } from "../../Hooks/tagsHooks";
import Modal from "react-modal";

Modal.setAppElement("#root");

const colors = [
  "Red",
  "Pink",
  "Orange",
  "Yellow",
  "Green",
  "Blue",
  "Purple",
  "Gold",
  "Silver",
  "Black",
  "Gray",
  "White",
  "Cream",
  "Brown",
  "Tan",
];

const styles = [
  "Classic",
  "Rustic",
  "Romantic",
  "Modern",
  "Bohemian",
  "Eclectic",
  "Glamorous",
];

const NewProduct = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    productName: "",
    description: "",
    category: "",
    subcategory: "",
    brandName: "",
    stockQuantity: "",
    regularPrice: "",
    salePrice: "",
    discountedPrice: "",
    selectedTags: [],
    images: [],
    video: "",
    quantityType: "single",
    quantity: 1,
    manageStock: false,
    earnings: "",
    selectedColors: [],
    selectedStyles: [],
    minimumOrderQuantity: 1, // new field
    privateData: {
      sku: "",
      costPrice: "",
      otherInfo: "",
    },
    productType: "New",
  });

  // Fetch categories and tags
  useEffect(() => {
    const fetchCategoriesAndTags = async () => {
      try {
        const categoryResponse = await getAllCategories();
        const tagResponse = await getAllTags();

        if (categoryResponse.success) {
          const transformedCategories = categoryResponse.categories.map(
            (category) => ({
              category: category.name,
              subcategories: category.subcategories.map(
                (subcategory) => subcategory.name
              ),
            })
          );
          setCategories(transformedCategories);
        } else {
          toast.error(
            categoryResponse.message || "Failed to fetch categories."
          );
        }

        if (tagResponse.success) {
          const transformedTags = tagResponse.tags.map((tag) => tag.name);
          setTags(transformedTags);
        } else {
          toast.error(tagResponse.message || "Failed to fetch tags.");
        }
      } catch (error) {
        console.error("Error fetching categories and tags:", error);
        toast.error(
          "An error occurred while fetching categories or tags."
        );
      }
    };

    fetchCategoriesAndTags();
  }, []);

  // If we are editing, fetch existing product data
  useEffect(() => {
    const fetchProductData = async () => {
      if (id) {
        try {
          const response = await getVendorProduct(id);
          if (response.success) {
            const product = response.product;
            setFormData({
              productName: product.productName || "",
              description: product.description || "",
              category: product.category || "",
              subcategory: product.subcategory || "",
              brandName: product.brandName || "",
              stockQuantity: product.stockQuantity || "",
              regularPrice: product.regularPrice || "",
              salePrice: product.salePrice || "",
              discountedPrice: product.discountedPrice || "",
              selectedTags: product.tags || [],
              images: product.images || [],
              video: product.video || "",
              quantityType: product.quantityType || "single",
              quantity: product.quantity,
              manageStock: product.manageStock || false,
              earnings: product.earnings || "",
              selectedColors: product.selectedColors || [],
              selectedStyles: product.selectedStyles || [],
              minimumOrderQuantity:
                product.minimumOrderQuantity !== undefined
                  ? product.minimumOrderQuantity
                  : 1,
              privateData: {
                sku: product.privateData?.sku || "",
                costPrice: product.privateData?.costPrice || "",
                otherInfo: product.privateData?.otherInfo || "",
              },
              productType: product.productType || "New",
            });
          } else {
            toast.error(
              response.message || "Failed to fetch product details."
            );
          }
        } catch (error) {
          console.error("Error fetching product:", error);
          toast.error(
            "An error occurred while fetching product details."
          );
        }
      }
    };

    fetchProductData();
  }, [id]);

  // State for modals
  const [isMediaModalOpen, setIsMediaModalOpen] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  // Dropdown toggles
  const [showCategoryDropdown, setShowCategoryDropdown] = useState(false);
  const [showSubcategoryDropdown, setShowSubcategoryDropdown] = useState(false);
  const [showColorDropdown, setShowColorDropdown] = useState(false);
  const [showStyleDropdown, setShowStyleDropdown] = useState(false);
  const [showTagDropdown, setShowTagDropdown] = useState(false);

  // Refs for dropdowns
  const categoryButtonRef = useRef(null);
  const categoryDropdownRef = useRef(null);
  const subcategoryButtonRef = useRef(null);
  const subcategoryDropdownRef = useRef(null);
  const colorButtonRef = useRef(null);
  const colorDropdownRef = useRef(null);
  const styleButtonRef = useRef(null);
  const styleDropdownRef = useRef(null);
  const tagButtonRef = useRef(null);
  const tagDropdownRef = useRef(null);

  // Popper references
  const [categoryReferenceElement, setCategoryReferenceElement] = useState(null);
  const [categoryPopperElement, setCategoryPopperElement] = useState(null);
  const [subcategoryReferenceElement, setSubcategoryReferenceElement] =
    useState(null);
  const [subcategoryPopperElement, setSubcategoryPopperElement] = useState(null);
  const [colorReferenceElement, setColorReferenceElement] = useState(null);
  const [colorPopperElement, setColorPopperElement] = useState(null);
  const [styleReferenceElement, setStyleReferenceElement] = useState(null);
  const [stylePopperElement, setStylePopperElement] = useState(null);
  const [tagReferenceElement, setTagReferenceElement] = useState(null);
  const [tagPopperElement, setTagPopperElement] = useState(null);

  // Popper setups
  const { styles: categoryStyles, attributes: categoryAttributes } = usePopper(
    categoryReferenceElement,
    categoryPopperElement,
    {
      placement: "bottom-start",
    }
  );

  const { styles: subcategoryStyles, attributes: subcategoryAttributes } =
    usePopper(subcategoryReferenceElement, subcategoryPopperElement, {
      placement: "bottom-start",
    });

  const { styles: colorStyles, attributes: colorAttributes } = usePopper(
    colorReferenceElement,
    colorPopperElement,
    {
      placement: "bottom-start",
    }
  );

  const {
    styles: stylePopperStyles,
    attributes: stylePopperAttributes,
  } = usePopper(styleReferenceElement, stylePopperElement, {
    placement: "bottom-start",
  });

  const { styles: tagStyles, attributes: tagAttributes } = usePopper(
    tagReferenceElement,
    tagPopperElement,
    {
      placement: "bottom-start",
    }
  );

  // Tag filter text
  const [tagFilterText, setTagFilterText] = useState("");

  // Category selection
  const handleSelectCategory = (category) => {
    setFormData((prev) => ({
      ...prev,
      category: category.category,
      subcategory: "",
    }));
    setShowCategoryDropdown(false);
  };

  // Subcategory selection
  const handleSelectSubcategory = (subcategory) => {
    setFormData((prev) => ({
      ...prev,
      subcategory,
    }));
    setShowSubcategoryDropdown(false);
  };

  // Color selection
  const handleSelectColor = (color) => {
    setFormData((prev) => {
      const selectedColors = prev.selectedColors.includes(color)
        ? prev.selectedColors.filter((c) => c !== color)
        : prev.selectedColors.length < 2
        ? [...prev.selectedColors, color]
        : prev.selectedColors;
      return { ...prev, selectedColors };
    });
  };

  // Style selection
  const handleSelectStyle = (style) => {
    setFormData((prev) => {
      const selectedStyles = prev.selectedStyles.includes(style)
        ? prev.selectedStyles.filter((s) => s !== style)
        : [...prev.selectedStyles, style];
      return { ...prev, selectedStyles };
    });
  };
  const handleRemoveStyle = (style) => {
    setFormData((prev) => ({
      ...prev,
      selectedStyles: prev.selectedStyles.filter((s) => s !== style),
    }));
  };

  // Tag selection
  const handleSelectTag = (tag) => {
    setFormData((prev) => {
      const selectedTags = prev.selectedTags.includes(tag)
        ? prev.selectedTags.filter((t) => t !== tag)
        : [...prev.selectedTags, tag];
      return { ...prev, selectedTags };
    });
  };
  const handleRemoveTag = (tag) => {
    setFormData((prev) => ({
      ...prev,
      selectedTags: prev.selectedTags.filter((t) => t !== tag),
    }));
  };

  // Add new tag (if not existing)
  const handleAddNewTag = () => {
    const newTag = tagFilterText.trim();
    if (
      newTag !== "" &&
      !tags.some((tag) => tag.toLowerCase() === newTag.toLowerCase())
    ) {
      // 1) add to the global tags list
      setTags((prev) => [...prev, newTag]);
      // 2) select it for this product
      setFormData((prev) => ({
        ...prev,
        selectedTags: [...prev.selectedTags, newTag],
      }));
      // 3) clear the filter text
      setTagFilterText("");
    }
  };

  // Pressing Enter in the tag input
  const handleTagInputKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // stop form submission on Enter
      const enteredTag = tagFilterText.trim();
      if (!enteredTag) return;
      // Check if the user typed an existing tag
      const existingTag = tags.find(
        (t) => t.toLowerCase() === enteredTag.toLowerCase()
      );
      if (existingTag) {
        // Just select the existing tag
        handleSelectTag(existingTag);
        setTagFilterText("");
      } else {
        // Add new tag
        handleAddNewTag();
      }
    }
  };

  // Handle changes
  const handleQuantityTypeChange = (type) => {
    setFormData((prev) => ({
      ...prev,
      quantityType: type,
      quantity: type === "single" ? 1 : prev.quantity,
    }));
  };
  const handleManageStockChange = (manageStock) => {
    setFormData((prev) => ({
      ...prev,
      manageStock,
      quantity: manageStock ? prev.quantity : 1,
    }));
  };
  const handleQuantityChange = (e) => {
    const val = parseInt(e.target.value, 10);
    if (!isNaN(val) && val >= 0) {
      setFormData((prev) => ({
        ...prev,
        quantity: val,
      }));
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name.startsWith("privateData.")) {
      const key = name.split(".")[1];
      setFormData((prev) => ({
        ...prev,
        privateData: {
          ...prev.privateData,
          [key]: value,
        },
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };
  const handleDescriptionChange = (content) => {
    setFormData((prev) => ({
      ...prev,
      description: content,
    }));
  };

  // Media
  const handleSaveMedia = (images, video) => {
    setFormData((prev) => ({
      ...prev,
      images,
      video,
    }));
  };
  const handleRemoveImage = (index) => {
    setFormData((prev) => ({
      ...prev,
      images: prev.images.filter((_, i) => i !== index),
    }));
  };
  const handleRemoveVideo = () => {
    setFormData((prev) => ({
      ...prev,
      video: "",
    }));
  };

  // Price & Earnings
  const handlePriceChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  useEffect(() => {
    const calculateEarnings = () => {
      const price = formData.discountedPrice
        ? formData.discountedPrice
        : formData.regularPrice;
      if (price) {
        const earnings = price - price * 0.1; // 10% commission
        setFormData((prev) => ({
          ...prev,
          earnings: earnings.toFixed(2),
        }));
      }
    };
    calculateEarnings();
  }, [formData.discountedPrice, formData.regularPrice]);

  // Convert images to base64
  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  // Submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Validate required fields
    if (!formData.productName || !formData.category || !formData.discountedPrice) {
      toast.error(
        "Please fill in the required fields: Title, Category, and Listing Price."
      );
      return;
    }

    setLoading(true);
    try {
      const base64Images = await Promise.all(
        formData.images.map(async (img) => {
          const base64Image = await toBase64(
            await fetch(img.image).then((res) => res.blob())
          );
          return { image: base64Image, filter: img.filter };
        })
      );

      const base64Video =
        formData.video && formData.video.file
          ? await toBase64(formData.video.file)
          : formData.video;

      const updatedFormData = {
        ...formData,
        images: base64Images,
        video: base64Video,
      };

      let response;
      if (id) {
        // Update product
        response = await updateProduct(id, updatedFormData);
      } else {
        // Add new product
        response = await addProduct(updatedFormData);
      }

      if (response.success) {
        toast.success(response.message);
        // Reset form
        setFormData({
          productName: "",
          description: "",
          category: "",
          subcategory: "",
          brandName: "",
          stockQuantity: "",
          regularPrice: "",
          salePrice: "",
          discountedPrice: "",
          selectedTags: [],
          images: [],
          video: "",
          quantityType: "single",
          quantity: 1,
          manageStock: false,
          earnings: "",
          selectedColors: [],
          selectedStyles: [],
          minimumOrderQuantity: 1,
          privateData: {
            sku: "",
            costPrice: "",
            otherInfo: "",
          },
          productType: "New",
        });
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.error("Error submitting product:", error);
      toast.error("An error occurred while submitting the product.");
    } finally {
      setLoading(false);
    }
  };

  // Close dropdowns when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        categoryButtonRef.current &&
        !categoryButtonRef.current.contains(event.target) &&
        categoryDropdownRef.current &&
        !categoryDropdownRef.current.contains(event.target)
      ) {
        setShowCategoryDropdown(false);
      }
      if (
        subcategoryButtonRef.current &&
        !subcategoryButtonRef.current.contains(event.target) &&
        subcategoryDropdownRef.current &&
        !subcategoryDropdownRef.current.contains(event.target)
      ) {
        setShowSubcategoryDropdown(false);
      }
      if (
        colorButtonRef.current &&
        !colorButtonRef.current.contains(event.target) &&
        colorDropdownRef.current &&
        !colorDropdownRef.current.contains(event.target)
      ) {
        setShowColorDropdown(false);
      }
      if (
        styleButtonRef.current &&
        !styleButtonRef.current.contains(event.target) &&
        styleDropdownRef.current &&
        !styleDropdownRef.current.contains(event.target)
      ) {
        setShowStyleDropdown(false);
      }
      if (
        tagButtonRef.current &&
        !tagButtonRef.current.contains(event.target) &&
        tagDropdownRef.current &&
        !tagDropdownRef.current.contains(event.target)
      ) {
        setShowTagDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Cancel changes
  const handleCancelConfirm = () => {
    setIsCancelModalOpen(false);
    navigate(-1);
  };

  // Delete product
  const handleDeleteConfirm = async () => {
    try {
      const response = await deleteProduct(id);
      if (response.success) {
        toast.success(response.message);
        setIsDeleteModalOpen(false);
        navigate("/admin/products");
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.error("Error deleting product:", error);
      toast.error("An error occurred while deleting the product.");
    }
  };

  return (
    <div className="p-6 max-w-7xl mx-auto bg-white text-black">
      <h1 className="text-2xl font-semibold mb-6">Create Listing</h1>
      <p className="mb-6 text-sm text-gray-700">
        At TWM, our refund policy allows refunds or exchanges within 7 days after
        the item is delivered. Keep this in mind when creating your listing, so 
        buyers are aware that they have 7 days from delivery to request a refund 
        or exchange if there’s an issue.
      </p>

      <form onSubmit={handleSubmit}>
        <div className="flex flex-col space-y-6">
          {/* Media */}
          <div className="border border-dashed border-gray-300 p-4 rounded-md text-center bg-white">
            <button
              type="button"
              onClick={() => setIsMediaModalOpen(true)}
              className="w-full"
            >
              <FaImage className="mx-auto text-2xl text-gray-500 mb-2" />
              <p className="font-medium text-black">Add Photos and Video</p>
              <p className="text-sm text-gray-500">
                Drop your images and video here, or browse <br /> .jpeg, .png, .mp4 are allowed
              </p>
            </button>
            {formData.images.length > 0 && (
              <div className="flex flex-wrap mt-4">
                {formData.images.map((img, index) => (
                  <div
                    key={index}
                    className="relative w-20 h-20 border border-gray-300 p-1 m-1"
                  >
                    <img
                      src={img.image}
                      alt={`uploaded-img-${index}`}
                      className="object-cover w-full h-full"
                    />
                    <button
                      className="absolute top-0 right-0 bg-black text-white p-1 rounded-full"
                      onClick={() => handleRemoveImage(index)}
                    >
                      <FaTimes size={12} />
                    </button>
                  </div>
                ))}
              </div>
            )}
            {formData.video && (
              <div className="relative mt-4">
                <video width="200" height="150" controls>
                  <source src={formData.video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <button
                  className="absolute top-0 right-0 bg-black text-white p-1 rounded-full"
                  onClick={handleRemoveVideo}
                >
                  <FaTimes size={12} />
                </button>
              </div>
            )}
          </div>

          {/* Basic info */}
          <div className="space-y-6">
            {/* Title */}
            <div className="flex flex-col md:flex-row items-center">
              <div className="md:w-1/4 w-full md:pr-4 mb-4 md:mb-0">
                <label className="font-medium text-black block">TITLE</label>
                <p className="text-sm text-gray-500">
                  Share key details like Brand, Size, and Color.
                </p>
              </div>
              <div className="md:w-3/4 w-full">
                <input
                  type="text"
                  name="productName"
                  value={formData.productName}
                  onChange={handleChange}
                  className="border border-gray-300 p-2 rounded-md w-full"
                  placeholder="What are you selling? (required)"
                />
              </div>
            </div>

            {/* Description */}
            <div className="flex flex-col md:flex-row items-start">
              <div className="md:w-1/4 w-full md:pr-4 mb-4 md:mb-0">
                <label className="font-medium text-black block">DESCRIPTION</label>
                <p className="text-sm text-gray-500">
                  Tell shoppers about your listing by describing the Brand, Size, Color, Condition, Material, and any other noteworthy details.
                </p>
              </div>
              <div className="md:w-3/4 w-full">
                <textarea
                  className="border border-gray-300 p-2 h-[200px] rounded-md w-full"
                  style={{ resize: "none" }}
                  value={formData.description}
                  onChange={(e) => handleDescriptionChange(e.target.value)}
                  placeholder="Describe your product..."
                />
              </div>
            </div>

            {/* Category & Subcategory */}
            <div className="flex flex-col md:flex-row items-start">
              <div className="md:w-1/4 w-full md:pr-4 mb-4 md:mb-0">
                <label className="font-medium text-black block">CATEGORY *</label>
                <p className="text-sm text-gray-500">
                  Add details to help your buyers find your item quickly.
                </p>
              </div>
              <div className="md:w-3/4 w-full flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
                <div className="relative w-full">
                  <button
                    type="button"
                    className="border border-gray-300 p-2 rounded-md w-full flex justify-between items-center"
                    ref={(el) => {
                      setCategoryReferenceElement(el);
                      categoryButtonRef.current = el;
                    }}
                    onClick={() =>
                      setShowCategoryDropdown(!showCategoryDropdown)
                    }
                  >
                    {formData.category ? formData.category : "Select Category"}
                    <FaChevronDown className="ml-2" />
                  </button>
                  {showCategoryDropdown && (
                    <div
                      ref={(el) => {
                        setCategoryPopperElement(el);
                        categoryDropdownRef.current = el;
                      }}
                      style={categoryStyles.popper}
                      {...categoryAttributes.popper}
                      className="bg-white border border-gray-300 mt-1 rounded-md shadow-lg z-10"
                    >
                      {categories.map((category) => (
                        <div
                          key={category.category}
                          className="px-4 py-2 hover:bg-gray-200 cursor-pointer"
                          onClick={() => handleSelectCategory(category)}
                        >
                          {category.category}
                        </div>
                      ))}
                    </div>
                  )}
                </div>

                <div className="relative w-full">
                  <button
                    type="button"
                    className={`border border-gray-300 p-2 rounded-md w-full flex justify-between items-center ${
                      !formData.category ? "cursor-not-allowed bg-gray-100" : ""
                    }`}
                    ref={(el) => {
                      setSubcategoryReferenceElement(el);
                      subcategoryButtonRef.current = el;
                    }}
                    onClick={() =>
                      formData.category &&
                      setShowSubcategoryDropdown(!showSubcategoryDropdown)
                    }
                    disabled={!formData.category}
                  >
                    {formData.subcategory
                      ? formData.subcategory
                      : "Select Subcategory (optional)"}
                    <FaChevronDown className="ml-2" />
                  </button>
                  {showSubcategoryDropdown && formData.category && (
                    <div
                      ref={(el) => {
                        setSubcategoryPopperElement(el);
                        subcategoryDropdownRef.current = el;
                      }}
                      style={subcategoryStyles.popper}
                      {...subcategoryAttributes.popper}
                      className="bg-white border border-gray-300 mt-1 rounded-md shadow-lg z-10"
                    >
                      {categories
                        .find(
                          (category) => category.category === formData.category
                        )
                        ?.subcategories.map((subcategory) => (
                          <div
                            key={subcategory}
                            className="px-4 py-2 hover:bg-gray-200 cursor-pointer"
                            onClick={() => handleSelectSubcategory(subcategory)}
                          >
                            {subcategory}
                          </div>
                        ))}
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* Color */}
            <div className="flex flex-col md:flex-row items-start">
              <div className="md:w-1/4 w-full md:pr-4 mb-4 md:mb-0">
                <label className="font-medium text-black block">COLOR</label>
                <p className="text-sm text-gray-500">Select up to 2 colors.</p>
              </div>
              <div className="md:w-3/4 w-full">
                <button
                  type="button"
                  className="border border-gray-300 p-2 rounded-md w-full flex justify-between items-center"
                  ref={(el) => {
                    setColorReferenceElement(el);
                    colorButtonRef.current = el;
                  }}
                  onClick={() => setShowColorDropdown(!showColorDropdown)}
                >
                  {formData.selectedColors.length > 0
                    ? formData.selectedColors.join(", ")
                    : "Select up to 2 colors"}
                  <FaChevronDown className="ml-2" />
                </button>
                {showColorDropdown && (
                  <div
                    ref={(el) => {
                      setColorPopperElement(el);
                      colorDropdownRef.current = el;
                    }}
                    style={colorStyles.popper}
                    {...colorAttributes.popper}
                    className="bg-white border border-gray-300 mt-1 rounded-md shadow-lg z-10 p-4"
                  >
                    <div className="grid grid-cols-5 gap-2">
                      {colors.map((color) => (
                        <div
                          key={color}
                          className={`cursor-pointer p-2 rounded-full text-center ${
                            formData.selectedColors.includes(color)
                              ? "bg-gray-200"
                              : ""
                          }`}
                          onClick={() => handleSelectColor(color)}
                        >
                          <div
                            className="w-6 h-6 rounded-full mx-auto"
                            style={{ backgroundColor: color.toLowerCase() }}
                          ></div>
                          <span className="block mt-1 text-sm">{color}</span>
                        </div>
                      ))}
                    </div>
                    <button
                      type="button"
                      className="mt-4 px-4 py-2 bg-black text-white rounded-md"
                      onClick={() => setShowColorDropdown(false)}
                    >
                      Done
                    </button>
                  </div>
                )}
              </div>
            </div>

            {/* Brand */}
            <div className="flex flex-col md:flex-row items-center">
              <div className="md:w-1/4 w-full md:pr-4 mb-4 md:mb-0">
                <label className="font-medium text-black block">BRAND</label>
                <p className="text-sm text-gray-500">
                  Enter the brand or designer name.
                </p>
              </div>
              <div className="md:w-3/4 w-full">
                <input
                  type="text"
                  name="brandName"
                  value={formData.brandName}
                  onChange={handleChange}
                  className="border border-gray-300 p-2 rounded-md w-full"
                  placeholder="Enter Brand (optional)"
                />
              </div>
            </div>

            {/* Quantity */}
            <div className="flex flex-col md:flex-row items-start">
              <div className="md:w-1/4 w-full md:pr-4 mb-4 md:mb-0">
                <label className="font-medium text-black block">QUANTITY</label>
                <p className="text-sm text-gray-500">
                  Select a single or multi item.
                </p>
              </div>
              <div className="md:w-3/4 w-full flex flex-col space-y-4">
                <div className="flex space-x-4">
                  <button
                    type="button"
                    onClick={() => handleQuantityTypeChange("single")}
                    className={`py-2 px-6 border ${
                      formData.quantityType === "single"
                        ? "bg-black text-white"
                        : "bg-white text-black border-gray-300"
                    } rounded-md`}
                  >
                    Single Item
                  </button>
                  <button
                    type="button"
                    onClick={() => handleQuantityTypeChange("multi")}
                    className={`py-2 px-6 border ${
                      formData.quantityType === "multi"
                        ? "bg-black text-white"
                        : "bg-white text-black border-gray-300"
                    } rounded-md`}
                  >
                    Multi Item
                  </button>
                </div>
                {formData.quantityType === "multi" && (
                  <div className="flex flex-col space-y-4">
                    <div className="flex space-x-4">
                      <label className="text-sm text-black">
                        Manage Stock (If you enable this, you'll be able to specify
                        exactly how many units of this product you have.):
                      </label>
                      <button
                        type="button"
                        onClick={() => handleManageStockChange(true)}
                        className={`py-2 px-6 border ${
                          formData.manageStock
                            ? "bg-black text-white"
                            : "bg-white text-black border-gray-300"
                        } rounded-md`}
                      >
                        Yes
                      </button>
                      <button
                        type="button"
                        onClick={() => handleManageStockChange(false)}
                        className={`py-2 px-6 border ${
                          !formData.manageStock
                            ? "bg-black text-white"
                            : "bg-white text-black border-gray-300"
                        } rounded-md`}
                      >
                        No
                      </button>
                    </div>
                    {formData.manageStock && (
                      <div className="flex flex-col">
                        <label className="font-medium text-black">Quantity</label>
                        <input
                          type="number"
                          name="quantity"
                          value={formData.quantity}
                          onChange={handleQuantityChange}
                          className="border border-gray-300 p-2 rounded-md w-full"
                          placeholder="Enter quantity"
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>

            {/* Minimum Order Quantity */}
            <div className="flex flex-col md:flex-row items-start">
              <div className="md:w-1/4 w-full md:pr-4 mb-4 md:mb-0">
                <label className="font-medium text-black block">
                  MINIMUM ORDER QUANTITY
                </label>
                <p className="text-sm text-gray-500">
                  The minimum quantity a buyer must purchase. (required)
                </p>
              </div>
              <div className="md:w-3/4 w-full">
                <input
                  type="number"
                  name="minimumOrderQuantity"
                  value={formData.minimumOrderQuantity}
                  onChange={handleChange}
                  className="border border-gray-300 p-2 rounded-md w-full"
                  placeholder="Enter minimum quantity"
                />
              </div>
            </div>

            {/* Price & Earnings */}
            <div className="flex flex-col md:flex-row items-start">
              <div className="md:w-1/4 w-full md:pr-4 mb-4 md:mb-0">
                <label className="font-medium text-black block">PRICE *</label>
                <p className="text-sm text-gray-500">Learn about TWM's Seller fee</p>
                <p className="text-sm text-gray-500">
                  10% commission for sellers
                </p>
              </div>
              <div className="md:w-3/4 w-full space-y-4">
                <div className="flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-4">
                  <div className="w-full md:w-1/2">
                    <label className="font-medium text-black">
                      Original Price
                    </label>
                    <input
                      type="number"
                      name="regularPrice"
                      value={formData.regularPrice}
                      onChange={handlePriceChange}
                      className="border border-gray-300 p-2 rounded-md w-full"
                      placeholder="Optional"
                    />
                  </div>
                  <div className="w-full md:w-1/2">
                    <label className="font-medium text-black">
                      Listing Price *
                    </label>
                    <input
                      type="number"
                      name="discountedPrice"
                      value={formData.discountedPrice}
                      onChange={handlePriceChange}
                      className="border border-gray-300 p-2 rounded-md w-full"
                      placeholder="*Required"
                    />
                  </div>
                </div>
                <div className="flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-4">
                  <div className="w-full md:w-1/2">
                    <label className="font-medium text-black">
                      Your Earnings
                    </label>
                    <input
                      type="text"
                      name="earnings"
                      value={formData.earnings}
                      readOnly
                      className="border border-gray-300 p-2 rounded-md w-full bg-gray-100"
                      placeholder="Earnings"
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Tags */}
            <div className="flex flex-col md:flex-row items-start">
              <div className="md:w-1/4 w-full md:pr-4 mb-4 md:mb-0">
                <label className="font-medium text-black block">TAGS</label>
                <p className="text-sm text-gray-500">
                  Add tags to make your item easier to find (e.g., 'sneakers', 'vintage').
                </p>
              </div>
              <div className="md:w-3/4 w-full">
                <button
                  type="button"
                  className="border border-gray-300 p-2 rounded-md w-full flex justify-between items-center"
                  ref={(el) => {
                    setTagReferenceElement(el);
                    tagButtonRef.current = el;
                  }}
                  onClick={() => setShowTagDropdown(!showTagDropdown)}
                >
                  {formData.selectedTags.length > 0
                    ? "Selected Tags"
                    : "Select Tags"}
                  <FaChevronDown className="ml-2" />
                </button>

                {showTagDropdown && (
                  <div
                    ref={(el) => {
                      setTagPopperElement(el);
                      tagDropdownRef.current = el;
                    }}
                    style={tagStyles.popper}
                    {...tagAttributes.popper}
                    className="bg-white border w-[50%] border-gray-300 mt-1 rounded-md shadow-lg z-10 p-4"
                  >
                    <input
                      type="text"
                      placeholder="Search or add a tag..."
                      value={tagFilterText}
                      onChange={(e) => setTagFilterText(e.target.value)}
                      onKeyDown={handleTagInputKeyDown}
                      className="border border-gray-300 p-2 rounded-md w-full mb-2"
                    />
                    <div className="max-h-60 overflow-y-auto scrollbar-hide">
                      {tags
                        .filter((tag) =>
                          tag.toLowerCase().includes(tagFilterText.toLowerCase())
                        )
                        .map((tag) => (
                          <div
                            key={tag}
                            className={`px-4 py-2 hover:bg-gray-200 cursor-pointer ${
                              formData.selectedTags.includes(tag)
                                ? "bg-gray-200"
                                : ""
                            }`}
                            onClick={() => handleSelectTag(tag)}
                          >
                            {tag}
                          </div>
                        ))}
                      {/* Show "Add new tag" if user typed something not in the list */}
                      {tagFilterText.trim() !== "" &&
                        !tags.some(
                          (tag) =>
                            tag.toLowerCase() ===
                            tagFilterText.trim().toLowerCase()
                        ) && (
                          <div
                            className="px-4 py-2 hover:bg-gray-200 cursor-pointer"
                            onClick={handleAddNewTag}
                          >
                            Add new tag: "{tagFilterText.trim()}"
                          </div>
                        )}
                    </div>
                    <button
                      type="button"
                      className="mt-4 px-4 py-2 bg-black text-white rounded-md"
                      onClick={() => setShowTagDropdown(false)}
                    >
                      Done
                    </button>
                  </div>
                )}

                {/* Selected tags */}
                <div className="flex flex-wrap mt-4 space-x-2">
                  {formData.selectedTags.map((tag, index) => (
                    <div
                      key={index}
                      className="bg-gray-200 px-3 py-1 rounded-full flex items-center space-x-2 mt-2"
                    >
                      <span>{tag}</span>
                      <button
                        type="button"
                        className="text-gray-600 hover:text-black"
                        onClick={() => handleRemoveTag(tag)}
                      >
                        <FaTimes size={12} />
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* Style */}
            <div className="flex flex-col md:flex-row items-start">
              <div className="md:w-1/4 w-full md:pr-4 mb-4 md:mb-0">
                <label className="font-medium text-black block">STYLE</label>
                <p className="text-sm text-gray-500">
                  Choose a style for your product.
                </p>
              </div>
              <div className="md:w-3/4 w-full">
                <button
                  type="button"
                  className="border border-gray-300 p-2 rounded-md w-full flex justify-between items-center"
                  ref={(el) => {
                    setStyleReferenceElement(el);
                    styleButtonRef.current = el;
                  }}
                  onClick={() => setShowStyleDropdown(!showStyleDropdown)}
                >
                  {formData.selectedStyles.length > 0
                    ? "Selected Styles"
                    : "Select Styles"}
                  <FaChevronDown className="ml-2" />
                </button>
                {showStyleDropdown && (
                  <div
                    ref={(el) => {
                      setStylePopperElement(el);
                      styleDropdownRef.current = el;
                    }}
                    style={stylePopperStyles.popper}
                    {...stylePopperAttributes.popper}
                    className="bg-white border border-gray-300 mt-1 rounded-md shadow-lg z-10"
                  >
                    {styles.map((style) => (
                      <div
                        key={style}
                        className="px-4 py-2 hover:bg-gray-200 cursor-pointer"
                        onClick={() => handleSelectStyle(style)}
                      >
                        {style}
                      </div>
                    ))}
                  </div>
                )}
                {/* Selected styles */}
                <div className="flex flex-wrap mt-4 space-x-2">
                  {formData.selectedStyles.map((style, index) => (
                    <div
                      key={index}
                      className="bg-gray-200 px-3 py-1 rounded-full flex items-center space-x-2"
                    >
                      <span>{style}</span>
                      <button
                        type="button"
                        className="text-gray-600 hover:text-black"
                        onClick={() => handleRemoveStyle(style)}
                      >
                        <FaTimes size={12} />
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* Product Type */}
            <div className="flex flex-col md:flex-row items-start">
              <div className="md:w-1/4 w-full md:pr-4 mb-4 md:mb-0">
                <label className="font-medium text-black block">PRODUCT TYPE</label>
                <p className="text-sm text-gray-500">Select product condition</p>
              </div>
              <div className="md:w-3/4 w-full flex space-x-4">
                <button
                  type="button"
                  onClick={() =>
                    setFormData((prev) => ({ ...prev, productType: "New" }))
                  }
                  className={`py-2 px-6 border rounded-md ${
                    formData.productType === "New"
                      ? "bg-black text-white"
                      : "bg-white text-black border-gray-300"
                  }`}
                >
                  New
                </button>
                <button
                  type="button"
                  onClick={() =>
                    setFormData((prev) => ({ ...prev, productType: "Used" }))
                  }
                  className={`py-2 px-6 border rounded-md ${
                    formData.productType === "Used"
                      ? "bg-black text-white"
                      : "bg-white text-black border-gray-300"
                  }`}
                >
                  Used
                </button>
              </div>
            </div>

            {/* Additional Details */}
            <div className="flex flex-col">
              <div className="border-t pt-4 mt-6">
                <div className="text-gray-700 font-semibold">
                  ADDITIONAL DETAILS (PRIVATE)
                </div>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4">
                <div>
                  <label className="text-sm text-gray-600">Listing SKU</label>
                  <input
                    type="text"
                    name="privateData.sku"
                    value={formData.privateData?.sku || ""}
                    onChange={handleChange}
                    className="border border-gray-300 p-2 rounded-md w-full"
                    placeholder="optional"
                  />
                </div>
                <div>
                  <label className="text-sm text-gray-600">Cost Price</label>
                  <input
                    type="number"
                    name="privateData.costPrice"
                    value={formData.privateData?.costPrice || ""}
                    onChange={handleChange}
                    className="border border-gray-300 p-2 rounded-md w-full"
                    placeholder="optional"
                  />
                </div>
                <div className="col-span-1 sm:col-span-2">
                  <label className="text-sm text-gray-600">Other Info</label>
                  <textarea
                    name="privateData.otherInfo"
                    value={formData.privateData?.otherInfo || ""}
                    onChange={handleChange}
                    className="border border-gray-300 p-2 rounded-md w-full"
                    placeholder="optional"
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Buttons */}
          <div className="flex justify-end mt-6 space-x-4">
            <button
              type="submit"
              className="py-2 px-6 bg-black text-white rounded-md flex items-center justify-center"
              disabled={loading}
            >
              {loading ? (
                <>
                  <FaSpinner className="animate-spin mr-2" /> Saving...
                </>
              ) : (
                "SAVE"
              )}
            </button>
            {id && (
              <button
                type="button"
                className="py-2 px-6 bg-gray-700 text-white rounded-md"
                onClick={() => setIsDeleteModalOpen(true)}
              >
                DELETE
              </button>
            )}
            <button
              type="button"
              className="py-2 px-6 bg-gray-300 text-black rounded-md"
              onClick={() => setIsCancelModalOpen(true)}
              disabled={loading}
            >
              CANCEL
            </button>
          </div>
        </div>
      </form>

      {/* Media Upload Modal */}
      <MediaUploadModal
        isOpen={isMediaModalOpen}
        onClose={() => setIsMediaModalOpen(false)}
        onSave={handleSaveMedia}
        existingImages={formData.images}
        existingVideo={formData.video}
      />

      {/* Confirm Modals */}
      <ConfirmModal
        isOpen={isCancelModalOpen}
        onRequestClose={() => setIsCancelModalOpen(false)}
        title="Discard Changes"
        message="Are you sure you want to discard all changes?"
        onConfirm={handleCancelConfirm}
        confirmText="Discard"
        cancelText="Keep Editing"
      />
      <ConfirmModal
        isOpen={isDeleteModalOpen}
        onRequestClose={() => setIsDeleteModalOpen(false)}
        title="Delete Product"
        message="Are you sure you want to delete this product?"
        onConfirm={handleDeleteConfirm}
        confirmText="Delete"
        cancelText="Cancel"
      />
    </div>
  );
};

export default NewProduct;
