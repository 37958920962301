import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function Tabs({ activeTab, setActiveTab }) {
  const navigate = useNavigate();

  return (
    <div className="w-full bg-white p-4 lg:p-6">
      <h2 className="text-xl lg:text-2xl font-bold mb-4 lg:mb-6 text-gray-700">Account Settings</h2>
      <ul className="space-y-4">
        <li
          className={`cursor-pointer text-lg font-semibold ${
            activeTab === 'profile' ? 'text-black font-bold' : 'text-gray-600'
          } hover:text-black`}
          onClick={() => setActiveTab('profile')}
        >
          Edit Profile
        </li>
        <li
          className={`cursor-pointer text-lg font-semibold ${
            activeTab === 'address' ? 'text-black font-bold' : 'text-gray-600'
          } hover:text-black`}
          onClick={() => setActiveTab('address')}
        >
          Address Settings
        </li>
        <li
          className={`cursor-pointer text-lg font-semibold ${
            activeTab === 'sharing' ? 'text-black font-bold' : 'text-gray-600'
          } hover:text-black`}
          onClick={() => setActiveTab('sharing')}
        >
          Sharing Settings
        </li>
        <li
          className={`cursor-pointer text-lg font-semibold ${
            activeTab === 'notifications' ? 'text-black font-bold' : 'text-gray-600'
          } hover:text-black`}
          onClick={() => setActiveTab('notifications')}
        >
          Notifications
        </li>
        <li
          className={`cursor-pointer text-lg font-semibold ${
            activeTab === 'vacation' ? 'text-black font-bold' : 'text-gray-600'
          } hover:text-black`}
          onClick={() => setActiveTab('vacation')}
        >
          Vacation Hold
        </li>
      </ul>
      <h2
        className="text-xl lg:text-2xl font-bold mb-4 lg:mt-6 text-gray-700 cursor-pointer hover:text-black"
        onClick={() => navigate('/how-to-sell')}
      >
        Become a Seller
      </h2>
    </div>
  );
}
