import React, { useState, useEffect } from 'react';
import SummaryCards from '../../components/Admin/Home/SummaryCards';
import SaleGraph from '../../components/Admin/Home/SaleGraph';
import BestSellers from '../../components/Admin/Home/BestSellers';
import RecentOrders from '../../components/Admin/Home/RecentOrders';

import { getVendorAnalytics } from '../../Hooks/analyticsHook';

function AdminHome() {
  const [analyticsData, setAnalyticsData] = useState(null);

  useEffect(() => {
    const fetchAnalytics = async () => {
      const response = await getVendorAnalytics();
      if (response.success) {
        console.log(response.data)
        setAnalyticsData(response.data);
      } else {
        console.error(response.message);
      }
    };

    fetchAnalytics();
  }, []);

  if (!analyticsData) {
    return <div>Loading...</div>; // Handle loading state
  }

  return (
    <>
      <SummaryCards data={analyticsData} />
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 mt-4">
        <div className="lg:col-span-2">
          <SaleGraph data={analyticsData} />
        </div>
        <div>
          <BestSellers data={analyticsData.bestSellers} />
        </div>
      </div>
      <div className="mt-4">
        <RecentOrders data={analyticsData.recentOrders} />
      </div>
    </>
  );
}

export default AdminHome;
