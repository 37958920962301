import React, { useState } from 'react';
import { FaSearch, FaShippingFast, FaMoneyBillWave, FaBoxOpen, FaPauseCircle, FaQuestionCircle, FaListAlt, FaEdit, FaTags, FaUndo, FaTimesCircle } from 'react-icons/fa';

export default function HelpCenter() {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <div className="bg-gray-100 py-10 px-5 sm:px-10">
      <div className="max-w-7xl mx-auto">
        {/* Search Bar */}
        <div className="flex items-center justify-center mb-10">
          <div className="w-full sm:w-1/2 relative">
            <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={handleSearchChange}
              className="w-full p-4 pl-10 rounded-lg shadow-md border-2 border-gray-300 focus:outline-none"
            />
            <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500" />
          </div>
        </div>

        {/* Title */}
        <h1 className="text-3xl sm:text-4xl font-bold text-center mb-10">All about The Wedding Marketplace</h1>

        {/* Grid section */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {/* Using TWM */}
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <div className="flex justify-center mb-4">
              <FaListAlt className="text-4xl" />
            </div>
            <h2 className="text-xl font-semibold text-center mb-4">Using The Wedding Marketplace (TWM)</h2>
            <p>When joining TWM, you are joining a community. While most users aren’t professionals in the wedding business, treating everyone in a professional manner is a great way to keep things pleasant for everyone.</p>
          </div>

          {/* Managing My Listing */}
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <div className="flex justify-center mb-4">
              <FaEdit className="text-4xl" />
            </div>
            <h2 className="text-xl font-semibold text-center mb-4">Managing My Listing</h2>
            <p>Staying active with your listing is the best way to ensure a successful transaction. You can edit your description or price at any time. Sold items cannot be edited.</p>
          </div>

          {/* Optimizing Views for Your Listing */}
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <div className="flex justify-center mb-4">
              <FaTags className="text-4xl" />
            </div>
            <h2 className="text-xl font-semibold text-center mb-4">Optimizing Views for Your Listing</h2>
            <p>To optimize your listing views, think like a buyer: use relevant keywords, select high-quality images, and price competitively.</p>
          </div>

          {/* Communication is Power */}
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <div className="flex justify-center mb-4">
              <FaQuestionCircle className="text-4xl" />
            </div>
            <h2 className="text-xl font-semibold text-center mb-4">Communication is Power</h2>
            <p>Clear, concise communication minimizes misunderstandings. Ask thoughtful questions and provide straightforward answers to facilitate smooth transactions.</p>
          </div>

          {/* Pausing Your Listings */}
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <div className="flex justify-center mb-4">
              <FaPauseCircle className="text-4xl" />
            </div>
            <h2 className="text-xl font-semibold text-center mb-4">Pausing Your Listings</h2>
            <p>If you need to be away, you can easily deactivate and reactivate your items. If sales are slow, check out tips on making your listing stand out.</p>
          </div>

          {/* Create Your Listing */}
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <div className="flex justify-center mb-4">
              <FaBoxOpen className="text-4xl" />
            </div>
            <h2 className="text-xl font-semibold text-center mb-4">Create Your Listing</h2>
            <p>Making a listing is easy. Just snap a few quality pictures, add your description, and choose your price. Our marketplace is here to help you connect with buyers.</p>
          </div>

          {/* What Can't I Sell? */}
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <div className="flex justify-center mb-4">
              <FaTimesCircle className="text-4xl" />
            </div>
            <h2 className="text-xl font-semibold text-center mb-4">What Can’t I Sell?</h2>
            <p>TWM is a specialized marketplace for wedding and event items. Items such as weapons, drugs, alcohol, and illegal activities are not allowed.</p>
          </div>

          {/* Choosing a Price */}
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <div className="flex justify-center mb-4">
              <FaMoneyBillWave className="text-4xl" />
            </div>
            <h2 className="text-xl font-semibold text-center mb-4">Choosing a Price</h2>
            <p>Set competitive prices by checking other listings. Adjust the price if your item isn’t selling, and make sure to describe your item accurately for better results.</p>
          </div>

          {/* Shipping Choices */}
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <div className="flex justify-center mb-4">
              <FaShippingFast className="text-4xl" />
            </div>
            <h2 className="text-xl font-semibold text-center mb-4">Shipping Choices</h2>
            <p>While listing, you’ll need to specify shipping size, weight, and costs. Always protect items with proper packing and track them during shipment.</p>
          </div>

          {/* Returns */}
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <div className="flex justify-center mb-4">
              <FaUndo className="text-4xl" />
            </div>
            <h2 className="text-xl font-semibold text-center mb-4">Returns</h2>
            <p>Sales on TWM are final. If an item doesn’t match its description, contact the seller for a resolution. If you can’t reach the seller, contact us for help.</p>
          </div>

          {/* How to Return Your Item */}
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <div className="flex justify-center mb-4">
              <FaBoxOpen className="text-4xl" />
            </div>
            <h2 className="text-xl font-semibold text-center mb-4">How to Return Your Item</h2>
            <p>To return an item, make sure to package it securely with bubble wrap and proper packing materials. Label fragile items and use the correct size box for safe delivery.</p>
          </div>

          {/* Refund Timeline */}
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <div className="flex justify-center mb-4">
              <FaMoneyBillWave className="text-4xl" />
            </div>
            <h2 className="text-xl font-semibold text-center mb-4">When You’ll Receive Your Refund</h2>
            <p>Once the item is returned, the seller has 24 hours to review and refund your money. It may take a few business days for the funds to show up in your account.</p>
          </div>
        </div>
      </div>
    </div>
  );
}
