// AdminLayout.js

import React from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Topbar from './components/Admin/Topbar';

function AdminLayout() {
  return (
    <div className="flex">
      <Sidebar />
      <div className="flex-grow">
        <Topbar /> {/* Add the Topbar here */}
        <div className="p-4">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default AdminLayout;
