// src/components/Tabs.js
import React from 'react';

const Tabs = ({ activeTab, setActiveTab }) => {
  return (
    <div className="flex space-x-6 border-b mb-6">
      <button
        className={`pb-2 ${activeTab === 'previousOrders' ? 'border-b-2 border-black' : ''}`}
        onClick={() => setActiveTab('previousOrders')}
      >
        Previous Orders
      </button>
      {/* <button
        className={`pb-2 ${activeTab === 'toBeDelivered' ? 'border-b-2 border-black' : ''}`}
        onClick={() => setActiveTab('toBeDelivered')}
      >
        To Be Delivered
      </button> */}
      <button
        className={`pb-2 ${activeTab === 'offers' ? 'border-b-2 border-black' : ''}`}
        onClick={() => setActiveTab('offers')}
      >
        Offers on Items
      </button>
    </div>
  );
};

export default Tabs;
