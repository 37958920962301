import React, { useState, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import io from "socket.io-client"; // Import socket.io-client
import Home from "./pages/Home";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Topheader from "./components/Topheader";
import ProductDetails from "./pages/ProductDetails";
import Cart from "./pages/Cart/Cart";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Products from "./pages/products/Products";
import Account from "./pages/Account";
import CategoryPage from "./pages/CategoryPage";
import AdminRoutes from "./AdminRoutes";
import MyOrders from "./pages/MyOrders";
import AdminLogin from "./pages/Admin/AdminLogin";
import AdminSignup from "./pages/Admin/AdminSignup";
import OTP from "./pages/Admin/OTP";
import SelectShipment from "./pages/Cart/SelectShipment";
import Checkout from "./pages/Checkout";
import WeddingQuiz from "./pages/WeddingQuiz";
import HomePage from "./pages/HomePage";
import ScrollToTop from "./helpers/ScrollToTop";
import Search from "./pages/Search";

//static pages
import AboutUs from "./pages/static/AboutUs";
import BuyerSellerAgreement from "./pages/static/BuyerSellerAgreement";
import Faqpage from "./pages/static/Faqpage";
import HelpCenter from "./pages/static/HelpCenter";
import HowToBuy from "./pages/static/HowToBuy";
import HowToSell from "./pages/static/HowToSell";
import HowToSellLocally from "./pages/static/HowToSellLocally";
import PrivacyPolicy from "./pages/static/PrivacyPolicy";
import SellerAgreement from "./pages/static/SellerAgreement";
import ContactUs from "./pages/static/ContactUs";
import ProfilePage from "./pages/ProfilePage";
import Wishlist from "./pages/Wishlist";
import CheckList from "./pages/CheckList";
import MyOrderDetail from "./components/Accounts/MyOrderDetail";
import SalesTax from "./pages/static/SalesTax";
import ProhibtedConduct from "./pages/static/ProhibtedConduct";
import Refund from "./pages/static/Refund";
import SalesTaxBuyer from "./pages/static/SalesTaxBuyer";
import { useDispatch } from "react-redux";
import PaymentSuccess from "./pages/PaymentSuccess";

import {
  setNotifications,
  addNotification,
} from "./redux/features/notificationSlice";
import { getAllNotification } from "./Hooks/notificationHook";

const SOCKET_URL = process.env.REACT_APP_BACKEND_URL || "http://localhost:5000";

const product = {
  title: "Quality Men's Hoodie for Winter",
  price: 75.9,
  discountedPrice: 65.9, // Added a discounted price
  rating: 4.5,
  reviews: 183,
  description: `This men's hoodie is perfect for winter. With high-quality fabric and a stylish design, it's both comfortable and fashionable. Stay warm in the cold season while looking great.`,
  images: [
    "https://placehold.co/300x400?text=Hoodie+Image+1",
    "https://placehold.co/300x400?text=Hoodie+Image+2",
    "https://placehold.co/300x400?text=Hoodie+Image+3",
    "https://placehold.co/300x400?text=Hoodie+Image+4",
  ],
  video: "https://www.w3schools.com/html/mov_bbb.mp4",
  sizes: ["S", "M", "L", "XL"],
  colors: ["Blue", "Red", "Green"],
  type: "Clothes",

  category: ["Men", "Winter", "Outerwear", "Hoodies", "Fashion", "Casual"],

  tags: [
    "Comfortable",
    "Warm",
    "Casual Wear",
    "Hoodie",
    "Streetwear",
    "Stylish",
    "Durable",
  ],

  material: "Fabric",
  brand: "No Brand",
  color: "Available in many colors",
  inStock: true, // Availability
  discount: true, // Discount status
  shipping: {
    weight: "1.2 lbs", // Shipping weight
    dimensions: "12 x 8 x 2 inches", // Shipping dimensions
    delivery: "Ships in 2-3 business days", // Shipping time
    returnPolicy: "30-day return policy", // Return policy
  },
  seller: {
    name: "FashionCo",
    rating: 4.7, // Seller rating
    reviews: 290, // Total reviews for seller
    active: "Active 5 hours ago", // Seller activity status
    listings: 120, // Total listings by the seller
  },
};

function App() {
  const location = useLocation();
  const dispatch = useDispatch();
  const [socket, setSocket] = useState(null); // Manage socket connection state
  

  useEffect(() => {
    // Establish socket connection only once
    const user = JSON.parse(localStorage.getItem("twmUser"));
    if (user) {
      const socketInstance = io(SOCKET_URL, {
        transports: ["websocket"],
        query: { userId: user._id },
      });

      socketInstance.on("connect", () => {
        console.log("Socket connected:", socketInstance.id);
        socketInstance.emit("register", user._id);
      });

      socketInstance.on("disconnect", () => {
        console.log("Socket disconnected");
      });

      socketInstance.on("newNotification", (notification) => {
        dispatch(addNotification(notification));
        toast.info(`New Notification: ${notification.title}`);
      });
      socketInstance.on("connect_error", (err) => {
        // the reason of the error, for example "xhr poll error"
        console.log(err.message);
      
        // some additional description, for example the status code of the initial HTTP response
        console.log(err.description);
      
        // some additional context, for example the XMLHttpRequest object
        console.log(err.context);
      });

      setSocket(socketInstance);

      // Cleanup on unmount
      return () => {
        socketInstance.off("newNotification");
        socketInstance.disconnect();
      };
    }
  }, [dispatch]);

  useEffect(() => {
    const user = localStorage.getItem("twmUser");
    if (user) {
      fetchNotifications();
    }
  }, []);

  const fetchNotifications = async () => {
    const result = await getAllNotification();
    if (result.success) {
      console.log("Notifications fetched:", result.notifications);
      dispatch(setNotifications(result.notifications));
    } else {
      console.error("Failed to fetch notifications:", result.message);
    }
  };

  // Conditionally show the Navbar based on the current route
  const shouldShowNavbar =
    !location.pathname.startsWith("/admin") &&
    location.pathname !== "/login" &&
    location.pathname !== "/signup" &&
    location.pathname !== "/sign-up" &&
    location.pathname !== "/otp" 


  return (
    <>
      {" "}
      {/* Wrap everything with ThemeProvider */}
      <ScrollToTop />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
      />
      {shouldShowNavbar && <Topheader />}
      {/* {shouldShowNavbar && <Branding />} */}
      {shouldShowNavbar && <Navbar />}
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/main-page" element={<Home />} /> */}

        
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<HomePage />} />
        <Route path="/sign-up" element={<Signup />} />
        <Route
          path="/product/:productId"
          element={<ProductDetails product={product} />}
        />
        <Route path="/payment-success" element={<PaymentSuccess/>} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/products" element={<Products />} />
        <Route path="/search" element={<Search />} />
        <Route path="/account" element={<Account />} />
        <Route path="/category/:slug" element={<CategoryPage />} />
        <Route path="/my-orders" element={<MyOrders />} />
        <Route path="/my-orders/:activetab" element={<MyOrders />} />
        <Route path="/select-shipment" element={<SelectShipment />} />
        <Route path="/profile/:userId" element={<ProfilePage />} />
        <Route path="/wedding-quiz" element={<WeddingQuiz />} />
        <Route path="/wish-list" element={<Wishlist />} />
        <Route path="/check-list" element={<CheckList />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/sales-tax" element={<SalesTax />} />
        <Route path="/prohibited-conduct" element={<ProhibtedConduct />} />
        <Route path="/refund-and-returns" element={<Refund />} />
        <Route path="/sales-tax-buyer" element={<SalesTaxBuyer />} />
        <Route path="/my-order/:orderId" element={<MyOrderDetail />} />
        <Route
          path="/buyer-seller-agreement"
          element={<BuyerSellerAgreement />}
        />
        <Route path="/faq" element={<Faqpage />} />
        <Route path="/help-center" element={<HelpCenter />} />
        <Route path="/how-to-buy" element={<HowToBuy />} />
        <Route path="/how-to-sell" element={<HowToSell />} />
        <Route path="/how-to-sell-locally" element={<HowToSellLocally />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/seller-agreement" element={<SellerAgreement />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/otp" element={<OTP />} />
        <Route path="/admin/login" element={<AdminLogin />} />
        <Route path="/admin/signup" element={<AdminSignup />} />
        <Route path="/admin/*" element={<AdminRoutes />} /> {/* Admin routes */}
      </Routes>
      {shouldShowNavbar && <Footer />}
    </>
  );
}

export default App;
