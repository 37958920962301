import React, { useState, useEffect, useMemo } from "react";
import { useTable } from "react-table";
import { useParams } from "react-router-dom";
import {
  FaCalendarAlt,
  FaPrint,
} from "react-icons/fa";
import {
  getOrderDetailsForVendor,
  updateOrderStatus,
} from "../../Hooks/ordersHook";
import { createFedexBooking } from "../../Hooks/productHooks";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { toast } from "react-toastify";

export default function OrderDetail() {
  const { id } = useParams();
  const [orderDetails, setOrderDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const response = await getOrderDetailsForVendor(id);
        if (response.success) {
          setOrderDetails(response.order);
          setSelectedStatus(response.order.orderStatus);
        } else {
          setError(response.message);
        }
      } catch (err) {
        setError("Failed to fetch order details. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchOrderDetails();
  }, [id]);

  const handleStatusChange = async (status) => {
    try {
      const response = await updateOrderStatus(orderDetails.orderId, status);
      if (response.success) {
        setSelectedStatus(status);
        toast.success("Order status updated successfully!");
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error("Failed to update order status.");
    }
    setStatusDropdownOpen(false);
  };

  const handleFedexBooking = async () => {
    try {
      const response = await createFedexBooking(orderDetails.orderId);
      if (response.success) {
        toast.success(`FedEx booking created: ${response.message}`);
        setOrderDetails((prevDetails) => ({
          ...prevDetails,
          deliveryStatus: true,
        }));
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error("Failed to create FedEx booking. Please try again.");
    }
  };

  // Prepare table data
  const data = useMemo(() => orderDetails?.items || [], [orderDetails]);

  const columns = useMemo(
    () => [
      {
        Header: "Product Name",
        accessor: "productName",
        Cell: ({ value }) => <span className="pl-2">{value}</span>,
      },
      {
        Header: "Quantity",
        accessor: "quantity",
        Cell: ({ value }) => <span className="text-center block">{value}</span>,
      },
      {
        Header: "Total",
        accessor: "total",
        Cell: ({ value }) => (
          <span className="pr-2 text-right block">${value.toFixed(2)}</span>
        ),
      },
    ],
    []
  );

  const tableInstance = useTable({ columns, data });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  // -- Commission & Grand Total Calculation --
  // Commission is 10% of the product subtotal
  // Final amount = (subtotal - discount + shipping) - commission
  const commission = useMemo(() => {
    const subtotal = parseFloat(orderDetails?.subtotal || 0);
    return (subtotal * 0.1).toFixed(2);
  }, [orderDetails]);

  const grandTotal = useMemo(() => {
    const subtotal = parseFloat(orderDetails?.subtotal || 0);
    const discount = parseFloat(orderDetails?.discount || 0);
    const shipping = parseFloat(orderDetails?.shippingRate || 0);
    const commissionVal = subtotal * 0.1;
    const finalVal = (subtotal - discount) - commissionVal;
    return `$${finalVal.toFixed(2)}`;
  }, [orderDetails]);

  // Generate PDF
  const downloadPDF = () => {
    const doc = new jsPDF("portrait", "pt", "A4");
    doc.setFontSize(18);
    doc.text("Order Summary", 40, 40);

    doc.setFontSize(12);
    doc.text(`Order ID: ${orderDetails.orderId}`, 40, 70);
    doc.text(`Date: ${new Date(orderDetails.date).toLocaleString()}`, 40, 90);
    doc.text(`Customer: ${orderDetails.customer.name}`, 40, 110);
    doc.text(`Email: ${orderDetails.customer.email}`, 40, 130);
    doc.text(`Phone: ${orderDetails.customer.phone || "N/A"}`, 40, 150);
    doc.text(
      `Shipping Address: ${Object.values(orderDetails.shippingAddress).join(
        ", "
      )}`,
      40,
      170
    );
    doc.text(`Order Status: ${selectedStatus}`, 40, 190);

    const tableColumn = ["Product Name", "Quantity", "Total"];
    const tableRows = orderDetails.items.map((item) => [
      item.productName,
      item.quantity,
      `$${item.total.toFixed(2)}`,
    ]);

    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 220,
      theme: "grid",
    });

    const finalY = doc.previousAutoTable.finalY + 20;
    doc.text(`Subtotal: ${orderDetails.subtotal}`, 40, finalY);
    doc.text(`Discount: ${orderDetails.discount}`, 40, finalY + 20);
    doc.text(`Shipping Rate: ${orderDetails.shippingRate}`, 40, finalY + 40);
    doc.text(`Commission (10%): -$${commission}`, 40, finalY + 60);
    doc.setFontSize(14);
    doc.text(`Final Amount (after commission): ${grandTotal}`, 40, finalY + 80);

    doc.save(`order_${orderDetails.orderId}.pdf`);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p className="text-red-500">{error}</p>;

  return (
    <div className="p-6 bg-white max-w-7xl text-black">
      <div className="bg-white p-6 rounded-lg shadow-sm mb-6 border border-gray-300">
        <div className="flex justify-between items-center mb-4">
          <div>
            <h1 className="text-xl font-semibold">
              Order ID: {orderDetails.orderId}
            </h1>
            <div className="flex items-center space-x-2 text-gray-600">
              <FaCalendarAlt />
              <span>{new Date(orderDetails.date).toLocaleString()}</span>
            </div>
          </div>
          <div className="flex items-center space-x-4">
            <div className="relative">
              <button
                onClick={() => setStatusDropdownOpen(!statusDropdownOpen)}
                className="p-2 border border-gray-400 rounded-md bg-black text-white"
              >
                Change Status ({selectedStatus})
              </button>
              {statusDropdownOpen && (
                <div className="absolute mt-2 bg-white shadow-lg rounded-md border border-gray-300">
                  {["processing", "shipped", "delivered", "cancelled"].map(
                    (status) => (
                      <div
                        key={status}
                        className="p-2 cursor-pointer hover:bg-gray-200"
                        onClick={() => handleStatusChange(status)}
                      >
                        {status.charAt(0).toUpperCase() + status.slice(1)}
                      </div>
                    )
                  )}
                </div>
              )}
            </div>
            <button
              onClick={downloadPDF}
              className="p-2 border border-gray-400 rounded-md"
            >
              <FaPrint />
            </button>
            {orderDetails.deliveryStatus ? (
              <span className="p-2 bg-green-500 text-white rounded-md">
                Booked
              </span>
            ) : (
              <button
                onClick={handleFedexBooking}
                className="p-2 bg-black text-white rounded-md"
              >
                Create FedEx Booking
              </button>
            )}
          </div>
        </div>

        <div className="grid grid-cols-3 gap-4">
          <div className="p-4 bg-white border border-gray-300 rounded-lg">
            <h2 className="font-semibold mb-2">Customer</h2>
            <p>Name: {orderDetails.customer.name}</p>
            <p>Email: {orderDetails.customer.email}</p>
            <p>Phone: {orderDetails.customer.phone || "N/A"}</p>
          </div>
          <div className="p-4 bg-white border border-gray-300 rounded-lg">
            <h2 className="font-semibold mb-2">Order Info</h2>
            <p>Payment Method: {orderDetails.paymentMethod}</p>
            <p>Status: {selectedStatus}</p>
          </div>
          <div className="p-4 bg-white border border-gray-300 rounded-lg">
            <h2 className="font-semibold mb-2">Delivery Address</h2>
            <p>
              {Object.entries(orderDetails.shippingAddress)
                .filter(
                  ([key]) =>
                    key !== "_id" &&
                    key !== "lat" &&
                    key !== "lng" &&
                    key !== "placeId"
                )
                .map(([, value]) => value)
                .join(", ")}
            </p>
          </div>
        </div>
      </div>

      {/* Products Table */}
      <h2 className="font-semibold text-lg mb-4">Products</h2>
      <table
        {...getTableProps()}
        className="w-full bg-white border border-gray-200 rounded-md"
      >
        <thead className="bg-gray-100">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()} className="p-4 text-left">
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className="hover:bg-gray-50">
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()} className="p-4 border-b">
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* Totals */}
      <div className="mt-6 text-right">
        <p>Subtotal: {orderDetails.subtotal}</p>
        <p>Discount: {orderDetails.discount}</p>
        <p>Shipping Rate: {orderDetails.shippingRate}</p>
        <p>Commission (10%): -${commission}</p>
        <p className="text-lg font-bold">
          Final Amount (after commission): {grandTotal}
        </p>
      </div>
    </div>
  );
}
