import React, { useEffect, useState } from "react";
import OfferModal from "../../Modals/Offermodal";
import { declineCounterOffer, acceptVendorOffer } from "../../../Hooks/offerHooks";
import { toast } from "react-toastify";

const Offers = ({ offers, filter, setFilter, onAccept, onDecline }) => {
  const [offerList, setOfferList] = useState(offers); // Local state for offers
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal visibility state
  const [selectedOffer, setSelectedOffer] = useState(null); // Selected offer for the modal

  useEffect(() => {
    setOfferList(offers); // Initialize the local state with the offers prop
  }, [offers]);

  const openModal = (offer) => {
    setSelectedOffer(offer); // Set the selected offer details
    setIsModalOpen(true); // Open the modal
  };

  // Handle Decline Counter Offer
  const handleDeclineCounterOffer = async (offerId) => {
    const response = await declineCounterOffer(offerId);
    if (response.success) {
      toast.success("Counter offer declined successfully.");
      setOfferList((prevOffers) =>
        prevOffers.filter((offer) => offer._id !== offerId) // Remove the declined offer
      );
    } else {
      toast.error(response.message);
    }
  };

  // Handle Accept Vendor Offer
  const handleAcceptVendorOffer = async (offerId) => {
    const response = await acceptVendorOffer(offerId);
    if (response.success) {
      toast.success("Vendor offer accepted successfully.");
      setOfferList((prevOffers) =>
        prevOffers.filter((offer) => offer._id !== offerId) // Remove the accepted offer
      );
    } else {
      toast.error(response.message);
    }
  };

  const filteredOffers =
    filter === "yourOffers"
      ? offerList.filter((offer) => offer.offerType === "Your Offer")
      : offerList.filter((offer) => offer.offerType === "Vendor Offer");

  const showNoOffersMessage = filteredOffers.length === 0;

  return (
    <div>
      {/* Filter Dropdown */}
      <div className="mb-4 flex justify-between items-center">
        <label htmlFor="filter" className="font-semibold mr-4">
          Filter Offers:
        </label>
        <select
          id="filter"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          className="border border-black p-2 rounded-md"
        >
          <option value="yourOffers">Your Offers</option>
          <option value="vendorOffers">Sellers Offers</option>
        </select>
      </div>

      {showNoOffersMessage ? (
        <div className="text-center mt-8 p-6 bg-gray-100 rounded-lg shadow-lg max-w-md mx-auto">
          <h3 className="text-xl font-bold mb-2">No Offers Found</h3>
          <p className="text-gray-600 mb-4">
            Try changing the filter or check back later.
          </p>
        </div>
      ) : (
        filteredOffers.map((offer) => (
          <div
            key={offer._id}
            className="flex flex-col sm:flex-row items-center mb-4 border p-4 rounded-md shadow"
          >
            {/* Offer Details */}
            <div className="flex-1 text-center sm:text-left">
              <h4 className="font-semibold text-base sm:text-lg">
                {offer.item}
              </h4>
              <p>Offer Price: ${offer.offerPrice.toFixed(2)}</p>
              <p>Original Price: ${offer.originalPrice.toFixed(2)}</p>
              <p>Offer Type: {offer.offerType}</p>
            </div>

            {/* Action Buttons */}
            <div className="flex flex-col gap-3 items-center mt-4 sm:mt-0 sm:ml-4">
              {offer.offerType === "Vendor Offer" && (
                <>
                  <button
                    className="bg-black text-white border border-black hover:bg-white hover:text-black py-1 px-4 rounded-md w-full"
                    onClick={() => handleAcceptVendorOffer(offer._id)} // Accept vendor offer
                  >
                    Accept Offer
                  </button>
                  <button
                    className="bg-black text-white border border-black hover:bg-white hover:text-black py-1 px-4 rounded-md w-full"
                    onClick={() => handleDeclineCounterOffer(offer._id)} // Decline counter offer
                  >
                    Decline Offer
                  </button>
                </>
              )}
              <button
                className="bg-black text-white border border-black hover:bg-white hover:text-black py-1 px-4 rounded-md w-full"
                onClick={() => openModal(offer)} // Open the modal
              >
                Make a Counter Offer
              </button>
            </div>
          </div>
        ))
      )}

      {selectedOffer && (
        <OfferModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          price={selectedOffer.originalPrice}
          productId={selectedOffer.productId}
          offerId={selectedOffer._id} // Pass offerId to modal
          isCounterOffer={true} // Indicate counter offer
        />
      )}
    </div>
  );
};

export default Offers;
