import React from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root'); // Replace '#root' with your root element if different

export default function DeleteConfirmModal({ isOpen, onClose, onConfirm }) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
      overlayClassName="fixed inset-0 bg-gray-900 bg-opacity-75"
    >
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full text-center">
        <h2 className="text-lg font-bold mb-4">Delete Account</h2>
        <p className="text-gray-700 mb-6">
          Are you sure you want to permanently delete your account? This action
          cannot be undone.
        </p>
        <div className="flex justify-center gap-4">
          <button
            onClick={onConfirm}
            className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700 transition"
          >
            Yes, Delete
          </button>
          <button
            onClick={onClose}
            className="bg-gray-300 text-gray-800 px-4 py-2 rounded hover:bg-gray-400 transition"
          >
            No, Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
}
