import axios from "axios";

axios.defaults.baseURL =
  process.env.REACT_APP_BACKEND_URL || "http://localhost:5000";

export async function createOrder(orderData) {
  try {
    const token = localStorage.getItem("twmToken");

    if (!token) {
      return {
        success: false,
        message: "Please login first",
        status: 401,
      };
    }

    const { data, status } = await axios.post(
      "/api/orders/create-order",
      orderData,
      {
        headers: {
          "TWM-auth-token": token,
        },
      }
    );

    return {
      success: data.success,
      message: data.message,
      order: data.order,
      status,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}

export async function getOrdersByCustomer() {
  try {
    const token = localStorage.getItem("twmToken");

    if (!token) {
      return {
        success: false,
        message: "Please login first",
        status: 401,
      };
    }

    const { data, status } = await axios.get(
      "/api/orders/get-customer-orders",
      {
        headers: {
          "TWM-auth-token": token,
        },
      }
    );

    return {
      success: data.success,
      message: data.message,
      orders: data.orders,
      status,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}

export async function getOrdersByVendor(page = 1, limit = 10) {
  try {
    const token = localStorage.getItem("twmToken");

    if (!token) {
      return {
        success: false,
        message: "Please login first",
        status: 401,
      };
    }

    const { data, status } = await axios.get(
      `/api/orders/get-vendor-orders?page=${page}&limit=${limit}`,
      {
        headers: {
          "TWM-auth-token": token,
        },
      }
    );

    return {
      success: data.success,
      message: data.message,
      orders: data.orders,
      pagination: data.pagination, // Include pagination metadata
      status,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}

export async function getOrderDetailsForVendor(orderId) {
  try {
    const token = localStorage.getItem("twmToken");

    if (!token) {
      return {
        success: false,
        message: "Please login first",
        status: 401,
      };
    }

    const { data, status } = await axios.get(
      `/api/orders/get-order-details-vendor/${orderId}`,
      {
        headers: {
          "TWM-auth-token": token,
        },
      }
    );

    return {
      success: data.success,
      message: data.message,
      order: data.order,
      status,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}

export async function updateOrderStatus(orderId, status) {
  try {
    const token = localStorage.getItem("twmToken");

    if (!token) {
      return {
        success: false,
        message: "Please login first",
        status: 401,
      };
    }

    if (!["processing", "shipped", "delivered", "cancelled"].includes(status)) {
      return {
        success: false,
        message:
          "Invalid status value. Allowed statuses: processing, shipped, delivered, cancelled.",
        status: 400,
      };
    }

    const { data, status: responseStatus } = await axios.post(
      `/api/orders/update-order-status/${orderId}`,
      { status },
      {
        headers: {
          "TWM-auth-token": token,
        },
      }
    );

    return {
      success: data.success,
      message: data.message,
      updatedOrders: data.updatedOrders,
      status: responseStatus,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}

export async function getOrderDetailForCustomer(orderId) {
  try {
    const token = localStorage.getItem("twmToken");

    if (!token) {
      return {
        success: false,
        message: "Please login first",
        status: 401,
      };
    }

    const { data, status } = await axios.get(
      `/api/orders/get-order-detail-for-customer/${orderId}`,
      {
        headers: {
          "TWM-auth-token": token,
        },
      }
    );

    return {
      success: data.success,
      message: data.message,
      order: data.order,
      status,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}