import axios from "axios";

axios.defaults.baseURL =
  process.env.REACT_APP_BACKEND_URL || "http://localhost:5000";

export async function registerUser(credentials) {
  try {
    // credentials object should contain:
    // email: User's email address
    // name: User's full name
    // password: User's chosen password
    const {
      data: { success, message, token, user },
      status,
    } = await axios.post("/api/auth/signup", credentials);

    if (success) {
      localStorage.setItem("twmUser", JSON.stringify(user));
      localStorage.setItem("twmToken", token);
    }

    return { success, message, token, user, status };
  } catch (error) {
    // Handle error and return structured response
    return {
      success: false,
      message: error.response?.data?.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}

export async function loginUser(credentials) {
  try {
    // credentials object should contain:
    // email: User's email address
    // password: User's password
    const {
      data: { success, message, token, user },
      status,
    } = await axios.post("/api/auth/login", credentials);

    if (success) {
      localStorage.setItem("twmUser", JSON.stringify(user));
      localStorage.setItem("twmToken", token);
    }

    return { success, message, token, user, status };
  } catch (error) {
    // Handle error and return structured response
    return {
      success: false,
      message: error.response?.data?.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}

export async function verifyEmail(token) {
  try {
    // API call to verify the email
    const {
      data: { success, message },
      status,
    } = await axios.post("/api/auth/verify-email", { token });

    return { success, message, status };
  } catch (error) {
    // Handle errors and return structured response
    return {
      success: false,
      message:
        error.response?.data?.message ||
        "An error occurred during email verification",
      status: error.response?.status || 500,
    };
  }
}

export async function requestPasswordReset(credentials) {
  //   const credentials = {
  //     currentPassword: "currentPassword123",
  //     tmpPassword: "newTemporaryPassword456",
  //   };
  try {
    const token = localStorage.getItem("twmToken");
    if (!token) {
      return {
        success: false,
        message: "Please login first",
        status: 401,
      };
    }

    const {
      data: { success, message },
      status,
    } = await axios.post("/api/auth/request-password-reset", credentials, {
      headers: {
        "TWM-auth-token": token, // Pass the token in the header
      },
    });

    return { success, message, status };
  } catch (error) {
    // Handle errors and return structured response
    return {
      success: false,
      message:
        error.response?.data?.message ||
        "An error occurred while requesting password reset",
      status: error.response?.status || 500,
    };
  }
}

export async function verifyAndResetPassword(otp) {
  try {
    // Fetch the token from localStorage
    const authToken = localStorage.getItem("twmToken");

    // If no token is found, return an error response
    if (!authToken) {
      return {
        success: false,
        message: "Please login first",
        status: 401,
      };
    }

    // Make the API call to verify OTP and reset the password
    const {
      data: { success, message },
      status,
    } = await axios.post(
      "/api/auth/verify-and-reset-password",
      { otp },
      {
        headers: {
          "TWM-auth-token": authToken, // Pass the auth token in the headers
        },
      }
    );

    return { success, message, status };
  } catch (error) {
    // Handle errors and return structured response
    return {
      success: false,
      message:
        error.response?.data?.message ||
        "An error occurred while resetting the password",
      status: error.response?.status || 500,
    };
  }
}

export async function forgotPassword(email) {
  try {
    // Fetch the token from localStorage
    const authToken = localStorage.getItem("twmToken");

    // If no token is found, return an error response
    if (!authToken) {
      return {
        success: false,
        message: "Please login first",
        status: 401,
      };
    }

    // Make the API call to send OTP
    const {
      data: { success, message },
      status,
    } = await axios.post(
      "/api/auth/request-forgot-password",
      { email },
      {
        headers: {
          "TWM-auth-token": authToken, // Pass the token in the headers
        },
      }
    );

    return { success, message, status };
  } catch (error) {
    // Handle errors and return structured response
    return {
      success: false,
      message:
        error.response?.data?.message ||
        "An error occurred while processing your request",
      status: error.response?.status || 500,
    };
  }
}

export async function resetForgottenPassword(credentials) {
  //     const credentials = {
  //     email: "user@example.com",       // Replace with the user's email
  //     otp: "123456",                  // Replace with the OTP provided to the user
  //     newPassword: "newSecurePassword", // Replace with the user's new password
  //   };
  try {
    // Fetch the token from localStorage
    const authToken = localStorage.getItem("twmToken");

    // If no token is found, return an error response
    if (!authToken) {
      return {
        success: false,
        message: "Please login first",
        status: 401,
      };
    }

    // Make the API call to reset the password
    const {
      data: { success, message },
      status,
    } = await axios.post("/api/auth/request-forgotten-password", credentials, {
      headers: {
        "TWM-auth-token": authToken, // Pass the token in the headers
      },
    });

    return { success, message, status };
  } catch (error) {
    // Handle errors and return structured response
    return {
      success: false,
      message:
        error.response?.data?.message ||
        "An error occurred while resetting the password",
      status: error.response?.status || 500,
    };
  }
}

export async function oAuthLogin(credentials) {
  try {
    // Validate input
    if (!credentials.token || !credentials.provider) {
      throw new Error("Token and provider are required.");
    }

    // Make the API call
    const {
      data: { success, message, token, user },
      status,
    } = await axios.post("/api/auth/oauth-login", credentials);

    if (success) {
      // Store the user and token in localStorage
      localStorage.setItem("twmUser", JSON.stringify(user));
      localStorage.setItem("twmToken", token);
    }

    return { success, message, token, user, status };
  } catch (error) {
    // Handle errors and return structured response
    return {
      success: false,
      message:
        error.response?.data?.message ||
        error.message ||
        "An error occurred during OAuth login",
      status: error.response?.status || 500,
    };
  }
}
