import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { HiOutlineEye } from "react-icons/hi";
import { FaHeart } from "react-icons/fa";
import { addToCart } from "../../Hooks/UserHooks";
import { useDispatch } from "react-redux";
import { addCartItem } from "../../redux/features/cartSlice";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import { likeProduct, unlikeProduct } from "../../Hooks/productHooks";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

export default function ProductCard({ product }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // The logged-in user from localStorage
  const twmUser = JSON.parse(localStorage.getItem("twmUser"));
  const userId = twmUser?._id;

  // Initial check: did the user already like this product?
  const hasUserLikedInitially =
    userId && Array.isArray(product.likedUsers)
      ? product.likedUsers.some((user) => user._id === userId)
      : false;

  // Local state to track like status in the UI
  const [liked, setLiked] = useState(hasUserLikedInitially);

  // Like/Unlike handler
  const handleLikeToggle = async () => {
    if (!twmUser) {
      navigate("/login");
      return;
    }

    try {
      if (liked) {
        const response = await unlikeProduct(userId, product._id);
        if (response.success) {
          setLiked(false);
        } else {
          console.error("Unlike failed:", response.message);
        }
      } else {
        const response = await likeProduct(userId, product._id);
        if (response.success) {
          setLiked(true);
        } else {
          console.error("Like failed:", response.message);
        }
      }
    } catch (error) {
      console.error("Error toggling like:", error);
    }
  };

  // Add to cart handler
  const handleCartBTN = () => {
    if (!twmUser) {
      navigate("/login");
      return;
    }

    addToCart(twmUser._id, product._id, 1)
      .then((response) => {
        if (response.success) {
          dispatch(
            addCartItem({
              _id: product._id,
              productName: product.productName,
              firstImage: product.firstImage,
              quantity: 1,
              price: product.discountedPrice || product.regularPrice,
            })
          );
        } else {
          console.error("Failed to add item to cart:", response.message);
        }
      })
      .catch((error) => {
        console.error("Failed to add item to cart:", error);
      });
  };

  return (
    <div className="group w-[170px] sm:w-full p-4 border rounded-lg shadow-sm hover:shadow-lg transition-shadow duration-300">
      {/* Image Slider - Click to navigate */}
      <div className="relative cursor-pointer" onClick={() => navigate(`/product/${product._id}`)}>
        <Swiper
          modules={[Navigation, Pagination]}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          pagination={{ clickable: true }}
          className="rounded-lg relative"
        >
          {product.images.map((imgObj, index) => (
            <SwiperSlide key={index}>
              <img
                src={imgObj.image}
                alt={product.productName}
                className="w-full h-48 object-cover rounded-lg"
              />
            </SwiperSlide>
          ))}

          {/* Custom Navigation Buttons */}
          <div className="swiper-button-prev custom-nav"></div>
          <div className="swiper-button-next custom-nav"></div>
        </Swiper>
      </div>

        <div className="mt-4 flex items-center justify-between">
          <h3 className="text-sm text-gray-700">
            {product.productName.length < 30 ? product.productName : `${product.productName.substring(0, 27)}...`}
          </h3>
          <FaHeart
            size={24}
            className={`cursor-pointer transition-colors duration-300 ${liked ? "text-red-500" : "text-white"}`}
            onClick={handleLikeToggle}
            style={{ filter: liked ? "none" : "drop-shadow(0px 0px 1px black)" }}
          />
        </div>

        {/* Price */}
      <div className="flex items-center space-x-2 mt-2">
        <p className="text-lg font-medium text-gray-900">
          ${product.discountedPrice || product.regularPrice}
        </p>
      </div>

      {/* Buttons */}
      <div className="hidden sm:flex flex-col mt-4 gap-4">
        {/* View Details */}
        <Link
          to={`/product/${product._id}`}
          className="flex items-center px-4 py-2 text-sm font-medium text-white bg-black rounded-md hover:bg-[#262626]"
        >
          <HiOutlineEye className="mr-2" />
          View Details
        </Link>

        {/* Add to Cart */}
        <button
          onClick={handleCartBTN}
          className="flex items-center px-4 py-2 text-sm font-medium text-black border bg-white rounded-md hover:bg-gray"
        >
          Add to Cart
        </button>
      </div>
    </div>
  );
}
