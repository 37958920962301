import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
} from 'chart.js';

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement);

const SaleGraph = ({ data }) => {
  const [period, setPeriod] = useState('monthly');

  // Extracting data dynamically from backend response
  const periodData = {
    weekly: {
      labels: data.weeklyData.labels || [],
      data: data.weeklyData.data || [],
    },
    monthly: {
      labels: data.monthlyData.labels || [],
      data: data.monthlyData.data || [],
    },
    yearly: {
      labels: data.yearlyData.labels || [],
      data: data.yearlyData.data || [],
    },
  };

  // Prepare chart data
  const chartData = {
    labels: periodData[period].labels,
    datasets: [
      {
        label: 'Sales',
        data: periodData[period].data,
        borderColor: 'rgba(54, 162, 235, 1)',
        fill: false,
        tension: 0.4,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div className="bg-white shadow-md rounded-lg p-4">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-gray-700 text-lg font-semibold">Sale Graph</h2>
        <div className="flex space-x-2">
          <button
            className={`px-4 py-1 border rounded-lg text-sm ${
              period === 'weekly' ? 'bg-black text-white' : ''
            }`}
            onClick={() => setPeriod('weekly')}
          >
            WEEKLY
          </button>
          <button
            className={`px-4 py-1 border rounded-lg text-sm ${
              period === 'monthly' ? 'bg-black text-white' : ''
            }`}
            onClick={() => setPeriod('monthly')}
          >
            MONTHLY
          </button>
          <button
            className={`px-4 py-1 border rounded-lg text-sm ${
              period === 'yearly' ? 'bg-black text-white' : ''
            }`}
            onClick={() => setPeriod('yearly')}
          >
            YEARLY
          </button>
        </div>
      </div>
      <Line data={chartData} options={options} className="text-black" />
    </div>
  );
};

export default SaleGraph;
