import React, { useState } from 'react';
import { Link } from 'react-router-dom';

export default function SelectShipment() {
    const [selectedOption, setSelectedOption] = useState('');

    // Static shipment options (Delivery partners)
    const shipmentOptions = [
        { id: 1, name: 'FedEx', price: 20, image: 'https://via.placeholder.com/100' },
        { id: 2, name: 'Roadie', price: 15, image: 'https://via.placeholder.com/100' },
        { id: 3, name: 'UPS', price: 18, image: 'https://via.placeholder.com/100' }
    ];

    // Handle option selection
    const handleOptionChange = (option) => {
        setSelectedOption(option.name);
    };

    return (
        <div className="p-8 bg-white rounded-lg shadow-lg max-w-screen-lg mx-auto">
            <h2 className="text-3xl font-bold mb-8 text-black text-center">Choose Your Shipping Method</h2>

            {/* Shipment options displayed in horizontal cards */}
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-8">
                {shipmentOptions.map(option => (
                    <div
                        key={option.id}
                        className={`flex flex-col items-center justify-between bg-white shadow-md rounded-lg p-6 hover:shadow-xl transition-all duration-300 ${selectedOption === option.name ? 'border-2 border-black scale-105' : ''
                            }`}
                        onClick={() => handleOptionChange(option)}
                    >
                        {/* Shipping Partner Image */}
                        <img
                            src={option.image}
                            alt={option.name}
                            className="w-24 h-24 object-cover rounded-md mb-4"
                        />
                        {/* Shipping Partner Details */}
                        <h3 className="text-xl font-semibold text-black mb-2">{option.name}</h3>
                        <h3 className="text-2xl font-bold text-black mb-4">${option.price}</h3>

                        {/* Selection Indicator */}
                        <button
                            className={`w-full py-2 rounded-lg font-semibold ${selectedOption === option.name ? 'bg-black text-white' : 'bg-gray-100 text-gray-700'
                                } hover:bg-black hover:text-white transition-colors duration-300`}
                        >
                            {selectedOption === option.name ? 'Selected' : 'Select'}
                        </button>
                    </div>
                ))}
            </div>

            {/* Display shipment price */}
            {selectedOption && (
                <div className="bg-gray-100 p-6 rounded-lg shadow-md mt-10 text-center">
                    <h3 className="text-lg font-semibold text-black">Selected Shipment: {selectedOption}</h3>
                    <p className="text-gray-800 mt-2">
                        Shipment Price: <span className="text-black font-bold">${shipmentOptions.find(opt => opt.name === selectedOption).price}</span>
                    </p>
                </div>
            )}

            {/* Continue Button */}
            <div className="flex justify-center mt-8">
                <Link
                    to={'/cart'}
                    className="px-10 py-3 bg-black text-white text-lg font-semibold rounded-lg hover:bg-gray-800 transition-all duration-300"
                >
                    Continue
                </Link>
            </div>
        </div>
    );
}
