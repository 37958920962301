import React from 'react';

export default function Refund() {
  return (
    <div className="min-h-screen p-4">
      <div className="max-w-4xl mx-auto bg-white shadow-lg rounded-lg p-6">
        <h1 className="text-3xl font-bold mb-6 text-gray-800">Refunds and Returns</h1>
        
        <p className="mb-4 text-lg text-gray-700">
          The Wedding Marketplace is an online platform dedicated to all things wedding-related, offering a wide range of products for buyers and sellers. Our platform ensures that all return and refund requests are handled in accordance with e-commerce regulations and our Terms of Service (TOS) and policies.
        </p>
        
        <p className="mb-4 text-lg text-gray-700">
          Our Return and Refund policy is designed to protect the interests of both buyers and sellers. Sellers are encouraged to provide accurate descriptions in their listings to ensure transparency for buyers. If a buyer feels that the purchased item significantly deviates from the listing description, they can request a return. Each return request is carefully reviewed by The Wedding Marketplace to determine if the item is indeed not as described.
        </p>
        
        <p className="mb-4 text-lg text-gray-700">
          Buyers are advised to inquire about any concerns regarding items such as odors, pet dander, or cosmetic imperfections before making a purchase. Minor imperfections that do not affect the functionality of the item, such as scratches on packaging, are generally not grounds for a return or refund.
        </p>
        
        <p className="mb-4 text-lg text-gray-700">
          To ensure a smooth process and prompt payment for our sellers, The Wedding Marketplace automatically rates transactions within 72 hours of confirmed delivery by the carrier. It is important for buyers to initiate return requests through the designated channels before a transaction is completed. Once a transaction is finalized, returns or refunds are not permitted.
        </p>
        
        <p className="mb-4 text-lg text-gray-700">
          If a return request is approved, The Wedding Marketplace provides the buyer with a pre-paid return shipping label for items up to 50 pounds. Buyers must return the item within 72 hours of receiving the label. For items over 50 pounds, sellers are responsible for providing their own shipping labels. The weight of the item is determined by the greater of the actual weight or the dimensional weight of the package.
        </p>
        
        <p className="mb-4 text-lg text-gray-700">
          Returns must be initiated within 72 hours of delivery and before the transaction is marked as completed. Eligible reasons for returns include significant discrepancies in the item description, shipping damage, or authenticity concerns. Buyers are required to provide clear photos highlighting the differences from the listing and additional documentation upon request.
        </p>
        
        <p className="mb-4 text-lg text-gray-700">
          Returned items must be unworn, unwashed, and in their original condition with all tags attached. Certain items like swimwear, lingerie, and opened beauty products are typically non-returnable for hygiene reasons. Approved returns will be refunded to the original form of payment within 14 days of the item being returned to the seller.
        </p>
        
        <p className="mb-4 text-lg text-gray-700">
          The Wedding Marketplace values the trust of our users and takes all return issues seriously. Any abuse of the return process may result in account restrictions or further actions at our discretion.
        </p>
        
        <p className="mb-4 text-lg text-gray-700">
          We strive to provide a seamless and secure marketplace for all your wedding needs at The Wedding Marketplace.
        </p>
        
        <p className="text-gray-700 font-semibold">
          Last Updated: 2/28/2025
        </p>
      </div>
    </div>
  );
}
