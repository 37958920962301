import React from 'react';

const additionalInfoHtml =`
<p>
  "Discover the epitome of luxury with the Louis Vuitton Brown Monogram LV Record Christopher Macassar MM Backpack. Crafted from premium brown monogram-coated canvas, this backpack features textured calfskin trim. The design includes a sophisticated flap top with belt closures and a practical cinch cord closure, ensuring your belongings are secure. Stay organized with two external front flap pockets, perfect for quick access items, and enjoy the comfort of adjustable shoulder straps for a personalized fit. Inside, the brown fabric interior boasts a convenient zipper pocket, providing a seamless blend of style and functionality. Elevate your everyday look with this timeless piece from Louis Vuitton."
</p>

<ul>
  <li>Monogram brown coated canvas</li>
  <li>Calfskin trim</li>
  <li>Silver hardware</li>
  <li>Flap top</li>
  <li>Buckle closures</li>
  <li>A cinch cord closure</li>
  <li>Two external front flap pockets</li>
  <li>Three interior pockets</li>
  <li>Adjustable shoulder straps</li>
  <li>Brown fabric interior with a zipper pocket</li>
</ul>

<p>
  Dimensions: 15.5 x 18.2 x 5 inches (WHD)
</p>

`

const AdditionalInfo = ({ description }) => {
  return (
    <div>
      <h2 className="text-xl font-semibold mb-4">Product Description</h2>

      <div dangerouslySetInnerHTML={{ __html: description }} />
    </div>
  );
};

export default AdditionalInfo;
