import React, { useState, useEffect } from "react";
import {
  getAllCategories,
  addRootCategory,
  addSubCategory,
  changeCategoryOrder,
  deleteMainCategory,
  updateCategoryName,
  updateSubcategoryOrder,
  deleteSubcategory,
  updateSubcategoryName,
} from "../../Hooks/categoryHooks";

const CategoryItem = ({
  category,
  handleEdit,
  handleDelete,
  handleMoveUp,
  handleMoveDown,
}) => {
  return (
    <div className="border border-gray-300 p-3 my-1 flex justify-between items-center">
      <span>{category.name}</span>
      <div className="flex space-x-3">
        <button onClick={handleEdit} className="text-black">
          ✏️
        </button>
        <button onClick={handleDelete} className="text-black">
          🗑️
        </button>
        <button onClick={handleMoveUp} className="text-black">
          ⬆️
        </button>
        <button onClick={handleMoveDown} className="text-black">
          ⬇️
        </button>
      </div>
    </div>
  );
};

export default function Questionares() {
  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState("");
  const [parentCategory, setParentCategory] = useState("");
  const [editingCategory, setEditingCategory] = useState(null);
  const [editedCategoryName, setEditedCategoryName] = useState("");
  const [editingSubcategory, setEditingSubcategory] = useState(null);
  const [editedSubcategoryName, setEditedSubcategoryName] = useState("");

  // Fetch all categories on load
  useEffect(() => {
    const fetchCategories = async () => {
      const result = await getAllCategories();
      if (result.success) {
        setCategories(result.categories);
        console.log(result.categories);
      } else {
        console.error("Failed to fetch categories:", result.message);
      }
    };

    fetchCategories();
  }, []);

  const addCategory = async () => {
    if (newCategory.trim() === "") return;

    if (!parentCategory) {
      // Adding a root category
      try {
        const order = categories.length + 1; // Assign order value based on the length of the current categories list
        const response = await addRootCategory({ name: newCategory, order });
        if (response.success) {
          setCategories([...categories, response.category]);
          setNewCategory("");
          setParentCategory("");
        } else {
          console.error("Failed to add root category:", response.message);
        }
      } catch (error) {
        console.error("Error adding root category:", error);
      }
    } else {
      // Adding a subcategory
      try {
        const parent = categories.find((cat) => cat.name === parentCategory);
        if (parent) {
          const response = await addSubCategory({
            categoryId: parent._id,
            subcategoryName: newCategory,
          });

          if (response.success) {
            const updatedCategories = categories.map((cat) =>
              cat._id === parent._id ? response.category : cat
            );
            setCategories(updatedCategories);
            setNewCategory("");
            setParentCategory("");
          } else {
            console.error("Failed to add subcategory:", response.message);
          }
        }
      } catch (error) {
        console.error("Error adding subcategory:", error);
      }
    }
  };

  const deleteCategory = async (index, categoryId) => {
    try {
      const response = await deleteMainCategory(categoryId);
      if (response.success) {
        const newCategories = categories.filter((_, i) => i !== index);
        setCategories(newCategories);
      } else {
        console.error("Failed to delete category:", response.message);
      }
    } catch (error) {
      console.error("Error deleting category:", error);
    }
  };

  const deleteSubCategory = async (parentIndex, subcategoryId, subIndex) => {
    try {
      const parentCategory = categories[parentIndex];
      const response = await deleteSubcategory({
        categoryId: parentCategory._id,
        subcategoryId,
      });

      if (response.success) {
        const updatedCategories = [...categories];
        updatedCategories[parentIndex].subcategories = updatedCategories[
          parentIndex
        ].subcategories.filter((_, i) => i !== subIndex);
        setCategories(updatedCategories);
      } else {
        console.error("Failed to delete subcategory:", response.message);
      }
    } catch (error) {
      console.error("Error deleting subcategory:", error);
    }
  };

  const moveCategoryUp = async (index) => {
    if (index === 0) return;

    const newCategories = [...categories];
    const currentCategory = newCategories[index];
    const previousCategory = newCategories[index - 1];

    // Swap categories in the local state
    newCategories[index - 1] = currentCategory;
    newCategories[index] = previousCategory;
    setCategories(newCategories);

    // Update the order in the backend
    try {
      await changeCategoryOrder({
        categoryId: currentCategory._id,
        newOrder: index,
      });
      await changeCategoryOrder({
        categoryId: previousCategory._id,
        newOrder: index + 1,
      });
    } catch (error) {
      console.error("Error changing category order:", error);
    }
  };

  const moveCategoryDown = async (index) => {
    if (index === categories.length - 1) return;

    const newCategories = [...categories];
    const currentCategory = newCategories[index];
    const nextCategory = newCategories[index + 1];

    // Swap categories in the local state
    newCategories[index + 1] = currentCategory;
    newCategories[index] = nextCategory;
    setCategories(newCategories);

    // Update the order in the backend
    try {
      await changeCategoryOrder({
        categoryId: currentCategory._id,
        newOrder: index + 2,
      });
      await changeCategoryOrder({
        categoryId: nextCategory._id,
        newOrder: index + 1,
      });
    } catch (error) {
      console.error("Error changing category order:", error);
    }
  };

  const handleEditCategory = (category) => {
    setEditingCategory(category);
    setEditedCategoryName(category.name);
  };

  const handleUpdateCategory = async () => {
    if (!editingCategory || editedCategoryName.trim() === "") return;

    try {
      const response = await updateCategoryName({
        categoryId: editingCategory._id,
        newName: editedCategoryName,
      });

      if (response.success) {
        const updatedCategories = categories.map((category) =>
          category._id === editingCategory._id
            ? { ...category, name: editedCategoryName }
            : category
        );
        setCategories(updatedCategories);
        setEditingCategory(null);
        setEditedCategoryName("");
      } else {
        console.error("Failed to update category name:", response.message);
      }
    } catch (error) {
      console.error("Error updating category name:", error);
    }
  };

  const handleEditSubcategory = (parentCategory, subcategory) => {
    setEditingSubcategory({ parentCategory, subcategory });
    setEditedSubcategoryName(subcategory.name);
  };

  const handleUpdateSubcategory = async () => {
    if (!editingSubcategory || editedSubcategoryName.trim() === "") return;

    try {
      const { parentCategory, subcategory } = editingSubcategory;
      const response = await updateSubcategoryName({
        categoryId: parentCategory._id,
        subcategoryId: subcategory._id,
        newName: editedSubcategoryName,
      });

      if (response.success) {
        const updatedCategories = categories.map((category) =>
          category._id === parentCategory._id
            ? {
                ...category,
                subcategories: category.subcategories.map((sub) =>
                  sub._id === subcategory._id
                    ? { ...sub, name: editedSubcategoryName }
                    : sub
                ),
              }
            : category
        );
        setCategories(updatedCategories);
        setEditingSubcategory(null);
        setEditedSubcategoryName("");
      } else {
        console.error("Failed to update subcategory name:", response.message);
      }
    } catch (error) {
      console.error("Error updating subcategory name:", error);
    }
  };

  const moveSubcategoryUp = async (parentIndex, subIndex) => {
    if (subIndex === 0) return;

    const newCategories = [...categories];
    const parentCategory = newCategories[parentIndex];
    const currentSubcategory = parentCategory.subcategories[subIndex];
    const previousSubcategory = parentCategory.subcategories[subIndex - 1];

    // Swap subcategories in the local state
    parentCategory.subcategories[subIndex - 1] = currentSubcategory;
    parentCategory.subcategories[subIndex] = previousSubcategory;
    setCategories(newCategories);

    // Update the order in the backend
    try {
      await updateSubcategoryOrder({
        categoryId: parentCategory._id,
        subcategoryId: currentSubcategory._id,
        newOrder: subIndex,
      });
    } catch (error) {
      console.error("Error changing subcategory order:", error);
    }
  };

  const moveSubcategoryDown = async (parentIndex, subIndex) => {
    const newCategories = [...categories];
    const parentCategory = newCategories[parentIndex];

    if (subIndex >= parentCategory.subcategories.length - 1) return;

    const currentSubcategory = parentCategory.subcategories[subIndex];
    const nextSubcategory = parentCategory.subcategories[subIndex + 1];

    // Swap subcategories in the local state
    parentCategory.subcategories[subIndex + 1] = currentSubcategory;
    parentCategory.subcategories[subIndex] = nextSubcategory;
    setCategories(newCategories);

    // Update the order in the backend
    try {
      await updateSubcategoryOrder({
        categoryId: parentCategory._id,
        subcategoryId: currentSubcategory._id,
        newOrder: subIndex + 2,
      });
    } catch (error) {
      console.error("Error changing subcategory order:", error);
    }
  };

  return (
    <div className="flex">
      {/* Category List */}
      <div className="w-1/2 p-4 border-r">
        <h2 className="text-2xl mb-4">Categories</h2>
        <div className="mb-4">
          {categories.map((category, index) => (
            <div key={index}>
              <CategoryItem
                category={category}
                handleEdit={() => handleEditCategory(category)}
                handleDelete={() => deleteCategory(index, category._id)}
                handleMoveUp={() => moveCategoryUp(index)}
                handleMoveDown={() => moveCategoryDown(index)}
              />
              {/* Subcategories */}
              {category.subcategories &&
                category.subcategories.map((sub, subIndex) => (
                  <div key={subIndex} className="ml-6">
                    <CategoryItem
                      category={sub}
                      handleEdit={() =>
                        handleEditSubcategory(category, sub)
                      }
                      handleDelete={() =>
                        deleteSubCategory(index, sub._id, subIndex)
                      }
                      handleMoveUp={() => moveSubcategoryUp(index, subIndex)}
                      handleMoveDown={() =>
                        moveSubcategoryDown(index, subIndex)
                      }
                    />
                  </div>
                ))}
            </div>
          ))}
        </div>
      </div>

      {/* Category Form */}
      <div className="w-1/2 p-4">
        <h2 className="text-2xl mb-4">New Category</h2>
        <div className="mb-4">
          <label className="block text-black">New Category</label>
          <input
            type="text"
            value={newCategory}
            onChange={(e) => setNewCategory(e.target.value)}
            className="border border-black p-2 w-full"
          />
        </div>
        <div className="mb-4">
          <label className="block text-black">Parent Category</label>
          <select
            value={parentCategory}
            onChange={(e) => setParentCategory(e.target.value)}
            className="border border-black p-2 w-full bg-white"
          >
            <option value="">None</option>
            {categories.map((category, index) => (
              <option key={index} value={category.name}>
                {category.name}
              </option>
            ))}
          </select>
        </div>
        <button onClick={addCategory} className="bg-black text-white p-2 mt-4">
          Add Category
        </button>
      </div>

      {/* Edit Category Modal */}
      {editingCategory && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg shadow-lg w-1/3">
            <h2 className="text-2xl mb-4">Edit Category</h2>
            <input
              type="text"
              value={editedCategoryName}
              onChange={(e) => setEditedCategoryName(e.target.value)}
              className="border border-black p-2 w-full mb-4"
            />
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setEditingCategory(null)}
                className="bg-gray-300 text-black p-2 rounded-md"
              >
                Cancel
              </button>
              <button
                onClick={handleUpdateCategory}
                className="bg-black text-white p-2 rounded-md"
              >
                Update
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Edit Subcategory Modal */}
      {editingSubcategory && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg shadow-lg w-1/3">
            <h2 className="text-2xl mb-4">Edit Subcategory</h2>
            <input
              type="text"
              value={editedSubcategoryName}
              onChange={(e) => setEditedSubcategoryName(e.target.value)}
              className="border border-black p-2 w-full mb-4"
            />
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setEditingSubcategory(null)}
                className="bg-gray-300 text-black p-2 rounded-md"
              >
                Cancel
              </button>
              <button
                onClick={handleUpdateSubcategory}
                className="bg-black text-white p-2 rounded-md"
              >
                Update
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
