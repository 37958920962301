import React, { useState, useEffect } from "react";
import { useTable } from "react-table";
import { getRefunds, approveRefund, rejectRefund } from "../../Hooks/refundHooks";
import { toast } from "react-toastify";

export default function Refund() {
  const [refunds, setRefunds] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRefund, setSelectedRefund] = useState(null);

  useEffect(() => {
    const fetchRefunds = async () => {
      try {
        setLoading(true);
        const response = await getRefunds();
        if (response.success) {
          setRefunds(response.refunds);
        } else {
          setError(response.message);
        }
      } catch (err) {
        setError("An unexpected error occurred.");
      } finally {
        setLoading(false);
      }
    };

    fetchRefunds();
  }, []);

  const handleApprove = async (refundId) => {
    const response = await approveRefund(refundId);
    if (response.success) {
      toast.success("Refund Approved!");
      
    } else {
      alert(response.message);
    }
  };

  const handleReject = async (refundId) => {
    const response = await rejectRefund(refundId);
    if (response.success) {
        toast.success("Refund Rejected!");
        setRefunds((prevRefunds) =>
        prevRefunds.filter((refund) => refund._id !== refundId)
      );
    } else {
      alert(response.message);
    }
  };

  const handleViewDetails = (refund) => {
    setSelectedRefund(refund);
    setIsModalOpen(true);
  };

  // Table columns
  const columns = React.useMemo(
    () => [
      {
        Header: "Refund ID",
        accessor: "_id",
      },
      {
        Header: "Refund Status",
        accessor: "refundStatus",
      },
      {
        Header: "Amount",
        accessor: "refundAmount",
      },
      {
        Header: "Reason",
        accessor: "refundReason",
      },
      {
        Header: "Actions",
        Cell: ({ row }) => (
          <div className="flex gap-2">
            <button
              className="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-500"
              onClick={() => handleApprove(row.original._id)}
            >
              Approve
            </button>
            <button
              className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-500"
              onClick={() => handleReject(row.original._id)}
            >
              Reject
            </button>
            <button
              className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-500"
              onClick={() => handleViewDetails(row.original)}
            >
              View Details
            </button>
          </div>
        ),
      },
    ],
    []
  );

  const data = React.useMemo(() => refunds, [refunds]);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="text-lg font-semibold">Loading...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="text-red-500 text-lg font-semibold">{error}</div>
      </div>
    );
  }

  return (
    <div className="p-6 mx-auto bg-white overflow-x-auto">
      <h2 className="text-3xl font-bold mb-8 text-center text-gray-800">
        Refunds
      </h2>

      {/* Table */}
      <table
        {...getTableProps()}
        className="min-w-full border-collapse border border-gray-300"
      >
        <thead className="bg-gray-100">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  className="border border-gray-300 px-4 py-2 text-left text-gray-800 font-medium"
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                className="border-b border-gray-300 hover:bg-gray-100"
              >
                {row.cells.map((cell) => (
                  <td
                    {...cell.getCellProps()}
                    className="border border-gray-300 px-4 py-2 text-gray-600"
                  >
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* Modal */}
      {isModalOpen && selectedRefund && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white rounded-xl shadow-xl p-6 max-w-3xl w-full relative">
            {/* Close button at top-right */}
            <button
              onClick={() => setIsModalOpen(false)}
              className="absolute top-3 right-3 text-gray-400 hover:text-gray-600 text-2xl font-bold"
            >
              &times;
            </button>

            {/* Modal Header */}
            <div className="mb-6">
              <h3 className="text-2xl font-bold text-gray-800">Refund Details</h3>
              <p className="text-sm text-gray-500">
                Refund information &amp; associated order details
              </p>
            </div>

            {/* Basic refund info (grid) */}
            <div className="grid grid-cols-2 gap-x-4 gap-y-2 mb-6">
              <div>
                <strong>Refund ID:</strong>
                <div>{selectedRefund._id}</div>
              </div>
              <div>
                <strong>Status:</strong>
                <div>{selectedRefund.refundStatus}</div>
              </div>
              <div>
                <strong>Amount:</strong>
                <div>${selectedRefund.refundAmount}</div>
              </div>
              <div>
                <strong>Reason:</strong>
                <div>{selectedRefund.refundReason}</div>
              </div>
              <div>
                <strong>Date:</strong>
                <div>
                  {new Date(selectedRefund.refundDate).toLocaleDateString()}
                </div>
              </div>
            </div>

            {/* Vendor & User info side by side (flex) */}
            <div className="flex gap-8 mb-6">
              {/* Vendor Info */}
              {selectedRefund.orderId?.vendorId && (
                <div className="flex-1">
                  <h4 className="text-lg font-semibold mb-2">Vendor Info</h4>
                  <div className="mb-1">
                    <strong>Name:</strong> {selectedRefund.orderId.vendorId.name}
                  </div>
                  <div>
                    <strong>Email:</strong>{" "}
                    <a
                      href={`mailto:${selectedRefund.orderId.vendorId.email}`}
                      className="text-blue-600 underline"
                    >
                      {selectedRefund.orderId.vendorId.email}
                    </a>
                  </div>
                </div>
              )}

              {/* User Info */}
              {selectedRefund.orderId?.userId && (
                <div className="flex-1">
                  <h4 className="text-lg font-semibold mb-2">User Info</h4>
                  <div className="mb-1">
                    <strong>Name:</strong> {selectedRefund.orderId.userId.name}
                  </div>
                  <div>
                    <strong>Email:</strong>{" "}
                    <a
                      href={`mailto:${selectedRefund.orderId.userId.email}`}
                      className="text-blue-600 underline"
                    >
                      {selectedRefund.orderId.userId.email}
                    </a>
                  </div>
                </div>
              )}
            </div>

            {/* Product info */}
            {selectedRefund.orderId?.items && (
              <div className="mb-6">
                <h4 className="text-lg font-semibold mb-2">Product(s)</h4>
                <ul className="list-disc list-inside pl-4">
                  {selectedRefund.orderId.items.map((item, idx) => (
                    <li key={idx}>
                      {item.productId?.productName || "Unnamed Product"}
                    </li>
                  ))}
                </ul>
              </div>
            )}

            {/* Images */}
            {selectedRefund.images && selectedRefund.images.length > 0 && (
              <div className="mb-6">
                <h4 className="text-lg font-semibold mb-2">Images</h4>
                <div className="grid grid-cols-2 gap-2">
                  {selectedRefund.images.map((image, index) => (
                    <div key={index} className="relative border rounded-md">
                      <img
                        src={image}
                        alt={`Refund Image ${index + 1}`}
                        className="w-full h-32 object-cover rounded-md"
                      />
                      <a
                        href={image}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="absolute top-2 right-2 text-gray-800 hover:text-gray-600 bg-white bg-opacity-70 rounded-full px-2 py-1 text-sm"
                      >
                        View
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {/* Close button at bottom */}
            <div className="flex justify-end">
              <button
                onClick={() => setIsModalOpen(false)}
                className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
