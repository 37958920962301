// ===========================
// Payouts.jsx
// ===========================
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  getPayoutInfo,
  getAccountIdsBool,
  addStripeAccount,
  addBankAccount,
  addPayPalId,
  payoutToStripeAccount,
  payoutToBankAccount,
  payoutToPayPal,
} from "../../Hooks/UserHooks";

import PayoutOptionsModal from "../../components/Modals/PayoutOptionsModal";
import PayoutInfoModal from "../../components/Modals/PayoutInfoModal";

export default function Payouts() {
  const [payouts, setPayouts] = useState({
    payoutBalance: 0,
    payoutOrders: [],
    lastPayouts: [],
    lastPayoutOrders: [],
  });

  const [isPayoutModalOpen, setIsPayoutModalOpen] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false); // State for the second modal
  const [selectedMethod, setSelectedMethod] = useState(""); // State to track selected method

  const [isStripeAccountId, setIsStripeAccountId] = useState(false);
  const [isPaypalAccountId, setIsPaypalAccountId] = useState(false);
  const [isBankAccountId, setIsBankAccountId] = useState(false);
  const [isStripeIdForBank, setIsStripeIdForBank] = useState(false);

  useEffect(() => {
    const fetchPayouts = async () => {
      const response = await getPayoutInfo();
      if (response.success) {
        console.log(response.payoutInfo);
        setPayouts(response.payoutInfo);
      } else {
        toast.error(response.message);
      }
    };
    fetchPayouts();
  }, []);

  useEffect(() => {
    const fetchAccountIds = async () => {
      const response = await getAccountIdsBool();
      if (response.success) {
        console.log(response);
        setIsStripeAccountId(response.isStripeAccountID);
        setIsPaypalAccountId(response.isPayPalID);
        setIsBankAccountId(response.isStripeBankAccountID);
        setIsStripeIdForBank(response.isStripeAccountIDForBank);
      } else {
        toast.error(response.message);
      }
    };
    fetchAccountIds();
  }, []);

  const handleRequestPayout = () => {
    if (payouts.payoutBalance > 0) {
      setIsPayoutModalOpen(true); // Open modal
    } else {
      toast.error("Payout balance is insufficient to request a payout.");
    }
  };

  const handleInfoSubmit = async (info) => {
    setIsInfoModalOpen(false); // Close the second modal
    console.log("Payout info:", info);
    console.log("Selected method:", selectedMethod);

    if (selectedMethod === "Stripe") {
      try {
        const response = await addStripeAccount({ email: info.email });

        if (response.success) {
          const accountId = response.accountId;
          localStorage.setItem("stripeAccountId", accountId);
          window.location.href = response.onboardingLink;

          toast.success("Stripe account added successfully!");
          console.log("Stripe account response:", response);
          setIsStripeAccountId(true); // Update the account ID status
        } else {
          toast.error(response.message || "Failed to add Stripe account.");
        }
      } catch (error) {
        toast.error("An error occurred while adding Stripe account.");
        console.error(error);
      }
    }

    if (selectedMethod === "Bank") {
      console.log("Bank account info:", info.bankDetails);
      const response = await addBankAccount(info.bankDetails);
      if (response.success) {
        toast.success("Bank account added successfully!");
        console.log("Bank account response:", response);
        setIsBankAccountId(true); // Update the account ID status
      } else {
        toast.error(response.message || "Failed to add Bank account.");
      }
    }

    if (selectedMethod === "PayPal") {
      try {
        const response = await addPayPalId(info.email);
        if (response.success) {
          toast.success("PayPal account added successfully!");
          console.log("PayPal account response:", response);
          setIsPaypalAccountId(true); // Update the account ID status
        } else {
          toast.error(response.message || "Failed to add PayPal account.");
        }
      } catch (error) {
        toast.error("An error occurred while adding PayPal account.");
        console.error(error);
      }
    }
  };

  // ===========================
  // UPDATED: Call the correct payout function if ID is present
  // ===========================
  const handlePayoutMethod = async (method) => {
    setSelectedMethod(method); // Store the selected method
    setIsPayoutModalOpen(false); // Close the first modal

    switch (method) {
      case "Stripe":
        if (isStripeAccountId) {
          // Call the payout to Stripe function
          try {
            const response = await payoutToStripeAccount((payouts.payoutBalance * 100));
            if (response.success) {
              toast.success(
                `Payout of $${payouts.payoutBalance} requested via Stripe.`
              );
              console.log(
                `Payout requested via Stripe for $${payouts.payoutBalance}`
              );
            } else {
              toast.error(response.message || "Failed to request payout via Stripe.");
            }
          } catch (error) {
            toast.error("An error occurred while requesting Stripe payout.");
            console.error(error);
          }
        } else {
          setIsInfoModalOpen(true); // Open the second modal if invalid
        }
        break;

      case "Bank":
        if (isBankAccountId && isStripeIdForBank) {
          // Call the payout to Bank function
          try {
            const response = await payoutToBankAccount((payouts.payoutBalance * 100));
            if (response.success) {
              toast.success(
                `Payout of $${payouts.payoutBalance} requested via Bank.`
              );
              console.log(
                `Payout requested via Bank for $${payouts.payoutBalance}`
              );
            } else {
              toast.error(response.message || "Failed to request payout via Bank.");
            }
          } catch (error) {
            toast.error("An error occurred while requesting Bank payout.");
            console.error(error);
          }
        } else {
          setIsInfoModalOpen(true); // Open the second modal if invalid
        }
        break;

      case "PayPal":
        if (isPaypalAccountId) {
          // Call the payout to PayPal function
          try {
            const response = await payoutToPayPal(payouts.payoutBalance);
            if (response.success) {
              toast.success(
                `Payout of $${payouts.payoutBalance} requested via PayPal.`
              );
              console.log(
                `Payout requested via PayPal for $${payouts.payoutBalance}`
              );
            } else {
              toast.error(response.message || "Failed to request payout via PayPal.");
            }
          } catch (error) {
            toast.error("An error occurred while requesting PayPal payout.");
            console.error(error);
          }
        } else {
          setIsInfoModalOpen(true); // Open the second modal if invalid
        }
        break;

      default:
        toast.error("Invalid payout method selected.");
    }
  };

  return (
    <div className="container mx-auto p-5">
      {/* Header */}
      <div className="flex items-center justify-between mb-5">
        <h1 className="text-3xl font-bold text-gray-800">Payout Details</h1>
        {/* Request Payout Button */}
        <button
          onClick={handleRequestPayout}
          className="bg-[#1F2937] text-white font-semibold px-4 py-2 rounded-md hover:bg-[#1a232f] transition duration-300"
        >
          Request Payout (${payouts.payoutBalance})
        </button>
      </div>

      {/* Current Payout Balance */}
      <div className="bg-blue-50 border-l-4 border-blue-500 p-4 mb-6 rounded-md">
        <p className="text-lg font-semibold text-blue-700">
          Current Payout Balance:{" "}
          <span className="text-blue-900 text-xl">
            ${payouts.payoutBalance}
          </span>
        </p>
      </div>

      {/* Current Payout Orders */}
      <div>
        <h2 className="text-2xl font-semibold mb-4 text-gray-700">
          Current Payout Orders
        </h2>
        {payouts?.payoutOrders?.length > 0 ? (
          payouts.payoutOrders.map((order) => (
            <div
              key={order.orderId}
              className="border rounded-lg p-4 shadow-sm mb-5"
            >
              <p className="text-sm text-gray-600 mb-2">
                Order ID: <span className="font-mono">{order.orderId}</span>
              </p>
              <p className="text-sm text-gray-600 mb-2">
                Date:{" "}
                <span className="font-semibold">
                  {new Date(order.date).toLocaleString()}
                </span>
              </p>
              {/* Product Details */}
              <h3 className="text-lg font-medium mt-3 mb-2">Products:</h3>
              {order?.products?.length > 0 ? (
                order.products.map((product, index) => (
                  <div
                    key={index}
                    className="flex justify-between border-b py-2"
                  >
                    <p>{product.productName}</p>
                    <span>Quantity: {product.quantity}</span>
                  </div>
                ))
              ) : (
                <p className="text-gray-500">No products in this order.</p>
              )}
            </div>
          ))
        ) : (
          <p className="text-gray-500">No current payout orders available.</p>
        )}
      </div>

      {/* Previous Payouts & Orders */}
      <div className="mt-8">
        <h2 className="text-2xl font-semibold mb-4 text-gray-700">
          Previous Payouts & Orders
        </h2>

        {payouts?.lastPayoutOrders?.length > 0 ? (
          payouts.lastPayoutOrders.map((payout, index) => (
            <div key={index} className="border rounded-lg p-5 shadow-sm mb-6">
              {/* Payout Date */}
              <div className="bg-green-50 border-l-4 border-green-500 p-3 mb-4 rounded-md">
                <p className="text-lg text-green-700 font-medium">
                  Payout #{index + 1} - Date:{" "}
                  <span className="text-green-900 font-semibold">
                    {new Date(payout.payoutDate).toLocaleString()}
                  </span>
                </p>
              </div>

              {/* Orders for this Payout */}
              {payout?.orders?.length > 0 ? (
                payout.orders.map((order) => (
                  <div
                    key={order._id}
                    className="border rounded-md p-4 mb-3 shadow-sm"
                  >
                    <p className="text-gray-600 mb-2">
                      Order ID: <span className="font-mono">{order._id}</span>
                    </p>
                    <p className="text-gray-600 mb-2">
                      Order Date:{" "}
                      <span className="font-semibold">
                        {new Date(order.date).toLocaleString()}
                      </span>
                    </p>

                    {/* Product Details */}
                    <div>
                      <h4 className="text-md font-semibold mb-2">Products:</h4>
                      {order?.products?.length > 0 ? (
                        order.products.map((product, idx) => (
                          <div
                            key={idx}
                            className="flex justify-between border-b py-2"
                          >
                            <p className="text-gray-800">
                              {product.productName}
                            </p>
                            <span className="text-gray-600">
                              Quantity: {product.quantity}
                            </span>
                          </div>
                        ))
                      ) : (
                        <p className="text-gray-500">
                          No products in this order.
                        </p>
                      )}
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-gray-500">No orders for this payout.</p>
              )}
            </div>
          )).reverse()
        ) : (
          <p className="text-gray-500">No previous payouts available.</p>
        )}
      </div>

      {/* Modals */}
      <PayoutOptionsModal
        isOpen={isPayoutModalOpen}
        onRequestClose={() => setIsPayoutModalOpen(false)}
        onSelectPayoutMethod={handlePayoutMethod}
      />

      <PayoutInfoModal
        isOpen={isInfoModalOpen}
        onRequestClose={() => setIsInfoModalOpen(false)}
        method={selectedMethod}
        onSubmit={handleInfoSubmit}
      />
    </div>
  );
}
