import { initializeApp } from "firebase/app";
import { getAuth, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider, OAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCKSzeq2Zvwq9vckY8jv_9yEFHuIFfvH4M",
  authDomain: "theweddingmarketplace-90ae8.firebaseapp.com",
  projectId: "theweddingmarketplace-90ae8",
  storageBucket: "theweddingmarketplace-90ae8.firebasestorage.app",
  messagingSenderId: "231014927275",
  appId: "1:231014927275:web:dfbcd5704fa099996520ee",
  measurementId: "G-N8FEYHX0X7"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
export const facebookProvider = new FacebookAuthProvider();
export const appleProvider = new OAuthProvider("apple.com"); // Add this line


