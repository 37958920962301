import axios from "axios";

axios.defaults.baseURL =
  process.env.REACT_APP_BACKEND_URL || "http://localhost:5000";

export async function addQuestion({
  productId,
  question,
  guestName = null,
  createdBy = null,
}) {
  try {
    const token = localStorage.getItem("twmToken");

    const headers = token
      ? {
          "TWM-auth-token": token,
        }
      : {};

    const { data, status } = await axios.post(
      `/api/faqs/add-question`,
      {
        productId,
        question,
        guestName,
        createdBy,
      },
      { headers }
    );

    return {
      success: data.success,
      message: data.message,
      status,
      question: data.question || null,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}

export async function replyToQuestion({ questionId, answer }) {
  try {
    // Validate input
    if (!questionId || !answer) {
      throw new Error("Question ID and answer are required.");
    }

    const token = localStorage.getItem("twmToken");

    // Set up headers
    const headers = {
      "TWM-auth-token": token,
    };

    // Make the API request
    const { data, status } = await axios.post(
      `/api/faqs/reply-to-question`,
      {
        questionId,
        answer,
      },
      { headers }
    );

    return {
      success: data.success,
      message: data.message,
      status,
      question: data.question || null,
    };
  } catch (error) {
    return {
      success: false,
      message:
        error.response?.data?.message || error.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}

export async function getPaginatedFAQs(productId, page = 1, limit = 10) {
  if (page <= 0 || limit <= 0) {
    return {
      success: false,
      message: "Page and limit must be positive integers.",
      status: 400,
    };
  }

  try {
    const { data, status } = await axios.post(
      `/api/faqs/get-paginated-faqs/${productId}`,
      { page, limit }
    );

    return {
      success: data.success,
      message: data.message,
      status,
      data: data.data || {},
    };
  } catch (error) {
    return {
      success: false,
      message:
        error.response?.data?.message || error.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}

export async function getQuestionsForAdmin(userId, page = 1, limit = 10) {
  try {
    const token = localStorage.getItem("twmToken"); 
    const user = localStorage.getItem("twmUser")

    if (!token) {
      return {
        success: false,
        message: "Please login first",
        status: 401,
      };
    }

    const { data, status } = await axios.get(`/api/faqs/get-questions-for-admin`, {
      params: { page, limit },
      headers: {
        "TWM-auth-token": token,
      },
    });

    return {
      success: data.success,
      message: data.message,
      status,
      data: {
        totalFAQs: data.totalFAQs,
        totalPages: data.totalPages,
        currentPage: data.currentPage,
        faqs: data.faqs || [],
      },
    };
  } catch (error) {
    return {
      success: false,
      message:
        error.response?.data?.message || error.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}
