import React, { useState } from 'react';
import Modal from 'react-modal';
import { makeOffer } from '../../Hooks/offerHooks'; // Import your makeOffer function
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Required for accessibility
Modal.setAppElement('#root');

const OfferModal = ({ isOpen, onClose, price, productId, isCounterOffer, offerId }) => {
  const [formData, setFormData] = useState({
    percentage: '',
    customPrice: '',
  });
  const [loading, setLoading] = useState(false);

  const user = JSON.parse(localStorage.getItem("twmUser")); // Get user data from local storage

  const handlePercentageClick = (percentage) => {
    setFormData({ ...formData, percentage, customPrice: '' });
  };

  const handleCustomChange = (e) => {
    setFormData({ ...formData, customPrice: e.target.value, percentage: '' });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const offerAmount = formData.customPrice || (price * (100 - formData.percentage) / 100).toFixed(2);

    if (!user) {
      toast.error('Please log in to make an offer.');
      setLoading(false);
      return;
    }

    try {
      const payload = {
        productId,
        offerAmount,
        userId: user.id,
        isCounterOffer, // Pass the isCounterOffer value
      };

      if (isCounterOffer) {
        payload.offerId = offerId; // Add offerId if it's a counter offer
      }

      const response = await makeOffer(payload);

      if (response.success) {
        toast.success('Offer submitted successfully!');
        onClose(); // Close the modal after successful submission
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error('An error occurred while making the offer.');
    } finally {
      setLoading(false);
    }
  };

  const calculatePrice = (percentage) => {
    const discount = percentage ? (percentage / 100) : 1;
    return (price * discount).toFixed(2);
  };

  const calculateCustomDiscount = (customPrice) => {
    if (!customPrice || isNaN(customPrice)) return 0;
    const discountPercentage = ((price - customPrice) / price) * 100;
    return discountPercentage.toFixed(2);
  };

  const getPercentageValue = () => {
    return formData.customPrice
      ? calculateCustomDiscount(formData.customPrice)
      : formData.percentage;
  };

  const selectedPercentage = getPercentageValue();
  const discountedPrice = formData.customPrice || (selectedPercentage ? calculatePrice(100 - selectedPercentage) : price);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Make an Offer"
      className="bg-white p-6 w-full max-w-md mx-auto my-20 rounded-md shadow-lg"
      overlayClassName="fixed z-50 inset-0 bg-black bg-opacity-50 flex justify-center items-center"
    >
      <h2 className="text-2xl font-semibold mb-4">Make an Offer</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">Select Offer</label>

          <div className="flex justify-between mb-4">
            <button
              type="button"
              className={`w-1/3 py-2 px-4 rounded-md border ${
                formData.percentage === '30' ? 'bg-black text-white' : 'bg-white text-black border-gray-300'
              }`}
              onClick={() => handlePercentageClick('30')}
            >
              Pay ${calculatePrice(70)} 30% off
            </button>
            <button
              type="button"
              className={`w-1/3 py-2 px-4 rounded-md border ${
                formData.percentage === '20' ? 'bg-black text-white' : 'bg-white text-black border-gray-300'
              }`}
              onClick={() => handlePercentageClick('20')}
            >
              Pay ${calculatePrice(80)} 20% off
            </button>
            <button
              type="button"
              className={`w-1/3 py-2 px-4 rounded-md border ${
                formData.percentage === '10' ? 'bg-black text-white' : 'bg-white text-black border-gray-300'
              }`}
              onClick={() => handlePercentageClick('10')}
            >
              Pay ${calculatePrice(90)} 10% off
            </button>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Or Enter Your Price</label>
            <input
              type="number"
              name="customPrice"
              value={formData.customPrice}
              onChange={handleCustomChange}
              placeholder="Enter your price"
              className="mt-1 block w-full border border-gray-300 rounded-md p-2"
              min="1"
              max={price}
            />
          </div>
        </div>

        {/* Display calculated price based on the selected percentage or custom price */}
        {formData.customPrice ? (
          <div className="text-lg font-medium mt-4">
            You are offering ${formData.customPrice} ({calculateCustomDiscount(formData.customPrice)}% off)
          </div>
        ) : selectedPercentage ? (
          <div className="text-lg font-medium mt-4">
            Pay ${discountedPrice} (pay {100 - selectedPercentage}%)
          </div>
        ) : null}

        <div>
          <button
            type="submit"
            className="w-full bg-black text-white py-2 px-4 rounded hover:bg-[#262626]"
            disabled={loading}
          >
            {loading ? 'Submitting...' : 'Send Offer'}
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default OfferModal;
