import React, { useState } from 'react';
import Modal from 'react-modal';
import { addQuestion } from '../../Hooks/faqHooks'; // Import your addQuestion function

const AskQuestionModal = ({ isOpen, onRequestClose, productId, onSubmit }) => {
  const [userQuestion, setUserQuestion] = useState('');
  const [guestName, setGuestName] = useState('');
  const [loading, setLoading] = useState(false);
  const twmUser = JSON.parse(localStorage.getItem('twmUser'));

  const handleSubmit = async () => {
    if (userQuestion.trim() === '') {
      alert('Please enter a question.');
      return;
    }

    setLoading(true);

    const response = await addQuestion({
      productId,
      question: userQuestion,
      guestName: twmUser ? null : guestName,
      createdBy: twmUser?._id || null,
    });

    if (response.success) {
      onSubmit(response.question); // Pass the new question to the parent component
      setUserQuestion('');
      setGuestName('');
      onRequestClose();
    } else {
      alert(response.message);
    }

    setLoading(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Ask a Question Modal"
      className="bg-white p-6 rounded shadow-lg z-50 max-w-lg mx-auto mt-20"
      overlayClassName="fixed inset-0 bg-black z-50 bg-opacity-50"
    >
      <h2 className="text-xl font-semibold mb-4">Ask a Question</h2>
      <textarea
        className="w-full p-2 border border-gray-300 rounded mb-4"
        rows="5"
        placeholder="Type your question here..."
        value={userQuestion}
        onChange={(e) => setUserQuestion(e.target.value)}
      />
      {!twmUser && (
        <input
          type="text"
          className="w-full p-2 border border-gray-300 rounded mb-4"
          placeholder="Enter your name"
          value={guestName}
          onChange={(e) => setGuestName(e.target.value)}
        />
      )}
      <div className="flex justify-between">
        <button
          className="bg-gray-400 text-white px-4 py-2 rounded hover:bg-gray-500"
          onClick={onRequestClose}
          disabled={loading}
        >
          Cancel
        </button>
        <button
          className={`px-4 py-2 rounded ${loading ? 'bg-gray-400' : 'bg-black text-white hover:bg-gray-800'}`}
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? 'Submitting...' : 'Submit Question'}
        </button>
      </div>
    </Modal>
  );
};

export default AskQuestionModal;
