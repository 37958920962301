import React, { useEffect, useState } from "react";
import { getOrderDetailForCustomer } from "../../Hooks/ordersHook";
import { createRefund } from "../../Hooks/refundHooks";
import RefundModal from "../Modals/refundModal";
import { toast } from "react-toastify";

export default function MyOrderDetail() {
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [refundVendor, setRefundVendor] = useState(null);

  useEffect(() => {
    const fetchOrder = async () => {
      const orderId = window.location.pathname.split("/")[2];
      const response = await getOrderDetailForCustomer(orderId);
      if (response.success) {
        setOrder(response.order);
      } else {
        setError(response.message);
      }
      setLoading(false);
    };
    fetchOrder();
  }, []);

  const handleRefundRequest =async (refundData) => {
    const refundPayload = {
      ...refundData,
      orderId: refundVendor? refundVendor.orderId : order._id,
      refundAmount: refundVendor ? refundVendor.totalAmount : order.totalAmount,
      vendorId: refundVendor ? refundVendor.vendorId : null,
    };
    console.log("Refund Payload:", refundPayload);

    const response = await createRefund(refundPayload);
    if (response.success) {
        toast.success(response.message);
        toast.success("We will get back to you when admin see this request");

    } else {
        toast.error(response.message);
    }
  };

  if (loading)
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="text-lg font-semibold">Loading...</div>
      </div>
    );
  if (error)
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="text-red-500 text-lg font-semibold">Error: {error}</div>
      </div>
    );

  return (
    <div className="p-6 max-w-5xl mx-auto bg-gray-100">
      <h2 className="text-3xl font-bold mb-8 text-center text-gray-800">
        Order Details
      </h2>

      {/* General Order Info */}
      <div className="mb-8 border p-6 rounded-lg shadow-md bg-white">
        <h3 className="text-xl font-semibold mb-4 text-gray-700">
          General Information
        </h3>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 text-gray-600">
          <p>
            <strong className="text-gray-700">Order ID:</strong>{" "}
            <span>{order._id}</span>
          </p>
          <p>
            <strong className="text-gray-700">Status:</strong>{" "}
            <span
              className={
                order.orderStatus === "Delivered"
                  ? "text-green-600 font-semibold"
                  : "text-yellow-600 font-semibold"
              }
            >
              {order.orderStatus}
            </span>
          </p>
          <p>
            <strong className="text-gray-700">Payment Method:</strong>{" "}
            <span>{order.paymentMethod}</span>
          </p>
          <p>
            <strong className="text-gray-700">Total Amount:</strong>{" "}
            <span className="font-bold">${order.totalAmount.toFixed(2)}</span>
          </p>
          <p>
            <strong className="text-gray-700">Final Amount:</strong>{" "}
            <span className="font-bold">${order.finalAmount.toFixed(2)}</span>
          </p>
          <p>
            <strong className="text-gray-700">Shipping Rate:</strong>{" "}
            <span className="font-bold">${order.shippingRate.toFixed(2)}</span>
          </p>
          {order.orderStatus === "delivered" && (
            <button
              className="mt-4 px-4 py-2 bg-blue-600 text-white font-semibold rounded hover:bg-blue-700"
              onClick={() => {
                setIsModalOpen(true);
                setRefundVendor(null);
              }}
            >
              Request Refund
            </button>
          )}
        </div>
      </div>

      {/* Items or Related Orders */}
      {order.items.length > 0 && (
        <div className="mb-8 border p-6 rounded-lg shadow-md bg-white">
          <h3 className="text-xl font-semibold mb-4 text-gray-700">Items</h3>
          {order.items.map((item) => (
            <div key={item._id} className="mb-6 border-b pb-4">
              <h4 className="font-semibold text-gray-800 mb-2 text-lg">
                {item.productId.productName}
              </h4>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 text-gray-600">
                <p>
                  <strong className="text-gray-700">Price:</strong>{" "}
                  <span className="font-bold">${item.price.toFixed(2)}</span>
                </p>
                <p>
                  <strong className="text-gray-700">Quantity:</strong>{" "}
                  <span>{item.quantity}</span>
                </p>
                <p>
                  <strong className="text-gray-700">Regular Price:</strong>{" "}
                  <span className="font-bold">
                    ${item.productId.regularPrice.toFixed(2)}
                  </span>
                </p>
                {item.productId.discountedPrice && (
                  <p>
                    <strong className="text-gray-700">Discounted Price:</strong>{" "}
                    <span className="font-bold text-green-600">
                      ${item.productId.discountedPrice.toFixed(2)}
                    </span>
                  </p>
                )}
              </div>
              <div className="mt-4">
                <img
                  src={item.productId.images[0]?.image}
                  alt={item.productId.productName}
                  className="w-32 h-32 object-cover border rounded-md shadow-sm"
                />
              </div>
            </div>
          ))}
        </div>
      )}

      {order.relatedOrders.length > 0 && (
        <div className="mb-8 border p-6 rounded-lg shadow-md bg-white">
          <h3 className="text-xl font-semibold mb-4 text-gray-700">
            Related Orders
          </h3>
          {order.relatedOrders.map((relatedOrder) => (
            <div key={relatedOrder._id} className="mb-6 border-b pb-4">
              <h4 className="font-semibold text-gray-800 mb-4 text-lg">
                Vendor: {relatedOrder.vendorName}
              </h4>
              {relatedOrder.items.map((item) => (
                <div key={item._id} className="mb-6">
                  <h5 className="font-semibold text-gray-800 mb-2">
                    {item.productId.productName}
                  </h5>
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 text-gray-600">
                    <p>
                      <strong className="text-gray-700">Price:</strong>{" "}
                      <span className="font-bold">${item.price.toFixed(2)}</span>
                    </p>
                    <p>
                      <strong className="text-gray-700">Quantity:</strong>{" "}
                      <span>{item.quantity}</span>
                    </p>
                  </div>
                </div>
              ))}
              {relatedOrder.orderStatus === "delivered" && (
                <button
                  className="mt-4 px-4 py-2 bg-blue-600 text-white font-semibold rounded hover:bg-blue-700"
                  onClick={() => {
                    setIsModalOpen(true);
                    setRefundVendor({
                      vendorId: relatedOrder.vendorId,
                      totalAmount: relatedOrder.totalAmount,
                      orderId: relatedOrder._id,
                    });
                  }}
                >
                  Request Refund
                </button>
              )}
            </div>
          ))}
        </div>
      )}

      <RefundModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={handleRefundRequest}
      />
    </div>
  );
}
