import React, { useState, useEffect } from "react";
import Tabs from "../components/Accounts/Tabs";
import ProfileSettings from "../components/Accounts/ProfileSettings";
import AddressSettings from "../components/Accounts/AddressSettings";
import OrderManagement from "../components/Accounts/OrderManagement";
import { getUserData } from "../Hooks/UserHooks"; // Import your custom API function
import { toast } from "react-toastify";

export default function Account() {
  const [activeTab, setActiveTab] = useState("profile"); // State to handle active tab
  const [userData, setUserData] = useState(null); // State to store user data
  const [loading, setLoading] = useState(true); // State for loading status

  // Fetch user data on component mount
  useEffect(() => {
    const fetchUserData = async () => {
      setLoading(true);
      const response = await getUserData(); // Call the custom API function
      if (response.success) {
        setUserData(response.user); // Set the user data
        console.log(response.user)
      } else {
        toast.error(response.message || "Failed to fetch user data");
      }
      setLoading(false);
    };

    fetchUserData();
  }, []);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <p>Loading user data...</p>
      </div>
    );
  }

  if (!userData) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <p>Failed to load user data. Please try again later.</p>
      </div>
    );
  }

  return (
    <div className="bg-gray-100 min-h-screen flex flex-col lg:flex-row">
      {/* Left Sidebar */}
      <div className="w-full lg:w-1/5 bg-white shadow-lg">
        <Tabs activeTab={activeTab} setActiveTab={setActiveTab} />
      </div>

      {/* Right Content Area */}
      <div className="w-full lg:w-4/5 bg-white p-6 lg:py-10 lg:px-10 shadow-lg">
        {activeTab === "profile" && <ProfileSettings userData={userData} />}
        {activeTab === "address" && <AddressSettings userData={userData} />}
        {activeTab === "orders" && <OrderManagement userData={userData} />}
      </div>
    </div>
  );
}
