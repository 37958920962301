import axios from "axios";

axios.defaults.baseURL =
  process.env.REACT_APP_BACKEND_URL || "http://localhost:5000";

export async function getVendorAnalytics() {
  try {
    const token = localStorage.getItem("twmToken");
    if (!token) return { success: false, message: "Please Login First" };

    const { data } = await axios.get("/api/analytics/vendor-analytics", {
      headers: {
        "TWM-auth-token": token,
      },
    });

    return data;
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || "An error occurred",
    };
  }
}
