import axios from "axios";

axios.defaults.baseURL =
  process.env.REACT_APP_BACKEND_URL || "http://localhost:5000";

export async function addRootCategory({ name, order = 0 }) {
  try {
    const token = localStorage.getItem("twmToken");

    if (!token) {
      return {
        success: false,
        message: "Please login first",
        status: 401,
      };
    }

    const { data, status } = await axios.post(
      "/api/categories",
      { name, order },
      {
        headers: {
          "TWM-auth-token": token,
        },
      }
    );

    return {
      success: data.success,
      message: data.message,
      category: data.category,
      status,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}

export async function changeCategoryOrder({ categoryId, newOrder }) {
  try {
    const token = localStorage.getItem("twmToken");

    if (!token) {
      return {
        success: false,
        message: "Please login first",
        status: 401,
      };
    }

    const { data, status } = await axios.put(
      "/api/categories/order",
      { categoryId, newOrder },
      {
        headers: {
          "TWM-auth-token": token,
        },
      }
    );

    return {
      success: data.success,
      message: data.message,
      updatedCategory: data.updatedCategory,
      swappedCategory: data.swappedCategory,
      status,
    };
  } catch (error) {
    return {
      success: false,
      message:
        error.response?.data?.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}

export async function deleteMainCategory(categoryId) {
  try {
    const token = localStorage.getItem("twmToken");

    if (!token) {
      return {
        success: false,
        message: "Please login first",
        status: 401,
      };
    }

    const { data, status } = await axios.delete(
      `/api/categories/${categoryId}`,
      {
        headers: {
          "TWM-auth-token": token,
        },
      }
    );

    return { success: data.success, message: data.message, status };
  } catch (error) {
    return {
      success: false,
      message:
        error.response?.data?.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}

export async function updateCategoryName({ categoryId, newName }) {
  try {
    const token = localStorage.getItem("twmToken");

    if (!token) {
      return {
        success: false,
        message: "Please login first",
        status: 401,
      };
    }

    const { data, status } = await axios.put(
      `/api/categories/${categoryId}`,
      { newName },
      {
        headers: {
          "TWM-auth-token": token,
        },
      }
    );

    return {
      success: data.success,
      message: data.message,
      category: data.category,
      status,
    };
  } catch (error) {
    return {
      success: false,
      message:
        error.response?.data?.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}

export async function addSubCategory({ categoryId, subcategoryName }) {
  try {
    const token = localStorage.getItem("twmToken");

    if (!token) {
      return {
        success: false,
        message: "Please login first",
        status: 401,
      };
    }

    const { data, status } = await axios.post(
      `/api/categories/${categoryId}/subcategories`,
      { subcategoryName },
      {
        headers: {
          "TWM-auth-token": token,
        },
      }
    );

    return {
      success: data.success,
      message: data.message,
      category: data.category,
      status,
    };
  } catch (error) {
    return {
      success: false,
      message:
        error.response?.data?.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}

export async function updateSubcategoryOrder({
  categoryId,
  subcategoryId,
  newOrder,
}) {
  try {
    const token = localStorage.getItem("twmToken");

    if (!token) {
      return {
        success: false,
        message: "Please login first",
        status: 401,
      };
    }

    const { data, status } = await axios.put(
      `/api/categories/${categoryId}/subcategories/order`,
      { subcategoryId, newOrder },
      {
        headers: {
          "TWM-auth-token": token,
        },
      }
    );

    return {
      success: data.success,
      message: data.message,
      category: data.category,
      status,
    };
  } catch (error) {
    return {
      success: false,
      message:
        error.response?.data?.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}

export async function deleteSubcategory({ categoryId, subcategoryId }) {
  try {
    const token = localStorage.getItem("twmToken");

    if (!token) {
      return {
        success: false,
        message: "Please login first",
        status: 401,
      };
    }

    const { data, status } = await axios.delete(
      `/api/categories/${categoryId}/subcategories/${subcategoryId}`,
      {
        headers: {
          "TWM-auth-token": token,
        },
      }
    );

    return {
      success: data.success,
      message: data.message,
      category: data.category,
      status,
    };
  } catch (error) {
    return {
      success: false,
      message:
        error.response?.data?.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}

export async function updateSubcategoryName({
  categoryId,
  subcategoryId,
  newName,
}) {
  try {
    const token = localStorage.getItem("twmToken");

    if (!token) {
      return {
        success: false,
        message: "Please login first",
        status: 401,
      };
    }

    const { data, status } = await axios.put(
      `/api/categories/${categoryId}/subcategories/${subcategoryId}`,
      { newName },
      {
        headers: {
          "TWM-auth-token": token,
        },
      }
    );

    return {
      success: data.success,
      message: data.message,
      category: data.category,
      status,
    };
  } catch (error) {
    return {
      success: false,
      message:
        error.response?.data?.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}

export async function getAllCategories() {
  try {
    const { data, status } = await axios.get("/api/categories");

    return {
      success: data.success,
      message: data.message,
      categories: data.categories,
      status,
    };
  } catch (error) {
    return {
      success: false,
      message:
        error.response?.data?.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}
