import React, { useState, useMemo, useEffect } from 'react';
import { useTable, usePagination } from 'react-table';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaCheckCircle, FaTimesCircle, FaShippingFast, FaSpinner } from 'react-icons/fa';
import { getOrdersByVendor } from '../../Hooks/ordersHook'; // Import the API call

export default function Orders() {
  const location = useLocation();
  const navigate = useNavigate();

  const [data, setData] = useState([]); // State to store orders
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const [page, setPage] = useState(1); // Current page
  const [totalPages, setTotalPages] = useState(1); // Total pages
  const [statusFilter, setStatusFilter] = useState('All'); // Filter for status
  const [searchQuery, setSearchQuery] = useState(''); // Search query state

  // Apply filter from navigation state
  useEffect(() => {
    if (location.state?.filter) {
      setStatusFilter(location.state.filter);
    }
  }, [location.state?.filter]);

  // Fetch vendor orders when component mounts, filters, or page changes
  useEffect(() => {
    const fetchOrders = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await getOrdersByVendor(page, 10); // Fetch with pagination
        if (response.success) {
          let filteredOrders = response.orders;

          // Apply status filter
          if (statusFilter !== 'All') {
            filteredOrders = filteredOrders.filter(
              (order) => order.status.toLowerCase() === statusFilter.toLowerCase()
            );
          }

          setData(filteredOrders);
          setTotalPages(response.pagination.totalPages);
        } else {
          setError(response.message);
        }
      } catch (err) {
        setError('Failed to fetch orders.');
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, [page, statusFilter]);

  // Icons and status colors
  const renderStatus = (status) => {
    switch (status.toLowerCase()) {
      case 'delivered':
        return (
          <div className="flex items-center space-x-2 text-green-500">
            <FaCheckCircle />
            <span>Delivered</span>
          </div>
        );
      case 'cancelled':
        return (
          <div className="flex items-center space-x-2 text-red-500">
            <FaTimesCircle />
            <span>Cancelled</span>
          </div>
        );
      case 'shipped':
        return (
          <div className="flex items-center space-x-2 text-blue-500">
            <FaShippingFast />
            <span>Shipped</span>
          </div>
        );
      case 'processing':
        return (
          <div className="flex items-center space-x-2 text-yellow-500">
            <FaSpinner className="animate-spin" />
            <span>Processing</span>
          </div>
        );
      default:
        return status;
    }
  };

  // Table columns
  const columns = useMemo(
    () => [
      { Header: 'Product', accessor: 'product.productName' },
      { Header: 'Order ID', accessor: 'orderId' },
      { Header: 'Date', accessor: 'date' },
      {
        Header: 'Customer',
        accessor: 'customer',
        Cell: ({ value }) => <span>{value}</span>,
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ value }) => renderStatus(value),
      },
      { Header: 'Amount', accessor: 'amount' },
      {
        Header: 'Actions',
        accessor: 'actions',
        Cell: ({ row }) => (
          <button
            onClick={() => viewOrderDetails(row.original)}
            className="text-blue-500 hover:underline"
          >
            View Details
          </button>
        ),
      },
    ],
    []
  );

  const viewOrderDetails = (order) => {
    navigate(`/admin/order-detail/${order.orderId}`);
  };

  // React Table hooks
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page: tablePage,
  } = useTable(
    { columns, data, initialState: { pageIndex: 0, pageSize: 10 } },
    usePagination
  );

  return (
    <div className="p-6">
      <h1 className="text-2xl font-semibold mb-4">Vendor Orders</h1>

      {/* Search and filter */}
      <div className="mb-4 flex items-center">
        <input
          type="text"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="Search by Customer Name"
          className="p-2 border border-gray-300 rounded-md mr-4"
        />
        <select
          value={statusFilter}
          onChange={(e) => setStatusFilter(e.target.value)}
          className="p-2 border border-gray-300 rounded-md"
        >
          <option value="All">All</option>
          <option value="processing">Processing</option>
          <option value="shipped">Shipped</option>
          <option value="delivered">Delivered</option>
          <option value="cancelled">Cancelled</option>
        </select>
      </div>

      {/* Table */}
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p className="text-red-500">{error}</p>
      ) : (
        <>
          <table {...getTableProps()} className="w-full border-collapse border border-gray-200">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()} className="bg-gray-50">
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()} className="p-4 border-b border-gray-200 text-left">
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {tablePage.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} className="hover:bg-gray-50">
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()} className="p-4 border-b border-gray-200">
                        {cell.render('Cell')}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>

          {/* Pagination Controls */}
          <div className="mt-4 flex justify-between items-center">
            <button
              disabled={page === 1}
              onClick={() => setPage((prev) => prev - 1)}
              className="px-3 py-1 border border-gray-300 rounded-md"
            >
              Prev
            </button>
            <span>
              Page {page} of {totalPages}
            </span>
            <button
              disabled={page === totalPages}
              onClick={() => setPage((prev) => prev + 1)}
              className="px-3 py-1 border border-gray-300 rounded-md"
            >
              Next
            </button>
          </div>
        </>
      )}
    </div>
  );
}
