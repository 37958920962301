import React, { useState, useCallback, useEffect } from 'react';
import Modal from 'react-modal';
import Slider from 'react-slick';
import { FaUpload } from 'react-icons/fa'; // Icon for the upload button
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Cropper from 'react-easy-crop';
import getCroppedImg from './cropImage'; // A utility function to create the cropped image

const filters = {
  original: '',
  rich: 'brightness(1.04) contrast(1.01)',
  chrome: 'contrast(1.05) grayscale(0.1)',
  mild: 'sepia(0.25) brightness(0.9)',
  warm: 'sepia(0.5) brightness(.9)',
  dusk: 'brightness(0.8) contrast(0.9) hue-rotate(-10deg)',
  haze: 'brightness(1.05) contrast(0.65)',
  sunset: 'sepia(0.5) brightness(.8) hue-rotate(-30deg)',
  cool: 'brightness(0.9) contrast(1.2) hue-rotate(180deg)',
};

const MediaUploadModal = ({ isOpen, onClose, onSave, existingImages = [], existingVideo = null }) => {
  const [images, setImages] = useState(existingImages);
  const [video, setVideo] = useState(existingVideo);
  const [selectedMedia, setSelectedMedia] = useState(images.length > 0 ? { type: 'image', index: 0 } : { type: null });
  const [appliedFilter, setAppliedFilter] = useState(filters.original);
  const [cropping, setCropping] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1); // Default zoom level
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  // For simple drag-and-drop support
  const [dragActive, setDragActive] = useState(false);

  useEffect(() => {
    if (isOpen) {
      const formattedImages = existingImages
        .map((img) => {
          // Ensure the `img` object has a valid `image` and `filter`
          if (img.image && typeof img.image === 'string') {
            // Image is already a valid URL
            return { image: img.image, filter: img.filter || filters.original };
          } else if (img.image instanceof File || img.image instanceof Blob) {
            // Image is a File or Blob, create an object URL
            return { image: URL.createObjectURL(img.image), filter: img.filter || filters.original };
          } else {
            console.error('Invalid image format:', img);
            return null; // Filter out invalid data
          }
        })
        .filter(Boolean); // Remove null values

      setImages(formattedImages);

      // Handle the video initialization
      setVideo(
        existingVideo
          ? typeof existingVideo === 'string'
            ? existingVideo
            : URL.createObjectURL(existingVideo)
          : null
      );

      // Set selected media based on available data
      if (formattedImages.length > 0) {
        setSelectedMedia({ type: 'image', index: 0 });
        setAppliedFilter(formattedImages[0].filter || filters.original);
      } else if (existingVideo) {
        setSelectedMedia({ type: 'video' });
      } else {
        setSelectedMedia({ type: null });
      }
    }
  }, [isOpen, existingImages, existingVideo]);

  // Helper to handle both drag-drop and file-input changes
  const processMediaFiles = (files) => {
    const imageFiles = files.filter((file) =>
      ['image/jpeg', 'image/jpg', 'image/png'].includes(file.type)
    );
    const videoFiles = files.filter((file) => file.type === 'video/mp4');

    const imageList = imageFiles.map((file) => ({
      image: URL.createObjectURL(file),
      filter: filters.original,
    }));

    setImages((prevImages) => [...prevImages, ...imageList]);

    if (videoFiles.length > 0) {
      // Only allow one video
      const file = videoFiles[0];
      setVideo(URL.createObjectURL(file));
    }

    // If we did get images, select the first one from the newly added
    if (imageList.length > 0) {
      setSelectedMedia({ type: 'image', index: images.length });
      setAppliedFilter(filters.original);
    }
  };

  const handleMediaChange = (e) => {
    const files = Array.from(e.target.files);
    processMediaFiles(files);
  };

  // Drag event handlers
  const handleDragOver = (e) => {
    e.preventDefault();
    setDragActive(true);
  };

  const handleDragLeave = () => {
    setDragActive(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragActive(false);
    const droppedFiles = Array.from(e.dataTransfer.files);
    processMediaFiles(droppedFiles);
  };

  const handleSave = () => {
    onSave(images, video);
    onClose();
  };

  const applyFilter = (filterKey) => {
    if (selectedMedia.type === 'image' && images[selectedMedia.index]) {
      const updatedImages = images.map((img, index) =>
        index === selectedMedia.index ? { ...img, filter: filters[filterKey] } : img
      );
      setImages(updatedImages);
      setAppliedFilter(filters[filterKey]);
    }
  };

  const handleMediaSelect = (type, index = null) => {
    if (type === 'video') {
      setSelectedMedia({ type: 'video' });
      setAppliedFilter(filters.original);
    } else if (type === 'image' && images[index]) {
      setSelectedMedia({ type: 'image', index });
      setAppliedFilter(images[index].filter || filters.original);
    }
  };

  const startCropping = () => {
    setCropping(true);
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const cropImage = async () => {
    const croppedImage = await getCroppedImg(
      images[selectedMedia.index].image,
      croppedAreaPixels
    );
    const updatedImages = images.map((img, index) =>
      index === selectedMedia.index ? { ...img, image: croppedImage } : img
    );
    setImages(updatedImages);
    setCropping(false);
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    centerMode: false,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 4,
        },
      },
    ],
  };

  const removeImage = (index) => {
    const updatedImages = images.filter((_, idx) => idx !== index);
    setImages(updatedImages);
    if (selectedMedia.type === 'image' && index === selectedMedia.index) {
      setSelectedMedia(updatedImages.length > 0 ? { type: 'image', index: 0 } : { type: null });
      if (updatedImages.length > 0) {
        setAppliedFilter(updatedImages[0]?.filter || filters.original);
      } else {
        setAppliedFilter(filters.original);
      }
    }
  };

  const removeVideo = () => {
    setVideo(null);
    if (selectedMedia.type === 'video') {
      setSelectedMedia(images.length > 0 ? { type: 'image', index: 0 } : { type: null });
    }
  };

  // Function to disable body scroll when modal is open
  const disableBodyScroll = () => {
    document.body.style.overflow = 'hidden';
  };

  // Function to enable body scroll when modal is closed
  const enableBodyScroll = () => {
    document.body.style.overflow = 'auto';
    setImages([]);
    setVideo(null);
  };

  // Zoom in and out handlers
  const zoomIn = () => {
    setZoom((prevZoom) => Math.min(prevZoom + 0.1, 3));
  };

  const zoomOut = () => {
    setZoom((prevZoom) => Math.max(prevZoom - 0.1, 1));
  };

  return (
    <Modal
      isOpen={isOpen}
      onAfterOpen={disableBodyScroll}
      onRequestClose={() => {
        enableBodyScroll();
        setImages([]);
        setVideo(null);
        onClose();
      }}
      onAfterClose={enableBodyScroll}
      contentLabel="Media Upload"
      className="w-[98vw] sm:max-w-5xl h-[90vh] scrollbar-hide mx-auto z-50 bg-white text-black rounded-md p-4 md:p-6 overflow-auto"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex z-50 justify-center items-center"
    >
      <div className="flex flex-col md:flex-row md:justify-between">
        {/* Filters */}
        {selectedMedia.type === 'image' && (
          <div className="md:w-1/3 md:border-r border-gray-300 md:pr-6 mb-6 md:mb-0">
            <h3 className="text-lg font-semibold mb-4 text-center md:text-left">Filters</h3>
            {/* Filters Container */}
            <div
              className={`${
                window.innerWidth < 768
                  ? 'flex overflow-x-auto space-x-4 pb-2'
                  : 'h-80 overflow-y-auto'
              } scrollbar-hide`}
            >
              {Object.keys(filters).map((filterKey) => (
                <div
                  key={filterKey}
                  className="cursor-pointer flex-col flex justify-center items-center mb-2"
                  onClick={() => applyFilter(filterKey)}
                  style={{ flex: '0 0 auto' }}
                >
                  <img
                    src={images[selectedMedia.index]?.image}
                    alt={filterKey}
                    className="w-20 h-20 object-cover mb-2"
                    style={{ filter: filters[filterKey] }}
                  />
                  <p className="text-left text-sm mt-1 capitalize">{filterKey}</p>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Main content */}
        <div className="flex-1 md:pl-6">
          <h2 className="text-2xl font-bold mb-4 text-center">Upload Media</h2>

          {selectedMedia.type === null ? (
            // When no media is uploaded yet
            <div
              className={`relative flex flex-col items-center justify-center ${
                dragActive ? 'bg-gray-100' : ''
              } border-4 border-dashed border-gray-300 rounded-md p-8 mx-auto w-full max-w-xl`}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
            >
              <FaUpload className="text-6xl mb-4 text-black" />
              <p className="text-lg mb-4 text-gray-600 text-center">
                Drag &amp; drop or click below to upload images or a single video
              </p>
              <label className="py-2 px-6 bg-black text-white rounded-md cursor-pointer hover:bg-gray-800">
                <input
                  type="file"
                  accept="image/jpeg, image/jpg, image/png, video/mp4"
                  multiple
                  onChange={handleMediaChange}
                  className="hidden"
                />
                Choose Files
              </label>
            </div>
          ) : (
            <div className="flex flex-col justify-center items-center">
              {/* Media display or cropper */}
              <div className="flex justify-center mb-4 relative w-full max-w-xs h-60">
                {cropping && selectedMedia.type === 'image' ? (
                  <Cropper
                    image={images[selectedMedia.index]?.image}
                    crop={crop}
                    zoom={zoom}
                    aspect={1}
                    onCropChange={setCrop}
                    onZoomChange={setZoom}
                    onCropComplete={onCropComplete}
                  />
                ) : (
                  <>
                    {selectedMedia.type === 'video' && video && (
                      <video width="100%" height="auto" controls className="rounded-md">
                        <source src={video} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    )}
                    {selectedMedia.type === 'image' && images.length > 0 && (
                      <img
                        src={images[selectedMedia.index]?.image}
                        alt="Selected"
                        className="w-full h-full object-cover rounded-md"
                        style={{ filter: appliedFilter }}
                      />
                    )}
                  </>
                )}
              </div>

              {/* Crop and Remove buttons */}
              {selectedMedia.type === 'image' && images.length > 0 && !cropping && (
                <div className="flex space-x-4 mb-4">
                  <button
                    onClick={startCropping}
                    className="py-2 px-4 bg-black text-white rounded-md"
                  >
                    Crop Image
                  </button>
                  <button
                    onClick={() => removeImage(selectedMedia.index)}
                    className="py-2 px-4 bg-red-500 text-white rounded-md"
                  >
                    Remove Image
                  </button>
                </div>
              )}

              {/* Remove Video button when video is selected */}
              {selectedMedia.type === 'video' && video && (
                <div className="flex space-x-4 mb-4">
                  <button
                    onClick={removeVideo}
                    className="py-2 px-4 bg-red-500 text-white rounded-md"
                  >
                    Remove Video
                  </button>
                </div>
              )}

              {/* Save cropped image button */}
              {cropping && selectedMedia.type === 'image' && (
                <button
                  onClick={cropImage}
                  className="py-2 px-4 bg-black text-white rounded-md mb-4"
                >
                  Save Cropped Image
                </button>
              )}

              {/* Zoom slider for cropping with - and + buttons */}
              {cropping && (
                <div className="flex items-center justify-center mt-4 space-x-4">
                  <button
                    onClick={zoomOut}
                    className="px-3 py-1 bg-gray-300 rounded-full text-black hover:font-bold transition"
                    disabled={zoom <= 1}
                  >
                    -
                  </button>
                  <input
                    type="range"
                    min="1"
                    max="3"
                    step="0.1"
                    value={zoom}
                    onChange={(e) => setZoom(parseFloat(e.target.value))}
                    className="w-full max-w-xs"
                  />
                  <button
                    onClick={zoomIn}
                    className="px-3 py-1 bg-gray-300 rounded-full text-black hover:font-bold transition"
                    disabled={zoom >= 3}
                  >
                    +
                  </button>
                </div>
              )}

              {/* Media slider at the bottom */}
              <div className="mt-4 w-full max-w-md">
                <Slider {...settings}>
                  {images.map((img, index) => (
                    <div key={index} className="px-2">
                      <img
                        src={img.image}
                        alt={`Thumbnail ${index}`}
                        className={`h-16 w-16 object-cover cursor-pointer border ${
                          selectedMedia.type === 'image' && selectedMedia.index === index
                            ? 'border-black'
                            : 'border-gray-400'
                        } hover:border-black rounded-md`}
                        onClick={() => handleMediaSelect('image', index)}
                      />
                    </div>
                  ))}
                  {video && (
                    <div className="px-2">
                      <video
                        width="64"
                        height="64"
                        className={`h-16 w-16 object-cover cursor-pointer border ${
                          selectedMedia.type === 'video' ? 'border-black' : 'border-gray-400'
                        } hover:border-black rounded-md`}
                        onClick={() => handleMediaSelect('video')}
                      >
                        <source src={video} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  )}
                </Slider>
              </div>

              {/* Upload more media */}
              <div className="mt-4">
                <label className="py-2 px-6 bg-black text-white rounded-md cursor-pointer hover:bg-gray-800">
                  <input
                    type="file"
                    accept="image/jpeg, image/jpg, image/png, video/mp4"
                    multiple
                    onChange={handleMediaChange}
                    className="hidden"
                  />
                  Upload More
                </label>
              </div>
            </div>
          )}

          {/* Action buttons */}
          <div className="mt-6 flex justify-end space-x-4">
            <button
              onClick={handleSave}
              className="py-2 px-6 bg-black text-white rounded-md hover:bg-gray-800"
            >
              Save Media
            </button>
            <button
              onClick={() => {
                enableBodyScroll();
                onClose();
              }}
              className="py-2 px-6 bg-gray-300 text-black rounded-md hover:bg-gray-400"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default MediaUploadModal;
