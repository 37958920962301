import React from 'react';
import weddinglogo from '../../assets/weddinglogo.png';


export default function AboutUs() {
  return (
    <div className="bg-white">
      {/* Hero Section */}
      <section className="relative">
        <img
          src="https://res.cloudinary.com/dlhwfesiz/image/upload/v1730195040/aboutHero_bgrjhh.jpg"
          alt="Wedding Marketplace"
          className="w-full h-[70vh] sm:h-[400px] lg:h-[500px] object-cover"
        />
        {/* Black Overlay */}
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>
        <div className="absolute inset-0 flex flex-col items-center justify-center text-white text-center z-10 px-4">
          <h1 className="text-3xl sm:text-4xl lg:text-5xl font-bold mb-4">A New Way to Shop for Your Wedding</h1>
          <p className="text-lg sm:text-xl">Start shopping for your wedding today</p>
        </div>
      </section>

      {/* Content Section */}
      <section className="max-w-7xl mx-auto py-12 sm:py-16 px-4 sm:px-6 md:px-8 lg:px-4 text-gray-900">
        <p className="text-base sm:text-lg mb-6">
          “Empowerment and choice are two of the most important tools we have and with them, the world can be ours.”
        </p>
        <p className="text-base sm:text-lg mb-6">
          The Wedding Marketplace (TWM) empowers you to create the look and feel of your dream wedding or event in one place and at the price point that is right for you.
        </p>
        <p className="text-base sm:text-lg mb-6">
          By uniting a community of already-wed couples and event organizers with those headed toward their big day, we have created a forward-thinking and sustainable resource for all your wedding and event needs. TWM is the unrivaled platform dedicated specifically to gently used wedding and event items.
        </p>
        <p className="text-base sm:text-lg mb-6">
          Our quality products are available 24/7, so there is no more scrambling to find the time to browse for what you want, worry about special orders or overpay for beautiful pieces. Your dream wedding starts here: buy sell celebrate, united wedding and event community!
        </p>
        <p className="text-base sm:text-lg mb-6">
          TWM is committed to the values that make us a great place to work, and a great team to work with whether you are a buyer or seller. We are passionate about what we do and believe sustainable weddings should never have to compromise on style.
        </p>

        {/* Core Values */}
        <h2 className="text-2xl sm:text-3xl font-bold mb-6">Our Commitment to You is Based on Our Core Values</h2>
        <ul className="space-y-6 text-base sm:text-lg">
          <li>
            <strong>Passion</strong> – Here at TWM, we are passionate about our endeavors. Your success is our success, and we will be here every step of the way to ensure yours is reached.
          </li>
          <li>
            <strong>Respect</strong> – We celebrate each other’s uniqueness, find common values and educate ourselves about our differences. Love is accepting someone as they are, not who you want them to be.
          </li>
          <li>
            <strong>Openness</strong> – We are open and transparent with our ideas, ideals and actions. We seek to learn from each other by conveying our thoughts in a genuine, thoughtful and concise way.
          </li>
          <li>
            <strong>Teamwork</strong> – We are collaborating, challenging and supporting each other on this beautiful journey to ensure everyone feels welcome and heard.
          </li>
          <li>
            <strong>Innovation</strong> – We nurture creativity by providing the time, space, resources and support to actualize great ideas.
          </li>
        </ul>

        <p className="text-base sm:text-lg mt-10">
          We celebrate every person, every success and every wedding or other event right along with you. We welcome you to our TWM family and look forward to making your special day exactly what you envision.
        </p>
      </section>

      <div className="flex justify-center items-center my-10">
        <div className="w-full border-2 border-t border-gray-300 mx-5" />
        <img src={weddinglogo} alt="" className='w-40 h-40' />
        <div className="w-full border-2 border-t border-gray-300 mx-5" />
      </div>
    </div>
  );
}
