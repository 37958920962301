import React, { useState, useRef, useEffect } from "react";
import { FiChevronDown, FiShoppingCart, FiHeart } from "react-icons/fi";
import { Link } from "react-router-dom";
import Cart from "../pages/Cart/Cart";
import { toast } from "react-toastify";
import { getCartProducts } from "../Hooks/UserHooks";
import { useDispatch, useSelector } from "react-redux";
import { setCartItems } from "../redux/features/cartSlice";

export default function Topheader() {
  const [accountDropdown, setAccountDropdown] = useState(false);
  const [cartOpen, setCartOpen] = useState(false);
  const [user, setUser] = useState(null);
  const cartRef = useRef(null);

  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.cart.items || []);

  useEffect(() => {
    const storedUser = localStorage.getItem("twmUser");
    if (storedUser) {
      try {
        const parsedUser = JSON.parse(storedUser);
        setUser(parsedUser);
      } catch (error) {
        console.error("Error parsing user data:", error);
        localStorage.removeItem("twmUser");
      }
    }
  }, []);

  useEffect(() => {
    const fetchCart = async () => {
      if (user && user._id && cartItems.length === 0) {
        try {
          const response = await getCartProducts(user._id);
          if (response.success) {
            dispatch(setCartItems(response.cartProducts));
          } else {
            toast.error(response.message);
          }
        } catch (error) {
          console.error("Error fetching cart:", error);
        }
      }
    };

    if (user && user._id) {
      fetchCart();
    }
  }, [user, cartOpen, cartItems.length, dispatch]);

  const handleClickOutside = (event) => {
    if (cartRef.current && !cartRef.current.contains(event.target)) {
      setCartOpen(false);
    }
  };

  useEffect(() => {
    if (cartOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [cartOpen]);

  const toggleCart = () => {
    setCartOpen(!cartOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem("twmUser");
    localStorage.removeItem("twmToken");
    setUser(null);
    dispatch(setCartItems([]));
    window.location.href = "/";
  };

  return (
    <>
      <div className="bg-black text-white p-4 flex justify-between items-center">
        {/* Left side */}
        <div className="flex items-center space-x-6">
          {user && (
            <div className="relative">
              <div className="flex justify-center items-center">
                <button
                  className="flex items-center hover:underline w-[120px]"
                  onClick={() => setAccountDropdown(!accountDropdown)}
                >
                  My Account
                  <FiChevronDown
                    className={`ml-1 transition-transform ${
                      accountDropdown ? "rotate-0" : "-rotate-90"
                    }`}
                  />
                </button>
                <button
                  onClick={handleLogout}
                  className="block text-left hover:underline"
                >
                  Logout
                </button>
              </div>
              {accountDropdown && (
                <div className="absolute z-50 mt-2 w-48 bg-white text-black rounded-md shadow-lg">
                  <Link
                    to={"/my-orders"}
                    className="block px-4 text-black py-1 hover:bg-gray-100"
                    onClick={() => setAccountDropdown(false)}
                  >
                    Orders
                  </Link>
                  <Link
                    to={"/my-orders/offers"}
                    className="block px-4 text-black py-1 hover:bg-gray-100"
                    onClick={() => setAccountDropdown(false)}
                  >
                    Offers
                  </Link>
                  <Link
                    to={"/admin"}
                    className="block px-4 text-black py-1 hover:bg-gray-100"
                    onClick={() => setAccountDropdown(false)}
                  >
                    Dashboard
                  </Link>
                  <Link
                    to={"/account"}
                    className="block px-4 text-black py-1 hover:bg-gray-100"
                    onClick={() => setAccountDropdown(false)}
                  >
                    Settings
                  </Link>
                </div>
              )}
            </div>
          )}
        </div>

        {/* Right side */}
        <div className="flex items-center space-x-4">
          {user ? (
            <>
              <Link
                to="/wish-list"
                className="flex items-center text-black bg-white px-4 py-2 rounded-md hover:bg-gray-100"
              >
                <FiHeart className="text-xl" />
              </Link>

              <button
                onClick={toggleCart}
                className="flex items-center text-black bg-white px-4 py-1 rounded-md hover:bg-gray-100"
              >
                <FiShoppingCart className="mr-1" /> CART
                <FiChevronDown
                  className={`ml-1 transition-transform ${
                    cartOpen ? "rotate-0" : "-rotate-90"
                  }`}
                />
              </button>

              {cartItems.length > 0 && (
                <span className="absolute top-4 right-4 transform translate-x-1/2 -translate-y-1/2 bg-red-600 text-white text-xs font-bold rounded-full w-5 h-5 flex items-center justify-center">
                  {cartItems.length}
                </span>
              )}
            </>
          ) : (
            <>
              <Link to={"/login"} className="hover:underline">
                Login
              </Link>
              <Link to={"/signup"} className="hover:underline">
                Sign up
              </Link>
            </>
          )}
        </div>
      </div>

      {user && (
        <div ref={cartRef}>
          <Cart
            isOpen={cartOpen}
            onClose={toggleCart}
            cartItems={cartItems || []}
          />
        </div>
      )}
    </>
  );
}
