import React from 'react';

export default function PrivacyPolicy() {
  return (
    <div className="bg-white py-10 px-5 sm:px-10">
      <div className="max-w-7xl mx-auto">
        {/* Header */}
        <h1 className="text-center text-4xl sm:text-5xl font-bold mb-8">TWM Privacy Policy</h1>

        {/* Introduction */}
        <p className="mb-4">
          Through this Policy, The Wedding Marketplace, an Illinois corporation, aims to inform individuals about the collection and utilization of personal information in connection with the use of The Wedding Marketplace's mobile application and website consumer-to-consumer marketplace service. This policy specifically focuses on data collection and processing by The Wedding Marketplace and does not encompass information collected by other entities or through linked sites or services.
        </p>

        {/* Section: Collection of Your Information */}
        <h2 className="text-2xl font-semibold mb-4">Collection of Your Information</h2>
        <p className="mb-4">
          <strong>Notice at Collection:</strong> California residents are entitled to receive notice of data collection practices, including the types of personal information collected, purposes of collection, sharing practices, and data retention periods.
        </p>

        {/* Section: Changes */}
        <h2 className="text-2xl font-semibold mb-4">Changes</h2>
        <p className="mb-4">
          The policy may be revised periodically to align with technological advancements and business modifications. Users are encouraged to review the current privacy policy each time they engage with the service.
        </p>

        {/* Section: Policy for Kids */}
        <h2 className="text-2xl font-semibold mb-4">Policy for Kids</h2>
        <p className="mb-4">
          Individuals under 18 years old are not permitted to use The Wedding Marketplace service. Parents or guardians who discover that their child has provided personal information are advised to contact The Wedding Marketplace for necessary actions.
        </p>

        {/* Section: Account Data */}
        <h2 className="text-2xl font-semibold mb-4">Account Data</h2>
        <p className="mb-4">
          Upon registration, users provide account information that can be managed within The Wedding Marketplace app or website, including contact details, financial information, and personal data. This information is utilized to provide and enhance services, communicate with users, and for marketing purposes.
        </p>

        {/* Section: Usage Data */}
        <h2 className="text-2xl font-semibold mb-4">Usage Data</h2>
        <p className="mb-4">
          Information regarding user transactions, interactions, and device details are processed to deliver services, enhance user experience, mitigate fraud, and tailor offerings. Usage data may be combined with data from other sources to improve service personalization.
        </p>

        {/* Section: Third-Party Sources */}
        <h2 className="text-2xl font-semibold mb-4">Third-Party Sources</h2>
        <p className="mb-4">
          The Wedding Marketplace may obtain information from third-party sources for identity verification and customization purposes, ensuring compliance with privacy policies.
        </p>

        {/* Section: Cookie Statement */}
        <h2 className="text-2xl font-semibold mb-4">Cookie Statement</h2>
        <p className="mb-4">
          The Wedding Marketplace and affiliated parties may collect device and online activity data through automated methods like cookies for targeted advertising purposes. Users have the option to modify cookie preferences in their browsers.
        </p>

        {/* Section: Data Access and Retention */}
        <h2 className="text-2xl font-semibold mb-4">Data Access and Retention</h2>
        <p className="mb-4">
          Users can access, edit, or close their accounts at any time. Account and usage data are retained based on legal requirements and operational needs.
        </p>

        {/* Section: Disclosures, Sharing */}
        <h2 className="text-2xl font-semibold mb-4">Disclosures, Sharing</h2>
        <p className="mb-4">
          The Wedding Marketplace may share personal information with consent, for operational purposes, compliance with laws, protection of interests, and in corporate transactions, among other reasons.
        </p>

        {/* Section: International Data Transfer */}
        <h2 className="text-2xl font-semibold mb-4">International Data Transfer</h2>
        <p className="mb-4">
          Personal information may be transferred, stored, and processed in the United States and other jurisdictions as necessary for operational purposes.
        </p>

        {/* Section: Your Choices and Rights */}
        <h2 className="text-2xl font-semibold mb-4">Your Choices and Rights</h2>
        <p className="mb-4">
          Users have the right to manage communication preferences, access personal information, request corrections, opt-out of certain processing activities, request deletion, and appeal decisions. Specific rights and contact details are outlined for California residents under the CCPA.
        </p>

        {/* Contact Information */}
        <div className="mt-10 text-center">
          <h2 className="text-3xl font-semibold mb-4">Contact Us</h2>
          <p className="mb-2">If you have any questions, please feel free to contact us at:</p>
          <p className="font-bold">The Wedding Marketplace</p>
          <p className="mb-2">PO BOX 337</p>
          <p className="mb-2">Indian Hills, Colorado 80454</p>
        </div>
      </div>
    </div>
  );
}