import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { addStripeAccountId } from '../../Hooks/UserHooks';
import { toast } from 'react-toastify';

export default function AccountSuccess() {
  const accountId = localStorage.getItem('stripeAccountId');

  useEffect(() => {
    const handleStripeAccount = async () => {
      try {
        const response = await addStripeAccountId({ stripeAccountId: accountId });
        if (response.success) {
          localStorage.removeItem('stripeAccountId');
          toast.success('Account linked successfully!');
        }
      } catch (error) {
        console.error('Error adding Stripe account ID:', error);
        toast.error('Failed to link account. Please try again.');
      }
    };

    handleStripeAccount();
  }, [accountId]);

  return (
    <div className="container mx-auto p-5 flex justify-center items-center h-screen">
      <motion.div
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5, ease: 'easeOut' }}
        className="bg-green-100 border-l-4 border-green-500 p-8 rounded-md shadow-lg text-center"
      >
        <motion.h1
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className="text-3xl font-bold text-green-800"
        >
          Account Linked Successfully!
        </motion.h1>
        <motion.p
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.4 }}
          className="text-lg text-gray-700 mt-4"
        >
          Thank you for linking your account. You can now start using our platform for payouts!
        </motion.p>
      </motion.div>
    </div>
  );
}
