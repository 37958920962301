import React from 'react';
import { FaFacebook, FaInstagram } from 'react-icons/fa';

export default function ContactUs() {
  return (
    <div className="bg-white">
      {/* Contact Us Section */}
      <main className="max-w-7xl mx-auto px-4  md:px-10 py-12">
        <div className="grid md:grid-cols-2 gap-8">
          {/* Left side - Contact Form */}
          <div>
            <h2 className="text-4xl text-pink-500 font-bold mb-4">Contact Us</h2>
            <p className="mb-4">
              Contact our support team and a real live person will get back to you within <strong>24 hours</strong>.
            </p>
            <p className="mb-4 text-sm">* Indicates required fields</p>

            <form>
              {/* Name Field */}
              <div className="flex gap-4 mb-4">
                <div className="w-1/2">
                  <label htmlFor="firstName" className="block mb-1 text-sm">First Name</label>
                  <input
                    type="text"
                    id="firstName"
                    className="w-full border border-gray-300 rounded px-3 py-2"
                  />
                </div>
                <div className="w-1/2">
                  <label htmlFor="lastName" className="block mb-1 text-sm">Last Name</label>
                  <input
                    type="text"
                    id="lastName"
                    className="w-full border border-gray-300 rounded px-3 py-2"
                  />
                </div>
              </div>

              {/* Email Field */}
              <div className="mb-4">
                <label htmlFor="email" className="block mb-1 text-sm">Email <span className="text-red-500">*</span></label>
                <input
                  type="email"
                  id="email"
                  className="w-full border border-gray-300 rounded px-3 py-2"
                />
              </div>

              {/* Phone Field */}
              <div className="mb-4">
                <label htmlFor="phone" className="block mb-1 text-sm">Phone <span className="text-red-500">*</span></label>
                <input
                  type="tel"
                  id="phone"
                  className="w-full border border-gray-300 rounded px-3 py-2"
                />
              </div>

              {/* Message Field */}
              <div className="mb-4">
                <label htmlFor="message" className="block mb-1 text-sm">Message</label>
                <textarea
                  id="message"
                  rows="4"
                  className="w-full border border-gray-300 rounded px-3 py-2"
                />
              </div>

              {/* Submit Button */}
              <button
                type="submit"
                className="bg-black text-white px-6 py-2 rounded font-semibold"
              >
                Submit
              </button>
            </form>
          </div>

          {/* Right side - Contact Information */}
          <div>
            <h2 className="text-4xl text-pink-500 font-bold mb-4">Contact Information</h2>
            <p className="mb-4">
              <strong>Email us</strong><br />
              <a href="mailto:hello@theweddingmarketplace.com" className="text-blue-500">
                hello@theweddingmarketplace.com
              </a>
            </p>
            <div className="flex gap-4 mt-6">
              <FaFacebook className='text-xl'/>
              <FaInstagram className='text-xl'/>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
