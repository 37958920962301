import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

const MoreProducts = ({ products }) => {
  const scrollContainerRef = useRef(null);

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: -900, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: 900, behavior: 'smooth' });
    }
  };

  return (
    <div className="m-4 mt-10 border-t">
      <h2 className="text-xl font-semibold mb-4 text-black">Products You May Like</h2>
      {/* Horizontal scrollable container with scroll buttons */}
      <div className="relative">
        {/* Left Scroll Button */}
        <button
          className="absolute left-0 top-1/2 transform -translate-y-1/2 z-10 bg-white p-2 rounded-full shadow-md"
          onClick={scrollLeft}
        >
          <FaChevronLeft />
        </button>
        <div
          ref={scrollContainerRef}
          className="flex overflow-x-scroll space-x-6 scrollbar-hide"
        >
          {products.map((product) => (
            <Link
              to={`/product/${product._id}`}
              key={product.id}
              className="min-w-[200px] rounded-lg p-4 flex flex-col items-center cursor-pointer relative"
            >
              {/* Free Shipping and Promoted Label */}
              {product.freeShipping && (
                <span className="absolute top-2 left-2 bg-white text-black text-xs font-semibold py-1 px-2 rounded">
                  Free
                </span>
              )}
              {product.promoted && (
                <span className="absolute bottom-2 left-2 bg-gray-500 text-white text-xs font-semibold py-1 px-2 rounded">
                  Promoted
                </span>
              )}
              <img src={product.firstImage.image} alt={product.productName} className="w-[150px] h-[150px] object-cover mb-4" />
              <h3 className="text-md font-semibold mb-2 text-center text-black">
                {product.productName.length > 20 ? `${product.productName.substring(0, 20)}...` : product.productName}
              </h3>

              {/* Pricing */}
              <div className="flex flex-col items-center">
                {/* Original price (crossed out) */}
                {product.regularPrice > product.discountedPrice && (
                  <p className="text-gray-500 line-through">${product.regularPrice.toFixed(2)}</p>
                )}
                {/* Discounted price */}
                <p className="text-black font-bold">${product.discountedPrice.toFixed(2)}</p>
              </div>
            </Link>
          ))}
        </div>
        {/* Right Scroll Button */}
        <button
          className="absolute right-0 top-1/2 transform -translate-y-1/2 z-10 bg-white p-2 rounded-full shadow-md"
          onClick={scrollRight}
        >
          <FaChevronRight />
        </button>
      </div>

      <div className="flex justify-end items-end mt-8 text-center">
        {/* <button className="bg-black border border-black text-white px-6 py-2 rounded hover:bg-white hover:text-black transition">
          See All
        </button> */}
      </div>
    </div>
  );
};

export default MoreProducts;
