import React from 'react';
import { FaBoxOpen } from 'react-icons/fa';

const SummaryCards = ({ data }) => {
  const summaryData = [
    {
      title: 'Total Orders',
      amount: `$${data.completed.totalAmount}`,
      percentage: data.completed.growth,
    },
    {
      title: 'Active Orders',
      amount: `$${data.active.totalAmount}`,
      percentage: data.active.growth,
    },
    {
      title: 'Completed Orders',
      amount: `$${data.completed.totalAmount}`,
      percentage: data.completed.growth,
    },
    {
      title: 'Refunded Orders',
      amount: `$${data.refunded.totalAmount}`,
      percentage: data.refunded.growth,
    },
  ];

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
      {summaryData.map((card, index) => (
        <div
          key={index}
          className="bg-white shadow-md rounded-lg p-4 flex flex-col justify-between"
        >
          <div className="flex justify-between items-center mb-4">
            <div className="flex items-center">
              <FaBoxOpen className="text-2xl" />
              <h2 className="text-gray-700 text-md font-semibold ml-2">
                {card.title}
              </h2>
            </div>
            <div className="text-gray-400">⋮</div>
          </div>
          <div>
            <p className="text-gray-800 font-bold text-xl">{card.amount}</p>
            <div className="flex items-center mt-1">
              <p
                className={`font-bold text-md ${
                  card.percentage > 0 ? 'text-green-600' : 'text-red-600'
                }`}
              >
                {card.percentage > 0 ? '↑' : '↓'} {Math.abs(card.percentage)}%
              </p>
              <span className="text-gray-500 ml-2 text-sm">
                Compared to Last Month
              </span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SummaryCards;
