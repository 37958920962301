import React, { useState, useEffect } from 'react';
import { useParams, Link, useSearchParams } from 'react-router-dom';
import ProductCard from './products/ProductCard';
import SearchBar from '../components/SearchBar';
import { getProductsByCategorySlug } from '../Hooks/productHooks';

export default function CategoryPage() {
  const { slug } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  // Get page number from URL or default to 1
  const pageFromUrl = parseInt(searchParams.get('page') || '1', 10);

  const [sortOption, setSortOption] = useState('Date');
  const [productCount, setProductCount] = useState(12);
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [productsData, setProductsData] = useState([]);
  const [currentPage, setCurrentPage] = useState(pageFromUrl);
  const [totalPages, setTotalPages] = useState(1);

  /** ✅ FIX 1: Prevent infinite re-renders by only updating if needed */
  useEffect(() => {
    if (pageFromUrl !== currentPage) {
      setCurrentPage(pageFromUrl);
    }
  }, [pageFromUrl]);

  /** ✅ FIX 2: Preserve other query params while updating 'page' */
  useEffect(() => {
    setSearchParams({
      ...Object.fromEntries(searchParams),
      page: currentPage,
    });
  }, [currentPage, setSearchParams]);

  /** ✅ FIX 3: Trigger fetch when any relevant state changes */
  useEffect(() => {
    fetchProducts();
  }, [slug, currentPage, productCount, sortOption]);

  useEffect(() => {
    // Clear suggestions if searchTerm changes
    setSuggestions([]);
  }, [searchTerm]);

  const fetchProducts = () => {
    getProductsByCategorySlug(slug, currentPage, productCount, sortOption)
      .then((response) => {
        if (response.success) {
          setProductsData(response.products);
          setTotalPages(response.totalPages);
          console.log('Products fetched:', response.products);
        } else {
          console.error('Error fetching products:', response.message);
        }
      })
      .catch((error) => {
        console.error('Error fetching products:', error);
      });
  };

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handleSearchTermSelect = (selectedTerm) => {
    console.log(`Search term selected: ${selectedTerm}`);
    // Optionally filter or perform new fetch if needed
  };

  return (
    <div className="bg-white">
      <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
        
        {/* Breadcrumb Navigation */}
        <nav className="text-gray-600 text-sm mb-6">
          <ul className="flex items-center space-x-2">
            <li>
              <Link to="/" className="hover:underline">Home</Link>
            </li>
            <li>/</li>
            <li>Category</li>
            <li>/</li>
            <li className="text-gray-900 font-semibold capitalize">
              {slug.replace(/-/g, ' ')}
            </li>
          </ul>
        </nav>

        <div className="mb-6">
          <SearchBar onSearchTermSelect={handleSearchTermSelect} />
        </div>

        {/* Sorting and View Control Panel */}
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center space-x-4">
            {/* Sort By Option */}
            <div className="flex items-center">
              <span className="text-gray-700 mr-2">Sort by</span>
              <select
                value={sortOption}
                onChange={(e) => setSortOption(e.target.value)}
                className="border rounded p-2 text-gray-700"
              >
                <option value="Date">Date</option>
                <option value="Price">Price</option>
                <option value="Rating">Rating</option>
              </select>
            </div>

            {/* Number of Products to Show */}
            <div className="flex items-center">
              <span className="text-gray-700 mr-2">Show</span>
              <select
                value={productCount}
                onChange={(e) => setProductCount(Number(e.target.value))}
                className="border rounded p-2 text-gray-700"
              >
                <option value={12}>12 Products</option>
                <option value={24}>24 Products</option>
                <option value={36}>36 Products</option>
              </select>
            </div>
          </div>
        </div>

        {/* Product Grid */}
        <div className="grid  gap-x-3 sm:gap-x-6 gap-y-10 grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          {productsData.map((product) => (
            <ProductCard key={product._id} product={product} />
          ))}
        </div>

        {productsData.length === 0 && (
          <div className="text-center text-gray-500">
            No products available for this category.
          </div>
        )}

        {/* Pagination */}
        <div className="flex justify-between items-center mt-6">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className="px-4 py-2 border border-gray-300 bg-gray-100 text-gray-500 hover:bg-gray-200 disabled:opacity-50"
          >
            Previous
          </button>
          <span className="text-gray-700">
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="px-4 py-2 border border-gray-300 bg-gray-100 text-gray-500 hover:bg-gray-200 disabled:opacity-50"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}
