import React, { useState, useMemo } from 'react';
import { useTable, usePagination } from 'react-table';

// Helper function to generate random users
const generateUsers = () => {
  const users = [];
  const randomImageUrls = [
    'https://randomuser.me/api/portraits/men/1.jpg',
    'https://randomuser.me/api/portraits/men/2.jpg',
    'https://randomuser.me/api/portraits/women/3.jpg',
    'https://randomuser.me/api/portraits/men/4.jpg',
    'https://randomuser.me/api/portraits/women/5.jpg',
  ];

  const roles = ['Customer', 'Vendor'];

  for (let i = 1; i <= 50; i++) {
    users.push({
      id: i,
      name: `User ${i}`,
      role: roles[Math.floor(Math.random() * roles.length)],
      avatar: randomImageUrls[Math.floor(Math.random() * randomImageUrls.length)],
      joinDate: `202${Math.floor(Math.random() * 3)}-0${Math.floor(Math.random() * 9)}-1${Math.floor(Math.random() * 9)}`, // Random dates
      email: `user${i}@example.com`,
    });
  }

  return users;
};

function Users() {
  const [users] = useState(generateUsers()); // Generate random users
  const [roleFilter, setRoleFilter] = useState('All'); // Role filter

  // Filtered users based on role
  const filteredUsers = useMemo(() => {
    if (roleFilter === 'All') {
      return users;
    }
    return users.filter((user) => user.role === roleFilter);
  }, [users, roleFilter]);

  // React Table columns
  const columns = useMemo(
    () => [
      {
        Header: 'Avatar',
        accessor: 'avatar',
        Cell: ({ value }) => (
          <img src={value} alt="Avatar" className="w-10 h-10 rounded-full" />
        ),
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Role',
        accessor: 'role',
      },
      {
        Header: 'Join Date',
        accessor: 'joinDate',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Actions',
        Cell: ({ row }) => (
          <button
            onClick={() => alert(`Viewing profile of ${row.original.name}`)}
            className="bg-black text-white px-3 py-1 rounded-md"
          >
            View Profile
          </button>
        ),
      },
    ],
    []
  );

  // Set up the table instance using react-table with pagination
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page, // Rows for the active page
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    state: { pageIndex, pageSize },
    setPageSize, // Use setPageSize from react-table
  } = useTable(
    {
      columns,
      data: filteredUsers,
      initialState: { pageIndex: 0, pageSize: 10 }, // Set initial page size and index
    },
    usePagination
  );

  return (
    <div className="p-6 bg-white text-black">
      <h1 className="text-2xl font-bold mb-4">Users</h1>

      {/* Role filter */}
      <div className="mb-4">
        <label htmlFor="roleFilter" className="font-semibold mr-2">
          Filter by Role:
        </label>
        <select
          id="roleFilter"
          value={roleFilter}
          onChange={(e) => setRoleFilter(e.target.value)}
          className="p-2 border border-gray-300 rounded-md"
        >
          <option value="All">All</option>
          <option value="Customer">Customer</option>
          <option value="Vendor">Vendor</option>
        </select>
      </div>

      {/* User Table */}
      <table {...getTableProps()} className="min-w-full bg-white border border-gray-300 rounded-lg shadow-md">
        <thead className="bg-gray-100">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()} className="text-left p-4 border-b">
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className="hover:bg-gray-50">
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()} className="p-4 border-b">
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* Pagination Controls */}
      <div className="mt-4 flex justify-between items-center">
        <div className="flex items-center space-x-2">
          <button
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            className="p-2 border rounded-md bg-gray-200"
          >
            Previous
          </button>
          <button
            onClick={() => nextPage()}
            disabled={!canNextPage}
            className="p-2 border rounded-md bg-gray-200"
          >
            Next
          </button>
        </div>

        <div className="flex items-center space-x-2">
          <span>
            Page {pageIndex + 1} of {pageOptions.length}
          </span>
          <select
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
            className="p-2 border border-gray-300 rounded-md"
          >
            {[10, 20, 30].map((size) => (
              <option key={size} value={size}>
                Show {size}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
}

export default Users;
