import React, { useState } from "react";
import { BsCheckCircleFill } from "react-icons/bs";
import { FaFacebook, FaGoogle, FaApple } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import weddinglogo from "../assets/weddinglogo.png";
import { registerUser, oAuthLogin } from "../Hooks/authHooks";
import { toast } from "react-toastify";
import { signInWithPopup } from "firebase/auth";
import { auth, googleProvider } from "../firebaseConfig"; // Ensure you have firebaseConfig set up

export default function Signup() {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errEmail, setErrEmail] = useState("");
  const [errName, setErrName] = useState("");
  const [errPassword, setErrPassword] = useState("");
  const [errConfirmPassword, setErrConfirmPassword] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [agree, setAgree] = useState(false);

  const navigate = useNavigate();

  const handleEmail = (e) => {
    setEmail(e.target.value);
    setErrEmail("");
  };

  const handleName = (e) => {
    setName(e.target.value);
    setErrName("");
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
    setErrPassword("");
  };

  const handleConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
    setErrConfirmPassword("");
  };

  const handleAgree = (e) => {
    setAgree(e.target.checked);
  };

  const handleSignUp = async (e) => {
    e.preventDefault();

    // Validation
    let isValid = true;
    if (!email) {
      setErrEmail("Enter your email");
      isValid = false;
    }
    if (!name) {
      setErrName("Enter your name");
      isValid = false;
    }
    if (!password) {
      setErrPassword("Create a password");
      isValid = false;
    }
    if (!confirmPassword) {
      setErrConfirmPassword("Confirm your password");
      isValid = false;
    }
    if (password !== confirmPassword) {
      setErrConfirmPassword("Passwords do not match");
      isValid = false;
    }

    if (!isValid) return;

    // Ensure user agrees to Terms and Conditions before signing up
    if (!agree) {
      toast.error("You must agree to all Terms and Conditions before signing up.");
      return;
    }

    // Call registerUser function
    const credentials = { email, name, password };
    const result = await registerUser(credentials);

    if (result.success) {
      setSuccessMsg(
        `Thank you for signing up, ${name}. Redirecting you to the main page...`
      );
      setTimeout(() => navigate("/"), 3000); // Redirect after 3 seconds
    } else {
      toast.error(result.message);
    }
  };

  const handleGoogleSignUp = async (e) => {
    e.preventDefault();
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const token = await result.user.getIdToken();
      const provider = "google";

      const response = await oAuthLogin({ token, provider });

      if (response.success) {
        toast.success("Sign-up successful! Welcome, " + response.user.name);
        navigate("/"); // Redirect to the main page
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.error("Error signing up:", error.message);
      toast.error("Failed to sign up with Google. Please try again.");
    }
  };

  return (
    <div className="w-full h-screen sm:h-[85vh] lg:h-screen bg-white flex items-center justify-center">
      <div className="w-1/2 hidden lg:inline-flex h-full bg-black text-white">
        <div className="w-[450px] h-full bg-primeColor py-6 px-10 flex flex-col gap-6 justify-center">
          {/* Left side content */}
          <div className="flex flex-col gap-1 -mt-1">
            <h1 className="font-titleFont text-xl font-medium">
              Stay signed in for more
            </h1>
            <p className="text-base">When you sign in, you are with us!</p>
          </div>
          <div className="w-[300px] flex items-start gap-3">
            <span className="text-green-500 mt-1">
              <BsCheckCircleFill />
            </span>
            <p className="text-base text-gray-300">
              <span className="text-white font-semibold font-titleFont">
                Get started fast with The Wedding Marketplace
              </span>
              <br />
              The Wedding Marketplace was the perfect place to sell all of my
              leftover wedding items. It is so simple to use and does not cost
              anything to list your items.
            </p>
          </div>
          <div className="w-[300px] flex items-start gap-3">
            <span className="text-green-500 mt-1">
              <BsCheckCircleFill />
            </span>
            <p className="text-base text-gray-300">
              <span className="text-white font-semibold font-titleFont">
                List, Ship and get Paid
              </span>
              <br />
              Easily list your items in our large selection of user-friendly
              categories. Don’t forget an accurate and enticing description to
              help your buyer find you faster!
            </p>
          </div>
        </div>
      </div>

      <div className="w-full lgl:w-1/2 h-full">
        {successMsg ? (
          <div className="w-full lg:w-full h-full flex flex-col justify-center lg:px-16">
            <p className="w-full px-4 py-10 text-green-500 font-medium font-titleFont">
              {successMsg}
            </p>
            <Link to="/signup">
              <button
                className="w-full h-10 bg-[#262626] text-gray-200 rounded-md text-base font-titleFont font-semibold 
             tracking-wide hover:bg-black hover:text-white duration-300"
              >
                Check Your MailBox
              </button>
            </Link>
          </div>
        ) : (
          <form className="w-full flex-col lgl:w-[450px] h-screen flex items-center justify-center relative">
            <div className="px-6 py-4 w-full h-[90%] flex flex-col justify-center items-center">
              <img src={weddinglogo} alt="Logo" className="w-36 h-36" />

              <h1 className="font-titleFont underline underline-offset-4 decoration-[1px] font-semibold text-3xl mdl:text-4xl mb-4">
                Sign up
              </h1>
              <div className="flex w-[80%] flex-col gap-3">
                {/* Email */}
                <div className="flex flex-col md:flex-row gap-1 md:gap-8">
                  <div className="flex flex-col gap-1 w-full md:w-[50%]">
                    <p className="font-titleFont text-base font-semibold text-gray-600">
                      Email
                    </p>
                    <input
                      onChange={handleEmail}
                      value={email}
                      className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
                      type="email"
                      placeholder="john@mail.com"
                    />
                    {errEmail && (
                      <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                        {errEmail}
                      </p>
                    )}
                  </div>

                  {/* Name */}
                  <div className="flex flex-col gap-1 w-full md:w-[50%]">
                    <p className="font-titleFont text-base font-semibold text-gray-600">
                      Name
                    </p>
                    <input
                      onChange={handleName}
                      value={name}
                      className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
                      type="text"
                      placeholder="John Doe"
                    />
                    {errName && (
                      <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                        {errName}
                      </p>
                    )}
                  </div>
                </div>

                <div className="flex flex-col md:flex-row gap-1 md:gap-8">
                  <div className="flex flex-col gap-1 w-full md:w-[50%]">
                    <p className="font-titleFont text-base font-semibold text-gray-600">
                      Password
                    </p>
                    <input
                      onChange={handlePassword}
                      value={password}
                      className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
                      type="password"
                      placeholder="Enter password"
                    />
                    {errPassword && (
                      <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                        {errPassword}
                      </p>
                    )}
                  </div>

                  {/* Confirm Password */}
                  <div className="flex flex-col gap-1 w-full md:w-[50%]">
                    <p className="font-titleFont text-base font-semibold text-gray-600">
                      Confirm Password
                    </p>
                    <input
                      onChange={handleConfirmPassword}
                      value={confirmPassword}
                      className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
                      type="password"
                      placeholder="Confirm your password"
                    />
                    {errConfirmPassword && (
                      <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                        {errConfirmPassword}
                      </p>
                    )}
                  </div>
                </div>

                {/* Sign Up Button */}
                <button
                  onClick={handleSignUp}
                  className="bg-[#262626] hover:bg-black text-gray-200 hover:text-white cursor-pointer w-full text-base font-medium h-10 rounded-md duration-300"
                >
                  Sign Up
                </button>

                {/* Social Sign-Up Buttons */}
                <button
                  onClick={handleGoogleSignUp}
                  className="flex items-center justify-center bg-white dark:bg-gray-900 border border-gray-300 rounded-lg shadow-md px-6 py-2 text-sm font-medium text-gray-800 dark:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                >
                  <svg
                    className="h-6 w-6 mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    xlink="http://www.w3.org/1999/xlink"
                    width="800px"
                    height="800px"
                    viewBox="-0.5 0 48 48"
                    version="1.1"
                  >
                    <title>Google-color</title>
                    <desc>Created with Sketch.</desc>
                    <defs></defs>
                    <g
                      id="Icons"
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <g
                        id="Color-"
                        transform="translate(-401.000000, -860.000000)"
                      >
                        <g
                          id="Google"
                          transform="translate(401.000000, 860.000000)"
                        >
                          <path
                            d="M9.82727273,24 C9.82727273,22.4757333 10.0804318,21.0144 10.5322727,19.6437333 L2.62345455,13.6042667 C1.08206818,16.7338667 0.213636364,20.2602667 0.213636364,24 C0.213636364,27.7365333 1.081,31.2608 2.62025,34.3882667 L10.5247955,28.3370667 C10.0772273,26.9728 9.82727273,25.5168 9.82727273,24"
                            id="Fill-1"
                            fill="#FBBC05"
                          ></path>
                          <path
                            d="M23.7136364,10.1333333 C27.025,10.1333333 30.0159091,11.3066667 32.3659091,13.2266667 L39.2022727,6.4 C35.0363636,2.77333333 29.6954545,0.533333333 23.7136364,0.533333333 C14.4268636,0.533333333 6.44540909,5.84426667 2.62345455,13.6042667 L10.5322727,19.6437333 C12.3545909,14.112 17.5491591,10.1333333 23.7136364,10.1333333"
                            id="Fill-2"
                            fill="#EB4335"
                          ></path>
                          <path
                            d="M23.7136364,37.8666667 C17.5491591,37.8666667 12.3545909,33.888 10.5322727,28.3562667 L2.62345455,34.3946667 C6.44540909,42.1557333 14.4268636,47.4666667 23.7136364,47.4666667 C29.4455,47.4666667 34.9177955,45.4314667 39.0249545,41.6181333 L31.5177727,35.8144 C29.3995682,37.1488 26.7323182,37.8666667 23.7136364,37.8666667"
                            id="Fill-3"
                            fill="#34A853"
                          ></path>
                          <path
                            d="M46.1454545,24 C46.1454545,22.6133333 45.9318182,21.12 45.6113636,19.7333333 L23.7136364,19.7333333 L23.7136364,28.8 L36.3181818,28.8 C35.6879545,31.8912 33.9724545,34.2677333 31.5177727,35.8144 L39.0249545,41.6181333 C43.3393409,37.6138667 46.1454545,31.6490667 46.1454545,24"
                            id="Fill-4"
                            fill="#4285F4"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <span>Continue with Google</span>
                </button>
                <button className="w-full bg-gray-800 text-white py-2 rounded-md hover:bg-gray-900 flex items-center justify-center gap-2">
                  <FaApple /> Sign Up with Apple
                </button>

                <div className="flex items-center justify-between w-full mt-4">
                  <Link
                    to="/login"
                    className="text-sm font-titleFont font-medium"
                  >
                    Already have an account?
                  </Link>
                  <label className="flex items-center text-sm font-titleFont font-medium">
                    <input
                      type="checkbox"
                      checked={agree}
                      onChange={handleAgree}
                      className="mr-2"
                    />
                    Agree to all Terms and Conditions
                  </label>
                </div>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
}
