import React, { useState, useEffect } from "react";
import {
  FaFilter,
  FaTimes,
  FaChevronDown,
  FaChevronRight,
  FaHeart,
  FaShare,
} from "react-icons/fa";
import { getLikedProducts, unlikeProduct } from "../Hooks/productHooks";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export default function Wishlist() {
  const navigate = useNavigate();

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [likedProducts, setLikedProducts] = useState([]);
  const [displayedProducts, setDisplayedProducts] = useState([]);
  const [expandedSections, setExpandedSections] = useState({
    price: true,
  });
  const [priceRange, setPriceRange] = useState({ min: "", max: "" });

  useEffect(() => {
    const fetchLikedProducts = async () => {
      const user = localStorage.getItem("twmUser");
      const userId = JSON.parse(user)._id;

      const response = await getLikedProducts(userId);
      if (response.success) {
        setLikedProducts(response.likedProducts);
        setDisplayedProducts(response.likedProducts);
      } else {
        toast.error(response.message);
      }
    };

    fetchLikedProducts();
  }, []);

  const handlePriceFilter = () => {
    const { min, max } = priceRange;

    const filteredProducts = likedProducts.filter((product) => {
      const price = product.discountedPrice || product.regularPrice;
      return (
        (!min || price >= parseFloat(min)) &&
        (!max || price <= parseFloat(max))
      );
    });

    setDisplayedProducts(filteredProducts);
  };

  const handlePriceInputChange = (e) => {
    const { name, value } = e.target;
    setPriceRange((prev) => ({ ...prev, [name]: value }));
  };

  const handleUnlike = async (productId) => {
    const user = localStorage.getItem("twmUser");
    const userId = JSON.parse(user)._id;

    const response = await unlikeProduct(userId, productId);
    if (response.success) {
      setLikedProducts(
        likedProducts.filter((product) => product._id !== productId)
      );
      setDisplayedProducts(
        displayedProducts.filter((product) => product._id !== productId)
      );
      toast.success(response.message);
    } else {
      toast.error(response.message);
    }
  };

  const handleShare = (productId) => {
    const shareUrl = `${window.location.origin}/product/${productId}`;
    const shareData = {
      text: "Check out this product!",
      url: shareUrl,
    };

    if (navigator.share) {
      navigator
        .share(shareData)
        .then(() => console.log("Successful share"))
        .catch((error) => console.log("Error sharing", error));
    } else {
      navigator.clipboard
        .writeText(shareUrl)
        .then(() => toast.success("Link copied to clipboard!"))
        .catch((error) => console.log("Error copying to clipboard", error));
    }
  };

  const navigateToProduct = (productId) => {
    navigate(`/product/${productId}`);
  };

  const toggleFilter = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const toggleSection = (section) => {
    setExpandedSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  return (
    <div className="bg-white text-black min-h-screen">
      <div className="flex space-x-8 ml-10 border-b border-gray-200 py-4"></div>

      <div className="flex justify-between items-center px-4 py-2 border-b border-gray-200 md:hidden">
        <button onClick={toggleFilter} className="text-black">
          <FaFilter className="text-lg" />
        </button>
      </div>

      <div className="flex">
        <aside className="hidden md:block w-1/4 p-4 border-r border-gray-200 space-y-6">
          <FilterSections
            priceRange={priceRange}
            onPriceInputChange={handlePriceInputChange}
            onApplyFilter={handlePriceFilter}
            setPriceRange={setPriceRange}
          />
        </aside>

        <main className="w-full md:w-3/4 p-4">
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
            {displayedProducts.map((product) => (
              <div
                key={product._id}
                className="border border-gray-200 p-4 rounded-lg shadow-sm"
                onClick={(e) => {
                  if (e.target.closest(".action-icon") === null) {
                    navigateToProduct(product._id);
                  }
                }}
              >
                <img
                  src={product.firstImage.image}
                  alt={product.productName}
                  className="w-full h-40 object-cover mb-2 rounded-md"
                />
                <h3 className="text-sm font-semibold">{product.productName}</h3>
                <p className="text-sm font-semibold text-black">
                  {product.discountedPrice || product.regularPrice}
                </p>
                <div className="flex items-center justify-between mt-4 text-gray-600">
                  <div
                    className="flex items-center space-x-2 action-icon"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleUnlike(product._id);
                    }}
                  >
                    <FaHeart className="text-red-500 cursor-pointer" />
                    <span className="text-xs">{product.likedCount}</span>
                  </div>
                  <FaShare
                    className="cursor-pointer action-icon"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleShare(product._id);
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        </main>
      </div>

      <div
        className={`fixed inset-0 z-50 flex justify-end transform transition-transform duration-300 ease-in-out ${
          isFilterOpen ? "translate-x-0" : "translate-x-full"
        } md:hidden`}
      >
        <div className="w-2/3 bg-white h-full p-4">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-lg font-semibold">Filter</h2>
            <button onClick={toggleFilter} className="text-black">
              <FaTimes className="text-lg" />
            </button>
          </div>
          <div className="space-y-4">
            {Object.keys(expandedSections).map((section) => (
              <div key={section}>
                <div
                  className="flex justify-between items-center cursor-pointer"
                  onClick={() => toggleSection(section)}
                >
                  <span className="capitalize">{section}</span>
                  {expandedSections[section] ? <FaChevronDown /> : <FaChevronRight />}
                </div>
                {expandedSections[section] && (
                  <FilterSectionContent
                    section={section}
                    priceRange={priceRange}
                    onPriceInputChange={handlePriceInputChange}
                    onApplyFilter={handlePriceFilter}
                    setPriceRange={setPriceRange}
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

const FilterSections = ({ priceRange, onPriceInputChange, onApplyFilter, setPriceRange }) => (
  <FilterSectionContent
    section="price"
    priceRange={priceRange}
    onPriceInputChange={onPriceInputChange}
    onApplyFilter={onApplyFilter}
    setPriceRange={setPriceRange}
  />
);

const FilterSectionContent = ({
  section,
  priceRange,
  onPriceInputChange,
  onApplyFilter,
  setPriceRange,
}) => {
  const predefinedRanges = [
    { label: "All Prices", min: null, max: null },
    { label: "Under $25", min: null, max: 25 },
    { label: "$25 - $50", min: 25, max: 50 },
    { label: "$50 - $100", min: 50, max: 100 },
    { label: "$100 - $250", min: 100, max: 250 },
    { label: "$250 - $500", min: 250, max: 500 },
    { label: "Over $500", min: 500, max: null },
  ];

  const handlePredefinedRangeSelect = (range) => {
    setPriceRange({ min: range.min, max: range.max });
    onApplyFilter();
  };

  switch (section) {
    case "price":
      return (
        <div className="mt-2">
          <ul className="mt-2 space-y-2">
            {predefinedRanges.map((range, index) => (
              <li key={index}>
                <label className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    checked={
                      priceRange.min === range.min && priceRange.max === range.max
                    }
                    onChange={() => handlePredefinedRangeSelect(range)}
                  />
                  <span>{range.label}</span>
                </label>
              </li>
            ))}
          </ul>

          <div className="mt-4 flex items-center space-x-2">
            <input
              type="text"
              name="min"
              value={priceRange.min || ""}
              placeholder="Min Price"
              className="w-1/2 border px-2 py-1 text-sm"
              onChange={onPriceInputChange}
            />
            <span>to</span>
            <input
              type="text"
              name="max"
              value={priceRange.max || ""}
              placeholder="Max Price"
              className="w-1/2 border px-2 py-1 text-sm"
              onChange={onPriceInputChange}
            />
            <button
              className="text-blue-600"
              onClick={(e) => {
                e.stopPropagation();
                onApplyFilter();
              }}
            >
              Apply
            </button>
          </div>
        </div>
      );
    default:
      return null;
  }
};
