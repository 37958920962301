import axios from "axios";

axios.defaults.baseURL =
  process.env.REACT_APP_BACKEND_URL || "http://localhost:5000";

export async function addProduct(productData) {
  try {
    const token = localStorage.getItem("twmToken");

    if (!token) {
      return {
        success: false,
        message: "Please login first",
        status: 401,
      };
    }

    const { data, status } = await axios.post("/api/product/add", productData, {
      headers: {
        "TWM-auth-token": token,
      },
    });

    return {
      success: data.success,
      message: data.message,
      product: data.product,
      status,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}

export async function updateProduct(productId, productData) {
  try {
    const token = localStorage.getItem("twmToken");

    if (!token) {
      return {
        success: false,
        message: "Please login first",
        status: 401,
      };
    }

    const { data, status } = await axios.put(
      `/api/product/update/${productId}`,
      productData,
      {
        headers: {
          "TWM-auth-token": token,
        },
      }
    );

    return {
      success: data.success,
      message: data.message,
      product: data.product,
      status,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}

export async function deleteProduct(productId) {
  try {
    const token = localStorage.getItem("twmToken");

    if (!token) {
      return {
        success: false,
        message: "Please login first",
        status: 401,
      };
    }

    const { data, status } = await axios.delete(
      `/api/product/delete/${productId}`,
      {
        headers: {
          "TWM-auth-token": token,
        },
      }
    );

    return { success: data.success, message: data.message, status };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}

export async function getVendorProduct(productId) {
  try {
    // Validate input
    if (!productId) {
      throw new Error("Product ID is required.");
    }

    const token = localStorage.getItem("twmToken");

    // Set up headers
    const headers = {
      "TWM-auth-token": token,
    };

    // Make the API request
    const { data, status } = await axios.get(
      `/api/product/get-vendor-product/${productId}`,
      { headers }
    );

    return {
      success: data.success,
      message: data.message,
      status,
      product: data.product || null,
    };
  } catch (error) {
    return {
      success: false,
      message:
        error.response?.data?.message || error.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}

export async function getProduct(productId) {
  try {
    const { data, status } = await axios.get(`/api/product/${productId}`);

    return {
      success: data.success,
      message: data.message,
      product: data.product,
      relatedProducts: data.relatedProducts,
      status,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}

export async function getUserProducts(userId) {
  try {
    const { data, status } = await axios.get(`/api/product/user/${userId}`);

    return {
      success: data.success,
      message: data.message,
      products: data.products,
      status,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}
export async function getVendorProducts(userId) {
  try {
    const { data, status } = await axios.get(`/api/product/vendor/${userId}`);
    return {
      success: data.success,
      message: data.message,
      products: data.products,
      status,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}

export async function getProductsByTags(tags, page = 1, limit = 10) {
  try {
    const { data, status } = await axios.get("/api/product/by-tags", {
      params: { tags, page, limit },
    });

    return {
      success: data.success,
      message: data.message,
      products: data.products,
      totalProducts: data.totalProducts,
      totalPages: data.totalPages,
      currentPage: data.currentPage,
      status,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}

export async function getProductsByCategorySlug(
  categorySlug,
  page = 1,
  limit = 10
) {
  try {
    const { data, status } = await axios.get(
      `/api/product/by-category/${categorySlug}`,
      {
        params: { page, limit },
      }
    );

    return {
      success: data.success,
      message: data.message,
      products: data.products,
      totalProducts: data.totalProducts,
      totalPages: data.totalPages,
      currentPage: data.currentPage,
      status,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}

export async function getUserInterestProducts(
  categories = [],
  tags = [],
  users = [],
  page = 1,
  limit = 10
) {
  try {
    const { data, status } = await axios.post(
      "/api/product/interests",
      { categories, tags, users },
      {
        params: { page, limit },
      }
    );

    return {
      success: data.success,
      message: data.message,
      products: data.products,
      totalProducts: data.totalProducts,
      totalPages: data.totalPages,
      currentPage: data.currentPage,
      status,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}

export async function likeProduct(userId, productId) {
  try {
    const token = localStorage.getItem("twmToken");

    if (!token) {
      return {
        success: false,
        message: "Please login first",
        status: 401,
      };
    }

    const { data, status } = await axios.post(
      `/api/product/like/${userId}`,
      { productId },
      {
        headers: {
          "TWM-auth-token": token,
        },
      }
    );

    return {
      success: data.success,
      message: data.message,
      likedProducts: data.likedProducts,
      likedCount: data.likedCount,
      status,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}

export async function unlikeProduct(userId, productId) {
  try {
    const token = localStorage.getItem("twmToken");

    if (!token) {
      return {
        success: false,
        message: "Please login first",
        status: 401,
      };
    }

    const { data, status } = await axios.post(
      `/api/product/unlike/${userId}`,
      { productId },
      {
        headers: {
          "TWM-auth-token": token,
        },
      }
    );

    return {
      success: data.success,
      message: data.message,
      likedProducts: data.likedProducts,
      likedCount: data.likedCount,
      status,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}

export async function getLikedProducts(userId) {
  try {
    const token = localStorage.getItem("twmToken");

    if (!token) {
      return {
        success: false,
        message: "Please login first",
        status: 401,
      };
    }

    const { data, status } = await axios.get(`/api/product/liked/${userId}`, {
      headers: {
        "TWM-auth-token": token,
      },
    });

    return {
      success: data.success,
      message: data.message,
      likedProducts: data.likedProducts,
      status,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}

export async function getProducts({ page = 1, limit = 10 }) {
  try {
    const { data, status } = await axios.get(`/api/product/random-products`, {
      params: { page, limit },
    });

    return {
      success: data.success,
      message: data.message,
      status,
      products: data.products || [],
      pagination: data.pagination || {},
    };
  } catch (error) {
    return {
      success: false,
      message:
        error.response?.data?.message || error.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}

export async function getFedexRates(
  userId = null,
  guestAddress = null,
  cart = []
) {
  try {
    const { data, status } = await axios.post(`/api/fedex/get-shipping-rates`, {
      userId,
      guestAddress,
      cart,
    });

    return {
      success: data.success,
      message: data.message,
      status,
      rates: data.rates || [],
    };
  } catch (error) {
    return {
      success: false,
      message:
        error.response?.data?.message || error.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}

export async function createFedexBooking(orderId) {
  try {
    const token = localStorage.getItem("twmToken");

    if (!token) {
      return {
        success: false,
        message: "Please login first",
        status: 401,
      };
    }
    const { data, status } = await axios.post(
      `/api/fedex/create-booking`,
      {
        orderId,
      },
      {
        headers: {
          "TWM-auth-token": token,
        },
      }
    );

    return {
      success: data.success,
      message: data.message,
      status,
      // booking: data.booking || null,
      bookingResponse: data.bookingResponse || null,
    };
  } catch (error) {
    return {
      success: false,
      message:
        error.response?.data?.message || error.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}

export async function searchItems(query) {
  try {
    if (!query || query.trim() === "") {
      return {
        success: false,
        message: "Search query is required.",
        status: 400,
      };
    }

    const { data, status } = await axios.get("/api/product/search-product/search", {
      params: { query },
    });

    return {
      success: data.success,
      message: data.message,
      status,
      results: data.results || [],
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}

export async function searchPaginatedProducts(query, page = 1, limit = 10) {
  try {
    if (!query || query.trim() === "") {
      return {
        success: false,
        message: "Search query is required.",
        status: 400,
      };
    }

    const { data, status } = await axios.get("/api/product/search-product/search-items", {
      params: { query, page, limit },
    });

    return {
      success: data.success,
      message: data.message,
      status,
      products: data.products || [],
      totalProducts: data.pagination?.totalProducts || 0,
      totalPages: data.pagination?.totalPages || 0,
      currentPage: data.pagination?.currentPage || 1,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}
