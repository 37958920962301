import React, { useState } from "react";
import DeleteConfirmModal from "../Modals/DeleteConfirmModal";
import { useNavigate } from "react-router-dom";
import { sendPhoneVerificationOtp, addCoverPhoto, addProfileImage } from "../../Hooks/UserHooks"; // Import the OTP and image upload functions
import { toast } from "react-toastify";

export default function ProfileSettings({ userData }) {
  const [isModalOpen, setModalOpen] = useState(false);
  const [phone, setPhone] = useState(""); // State to handle input phone number
  const [profilePreview, setProfilePreview] = useState(userData?.profilePicture || "");
  const [coverPreview, setCoverPreview] = useState(userData?.coverPhoto || "");
  const navigate = useNavigate();

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const confirmDelete = () => {
    closeModal();
    navigate("/otp"); // Redirect to /otp route
  };

  const handleVerifyPhone = async () => {
    if (!phone) {
      toast.error("Please enter a valid phone number");
      return;
    }
    const response = await sendPhoneVerificationOtp(phone);
    if (response.success) {
      localStorage.setItem("OTPreason", "number-verify");
      toast.success("OTP sent successfully!");
      navigate("/otp");
    } else {
      toast.error(response.message || "Failed to send OTP");
    }
  };

  const handleImageUpload = async (event, type) => {
    const file = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onloadend = async () => {
      const base64String = reader.result;

      if (type === "cover") {
        setCoverPreview(base64String); // Update cover preview immediately
        const response = await addCoverPhoto(base64String);
        if (response.success) {
          toast.success("Cover photo updated successfully!");
        } else {
          toast.error(response.message || "Failed to update cover photo");
        }
      } else if (type === "profile") {
        setProfilePreview(base64String); // Update profile preview immediately
        const response = await addProfileImage(base64String);
        if (response.success) {
          toast.success("Profile picture updated successfully!");
        } else {
          toast.error(response.message || "Failed to update profile picture");
        }
      }
    };
    reader.readAsDataURL(file);
  };

  return (
    <div className="max-w-5xl mx-auto p-4 sm:p-6 md:p-8">
      <h3 className="text-xl sm:text-2xl font-bold mb-6">Account Settings</h3>

      {/* Profile Picture Section */}
      <div className="mb-6 relative">
        <h4 className="text-lg sm:text-xl font-semibold mb-2">Profile Picture</h4>
        <div className="w-32 h-32 rounded-full overflow-hidden border border-gray-300 relative">
          <img
            src={profilePreview || "https://via.placeholder.com/150?text=No+Image+Found"}
            alt="Profile Picture"
            className="w-full h-full object-cover"
          />
          <label
            htmlFor="profileImageUpload"
            className="absolute bottom-2 right-2 bg-gray-800 text-white p-1 rounded-full cursor-pointer"
          >
            ✏️
          </label>
          <input
            type="file"
            id="profileImageUpload"
            className="hidden"
            accept="image/*"
            onChange={(e) => handleImageUpload(e, "profile")}
          />
        </div>
      </div>

      {/* Cover Photo Section */}
      <div className="mb-6 relative">
        <h4 className="text-lg sm:text-xl font-semibold mb-2">Cover Photo</h4>
        <div className="w-full h-48 rounded-lg overflow-hidden border border-gray-300 relative">
          <img
            src={coverPreview || "https://via.placeholder.com/600x200?text=No+Image+Found"}
            alt="Cover Photo"
            className="w-full h-full object-cover"
          />
          <label
            htmlFor="coverImageUpload"
            className="absolute bottom-2 right-2 bg-gray-800 text-white p-1 rounded-full cursor-pointer"
          >
            ✏️
          </label>
          <input
            type="file"
            id="coverImageUpload"
            className="hidden"
            accept="image/*"
            onChange={(e) => handleImageUpload(e, "cover")}
          />
        </div>
      </div>

      {/* Name Section */}
      <div className="mb-6">
        <h4 className="text-lg sm:text-xl font-semibold mb-2">Name</h4>
        <p className="text-base sm:text-lg">
          {userData?.name || "No name available"}
        </p>
      </div>

      {/* Email Section */}
      <div className="mb-6">
        <h4 className="text-lg sm:text-xl font-semibold mb-2">Email address</h4>
        <p className="text-base sm:text-lg">
          Your email address is {" "}
          <strong>{userData?.email || "Not provided"}</strong>
        </p>
      </div>

      {/* Phone Number Section */}
      <div className="mb-6">
        <h4 className="text-lg sm:text-xl font-semibold mb-2">Phone number</h4>
        {userData?.phone ? (
          <p className="text-base sm:text-lg">
            Your phone number is <strong>{userData.phone}</strong>
          </p>
        ) : (
          <div className="flex flex-col sm:flex-row items-start sm:items-center">
            <input
              type="text"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              placeholder="Enter phone number"
              className="border border-gray-300 p-2 w-full sm:w-auto mb-4 sm:mb-0"
            />
            <button
              onClick={handleVerifyPhone}
              className="text-blue-600 font-semibold sm:ml-4"
            >
              Verify phone number
            </button>
          </div>
        )}
      </div>

      {/* Password Section */}
      <div className="mb-6">
        <h4 className="text-lg sm:text-xl font-semibold mb-2">Password</h4>
        <p className="text-base sm:text-lg">
          Want to change your password? {" "}
          <button className="text-blue-600 font-semibold">
            Send a session email
          </button>{" "}
          to initiate the process.
        </p>
      </div>

      {/* Delete Account Section */}
      <div className="mt-8">
        <h4 className="text-lg sm:text-xl font-semibold mb-2">Delete account</h4>

        <button onClick={openModal} className="text-red-600 font-semibold">
          I want to delete my account
        </button>
      </div>

      {/* Delete Confirmation Modal */}
      <DeleteConfirmModal
        isOpen={isModalOpen}
        onClose={closeModal}
        onConfirm={confirmDelete}
      />
    </div>
  );
}
