import React from 'react';
import { Link } from 'react-router-dom';
import HeroSection from '../components/HeroSection';
import CategoryGrid from '../components/CategoryGrid';
import Quotes from '../components/Home/Quotes';
import Features from '../components/Home/Features';
import TestimonialSection from '../components/Home/TestimonialSection';

export default function Home() {
  return (
    <>
      <div className="bg-white">
        <HeroSection />
        <CategoryGrid />
      </div>
      <Quotes />
      <Features/>
      <TestimonialSection/>
    </>
  );
}
