import React from "react";
import { Link } from "react-router-dom";
import art from "../assets/art.jpg";
import decor from "../assets/Decor.jpg";
import dinnerware from "../assets/dinnerware.jpg";
import fabric from "../assets/fabric.jpeg";
import favors from "../assets/favors.jpg";
import flowers from "../assets/flowers.jpg";
import furniture from "../assets/furniture.jpg";
import gifts from "../assets/gifts.jpeg";
import jewelry from "../assets/jewelry.jpg";
import lights from "../assets/lights.jpg";
import paper from "../assets/paper.jpg";
import signs from "../assets/signs.jpg";
import linen from '../assets/linens.jpg'
import outdoor from '../assets/outdoor.jpg'

const categories = [
  { name: "Art", image: art },
  { name: "Decor", image: decor },
  { name: "Dinnerware", image: dinnerware },
  { name: "Accessories", image: jewelry },
  { name: "Favors", image: favors },
  { name: "Flowers", image: flowers },
  { name: "Furniture", image: furniture },
  { name: "Gifts", image: gifts },
  { name: "Linens", image: linen },
  { name: "Lighting", image: lights },
  { name: "Paper", image: paper },
  { name: "Outdoor", image: outdoor },
];

// Function to convert category name to slug format
const generateSlug = (name) => name.toLowerCase().replace(/\s+/g, "-");

const CategoryGrid = () => {
  return (
    <div className="container bg-white px-5 mx-auto pt-5 pb-10 border-t-2 border-b-2 mb-10">
      {/* Heading */}
      <h2 className="text-3xl font-semibold text-center mb-6 text-black">
        Click to View Category
      </h2>

      {/* Category Grid */}
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4">
        {categories.map((category, index) => (
          <Link
            key={index}
            to={`/category/${generateSlug(category.name)}`}
            className="relative group block"
          >
            <img
              src={category.image}
              alt={category.name}
              className="w-full h-[18rem] object-cover rounded-lg"
            />
            <div className="absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center transition-opacity duration-300 opacity-100">
              <span className="text-white text-xl font-Brittany">{category.name}</span>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default CategoryGrid;
