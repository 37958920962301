import axios from "axios";

axios.defaults.baseURL =
  process.env.REACT_APP_BACKEND_URL || "http://localhost:5000";

// Helper to get token and handle unauthorized
function getAuthHeaders() {
  const token = localStorage.getItem("twmToken");

  if (!token) {
    return {
      success: false,
      message: "Please login first",
      status: 401,
    };
  }

  return {
    headers: {
      "TWM-auth-token": token,
    },
  };
}

// Create Refund
export async function createRefund(refundData) {
  try {
    const token = localStorage.getItem("twmToken");

    if (!token) {
      return {
        success: false,
        message: "Please login first",
        status: 401,
      };
    }

    const { data, status } = await axios.post(
      "/api/refund/create-refund",
      refundData,
      {
        headers: {
          "TWM-auth-token": token,
        },
      }
    );

    return {
      success: data.success,
      message: data.message,
      status,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}

// Get Refunds
export async function getRefunds() {
  try {
    const token = localStorage.getItem("twmToken");

    if (!token) {
      return {
        success: false,
        message: "Please login first",
        status: 401,
      };
    }

    const { data, status } = await axios.get(
      "/api/refund/get-refunds",
      {
        headers: {
          "TWM-auth-token": token,
        },
      }
    );

    return {
      success: true,
      refunds: data.refunds,
      status,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}

// Approve Refund
export async function approveRefund(refundId) {
  try {
    const token = localStorage.getItem("twmToken");

    if (!token) {
      return {
        success: false,
        message: "Please login first",
        status: 401,
      };
    }
    const { data, status } = await axios.patch(
      `/api/refund/approve-refund/${refundId}`,
      null,
      {
        headers: {
          "TWM-auth-token": token,
        },
      }
    );

    return {
      success: data.success,
      message: data.message,
      status,
      deliveryBooking: data.deliveryBooking,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}

// Reject Refund
export async function rejectRefund(refundId) {
  try {
    const token = localStorage.getItem("twmToken");

    if (!token) {
      return {
        success: false,
        message: "Please login first",
        status: 401,
      };
    }
    const { data, status } = await axios.patch(
      `/api/refund/reject-refund/${refundId}`,
      null,
      {
        headers: {
          "TWM-auth-token": token,
        },
      }
    );

    return {
      success: data.success,
      message: data.message,
      status,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || "An error occurred",
      status: error.response?.status || 500,
    };
  }
}
