import React, { useState, useRef, useEffect } from "react";
import { FaSearch, FaTimes } from "react-icons/fa";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import logo from "../assets/logo-with-text-2.png";
import logomobile from "../assets/weddinglogo.png";
import { getAllCategories } from "../Hooks/categoryHooks";
import { searchItems } from "../Hooks/productHooks";

const Navbar = () => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [dropdownIndex, setDropdownIndex] = useState(null);
  const [menuItems, setMenuItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const navigate = useNavigate();
  const hideDropdownTimer = useRef(null);

  // Refs for desktop and mobile search areas
  const desktopSearchRef = useRef(null);
  const mobileSearchRef = useRef(null);

  // Update windowWidth on resize
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Fetch categories once on mount
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const { categories } = await getAllCategories();
        if (Array.isArray(categories)) {
          const formattedCategories = categories
            .sort((a, b) => (a.order || 0) - (b.order || 0))
            .map((category) => ({
              name: category.name,
              slug: category.slug,
              subitems: category.subcategories
                ? category.subcategories.map((sub) => ({
                    name: sub.name,
                    slug: sub.slug,
                  }))
                : [],
            }));
          setMenuItems([
            { name: "Home", slug: "/", subitems: [] },
            ...formattedCategories,
          ]);
        } else {
          console.error("Categories data is not an array:", categories);
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };
    fetchCategories();
  }, []);

  // Clear any existing dropdown hide timers on unmount
  useEffect(() => {
    return () => {
      if (hideDropdownTimer.current) {
        clearTimeout(hideDropdownTimer.current);
      }
    };
  }, []);

  // Close suggestions if user clicks outside the search area
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        desktopSearchRef.current &&
        !desktopSearchRef.current.contains(event.target) &&
        mobileSearchRef.current &&
        !mobileSearchRef.current.contains(event.target)
      ) {
        setSuggestions([]);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const navigateToCategory = (slug) => {
    if (slug === "/") {
      navigate("/");
    } else {
      navigate(`/category/${slug}`);
    }
  };

  const navigateToSubcategory = (categorySlug, subSlug) => {
    navigate(`/category/${subSlug}`);
  };

  const handleSearchChange = async (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    // If empty, clear suggestions
    if (value.trim().length === 0) {
      setSuggestions([]);
      return;
    }

    setLoading(true);
    try {
      const response = await searchItems(value);
      setSuggestions(response.success ? response.results.slice(0, 10) : []);
    } catch (error) {
      console.error("Error fetching search suggestions:", error);
      setSuggestions([]);
    } finally {
      setLoading(false);
    }
  };

  const handleSelectSuggestion = (suggestion) => {
    setSearchTerm("");
    setSuggestions([]);
    if (suggestion.isCategory) {
      navigate(`/category/${suggestion.slug}`);
    } else if (suggestion.isProduct) {
      navigate(`/product/${suggestion._id}`);
    }
  };

  const handleSearchSubmit = () => {
    if (searchTerm.trim()) {
      navigate(`/search?query=${encodeURIComponent(searchTerm.trim())}`);
      setSearchTerm("");
      setSuggestions([]);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearchSubmit();
    }
  };

  return (
    <>
      {/* Branding Section (Non-Sticky) */}
      <div className="bg-white shadow-md">
        <div className="flex flex-col items-center justify-between p-4 md:px-8">
          {/* Branding */}
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => navigate("/")}
          >
            {windowWidth < 768 ? (
              <>
                <img
                  src={logomobile}
                  alt="Wedding Logo"
                  className="h-20 self-start"
                />
              </>
            ) : (
              <div className="flex items-center justify-center">
                <img
                  src={logomobile}
                  alt="Wedding Marketplace Logo"
                  className="h-48"
                />
              </div>
            )}
          </div>

          {/* Desktop Search Bar */}
          <div
            ref={desktopSearchRef}
            className="hidden md:flex items-center self-end relative w-96"
          >
            <input
              type="text"
              value={searchTerm}
              onChange={handleSearchChange}
              onKeyDown={handleKeyDown}
              placeholder="Search listings..."
              style={{
                border: "1px solid #e5e5e5",
                padding: "0 16px",
                height: "40px",
                width: "100%",
                borderRadius: "8px",
                outline: "none",
                boxSizing: "border-box",
              }}
            />
            <button
              onClick={handleSearchSubmit}
              style={{
                backgroundColor: "#000",
                color: "#fff",
                padding: "0 16px",
                height: "40px",
                borderRadius: "8px",
                border: "none",
                cursor: "pointer",
              }}
            >
              <FaSearch className="w-5 h-5" />
            </button>

            {loading && (
              <div
                style={{
                  position: "absolute",
                  left: "0",
                  top: "100%",
                  width: "100%",
                  backgroundColor: "#fff",
                  border: "1px solid #e5e5e5",
                  borderRadius: "8px",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                  zIndex: "50",
                  marginTop: "4px",
                  padding: "8px 0",
                  textAlign: "center",
                  color: "gray",
                }}
              >
                Loading...
              </div>
            )}

            {suggestions.length > 0 && (
              <div
                style={{
                  position: "absolute",
                  left: "0",
                  top: "100%",
                  width: "100%",
                  backgroundColor: "#fff",
                  border: "1px solid #e5e5e5",
                  borderRadius: "8px",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                  zIndex: "999",
                  marginTop: "4px",
                }}
              >
                {suggestions.map((suggestion) => (
                  <div
                    key={suggestion._id}
                    style={{
                      padding: "10px 16px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => handleSelectSuggestion(suggestion)}
                  >
                    <span>{suggestion.name}</span>
                    {suggestion.isCategory ? (
                      <span style={{ fontSize: "14px", color: "#3b82f6" }}>
                        Category
                      </span>
                    ) : (
                      <span style={{ fontSize: "14px", color: "#10b981" }}>
                        Product
                      </span>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>

          {/* Mobile Search and Hamburger Icon */}
          <div className="md:hidden flex items-center space-x-4">
            <button
              onClick={() => setIsSearchOpen(!isSearchOpen)}
              className="text-gray-800 focus:outline-none"
            >
              {isSearchOpen ? (
                <FaTimes className="w-6 h-6" />
              ) : (
                <FaSearch className="w-6 h-6" />
              )}
            </button>
            <button
              onClick={toggleSidebar}
              className="text-gray-800 focus:outline-none"
            >
              {isSidebarOpen ? (
                <AiOutlineClose size={24} />
              ) : (
                <AiOutlineMenu size={24} />
              )}
            </button>
          </div>
        </div>

        {/* Mobile Search Bar Overlay */}
        <div
          ref={mobileSearchRef}
          className={`absolute top-full left-0 right-0 bg-white shadow-md transition-all duration-300 transform ${
            isSearchOpen ? "opacity-100 translate-y-0" : "opacity-0 -translate-y-2"
          } z-30 md:hidden`}
        >
          <div className="flex items-center px-4 py-2">
            <input
              type="text"
              value={searchTerm}
              onChange={handleSearchChange}
              onKeyDown={handleKeyDown}
              placeholder="Search listings..."
              style={{
                border: "1px solid #e5e5e5",
                padding: "0 16px",
                height: "40px",
                width: "100%",
                borderRadius: "8px",
                outline: "none",
                boxSizing: "border-box",
              }}
            />
            <button onClick={handleSearchSubmit}>
              <FaSearch className="w-6 h-6" />
            </button>
          </div>

          {loading && (
            <div
              style={{
                backgroundColor: "#fff",
                padding: "8px 0",
                textAlign: "center",
                color: "gray",
              }}
            >
              Loading...
            </div>
          )}

          {suggestions.length > 0 && (
            <div
              style={{
                backgroundColor: "#fff",
                border: "1px solid #e5e5e5",
                borderRadius: "8px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                marginTop: "4px",
              }}
            >
              {suggestions.map((suggestion) => (
                <div
                  key={suggestion._id}
                  style={{
                    padding: "10px 16px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => handleSelectSuggestion(suggestion)}
                >
                  <span>{suggestion.name}</span>
                  {suggestion.isCategory ? (
                    <span style={{ fontSize: "14px", color: "#3b82f6" }}>
                      Category
                    </span>
                  ) : (
                    <span style={{ fontSize: "14px", color: "#10b981" }}>
                      Product
                    </span>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      {/* Sticky Menu Items */}
      <div className="hidden md:flex justify-center space-x-4 py-3 bg-white shadow-md sticky top-0 z-50">
        {menuItems.map((item, index) => (
          <div
            key={index}
            className="relative group"
            onMouseEnter={() => {
              if (hideDropdownTimer.current) {
                clearTimeout(hideDropdownTimer.current);
                hideDropdownTimer.current = null;
              }
              setDropdownIndex(index);
            }}
            onMouseLeave={() => {
              hideDropdownTimer.current = setTimeout(() => {
                setDropdownIndex(null);
              }, 200);
            }}
          >
            <button
              className="text-gray-700 px-4 py-2 rounded-md text-sm font-medium hover:bg-gray-100 transition-all"
              onClick={() => navigateToCategory(item.slug)}
            >
              {item.name}
            </button>

            {item.subitems.length > 0 && dropdownIndex === index && (
              <div
                className="absolute top-full left-0 mt-1 w-48 bg-white rounded-md shadow-lg z-40"
                onMouseEnter={() => {
                  if (hideDropdownTimer.current) {
                    clearTimeout(hideDropdownTimer.current);
                    hideDropdownTimer.current = null;
                  }
                  setDropdownIndex(index);
                }}
                onMouseLeave={() => {
                  hideDropdownTimer.current = setTimeout(() => {
                    setDropdownIndex(null);
                  }, 200);
                }}
              >
                {item.subitems.map((subitem, subIndex) => (
                  <button
                    key={subIndex}
                    className="block px-4 py-2 text-gray-700 hover:bg-gray-200 transition-all w-full text-left"
                    onClick={() => navigateToSubcategory(item.slug, subitem.slug)}
                  >
                    {subitem.name}
                  </button>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>

      {/* Mobile Sidebar */}
      <div
        className={`fixed inset-y-0 left-0 bg-white w-64 transform ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-300 ease-in-out z-40 shadow-lg md:hidden`}
      >
        <div className="p-4 space-y-4">
          {menuItems.map((item, index) => (
            <div key={index}>
              <button
                className="text-gray-700 text-lg font-medium w-full text-left"
                onClick={() => {
                  setDropdownIndex(dropdownIndex === index ? null : index);
                  navigateToCategory(item.slug);
                }}
              >
                {item.name}
              </button>
              {item.subitems.length > 0 && dropdownIndex === index && (
                <div className="ml-4 mt-2 space-y-2">
                  {item.subitems.map((subitem, subIndex) => (
                    <button
                      key={subIndex}
                      className="block px-4 py-2 text-gray-700 hover:bg-gray-200 transition-all w-full text-left"
                      onClick={() =>
                        navigateToSubcategory(item.slug, subitem.slug)
                      }
                    >
                      {subitem.name}
                    </button>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>

      {isSidebarOpen && (
        <div
          className="fixed inset-0 bg-black opacity-50 z-30 md:hidden"
          onClick={toggleSidebar}
        />
      )}
    </>
  );
};

export default Navbar;
