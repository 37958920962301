import React from 'react';
import weddinglogo from '../../assets/weddinglogo.png';


export default function SellerAgreement() {
  return (
    <div className="bg-white">
      {/* Title Section */}
      <section className="max-w-7xl mx-auto py-16 px-4 text-gray-900">
        <h1 className="text-3xl font-bold mb-6">Seller Agreement</h1>
        
        {/* Content Section */}
        <h2 className="text-2xl font-semibold mb-4">Your Use of Our Services</h2>
        <p className="text-base sm:text-lg mb-6">
          We grant you a limited, non-exclusive, non-transferable and revocable license to use our Services. You agree that you will not violate any laws in connection with your use of the Services. This includes any local, state, federal and international laws that may apply to you.
        </p>
        <p className="text-base sm:text-lg mb-6">
          You are responsible for paying all fees that you owe to us.
        </p>
        <p className="text-base sm:text-lg mb-6">
          You agree not to attempt to obtain source code of the services. You agree to not interfere with or disrupt/harm our services.
        </p>
        <p className="text-base sm:text-lg mb-6">
          Violations of this policy may result in a range of actions, including any or all of the following: listing deletion, account suspension or legal action; if necessary.
        </p>
        <p className="text-base sm:text-lg mb-6">
          If you believe a User on our Site is violating these terms, please contact us.
        </p>

        <h2 className="text-2xl font-semibold mb-4">Purchase and Payment</h2>
        <p className="text-base sm:text-lg mb-6">
          Currently we support credit card and PayPal payments. These payments are processed by Stripe and PayPal, the most trusted and secure online processing payment software available.
        </p>
        <p className="text-base sm:text-lg mb-6">
          All sales on the platform are binding. The seller is obligated to complete the transaction with the buyer in a prompt manner unless an exceptional circumstance arises.
        </p>

        <h2 className="text-2xl font-semibold mb-4">Seller Commission</h2>
        <p className="text-base sm:text-lg mb-6">
          The seller of any item will pay us a commission on the total transaction amount received by the seller (including shipping costs) for any of the transactions made on our Site.
        </p>
        <p className="text-base sm:text-lg mb-6">
          Our commission will be deducted directly from your funds before the funds are deposited into your account.
        </p>

        <h2 className="text-2xl font-semibold mb-4">Seller’s Responsibilities</h2>
        <p className="text-base sm:text-lg mb-6">
          Sellers will properly manage and ensure that relevant information such as the price and the details of items, inventory amount and will not post inaccurate or misleading information.
        </p>
        <p className="text-base sm:text-lg mb-6">
          The pricing of items for sale are determined by the Seller at his/her own discretion. The price of an item and shipping charges will include any additional charges such as sales tax. Sellers will not charge Buyer such amounts additionally and separately.
        </p>
        <p className="text-base sm:text-lg mb-6">
          Sellers are obligated to deliver items as described in a prompt manner unless there is an exceptional circumstance. If there are any exceptional circumstance the seller is obliged to contact the Buyer to inform them of any delays or inability to complete the transaction.
        </p>
        <p className="text-base sm:text-lg mb-6">
          Sellers will issue additional receipts, credit card slips or tax invoices to Buyer on request.
        </p>
      </section>

      <div className="flex justify-center items-center my-10">
        <div className="w-full border-2 border-t border-gray-300 mx-5" />
        <img src={weddinglogo} alt="" className='w-40 h-40' />
        <div className="w-full border-2 border-t border-gray-300 mx-5" />
      </div>
    </div>
  );
}
