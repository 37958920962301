import React from "react";
import hero from "../assets/hero.jpeg";
import { Link, useNavigate } from "react-router-dom";

export default function HeroSection() {
  const navigate = useNavigate();

  const handleStartSelling = () => {
    const token = localStorage.getItem("twmToken");
    if (token) {
      navigate("/admin/new-product");
    } else {
      navigate("/signup");
    }
  };

  return (
    <div
      className="relative bg-cover bg-center h-[70vh] flex flex-col justify-center mb-10 items-center text-white"
      style={{
        backgroundImage: `url(${hero})`,
      }}
    >
      {/* Overlay */}
      <div className="absolute inset-0 bg-black opacity-50"></div>

      {/* Main Content */}
      <div className="relative z-10 text-center">
        {/* Main Heading */}
        <h1 className="text-3xl md:text-5xl font-bold text-center leading-tight font-Brittany">
          Your Dream Wedding Starts Here.
          <br />
        </h1>
        <h1 className="font-popins font-light text-3xl"> Buy. Sell. Celebrate!</h1>

        {/* Subheading */}
        <p className="mt-4 text-lg md:text-2xl text-center">
          List your wedding items for free
        </p>

        {/* Buttons */}
        <div className="mt-6 flex space-x-4 justify-center">
          <Link
            to={"/products"}
            className="bg-[#333333] hover:bg-gray-900 text-white py-3 px-6 rounded-md text-sm md:text-lg font-semibold"
          >
            START BUYING
          </Link>
          <button
            onClick={handleStartSelling}
            className="bg-[#333333] hover:bg-gray-900 text-white py-3 px-6 rounded-md text-sm md:text-lg font-semibold"
          >
            START SELLING
          </button>
        </div>

      </div>
    </div>
  );
}
