import React from "react";
import { Routes, Route } from "react-router-dom";
import AdminLayout from "./AdminLayout";
import AdminHome from "./pages/Admin/AdminHome";
import ProductManagement from "./pages/Admin/ProductManagement";
import Orders from "./pages/Admin/Orders";
import NewProduct from "./pages/Admin/NewProduct";
import OrderDetail from "./pages/Admin/OrderDetail";
import Users from "./pages/Admin/Users";
import Payments from "./pages/Admin/Payments";
import Categories from "./pages/Admin/Categories";
import Tags from "./pages/Admin/Tags";
import Questionares from "./pages/Admin/Questionares";
import Vouchers from "./pages/Admin/Vouchers";
import Offers from './pages/Admin/Offers'
import Payouts from "./pages/Admin/Payouts";
import AccountSuccess from "./pages/Admin/AccountSuccess";
import Refund from "./pages/Admin/Refund";

function AdminRoutes() {
  // Check the user's role from localStorage
  const user = JSON.parse(localStorage.getItem("twmUser"));
  const isAdmin = user?.role === "admin";

  return (
    <Routes>
      <Route path="/" element={<AdminLayout />}>
        <Route index element={<AdminHome />} />
        <Route path="products" element={<ProductManagement />} />
        <Route path="Offers" element={<Offers />} />
        <Route path="orders" element={<Orders />} />
        <Route path="new-product" element={<NewProduct />} />

        <Route path="update-product/:id" element={<NewProduct />} />
        <Route path="order-detail/:id" element={<OrderDetail />} />
        <Route path="questions" element={<Questionares />} />
        <Route path="payouts" element={<Payouts />} />
        <Route path="account-setup-success" element={<AccountSuccess />} />

        {/* Conditionally render admin-only routes */}
        {isAdmin && (
          <>
            <Route path="users" element={<Users />} />
            <Route path="payments" element={<Payments />} />
            <Route path="categories" element={<Categories />} />
            <Route path="tags" element={<Tags />} />
            <Route path="vouchers" element={<Vouchers />} />
            <Route path="refund" element={<Refund />} />
          </>
        )}
      </Route>
    </Routes>
  );
}

export default AdminRoutes;
