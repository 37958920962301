import React from 'react';
import { useParams } from 'react-router-dom';
import OrderManagement from '../components/Accounts/OrderManagement';

export default function MyOrders() {
  const { activetab } = useParams(); // Get the activetab value from URL params

  return (
    <>
      <OrderManagement activeTabParam={activetab} />
    </>
  );
}
